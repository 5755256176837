import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';
import bg1 from '../../assets/media/images/bg1.png';
import moment from 'moment/moment';
import { currencyFomater } from '../../helpers/currencyFomater';

const DashRevenueCard = ({ bdColor, title, amount }) => {
    return (
        <>
            <Box
                sx={{
                    backgroundImage: `url(${bg1})`,
                    borderRadius: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        py: 2,
                        borderRadius: 2,
                        background: `rgba(125, 98, 212, .8);`,
                    }}
                >
                    <Box
                        sx={{
                            height: 100,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: bdColor,
                                height: 110,
                                width: 0,
                                borderRight: `10px solid ${bdColor}`,
                                borderTopRightRadius: 15,
                                borderBottomRightRadius: 15,
                                position: 'absolute',
                                left: 0,
                            }}
                        ></Box>
                        <Box sx={{ ml: 2 }}>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: colors.bgColor1,
                                    mt: 0.8,
                                }}
                            >
                                {title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    fontWeight: 'bold',
                                    color: colors.bgColor1,
                                    mt: 0.8,
                                }}
                            >
                                TZS {currencyFomater(amount)}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 11,
                                    color: colors.bgColor1,
                                    mt: 0.8,
                                    fontStyle: 'italic',
                                }}
                            >
                                As of{' '}
                                {moment().format('MMMM Do YYYY, h:mm:ss a')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default DashRevenueCard;
