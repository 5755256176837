import React from 'react';
import {
    Category,
    LocationOn,
    Unpublished,
    Verified,
} from '@mui/icons-material';
import { Box, Chip, Rating, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';

const StoreWidget2 = ({ store }) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyItems: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        backgroundImage: `url(${store.store_image})`,
                        backgroundSize: `190 100%`,
                        overflow: 'hidden',
                        transition: '.3s all ease-in-out',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            height: 80,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            bgcolor: `rgba(125, 98, 212, .6)`,
                            px: 2,
                        }}
                    >
                        <Box
                            sx={{
                                height: 50,
                                bgcolor: `rgba(255, 255, 255, .4)`,
                                width: '50%',
                                borderRadius: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: 11,
                                    fontWeight: 'bold',
                                }}
                            >
                                <LocationOn color="primary" sx={{ mx: 0.5 }} />
                                {store.location_name}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                height: 50,
                                bgcolor: `rgba(255, 255, 255, .4)`,
                                width: `20%`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                borderRadius: 2,
                            }}
                        >
                            {store.isApproved ? (
                                <Chip
                                    label="Approved"
                                    color="success"
                                    variant="contained"
                                    size="small"
                                    sx={{ fontSize: 10, fontWeight: 'bold' }}
                                />
                            ) : (
                                <Chip
                                    label="Not approved"
                                    color="error"
                                    variant="contained"
                                    size="small"
                                    sx={{ fontSize: 10, fontWeight: 'bold' }}
                                />
                            )}

                            {store.isSuspended ? (
                                <Chip
                                    label="Suspended"
                                    color="warning"
                                    variant="contained"
                                    size="small"
                                    sx={{ fontSize: 10, fontWeight: 'bold' }}
                                />
                            ) : store.isActive ? (
                                <Chip
                                    label="Active"
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    sx={{ fontSize: 10, fontWeight: 'bold' }}
                                />
                            ) : (
                                <Chip
                                    label="In-active"
                                    color="error"
                                    variant="contained"
                                    size="small"
                                    sx={{ fontSize: 10, fontWeight: 'bold' }}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            height: 80,
                            background: `rgb(125, 98, 212, .9)`,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            p: 1.5,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                 sx={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    color: colors.gray,
                                    textTransform: 'uppercase',
                                }}
                            >
                                {store.title}
                            </Typography>
                            {store.verification_badge ? (
                                <Verified
                                    color="primary"
                                    sx={{
                                        bgcolor: colors.bgColor3,
                                        p: 0.4,
                                        borderRadius: 50,
                                        mb: 0.3,
                                    }}
                                />
                            ) : (
                                <Unpublished
                                    color="warning"
                                    sx={{
                                        bgcolor: colors.bgColor3,
                                        p: 0.4,
                                        borderRadius: 50,
                                        mb: 0.3,
                                    }}
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    color: colors.gray,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Category sx={{ mr: 1 }} />
                                {store.category_name}
                            </Typography>
                            {store.rating && (
                                <Rating
                                    sx={{ fontSize: 18 }}
                                    value={store.rating}
                                    readOnly
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default StoreWidget2;
