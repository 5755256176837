import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import DeliveryGuysSidebar from "../../../components/sidebars/DeliveryGuysSidebar";
import DeliveryGuyWidget from "../../../components/widgets/DeliveryGuyWidget";
import PageLayout from "../../layouts/PageLayout";
import CustomPaginationTwo from "../../../components/paginations/CustomPaginationTwo";
import { deliveryGuysSelector } from "../../../state/features/selectors";
import { useSelector } from "react-redux";
import { isEmpty, size } from "lodash";
import CountBadge from "../../../components/CountBadge";
import NoContent from "../../../components/NoContent";
import DeliveryGuysFilter from "../../../components/filters/DeliveryGuysFilter";
import { paginator } from "../../../helpers/paginationHelper";

const DeliveryGuys = () => {
    // ############### Component state ###################
    const [deliveryGuys, setDeliveryGuys] = useState([]);
     const [page, setPage] = React.useState(1);
     const [perPage, setPerPage] = React.useState(25);

    // ######### Redux states #############
    const { deliveryGuys: alldDeliveryGuys } =
        useSelector(deliveryGuysSelector);

    // ############## FILTER ###################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Filter by name/id
    const filterByNameOrID = (search) => {
        let deliveryGuys = [];
        if (search) {
            deliveryGuys = alldDeliveryGuys.filter((delivery_guy) => {
                return (
                    delivery_guy.registration_number
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    delivery_guy.first_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    delivery_guy.last_name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                );
            });
            return setDeliveryGuys(deliveryGuys);
        }
        return setDeliveryGuys(alldDeliveryGuys);
    };

    // ############## useEffect ################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setDeliveryGuys(alldDeliveryGuys);
        }
        return () => {
            isSubscribed = false;
        };
    }, [setDeliveryGuys, alldDeliveryGuys]);
    const paginatedDeliveryGuys = paginator(deliveryGuys, page, perPage);

    return (
        <>
            <PageLayout
                sidebar={<DeliveryGuysSidebar />}
                pageHead={"Agiza africa delivery guys"}
            >
                <CustomPaginationTwo
                    title={"Registered delivery guys"}
                    data={paginatedDeliveryGuys}
                    handlePageChange={handlePageChange}
                    handlePerPageChage={handlePerPageChage}
                    action={
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: 400,
                            }}
                        >
                            <DeliveryGuysFilter
                                filterByNameOrID={filterByNameOrID}
                            />
                            <CountBadge
                                total={size(deliveryGuys)}
                                item={"Delivery guys"}
                            />
                        </Box>
                    }
                >
                    {isEmpty(deliveryGuys) ? (
                        <>
                            <NoContent message={"No delivery guy added"} />
                        </>
                    ) : (
                        <Grid container spacing={3} padding={2}>
                            {deliveryGuys?.map((delivery_guy) => (
                                <Grid item sm={4} key={delivery_guy._id}>
                                    <DeliveryGuyWidget
                                        deliveryGuy={delivery_guy}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </CustomPaginationTwo>
            </PageLayout>
        </>
    );
};

export default DeliveryGuys;
