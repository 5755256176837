import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../assets/utils/colors";
import { ArrowCircleRightOutlined, PictureAsPdf } from "@mui/icons-material";
import ReportDialog from "../dialogs/ReportDialog";
import { useSelector } from "react-redux";
import { vendorsSelector } from "../../state/features/selectors";
import ReportLayout from "../../pages/layouts/ReportLayout";
import VendorReport from "../../pages/reports/VendorReport";

const StatsAndReportSidebar = () => {
    // ############### Component state ###################
    const [reportDialogOpen, setReportDialogOpen] = useState(false);
    const [reportType, setReportType] = useState("");

    // ############### Redux state ########################
    const { vendors } = useSelector(vendorsSelector);
    // => Handle dialog open
    const handleDialogOpen = (reportType) => {
        setReportType(reportType);
        setReportDialogOpen(true);
    };
    // => Handle dialog close
    const handleDialogClose = () => {
        setReportDialogOpen(false);
    };

    const reports = [
        {
            id: 1,
            type: "vendors",
            title: "Vendors report",
        },
        {
            id: 2,
            type: "customers",
            title: "Customers report",
        },
        {
            id: 3,
            type: "stores",
            title: "Stores report",
        },
        {
            id: 4,
            type: "orders",
            title: "Orders report",
        },
        {
            id: 5,
            type: "categories",
            title: "Categories report",
        },
        {
            id: 6,
            type: "finance",
            title: "Finance report",
        },
    ];
    return (
        <>
            <ReportDialog
                head={`${reportType} report`}
                customWidth={"md"}
                openDialog={reportDialogOpen}
                handleDialogClose={handleDialogClose}
            >
                <ReportLayout head={`${reportType} report`}>
                    {/* <VendorReport data={vendors} /> */}
                </ReportLayout>
            </ReportDialog>
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        flexDirection: "column",
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            color: colors.primary,
                        }}
                    >
                        Reports generate
                    </Typography>
                </Box>
                <Box sx={{ flex: 1, p: 1 }}>
                    <Grid container gap={2}>
                        {reports.map((report) => (
                            <Grid item sm={12} key={report.id}>
                                <Button
                                    sx={{
                                        p: `0px !important`,
                                        borderRadius: 2,
                                        " .arrow": {
                                            right: 20,
                                            position: "absolute",
                                            transition: `.3s all ease-in-out`,
                                        },
                                        ":hover": {
                                            " .arrow": {
                                                right: 10,
                                                transition: `.3s all ease-in-out`,
                                            },
                                        },
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    onClick={() => {
                                        handleDialogOpen(report.type);
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: 50,
                                            display: "flex",
                                            alignItems: "center",
                                            p: 2,
                                            position: "relative",
                                            width: "100%",
                                        }}
                                    >
                                        <PictureAsPdf
                                            sx={{ mr: 2, fontSize: 20 }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                textTransform: "capitalize",
                                                color: colors.primary,
                                            }}
                                        >
                                            {report.title}
                                        </Typography>
                                        <ArrowCircleRightOutlined className="arrow" />
                                    </Box>
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default StatsAndReportSidebar;
