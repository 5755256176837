import { createSlice } from '@reduxjs/toolkit';

// => initialState
const initialState = {
    admins: [],
};

export const adminsSlice = createSlice({
    name: 'adminsSlice',
    initialState,
    reducers: {
        setAdmins: (state, actions) => {
            let admins = actions.payload.data;
            state.admins = admins;
        },
        addAdmin: (state, action) => {
            let admin = action.payload;
            state.admins = [...state.admins, admin];
        },
    },
});

export const { setAdmins, addAdmin } = adminsSlice.actions;
export default adminsSlice.reducer;
