import React from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../../../assets/utils/colors';
import CustomSubmitButton from '../../../../components/forms/CustomSubmitButton';
import CustomMultlineTextField from '../../../../components/forms/CustomMultlineTextField';
import { Formik } from 'formik';

const DenyDocument = ({ document, type }) => {
    return (
        <>
            <Box
                sx={{
                    p: 2,
                    borderRadius: 2,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 22,
                            color: colors.red,
                            fontWeight: 'bold',
                            opacity: 0.8,
                        }}
                    >
                        Store {document} document deny
                    </Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: 14, opacity: 0.8 }}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Reiciendis labore illo aspernatur aliquid?
                    </Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Formik initialValues={{ deny_reason: '' }}>
                        {(formik) => (
                            <Box sx={{ width: '100%' }}>
                                <CustomMultlineTextField
                                    name={'deny_reason'}
                                    rows={4}
                                    label={'Provide deny reason'}
                                />
                            </Box>
                        )}
                    </Formik>
                </Box>
                <Box sx={{ width: '50%', my: 4 }}>
                    <CustomSubmitButton color={'error'}>
                        Deny document
                    </CustomSubmitButton>
                </Box>
            </Box>
        </>
    );
};

export default DenyDocument;
