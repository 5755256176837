// => Create promotion
const CREATE_PROMOTION = '/promotions';

// => Get all promotions
const GET_ALL_PROMOTIONS = '/promotions';

// => Update promotion
const UPDATE_PROMOTION = '/promotions';

// => Update promotion
const ACTIVATE_PROMOTION = '/promotions/activate';

// => Delete promotion
const DELETE_PROMOTION = '/promotions';

export const promotionsEndpoints = {
    CREATE_PROMOTION,
    ACTIVATE_PROMOTION,
    GET_ALL_PROMOTIONS,
    UPDATE_PROMOTION,
    DELETE_PROMOTION,
};
