import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';

// ############# CARD ###############
const Card = styled(Box)(({ theme }) => ({
    background: 'red',
    borderRadius: 15,
    // padding: 5,
    overflow: 'hidden',
    position: 'relative',
}));

// ############# CARD HEADER ###############
const CardHeader = styled(Box)(({ theme }) => ({
    background: colors.bgColor1,
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 10,
}));

// ############# CARD BODY ###############
const CardBody = styled(Box)(({ theme }) => ({
    padding: 5,
}));

const CustomCard = ({ height, bgColor, children, action, title }) => {
    return (
        <>
            <Card
                sx={{
                    height: height,
                    bgcolor: bgColor ? bgColor : colors.bgColor3,
                }}
            >
                {/* ==== header ===== */}
                <CardHeader sx={{ display: title ? 'flex' : 'none' }}>
                    <Box>
                        <Typography
                            sx={{ fontSize: 14, fontWeight: 'bold' }}
                            className="head-font"
                        >
                            {title}
                        </Typography>
                    </Box>
                    <Box>{action}</Box>
                </CardHeader>

                {/* ==== Body ===== */}
                <CardBody sx={{ height: height, overflow: 'auto',}}>
                    {children}
                </CardBody>
            </Card>
        </>
    );
};

export default CustomCard;
