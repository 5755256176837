import { Box, TextField } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';

const CustomDateFilter = ({ width, label, type, value, handleFilter }) => {
    return (
        <>
            <Box
                sx={{
                    borderRadius: 2,
                    border: `2px solid ${colors.bgColor2}`,
                    width: width ? width : 250,
                }}
            >
                <TextField
                    fullWidth
                    type={type ? type : 'text'}
                    variant="outlined"
                    value={value}
                    size="small"
                    placeholder={label}
                    onChange={(e) => handleFilter(e.target.value)}
                    sx={{
                        '&.MuiFormControl-root': {
                            bgcolor: colors.bgColor1,
                        },
                        '& input': {
                            fontSize: 14,
                        },
                        '& .MuiInputBase-root': {
                            height: 35,
                        },
                    }}
                />
            </Box>
        </>
    );
};

export default CustomDateFilter;
