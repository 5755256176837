import { Box } from '@mui/material';
import React from 'react';
import CustomTextFieldFilter from '../forms/CustomTextFieldFilter';

const DeliveryGuysFilter = ({ filterByNameOrID }) => {
    return (
        <>
            <Box
                sx={{
                    height: 50,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: 4,
                }}
            >
                <Box>
                    <CustomTextFieldFilter
                        label={'DeliveryGuyID / Name'}
                        handleFilter={filterByNameOrID}
                    />
                </Box>
            </Box>
        </>
    );
};

export default DeliveryGuysFilter;
