import React from 'react';
import OrderedProductTable from '../../../../components/tables/OrderedProductTable';
import { Box, Typography } from '@mui/material';
import { colors } from '../../../../assets/utils/colors';

const OrderedProducts = ({ products }) => {
    return (
        <>
            <Box>
                <Box
                    sx={{
                        borderBottom: `2px solid ${colors.bgColor1}`,
                        pb: 1,
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            opacity: 0.8,
                        }}
                    >
                        Ordered products
                    </Typography>
                </Box>
                <Box
                    sx={{
                        height: { md: 535, xs: '100%' },
                        overflow: 'auto',
                        mt: 2,
                    }}
                >
                    <OrderedProductTable products={products} />
                </Box>
            </Box>
        </>
    );
};

export default OrderedProducts;
