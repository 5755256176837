import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import DashMainStoresCard from "./DashMainStoresCard";
import DashStoresStatsCard from "./DashStoresStatsCard";
import { filter, size } from "lodash";

const DashStoresStats = ({ stores }) => {
    return (
        <>
            <Typography className="head-font section-head">Stores</Typography>
            <Box
                sx={{
                    height: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <DashMainStoresCard stores={stores} />
            </Box>
            <Box sx={{ mt: 4 }}>
                <Typography className="head-font section-head">
                    Store Statistics
                </Typography>
                <Grid container spacing={3}>
                    <Grid item sm={6} xs={6}>
                        <DashStoresStatsCard
                            title={"Approved stores"}
                            total={size(
                                filter(
                                    stores,
                                    ({ isApproved }) => isApproved === true
                                )
                            )}
                            bdColor={colors.green}
                        />
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <DashStoresStatsCard
                            title={"Active stores"}
                            total={size(
                                filter(
                                    stores,
                                    ({ isActive }) => isActive === true
                                )
                            )}
                            bdColor={colors.green}
                        />
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <DashStoresStatsCard
                            title={"Inactive stores"}
                            total={size(
                                filter(
                                    stores,
                                    ({ isActive }) => isActive === false
                                )
                            )}
                            bdColor={colors.yellow}
                        />
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <DashStoresStatsCard
                            title={"Denied stores"}
                            total={size(
                                filter(
                                    stores,
                                    ({ isDenied }) => isDenied === true
                                )
                            )}
                            bdColor={colors.red}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default DashStoresStats;
