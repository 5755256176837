import { baseURL } from "./baseUrl/baseURL";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { orderEndponts } from "./endpoints/ordersEndpoints";
import { headers } from "./baseUrl/setHeaders";

// !############## API ################
export const orderAPI = createApi({
    reducerPath: "orderAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ["Orders", "Order"],
    endpoints: (builder) => ({
        // => GET all orders
        getAllOrders: builder.query({
            query: () => `${orderEndponts.GET_ALL_ORDERS}`,
            providesTags: ["Orders"],
        }),

        // => GET order by id
        getOrderById: builder.query({
            query: (payload) => `${orderEndponts.GET_SINGLE_ORDER}/` + payload,
            providesTags: ["Order"],
            invalidatesTags: ["Order"],
        }),

        // => SHIP order
        shipOrder: builder.mutation({
            query: (payload) => ({
                url: `${orderEndponts.SHIP_ORDER}/` + payload.order_id,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: ["Orders"],
        }),

        // => Create POS order
        createPOSOrder: builder.mutation({
            query: (payload) => ({
                url: `${orderEndponts.CREATE_POS_ORDER}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ["Orders"],
        }),

        // => Cash order order
        cashOrderPayment: builder.mutation({
            query: (payload) => ({
                url: `${orderEndponts.CASH_PAYMENT}/` + payload,
                method: `PUT`,
            }),
            invalidatesTags: ["Orders"],
        }),

        // => GET delivery pin
        getDeliveryPin: builder.query({
            query: (payload) => `${orderEndponts.GET_DELIVERY_PIN}/` + payload,
        }),
    }),
});

export const {
    useGetAllOrdersQuery,
    useGetOrderByIdQuery,
    useShipOrderMutation,
    useCreatePOSOrderMutation,
    useCashOrderPaymentMutation,
    useGetDeliveryPinQuery,
} = orderAPI;
