import React, { useEffect, useState } from "react";
import { categoriesSelector } from "../../../../state/features/selectors";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Avatar, Box, Grid, MenuItem, Typography } from "@mui/material";
import CustomSelect from "../../../../components/forms/CustomSelect";
import CustomSubmitButton from "../../../../components/forms/CustomSubmitButton";
import { colors } from "../../../../assets/utils/colors";
import logo1 from "../../../../assets/media/images/logo1.png";
import { filter } from "lodash";
import { setProductDetails } from "../../../../state/features/products/productSlice";
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from "../../../../helpers/RTKHelpers";
import { errorNotify, successNotify } from "../../../../helpers/notify";
import { useAssignSubCategoryMutation } from "../../../../api/productAPI";
import * as Yup from "yup";
import SuccessFeedback from "../../../../components/SuccessFeedback";

// ############### VALIDATION ###############
const assignSubCategoryValidationSchema = Yup.object({
    sub_category_id: Yup.string().required("sub-category is a required field"),
});

const AssignSubCategory = ({ store, product }) => {
    // ############## Comp State ################
    const [subCategories, setSubCategories] = useState([]);

    // ############## Redux state ###############
    const { categories } = useSelector(categoriesSelector);

    // ############ FUNC #############
    const getStoreSubCategories = () => {
        const category = filter(
            categories,
            ({ _id }) => _id === store.category_id
        );
        setSubCategories(category[0]?.sub_categories);
    };

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getStoreSubCategories();
        }
        return () => {
            isSubscribed = false;
        };
    }, [getStoreSubCategories]);

    // ############## CONST ##############
    const dispatch = useDispatch();

    // ############## RTK ################
    // => Feature product
    const [
        assignCategory,
        { isLoading, isSuccess, isError, error, data: productDetails },
    ] = useAssignSubCategoryMutation();

    // ############### useEffect ###########
    // => Featured
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                successNotify(getRTKSuccessMessage(productDetails));
                dispatch(setProductDetails(getRTKResponseData(productDetails)));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, error, productDetails, dispatch]);

    return (
        <div>
            {isSuccess ? (
                <>
                    <SuccessFeedback message={productDetails.message} />
                </>
            ) : (
                <Box className="form-section">
                    <Formik
                        enableReinitialize
                        initialValues={{
                            category_id: store.category_id,
                            sub_category_id: product?.sub_category_id?._id,
                        }}
                        validationSchema={assignSubCategoryValidationSchema}
                        onSubmit={(payload) => {
                            let newPayload = {
                                product_id: product._id,
                                sub_category_id: payload.sub_category_id,
                            };
                            assignCategory(newPayload);
                        }}
                    >
                        {(formik) => (
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} sm={10}>
                                    <Box className="input-con">
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Avatar
                                                src={logo1}
                                                alt="Agiza logo"
                                                sx={{
                                                    width: 160,
                                                    height: 66,
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: 22,
                                                        color: colors.primary,
                                                        fontWeight: "bold",
                                                        opacity: 0.8,
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {product.title} sub category
                                                    Update
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Grid
                                                    container
                                                    columnSpacing={2}
                                                >
                                                    <Grid item sm={12} xs={12}>
                                                        <CustomSelect
                                                            name="category_id"
                                                            formik={formik}
                                                            label={
                                                                "Main category"
                                                            }
                                                            readOnly
                                                        >
                                                            {categories?.map(
                                                                (category) => (
                                                                    <MenuItem
                                                                        key={
                                                                            category._id
                                                                        }
                                                                        value={
                                                                            category._id
                                                                        }
                                                                    >
                                                                        {
                                                                            category.category_name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </CustomSelect>
                                                    </Grid>
                                                    <Grid item sm={12} xs={12}>
                                                        <CustomSelect
                                                            name="sub_category_id"
                                                            formik={formik}
                                                            label={
                                                                "Sub category"
                                                            }
                                                            // readOnly
                                                        >
                                                            {subCategories?.map(
                                                                (
                                                                    sub_category
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            sub_category._id
                                                                        }
                                                                        value={
                                                                            sub_category._id
                                                                        }
                                                                    >
                                                                        {
                                                                            sub_category.title
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </CustomSelect>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                        <Box sx={{ mt: 5 }}>
                                            <CustomSubmitButton
                                                loading={isLoading}
                                                onClick={formik.handleSubmit}
                                                btnColor={"info"}
                                            >
                                                Update product category
                                            </CustomSubmitButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                </Box>
            )}
        </div>
    );
};

export default AssignSubCategory;
