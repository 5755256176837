// GET all admins
const GET_ALL_ADMINS = '/admins';

// CREATE admin
const CREATE_ADMIN = '/admins';

// GET single admin
const GET_SINGLE_ADMIN = '/delivery-guys';

export const adminsEndpoints = {
    GET_ALL_ADMINS,
    CREATE_ADMIN,
    GET_SINGLE_ADMIN
};
