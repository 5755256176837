import { Box, Grid } from "@mui/material";
import React from "react";
import ComposeNotification from "./CRUD/ComposeNotification";
import PageLayout from "../layouts/PageLayout";
import CustomCard2 from "../../components/cards/CustomCard2";
import { colors } from "../../assets/utils/colors";
import NotificationWidget from "../../components/widgets/NotificationWidget";
import CountBadge from "../../components/CountBadge";
import { useSelector } from "react-redux";
import { notificationsSelector } from "../../state/features/selectors";
import { isEmpty, size } from "lodash";
import NoContent from "../../components/NoContent";
import NotificationsSidebar from "../../components/sidebars/NotificationsSidebar";

const Notification = () => {
    // ############ Redux State ###########
    const { notificationHistory } = useSelector(notificationsSelector);

    return (
        <>
            <PageLayout
                sidebar={<NotificationsSidebar />}
                pageHead={"Agiza africa notifications"}
            >
                <Grid
                    container
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                >
                    {/* ######### PAGE CONTENT ############# */}
                    <Grid
                        item
                        sm={5}
                        sx={{
                            height: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                height: "100%",
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                            }}
                        >
                            <CustomCard2
                                title={"Notification history"}
                                height={"100%"}
                                action={
                                    <CountBadge
                                        total={size(notificationHistory)}
                                        item={"Notification's"}
                                    />
                                }
                            >
                                <Grid container spacing={2} paddingY={2}>
                                    {isEmpty(notificationHistory) ? (
                                        <>
                                            <NoContent
                                                message={
                                                    "No notification history"
                                                }
                                                height={550}
                                            />
                                        </>
                                    ) : (
                                        notificationHistory.map((index) => (
                                            <Grid key={index} item sm={12}>
                                                <NotificationWidget />
                                            </Grid>
                                        ))
                                    )}
                                </Grid>
                            </CustomCard2>
                        </Box>
                    </Grid>
                    <Grid item sm={7}>
                        <Box>
                            <ComposeNotification />
                        </Box>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Notification;
