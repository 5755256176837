import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CustomDateFilter from '../forms/CustomDateFilter';

const FinanceOrderFilter = ({
    byDateFrom,
    byDateTo,
    filterByDateFrom,
    filterByDateTo,
    resetFilter,
}) => {
    return (
        <>
            <Box
                sx={{
                    height: `100%`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                    <IconButton sx={{ cursor: 'default' }} color="info">
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                            From
                        </Typography>
                    </IconButton>
                    <CustomDateFilter
                        value={byDateFrom}
                        width={150}
                        type={'date'}
                        handleFilter={filterByDateFrom}
                    />
                </Box>
                <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                    <IconButton sx={{ cursor: 'default' }} color="info">
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                            To
                        </Typography>
                    </IconButton>
                    <CustomDateFilter
                        value={byDateTo}
                        width={150}
                        type={'date'}
                        handleFilter={filterByDateTo}
                    />
                </Box>
                <IconButton color="info" onClick={() => resetFilter()}>
                    <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                        Reset
                    </Typography>
                </IconButton>
            </Box>
        </>
    );
};

export default FinanceOrderFilter;
