import React from "react";
import {
    Avatar,
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    styled,
} from "@mui/material";
import { Formik } from "formik";
import CustomTextField from "../../../../components/forms/CustomTextField";
import logo1 from "../../../../assets/media/images/logo1.png";
import CustomSubmitButton from "../../../../components/forms/CustomSubmitButton";
import * as Yup from "yup";
import { colors } from "../../../../assets/utils/colors";
import { PrintOutlined } from "@mui/icons-material";
// import { setNotifications } from "../../../../state/features/notifications/notificationsSlice";

// ############## MUI styled #################
const ContentSection = styled(Box)(({ theme }) => ({
    height: "80vh",
    borderRadius: 5,
    overflow: "hidden",
    boxShadow: "-1px 1px 2px 2px rgba(125, 98, 212, .1)",
    "& .form-section": {
        height: "80vh",
        background: colors.bgColor1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .input-sign-head": {
            fontWeight: "bold",
            color: colors.primary,
        },
        "& .input-con": {
            height: 400,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
        },
    },
    [theme.breakpoints.down("md")]: {},
}));

// ############### VALIDATION ###############
const generateShippingCoverValidationSchema = Yup.object({
    delivery_company: Yup.string().required("delivery company is a required"),
    delivery_date: Yup.string().required("delivery date is a required"),
});

const GenerateShippingCover = ({ handler }) => {
    const pdfSize = [
        {
            id: 1,
            value: "large",
            label: "large",
        },
        {
            id: 2,
            value: "medium",
            label: "medium",
        },
        {
            id: 3,
            value: "small",
            label: "small",
        },
    ];
    return (
        <>
            <ContentSection>
                <Box className="form-section">
                    <Formik
                        initialValues={{
                            delivery_company: "",
                            delivery_date: "",
                            pdf_size: "large",
                        }}
                        validationSchema={generateShippingCoverValidationSchema}
                        onSubmit={(payload) => {
                            handler(payload);
                        }}
                    >
                        {(formik) => (
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} sm={10}>
                                    <Box className="input-con">
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Avatar
                                                src={logo1}
                                                alt="Agiza logo"
                                                sx={{
                                                    width: 200,
                                                    height: 80,
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography className="input-sign-head">
                                                Delivery details
                                            </Typography>
                                            <Box>
                                                <Grid
                                                    container
                                                    columnSpacing={2}
                                                >
                                                    <Grid item sm={12}>
                                                        <CustomTextField
                                                            type="text"
                                                            name="delivery_company"
                                                            label={
                                                                "Delivery company"
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12}>
                                                        <CustomTextField
                                                            type="date"
                                                            name="delivery_date"
                                                            label={
                                                                "Delivery date"
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        sm={12}
                                                        sx={{ mt: 2 }}
                                                    >
                                                        <FormControl>
                                                            <FormLabel>
                                                                Select PDF Size
                                                            </FormLabel>
                                                            <RadioGroup
                                                                row
                                                                name="pdf_size"
                                                                value={
                                                                    formik
                                                                        .values[
                                                                        "pdf_size"
                                                                    ]
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    formik.setFieldValue(
                                                                        "pdf_size",
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                sx={{
                                                                    fontSize: 10,
                                                                }}
                                                            >
                                                                {pdfSize.map(
                                                                    (radio) => (
                                                                        <FormControlLabel
                                                                            key={
                                                                                radio.id
                                                                            }
                                                                            value={
                                                                                radio.value
                                                                            }
                                                                            control={
                                                                                <Radio />
                                                                            }
                                                                            label={
                                                                                radio.label
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                        <Box sx={{}}>
                                            <CustomSubmitButton
                                                startIcon={<PrintOutlined />}
                                                onClick={formik.handleSubmit}
                                            >
                                                Generate PDF
                                            </CustomSubmitButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                </Box>
            </ContentSection>
        </>
    );
};

export default GenerateShippingCover;
