import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Grid, MenuItem, Typography } from '@mui/material';
import { Formik } from 'formik';
import CustomTextField from '../../../components/forms/CustomTextField';
import logo1 from '../../../assets/media/images/logo1.png';
import CustomMultlineTextField from '../../../components/forms/CustomMultlineTextField';
import CustomSubmitButton from '../../../components/forms/CustomSubmitButton';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { errorNotify, successNotify } from '../../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../helpers/RTKHelpers';
import { useCreateSubCategoryMutation, useUpdateSubCategoryMutation } from '../../../api/subCategoryAPI';
import { addSubCategory, setSubCategories, setSubUpdatedCategories } from '../../../state/features/categories/subCategorySlice';
import { categoriesSelector } from '../../../state/features/selectors';
import CustomSelect from '../../../components/forms/CustomSelect';
import { isEmpty } from 'lodash';

// ############### VALIDATION ###############
const createSubCategoryValidationSchema = Yup.object({
    title: Yup.string().required('Sub category name is a required field'),
    category_id: Yup.string().required('category is a required field'),
});

const CreateSubCategory = ({
    isUpdate,
    resetForm,
    categoryID,
    mainCategoryID,
    categoryName,
    categoryDescription,
}) => {    

    const dispatch = useDispatch();

    // ############## Comp state ###############
    const [mainCat, setMainCat] = useState('');
    
    // ############## Redux state ###############
    const { categories } = useSelector(categoriesSelector);

    // ############## RTK ################
    // => CREATE category
    const [
        createSubCategory,
        {
            isLoading: createIsLoading,
            isSuccess: createIsSuccess,
            isError: createIsError,
            error: createError,
            data: createdCategory,
        },
    ] = useCreateSubCategoryMutation();
    // => UPDATE caetegory
    const [
        updateCategory,
        {
            isLoading: updateIsLoading,
            isSuccess: updateSuccess,
            isError: updateIsError,
            error: updateError,
            data: updatedCategory,
        },
    ] = useUpdateSubCategoryMutation();

    // ############## FUNC ###############
    // => Create category
    const handleCreateSubCategory = (payload) => {
        createSubCategory(payload);
    };
    // => Update category
    const handleUpdateCategory = (payload) => {
        let newPayload = {
            _id: categoryID,
            category_id: payload.category_id,
            title: payload.title,
            category_description: payload.category_description,
        };
        updateCategory(newPayload);
    };

    const CategorySelect = ({formik}) => (
        <CustomSelect 
        name="category_id" 
        formik={formik}
        label={'Select category'} >
            {
                categories?.map((category) => (
                    <MenuItem key={category._id} value={category._id}>{category.category_name}</MenuItem>
                ))
            }
        </CustomSelect>
    )

    // ############## useEffect ###############
    // => Dispatch created data
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (createIsSuccess) {
                successNotify(getRTKSuccessMessage(createdCategory));
                dispatch(addSubCategory(getRTKResponseData(createdCategory)));
            }
            if (createIsError) {
                errorNotify(getRTKErrorMessage(createError));
            }
            if (updateSuccess) {
                successNotify(getRTKSuccessMessage(updatedCategory));
                dispatch(
                    setSubCategories(updatedCategory),
                    // setSubCategories(getRTKResponseData(updatedCategory)),
                );
            }
            if (updateIsError) {
                errorNotify(getRTKErrorMessage(updateError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        createIsSuccess,
        createIsError,
        updateSuccess,
        updateIsError,
        createError,
        createdCategory,
        updatedCategory,
        updateError,
        dispatch,
    ]);
    // => Clear form after update
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (updateSuccess) {
                resetForm();
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [updateSuccess]);
    // => Set main category
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (!isEmpty(mainCategoryID)) {
                setMainCat(mainCategoryID);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [mainCategoryID]);

    return (
        <>
            <Box className="form-section">
                <Formik
                    enableReinitialize
                    initialValues={{
                        title: categoryName,
                        category_id: mainCategoryID,
                        category_description: categoryDescription,
                    }}
                    validationSchema={createSubCategoryValidationSchema}
                    onSubmit={(payload, { resetForm }) => {
                        isUpdate
                            ? handleUpdateCategory(payload)
                            : handleCreateSubCategory(payload);
                        // resetForm();
                    }}
                >
                    {(formik) => (
                        <Grid container justifyContent={'center'}>
                            <Grid item xs={12} sm={10}>
                                <Box className="input-con">
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Avatar
                                            src={logo1}
                                            alt="Agiza logo"
                                            sx={{
                                                width: 160,
                                                height: 66,
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography className="input-sign-head">
                                            {isUpdate ? 'Update' : 'Create'}{' '}
                                            store sub category
                                        </Typography>
                                        <Box>
                                            <Grid container columnSpacing={2}>
                                                <Grid item sm={12}>
                                                    <CategorySelect formik={formik} />
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <CustomTextField
                                                        type="text"
                                                        name="title"
                                                        label={'Sub category name'}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <CustomMultlineTextField
                                                rows={3}
                                                type="text"
                                                name="category_description"
                                                label={'Sub category description'}
                                            />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <CustomSubmitButton
                                            loading={
                                                createIsLoading
                                                    ? createIsLoading
                                                    : updateIsLoading
                                            }
                                            onClick={formik.handleSubmit}
                                            btnColor={
                                                isUpdate ? 'info' : 'primary'
                                            }
                                        >
                                            {isUpdate
                                                ? 'Update sub category'
                                                : 'Create sub category'}
                                        </CustomSubmitButton>
                                    </Box>

                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {isUpdate && (
                                            <Button
                                                size="small"
                                                color="error"
                                                sx={{
                                                    textTransform: 'capitalize',
                                                }}
                                                onClick={() => {
                                                    resetForm();
                                                }}
                                            >
                                                Cancel update
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default CreateSubCategory;
