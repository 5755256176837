import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from './baseUrl/baseURL';
import { headers } from './baseUrl/setHeaders';
import { deliveryGuyEndpoints } from './endpoints/deliveryGuysEndpoints';

export const deliveryGuyAPI = createApi({
    reducerPath: 'deliveryGuyAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    endpoints: (builder) => ({
        getDeliveryGuys: builder.query({
            query: () => `${deliveryGuyEndpoints.GET_ALL_DELIVERY_GUYS}`,
        }),
        createDeliveryGuy: builder.mutation({
            query: (payload) => ({
                url: `${deliveryGuyEndpoints.CREATE_DELIVERY_GUY}`,
                method: 'POST',
                body: payload,
            }),
        }),
        getSingleDeliveryGuy: builder.query({
            query: (payload) =>
                `${deliveryGuyEndpoints.GET_SINGLE_DELIVERY_GUY}/` + payload,
        }),
    }),
});

export const {
    useGetDeliveryGuysQuery,
    useCreateDeliveryGuyMutation,
    useGetSingleDeliveryGuyQuery,
} = deliveryGuyAPI;
