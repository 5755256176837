import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';

const StoreStatsWidget = ({title, total, bdColor}) => {
    return (
        <>
            <Box
                sx={{
                    height: 50,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    sx={{
                        'fontSize': 12,
                        'fontWeight': 'bold',
                        'position': 'relative',
                        '&::before': {
                            content: "''",
                            height: 5,
                            width: 90,
                            bgcolor: bdColor,
                            position: 'absolute',
                            bottom: -4,
                        },
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: colors.primary,
                    }}
                >
                    {total}
                </Typography>
            </Box>
        </>
    );
};

export default StoreStatsWidget;
