import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { customersSelector } from "../../state/features/selectors";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import CountBadge from "../../components/CountBadge";
import NoContent from "../../components/NoContent";
import CustomerWidget from "../../components/widgets/CustomerWidget";
import CustomPaginationTwo from "../../components/paginations/CustomPaginationTwo";
import { useLazyGetAllCustomersQuery } from "../../api/customerAPI";
import CustomersFilter from "../../components/filters/CustomersFilter";

const SelectCustomer = ({getCustomer}) => {
    // ############### CONST ####################
    const dispatch = useDispatch();

    // ############### Component state ###################
    const [customers, setCustomers] = useState([]);

    // ######### Redux states #############
    const {
        customers: allCustomers,
        paginatedCustomers,
        totalCustomers,
    } = useSelector(customersSelector);

    // ############### RTK #########################
    const [
        getCustomers,
        {
            // isLoading: customerLoading,
            isSuccess: customerSuccess,
            data: customersData,
        },
    ] = useLazyGetAllCustomersQuery();

    // ############### FUNC ###########################
    // => handle page change
    const handlePageChange = (page) => {
        getCustomers(page);
    };

    // => Dispatch main data
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (customerSuccess) {
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [customerSuccess, customersData, dispatch]);

    // => Filter by name/id
    const filterByNameOrID = (search) => {
        let customers = [];
        if (search) {
            customers = allCustomers.filter((customer) => {
                return (
                    customer.customer_id
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    customer.first_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    customer.last_name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                );
            });
            return setCustomers(customers);
        }
        return setCustomers(allCustomers);
    };

    // ############## useEffect ################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setCustomers(allCustomers);
        }
        return () => {
            isSubscribed = false;
        };
    }, [setCustomers, allCustomers]);

    return (
        <>
            <CustomPaginationTwo
                title={"All registered customer"}
                data={paginatedCustomers}
                handlePageChange={handlePageChange}
                action={
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: 400,
                        }}
                    >
                        <CountBadge total={totalCustomers} item={"customers"} />
                        <CustomersFilter filterByNameOrID={filterByNameOrID} />
                    </Box>
                }
            >
                {isEmpty(paginatedCustomers) ? (
                    <>
                        <NoContent message={"No customer available"} />
                    </>
                ) : (
                    <Grid container spacing={3} padding={2}>
                        {paginatedCustomers.data?.map((customer) => (
                            <Grid item sm={3} key={customer._id}>
                                <CustomerWidget
                                    customer={customer}
                                    handler={getCustomer}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </CustomPaginationTwo>
        </>
    );
};

export default SelectCustomer;
