import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Grid, IconButton, styled, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { colors } from "../assets/utils/colors";
import PlatformWidget from "./widgets/PlatformWidget";
import { agizaPlatforms } from "../assets/utils/agizaPlatforms";

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const AgizaAppBar = ({ openAppBar, closeAppDrawer }) => {
    const platforms = agizaPlatforms;

    return (
        <React.Fragment key={1}>
            <Drawer
                anchor={"right"}
                open={openAppBar}
                sx={{ position: "relative" }}
            >
                <Box
                    sx={{
                        width: { md: 400, sm: 400, xs: "100vw" },
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                        height: "100vh",
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                    }}
                >
                    <DrawerHeader />
                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <IconButton
                                onClick={() => closeAppDrawer()}
                                color="error"
                                size="small"
                                sx={{
                                    position: "absolute",
                                    left: 10,
                                    boxShadow: `1px 1px 10px 4px ${colors.bgColor2}`,
                                }}
                            >
                                <Close />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    color: colors.primary,
                                }}
                            >
                                Agiza Africa Platform
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1, p: 1, position: "relative" }}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                overflowY: "auto",
                                p: 2,
                            }}
                        >
                            <Grid container spacing={2}>
                                {platforms.map((platform) => (
                                    <Grid item sm={12} key={platform.id}>
                                        <PlatformWidget platform={platform} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </React.Fragment>
    );
};
export default AgizaAppBar;
