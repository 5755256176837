import {
    Box,
    Button,
    Grid,
    IconButton,
    ListItemText,
    Tooltip,
    Typography,
} from "@mui/material";
import React from "react";
import {
    addCartItem,
    decreaseFromCart,
} from "../../state/features/orders/posOrderSlice";
import { useDispatch } from "react-redux";
import { colors } from "../../assets/utils/colors";
import { AddCircle, RemoveCircle, Store } from "@mui/icons-material";
import { currencyFomater } from "../../helpers/currencyFomater";
import { isEmpty } from "lodash";

const CartProductWidget = ({ product }) => {
    // ############# Const ################
    const dispatch = useDispatch();

    // ###### Distructure product data #########
    const bannerImage = product?.banner_image;

    return (
        <>
            <Box
                sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    boxShadow: `0px 0px 2px ${colors.primary}`,
                }}
            >
                <Grid container>
                    <Grid item sm={3}>
                        <Box
                            sx={{
                                height: 100,
                                p: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                            }}
                        >
                            <img
                                src={bannerImage}
                                alt=""
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                    borderRadius: 15,
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        }}
                    >
                        <Box
                            sx={{
                                height: `100%`,
                                px: 1,
                                borderLeft: `1px dotted ${colors.primary}`,
                                borderRight: `1px dotted ${colors.primary}`,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                            }}
                        >
                            <Box sx={{ width: "100%" }}>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Typography
                                            noWrap
                                            className="head-font"
                                            sx={{
                                                fontWeight: "bold",
                                                color: colors.primary,
                                                fontSize: 11,
                                                textAlign: "left",
                                            }}
                                        >
                                            {product.title}
                                        </Typography>
                                    }
                                    secondary={
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Store
                                                sx={{
                                                    fontSize: 14,
                                                    color: colors.primary,
                                                    mr: 0.5,
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: 8,
                                                    fontWeight: "bold",
                                                    opacity: 0.8,
                                                }}
                                            >
                                                By {product.store_name}
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </Box>
                            <Box sx={{ position: "relative" }}>
                                <Grid container spacing={2}>
                                    {!isEmpty(product.selected_color) && (
                                        <Grid item xs={6}>
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    fontSize: 10,
                                                    py: 0.5,
                                                    color: colors.primary,
                                                }}
                                            >
                                                Selected color
                                            </Typography>
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    height: 24,
                                                    borderRadius: 2,
                                                    bgcolor:
                                                        product?.selected_color,
                                                    border: `1px dotted ${colors.primary}`,
                                                }}
                                            ></Box>
                                        </Grid>
                                    )}

                                    {!isEmpty(product.selected_size) && (
                                        <Grid item xs={6}>
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    fontSize: 10,
                                                    py: 0.5,
                                                    color: colors.primary,
                                                }}
                                            >
                                                Selected size
                                            </Typography>
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    borderRadius: 2,
                                                    p: 0.5,
                                                    border: `1px dotted ${colors.primary}`,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: 10,
                                                        fontWeight: "bold",
                                                        color: colors.primary,
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {product?.selected_size}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={3}>
                        <Box
                            sx={{
                                height: `100%`,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-between",
                                p: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 11,
                                    fontWeight: "bold",
                                    color: colors.primary,
                                    textAlign: "left",
                                }}
                            >
                                Quantity
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Tooltip title="Remove" arrow>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            p: 0.5,
                                            background: `linear-gradient(180deg, ${colors.secondary}, ${colors.primary})`,
                                        }}
                                        onClick={() => {
                                            dispatch(decreaseFromCart(product));
                                        }}
                                    >
                                        <RemoveCircle
                                            sx={{
                                                color: colors.bgColor4,
                                                fontSize: 15,
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        color: colors.primary,
                                        textAlign: "left",
                                    }}
                                >
                                    {product.cartQuantity}
                                </Typography>
                                <Tooltip title="Add" arrow>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            p: 0.5,
                                            background: `linear-gradient(180deg, ${colors.secondary}, ${colors.primary})`,
                                        }}
                                        onClick={() => {
                                            dispatch(addCartItem(product));
                                        }}
                                    >
                                        <AddCircle
                                            sx={{
                                                color: colors.bgColor4,
                                                fontSize: 15,
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box
                                sx={{
                                    borderRadius: 1,
                                    width: "100%",
                                    position: 'relative',
                                    background: `linear-gradient(180deg, ${colors.secondary}, ${colors.primary})`,
                                }}
                            >
                                {product.discounted_price > 0 && (
                                    <Typography
                                        className="head-font"
                                        sx={{
                                            fontSize: 9,
                                            fontWeight: "bold",
                                            color: colors.red,
                                            textAlign: "center",
                                        }}
                                    >
                                        <strike>
                                            TZS
                                            {currencyFomater(
                                                (product.discounted_price *
                                                    product.price) /
                                                    100
                                            )}
                                        </strike>
                                    </Typography>
                                )}
                                <Typography
                                    className="head-font"
                                    sx={{
                                        fontSize: 11,
                                        textAlign: "center",
                                        color: colors.bgColor4,
                                    }}
                                >
                                    {currencyFomater(
                                        product.price *
                                            parseInt(product.cartQuantity)
                                    )}
                                    <sup
                                        style={{
                                            color: colors.bgColor4,
                                            fontWeight: "bold",
                                            fontSize: 8,
                                        }}
                                    >
                                        TZS
                                    </sup>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default CartProductWidget;
