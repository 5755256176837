import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { useGetBeemBalanceQuery } from "../../api/beemAPI";
import { useDispatch, useSelector } from "react-redux";
import { setSmsBalance } from "../../state/features/beem/beemSlice";
import { beemSelector } from "../../state/features/selectors";

const BeemWidget = () => {
    // ######### COMP State #######
    const dispatch = useDispatch();
    const { smsBalance } = useSelector(beemSelector);

    // ######## RTK ##############
    // => Get balance
    const {
        isLoading,
        isSuccess,
        isError,
        data: balanceData,
    } = useGetBeemBalanceQuery();

    // ########### useEffect ############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(setSmsBalance(balanceData.data));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError]);

    return (
        <>
            <Box
                sx={{
                    p: 0.9,
                    px: 2,
                    borderRadius: 2,
                    border: `2px dotted ${
                        isLoading
                            ? colors.bgColor2
                            : smsBalance < 100
                            ? colors.red
                            : colors.green
                    }`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                }}
            >
                <Typography
                    noWrap
                    sx={{
                        color: colors.primary,
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        fontSize: 11,
                    }}
                >
                    sms balance :
                </Typography>
                <Typography
                    sx={{
                        color: colors.primary,
                        fontWeight: "bold",
                        ml: 3,
                    }}
                >
                    {isLoading ? (
                        <CircularProgress size={15} sx={{ mt: 1 }} />
                    ) : (
                        smsBalance
                    )}
                </Typography>
            </Box>
        </>
    );
};

export default BeemWidget;
