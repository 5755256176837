// => Categories selector
export const categoriesSelector = (state) => state.categoryReducer;

// => Sub-categories selector
export const subCategoriesSelector = (state) => state.subCategoryReducer;

// => Auth selector
export const authSelector = (state) => state.authReducer;

// => Admins selector
export const adminsSelector = (state) => state.adminsReducer;

// => Delivery guys selector
export const deliveryGuysSelector = (state) => state.deliveryGuysReducer;

// => vendors selector
export const vendorsSelector = (state) => state.vendorReducer;

// => Pos selector
export const posOrderSelector = (state) => state.posOrderReducer;

// => stores selector
export const storesSelector = (state) => state.storeReducer;

// => orders selector
export const ordersSelector = (state) => state.orderReducer;

// => products selector
export const productsSelector = (state) => state.productReducer;

// => promotions selector
export const promotionsSelector = (state) => state.promotionsReducer;

// => systemConfig selector
export const systemConfigSelector = (state) => state.systemConfigReducer;

// => customer selector
export const customersSelector = (state) => state.customersReducer;

// => beem selector
export const beemSelector = (state) => state.beemReducer;

// => notification selector
export const notificationsSelector = (state) => state.notificationsReducer;
