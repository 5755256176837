import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { productsSelector } from "../../state/features/selectors";
import CustomPaginationTwo from "../../components/paginations/CustomPaginationTwo";
import { filter, isEmpty, reverse, size } from "lodash";
import { Box, Grid } from "@mui/material";
import NoContent from "../NoContent";
import { paginator } from "../../helpers/paginationHelper";
import StoresFilter from "../filters/StoresFilter";
import ProductWidget from "../widgets/ProductWidget";
import ProductFilter from "../filters/ProductFilter";
import CountBadge from "../CountBadge";

const DashProducts = () => {
    // ############### Component state ########################
    const [products, setProducts] = useState([]);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ############### Redux state ########################
    const { products: allProducts } = useSelector(productsSelector);

    // ############### FUNC ###########################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Filter by product name
    const filterByProductName = (search) => {
        let filteredProducts = [];
        if (search) {
            filteredProducts = allProducts.filter((product) => {
                return product.title
                    .toLowerCase()
                    .includes(search.toLowerCase());
            });
            return setProducts(filteredProducts);
        }
        return setProducts(allProducts);
    };
    // => Filter by category
    const filterByCategory = (category_id) => {
        let filteredProducts = [];
        if (category_id) {
            filteredProducts = allProducts.filter((product) => {
                return product.category_id
                    .toLowerCase()
                    .includes(category_id.toLowerCase());
            });
            return setProducts(filteredProducts);
        }
        return setProducts(allProducts);
    };

    // => Get active products
    const activeProducts = () => {
        let activeProducts = filter(products, { isActive: true });
        return activeProducts;
    };
    const paginatedProducts = paginator(
        reverse(activeProducts()),
        page,
        perPage
    );

    // ############### useEffect ###################
      useEffect(() => {
          let isSubscribed = true;
          if (isSubscribed) {
              setProducts(allProducts);
          }

          return () => {
              isSubscribed = false;
          };
      }, [allProducts]);

    return (
        <>
            <CustomPaginationTwo
                title={"All active products"}
                data={paginatedProducts}
                handlePageChange={handlePageChange}
                handlePerPageChage={handlePerPageChage}
                action={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ProductFilter
                            filterByProductName={filterByProductName}
                            filterByCategory={filterByCategory}
                        />
                        <CountBadge
                            total={size(filter(products, { isActive: true }))}
                            item={"products"}
                        />
                    </Box>
                }
            >
                {isEmpty(products) ? (
                    <>
                        <NoContent message={"No store found!"} />
                    </>
                ) : (
                    <Grid container spacing={3} padding={2}>
                        {paginatedProducts.data?.map((product, index) => (
                            <Grid item sm={3} xs={12} key={index}>
                                <ProductWidget product={product} />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </CustomPaginationTwo>
        </>
    );
};

export default DashProducts;
