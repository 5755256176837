import { Message, Phone, RemoveRedEye } from '@mui/icons-material';
import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/colors';

const AdminWidget = ({ admin }) => {
    return (
        <>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                    position: 'relative',
                    borderRadius: 5,
                    overflow: 'hidden',
                }}
            >
                <Box sx={{ height: 120 }}>
                    <Box
                        sx={{
                            height: 70,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: -35,
                            }}
                        >
                            <Avatar
                                src={admin.profile_picture}
                                sx={{
                                    height: 80,
                                    width: 80,
                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.primary})`,
                                    p: 1,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            p: 0.5,
                            opacity: 0.7,
                            textTransform: 'capitalize',
                        }}
                    >
                        {`${admin.first_name} ${admin.last_name}`}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            p: 0.5,
                            fontSize: 14,
                            color: colors.primary,
                        }}
                    >
                        {`${admin.login_id}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        mt: 1,
                        py: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                    }}
                >
                    <Tooltip title={admin.phone_number} arrow>
                        <a href={`tel:${admin.phone_number}`}>
                            <IconButton
                                sx={{
                                    p: 0.8,
                                    bgcolor: colors.bgColor1,
                                    color: colors.primary,
                                    ml: 1,
                                }}
                                size="small"
                            >
                                <Phone
                                    sx={{
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        </a>
                    </Tooltip>
                    <Tooltip title={admin.email} arrow>
                        <a href={`mailTo:${admin.email}`}>
                            <IconButton
                                sx={{
                                    p: 0.8,
                                    bgcolor: colors.bgColor1,
                                    color: colors.primary,
                                    ml: 1,
                                }}
                                size="small"
                            >
                                <Message
                                    sx={{
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        </a>
                    </Tooltip>
                    <Tooltip title="More details" arrow>
                        <Link to={`/admins/details/${admin._id}`}>
                            <IconButton
                                sx={{
                                    p: 0.8,
                                    bgcolor: colors.bgColor1,
                                    color: colors.primary,
                                    ml: 1,
                                }}
                                size="small"
                            >
                                <RemoveRedEye
                                    sx={{
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Box>
            </Box>
        </>
    );
};

export default AdminWidget;
