import * as React from 'react';
import { colors } from '../../assets/utils/colors';
import { Avatar, Box, Grid, ListItemText, Typography } from '@mui/material';
import { LocationOn, StoreOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';

// !############### MAIN FUNC #####################
const CustomerWidget4 = ({ customer, total, height, type }) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    borderRadius: 2,
                }}
            >
                <Link to={`/customers/details/${customer._id}`}>
                    <Grid container>
                        <Grid item sm={3} xs={12}>
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative',
                                }}
                            >
                                <Avatar
                                    src={customer.profile_picture}
                                    sx={{
                                        height: 50,
                                        width: 50,
                                        borderRadius: 50,
                                        border: `3px solid ${colors.bgColor1}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Box sx={{ p: 1 }}>
                                <Box>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    className="head-font"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        color: colors.primary,
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {`${customer.first_name} ${customer.last_name}`}
                                                </Typography>
                                            </Box>
                                        }
                                        secondary={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <LocationOn
                                                    sx={{
                                                        fontSize: 13,
                                                        color: colors.primary,
                                                        mr: 0.5,
                                                    }}
                                                />
                                                <Typography
                                                    noWrap
                                                    sx={{
                                                        fontSize: 11,
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {`${customer.city}, ${customer.nationality}`}
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={2} xs={12}>
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    p: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                        p: 2,
                                        height: 60,
                                        width: '100%',
                                        display: 'flex',
                                        borderRadius: 5,
                                        flexDirection: 'column',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                        borderBottom: `2px solid ${colors.secondary}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 15,
                                            fontWeight: 'bold',
                                            opacity: 0.8,
                                        }}
                                    >
                                        {total}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 'bold',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: colors.primary,
                                        }}
                                    >
                                        <StoreOutlined
                                            sx={{
                                                fontSize: 10,
                                                mr: 0.5,
                                            }}
                                        />
                                        {type === 'store'
                                            ? 'Stores'
                                            : type === 'product'
                                            ? 'Products'
                                            : 'Orders'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Link>
            </Box>
        </React.Fragment>
    );
};

export default CustomerWidget4;
