// => RTK reducers
import { authAPI } from "../../api/authAPI";
import { vendorAPI } from "../../api/vendorAPI";
import { storeAPI } from "../../api/storeAPI";
import { orderAPI } from "../../api/orderAPI";
import { productAPI } from "../../api/productAPI";
import { categoryAPI } from "../../api/categoryAPI";
import { adminsAPI } from "../../api/adminsAPI";
import { deliveryGuyAPI } from "../../api/deliveryGuyAPI";
import { promotionAPI } from "../../api/promotionAPI";
import { customerAPI } from "../../api/customerAPI";
import { beemAPI } from "../../api/beemAPI";
import { configAPI } from "../../api/configAPI";
import { subCategoryAPI } from "../../api/subCategoryAPI";
import { notificationAPI } from "../../api/notificationAPI";

// => RTK middleware
export const RTKmiddleware = [
    authAPI.middleware,
    vendorAPI.middleware,
    storeAPI.middleware,
    orderAPI.middleware,
    productAPI.middleware,
    categoryAPI.middleware,
    adminsAPI.middleware,
    deliveryGuyAPI.middleware,
    promotionAPI.middleware,
    customerAPI.middleware,
    beemAPI.middleware,
    configAPI.middleware,
    subCategoryAPI.middleware,
    notificationAPI.middleware,
];
