import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Switch,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";
import React, { useEffect } from "react";
import { colors } from "../../assets/utils/colors";
import { useEnableAgizaModeMutation } from "../../api/configAPI";
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from "../../helpers/RTKHelpers";
import { errorNotify, successNotify } from "../../helpers/notify";
import { useDispatch, useSelector } from "react-redux";
import { systemConfigSelector } from "../../state/features/selectors";
import { setAgizaMode } from "../../state/features/systemConfig/systemConfigSlice";

const IOSSwitch = styled((props) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 42,
    height: 24,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor:
                    theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 19,
        height: 19,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "red" : "red",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

const AgizaModeBtn = () => {
    // ############# CONST #####################
    const dispatch = useDispatch();

    // ############# Redux state ################
    const { agizaMode } = useSelector(systemConfigSelector);

    // ############# RTK ##################
    const [
        enableAction,
        {
            isLoading: enableLoading,
            isSuccess: enableSuccess,
            isError: enableIsError,
            error: enableError,
            data: enableData,
        },
    ] = useEnableAgizaModeMutation();

    // ############### useEffect ###########
    // => Enable
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (enableSuccess) {
                dispatch(setAgizaMode(enableData?.data));
                setTimeout(() => {
                    successNotify(getRTKSuccessMessage(enableData));
                }, 1000);
            }
            if (enableIsError) {
                setTimeout(() => {
                    errorNotify(getRTKErrorMessage(enableError));
                }, 1000);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [enableSuccess, enableIsError, enableError, enableData]);

    return (
        <>
            <Tooltip
                title={`${
                    agizaMode !== false ? "Disable" : "Enable"
                } agiza mode`}
                arrow
            >
                <Button
                    size="small"
                    sx={{
                        px: 1,
                        py: 0,
                        height: 40,
                        borderRadius: 2,
                        border: `3px dotted ${colors.bgColor2}`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                        transition: `.3s all ease-in-out`,
                        ":hover": {
                            transition: `.3s all ease-in-out`,
                            boxShadow: `0px 0px 5px ${colors.primary}`,
                        },
                        border: `1px dotted ${colors.primary}`,
                    }}
                    onClick={() => {
                        enableAction();
                    }}
                >
                    <FormControlLabel
                        control={
                            enableLoading ? (
                                <>
                                    <Box sx={{ py: 0.5, px: 2 }}>
                                        <CircularProgress
                                            size={17}
                                            sx={{ mt: 1 }}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    {agizaMode === false ? (
                                        <>
                                            <IOSSwitch
                                                sx={{ m: 1 }}
                                                checked={false}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <IOSSwitch
                                                sx={{ m: 1 }}
                                                checked={true}
                                            />
                                        </>
                                    )}
                                </>
                            )
                        }
                        label={
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: 11,
                                    width: 50,
                                }}
                            >
                                Agiza Mode
                            </Typography>
                        }
                    />
                </Button>
            </Tooltip>
        </>
    );
};

export default AgizaModeBtn;
