import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import CustomSubmitButton from "../../../../components/forms/CustomSubmitButton";
import { useResumeProductMutation } from "../../../../api/productAPI";
import { useDispatch } from "react-redux";
import { errorNotify, successNotify } from "../../../../helpers/notify";
import SuccessFeedback from "../../../../components/SuccessFeedback";
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from "../../../../helpers/RTKHelpers";
import { setOrderDetails } from "../../../../state/features/orders/orderSlice";
import { useCashOrderPaymentMutation } from "../../../../api/orderAPI";

// !############## MAIN FUNC ################
const ConfirmOrderPayment = ({ orderID }) => {
    // ############## CONST ##############
    const dispatch = useDispatch();

    // ############## RTK ################
    // => Resume product
    const [
        confirmOrderPayment,
        { isLoading, isSuccess, isError, error, data: orderDetails },
    ] = useCashOrderPaymentMutation();

    // ############### FUNC ###############
    // => Handle product resume
    const handleCashPayment = async () => {
        confirmOrderPayment(orderID);
    };

    // ############### useEffect ###########
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                successNotify(getRTKSuccessMessage(orderDetails));
                dispatch(setOrderDetails(getRTKResponseData(orderDetails)));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, error, orderDetails, dispatch]);

    return (
        <>
            <Box
                sx={{
                    p: 2,
                    borderRadius: 2,
                    height: 300,
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                }}
            >
                {isSuccess ? (
                    <>
                        <SuccessFeedback message={orderDetails.message} />
                    </>
                ) : (
                    <>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 22,
                                    color: `green`,
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                            >
                                Order cash payment
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontSize: 14, opacity: 0.8 }}>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Reiciendis labore illo
                                aspernatur aliquid? Adipisci consequuntur, ipsum
                                id culpa natus minus numquam eaque pariatur
                                veniam cupiditate ratione quis accusamus! Ut,
                                vitae?
                            </Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                            <CustomSubmitButton
                                loading={isLoading}
                                onClick={() => {
                                    handleCashPayment();
                                }}
                            >
                                Confirm cash payment
                            </CustomSubmitButton>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

export default ConfirmOrderPayment;
