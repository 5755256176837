import React, { useEffect } from 'react';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import CustomTextField from '../../../components/forms/CustomTextField';
import logo1 from '../../../assets/media/images/logo1.png';
import CustomMultlineTextField from '../../../components/forms/CustomMultlineTextField';
import {
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
} from '../../../api/categoryAPI';
import CustomSubmitButton from '../../../components/forms/CustomSubmitButton';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import {
    addCategory,
    setUpdatedCategories,
} from '../../../state/features/categories/categorySlice';
import { errorNotify, successNotify } from '../../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../helpers/RTKHelpers';

// ############### VALIDATION ###############
const createCategoryValidationSchema = Yup.object({
    category_name: Yup.string().required('category name is a required field'),
});

const CreateCategory = ({
    isUpdate,
    resetForm,
    categoryID,
    categoryName,
    categoryDescription,
}) => {
    // ############## CONST ################
    const dispatch = useDispatch();

    // ############## RTK ################
    // => CREATE category
    const [
        createCategory,
        {
            isLoading: createIsLoading,
            isSuccess: createIsSuccess,
            isError: createIsError,
            error: createError,
            data: createdCategory,
        },
    ] = useCreateCategoryMutation();
    // => UPDATE caetegory
    const [
        updateCategory,
        {
            isLoading: updateIsLoading,
            isSuccess: updateSuccess,
            isError: updateIsError,
            error: updateError,
            data: updatedCategory,
        },
    ] = useUpdateCategoryMutation();

    // ############## FUNC ###############
    // => Create category
    const handleCreateCategory = (payload) => {
        createCategory(payload);
    };
    // => Update category
    const handleUpdateCategory = (payload) => {
        let newPayload = {
            category_id: categoryID,
            category_name: payload.category_name,
            category_description: payload.category_description,
        };
        updateCategory(newPayload);
    };

    // ############## useEffect ###############
    // => Dispatch created data
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (createIsSuccess) {
                successNotify(getRTKSuccessMessage(createdCategory));
                dispatch(addCategory(getRTKResponseData(createdCategory)));
            }
            if (createIsError) {
                errorNotify(getRTKErrorMessage(createError));
            }
            if (updateSuccess) {
                successNotify(getRTKSuccessMessage(updatedCategory));
                dispatch(
                    setUpdatedCategories(getRTKResponseData(updatedCategory)),
                );
            }
            if (updateIsError) {
                errorNotify(getRTKErrorMessage(updateError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        createIsSuccess,
        createIsError,
        updateSuccess,
        updateIsError,
        createError,
        createdCategory,
        updatedCategory,
        updateError,
        dispatch,
    ]);
    // => Clear form after update
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (updateSuccess) {
                resetForm();
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [updateSuccess, resetForm]);

    return (
        <>
            <Box className="form-section">
                <Formik
                    enableReinitialize
                    initialValues={{
                        category_name: categoryName,
                        category_description: categoryDescription,
                    }}
                    validationSchema={createCategoryValidationSchema}
                    onSubmit={(payload, { resetForm }) => {
                        isUpdate
                            ? handleUpdateCategory(payload)
                            : handleCreateCategory(payload);
                        resetForm();
                    }}
                >
                    {(formik) => (
                        <Grid container justifyContent={'center'}>
                            <Grid item xs={12} sm={10}>
                                <Box className="input-con">
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Avatar
                                            src={logo1}
                                            alt="Agiza logo"
                                            sx={{
                                                width: 160,
                                                height: 66,
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography className="input-sign-head">
                                            {isUpdate ? 'Update' : 'Create'}{' '}
                                            store category
                                        </Typography>
                                        <Box>
                                            <CustomTextField
                                                type="text"
                                                name="category_name"
                                                label={'Category name'}
                                            />
                                            <CustomMultlineTextField
                                                rows={4}
                                                type="text"
                                                name="category_description"
                                                label={'Category description'}
                                            />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <CustomSubmitButton
                                            loading={
                                                createIsLoading
                                                    ? createIsLoading
                                                    : updateIsLoading
                                            }
                                            onClick={formik.handleSubmit}
                                            btnColor={
                                                isUpdate ? 'info' : 'primary'
                                            }
                                        >
                                            {isUpdate
                                                ? 'Update category'
                                                : 'Create category'}
                                        </CustomSubmitButton>
                                    </Box>

                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {isUpdate && (
                                            <Button
                                                size="small"
                                                color="error"
                                                sx={{
                                                    textTransform: 'capitalize',
                                                }}
                                                onClick={() => {
                                                    resetForm();
                                                }}
                                            >
                                                Cancel update
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default CreateCategory;
