// => GET all customers
const GET_ALL_CUSTOMERS = "/admins/customers";

// => GET all customers
const GET_ALL_SUBSCRIBERS_CUSTOMERS = "/admins/customers/subscribers";

// => Get single customer
const GET_SINGLE_CUSTOMER = "/admins/customers";

// => GET customer by phone
const GET_BY_PHONE = "/customers/search";

// => Create customer
const CREATE_CUSTOMER = "/customers/pos-customer";

export const customersEndpoints = {
    GET_ALL_CUSTOMERS,
    GET_ALL_SUBSCRIBERS_CUSTOMERS,
    GET_SINGLE_CUSTOMER,
    GET_BY_PHONE,
    CREATE_CUSTOMER,
};
