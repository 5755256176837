import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/colors';
import { Store } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Grid,
    ListItemText,
    Skeleton,
    Typography,
} from '@mui/material';
import { currencyFomater } from '../../helpers/currencyFomater';

const ProductWidget = ({ product }, props) => {
    const { isLoading = false } = props;

    return (
        <>
            <Link to={`/products/details/${product._id}`}>
                <Box
                    sx={{
                        'background': `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        'borderRadius': 2.5,
                        'overflow': 'hidden',
                        'transition': '.2s all ease-in-out',
                        'boxShadow': `1px 1px 5px 0px ${colors.bgColor1}`,
                        '& .product-img': {
                            transition: '.2s all ease-in-out',
                            borderRadius: 2.5,
                            border: `1px solid ${colors.bgColor1}`,
                        },
                        '&:hover': {
                            'transition': '.2s all ease-in-out',
                            '& .product-img': {
                                transform: `scale(1.02)`,
                                transition: '.2s all ease-in-out',
                                border: `1px solid ${colors.secondary}`,
                            },
                        },
                    }}
                >
                    <Grid container padding={0.5}>
                        <Grid item sm={5} xs={4}>
                            <Box
                                className="product-img"
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                                }}
                            >
                                {isLoading ? (
                                    <Skeleton height={95} width={95} />
                                ) : (
                                    <Avatar
                                        variant="square"
                                        sx={{
                                            height: 95,
                                            width: 95,
                                            borderRadius: 2.5,
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                        }}
                                        src={product.banner_image}
                                    />
                                )}
                            </Box>
                        </Grid>
                        <Grid item sm={7} xs={8}>
                            <Box sx={{ p: 1, width: '100%' }}>
                                <Box sx={{ width: '100%' }}>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                noWrap
                                                className="head-font"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: colors.primary,
                                                    fontSize: 13,
                                                }}
                                            >
                                                {product.title}
                                            </Typography>
                                        }
                                        secondary={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                                // onClick={navigate(
                                                //     `/stores/details/${product.store_id}`,
                                                // )}
                                            >
                                                <Store
                                                    sx={{
                                                        fontSize: 14,
                                                        color: colors.primary,
                                                        mr: 0.5,
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontSize: 10,
                                                        fontWeight: 'bold',
                                                        opacity: 0.8,
                                                    }}
                                                >
                                                    By {product.store_name}
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        mt: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box>
                                        {product.discounted_price > 0 && (
                                            <Typography
                                                className="head-font"
                                                sx={{
                                                    fontSize: 10,
                                                    fontWeight: 'bold',
                                                    color: colors.red,
                                                }}
                                            >
                                                <strike>
                                                    TZS
                                                    {currencyFomater(
                                                        (product.discounted_price *
                                                            product.price) /
                                                            100,
                                                    )}
                                                </strike>
                                            </Typography>
                                        )}
                                        <Typography
                                            className="head-font"
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {currencyFomater(product.price)}
                                            <sup>TZS</sup>
                                        </Typography>
                                    </Box>
                                    {/* <Box sx={{ textAlign: 'center' }}>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                color: colors.primary,
                                            }}
                                        >
                                            4
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: 'bold',
                                                color: colors.primary,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <LocalShipping
                                                sx={{ fontSize: 10, mr: 0.4 }}
                                            />
                                            Ordered
                                        </Typography>
                                    </Box> */}
                                </Box>
                            </Box>
                        </Grid>
                        {/* <Grid item sm={3} xs={12}>
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                p: 1,
                            }}
                        >
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        mb: 0.5,
                                    }}
                                >
                                    Quantity
                                </Typography>
                                <Typography
                                    className="head-font"
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    3
                                </Typography>
                            </Box>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        mb: 0.5,
                                    }}
                                >
                                    Subtotal
                                </Typography>
                                <Typography
                                    noWrap
                                    className="head-font"
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    2,000{' '}
                                    <sup
                                        style={{
                                            fontSize: 9,
                                            color: colors.primary,
                                        }}
                                    >
                                        TZS
                                    </sup>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid> */}
                    </Grid>
                </Box>
            </Link>
        </>
    );
};

export default ProductWidget;
