// => GET all orders
const GET_ALL_ORDERS = "/admins/orders";

// => GET single order
const GET_SINGLE_ORDER = "/admins/orders";

// => Ship order
const SHIP_ORDER = "/orders/ship-orders";

// => Ship order
const GET_DELIVERY_PIN = "/orders/delivery-pins";

// => Ship order
const CASH_PAYMENT = "/payments/cash/orders";

// => Ship order
const CREATE_POS_ORDER = "/orders/pos-order";

export const orderEndponts = {
    GET_ALL_ORDERS,
    GET_SINGLE_ORDER,
    SHIP_ORDER,
    GET_DELIVERY_PIN,
    CASH_PAYMENT,
    CREATE_POS_ORDER,
};
