import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL2 } from "./baseUrl/baseURL";
import { headers } from "./baseUrl/setHeaders";
import { customersEndpoints } from "./endpoints/customersEndpoints";

export const customerAPI = createApi({
    reducerPath: "customerAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL2, prepareHeaders: headers }),
    tagTypes: ["Customers"],
    endpoints: (builder) => ({
        // => Get all customer
        getAllCustomers: builder.query({
            query: (payload) =>
                `${customersEndpoints.GET_ALL_CUSTOMERS}?page=${payload}`,
            providesTags: ["Customers"],
        }),
        // => Get all subscribers customer
        getAllSubscribersCustomers: builder.query({
            query: (payload) =>
                `${customersEndpoints.GET_ALL_SUBSCRIBERS_CUSTOMERS}?page=${payload}`,
        }),
        // => Get single customer
        getSingleCustomer: builder.query({
            query: (payload) =>
                `${customersEndpoints.GET_SINGLE_CUSTOMER}/` + payload,
        }),
        // => Search customer
        getCustomerByPhone: builder.query({
            query: (payload) => `${customersEndpoints.GET_BY_PHONE}/` + payload,
        }),
        // => Create customer
        createCustomer: builder.mutation({
            query: (payload) => ({
                url: `${customersEndpoints.CREATE_CUSTOMER}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ["Customers"],
        }),
    }),
});

export const {
    useGetAllCustomersQuery,
    useGetAllSubscribersCustomersQuery,
    useGetSingleCustomerQuery,
    useLazyGetCustomerByPhoneQuery,
    useCreateCustomerMutation,

    // => Lazy
    useLazyGetAllCustomersQuery,
    useLazyGetAllSubscribersCustomersQuery,
} = customerAPI;
