import {
    Box,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../../assets/utils/colors";
import { size } from "lodash";

const CartProductColor = ({ availableColors, handler, product }) => {
    // ############### Comp state ####################
    const [selectedColor, setSelectedColor] = useState(null);

    // ############## FUNC #######################
    const getSelectedColor = (e) => {
        setSelectedColor(e.target.value);
    };

    // ############## useEffect ##################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            handler(selectedColor);
            if (size(availableColors) === 1) {
                let color = availableColors[0];
                setSelectedColor(color.name);
                handler(selectedColor);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [selectedColor, product, availableColors]);

    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    overflow: "auto",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: 12,
                        color: colors.primary,
                        pt: 1,
                        px: 1,
                    }}
                >
                    Select color
                </Typography>
                <Grid container spacing={1} padding={1}>
                    {availableColors.map((color) => (
                        <Grid item sm={4} key={color.name}>
                            <RadioGroup
                                value={selectedColor}
                                onChange={(e) => {
                                    getSelectedColor(e);
                                }}
                                sx={{
                                    p: 1,
                                    borderRadius: 2,
                                    width: "100%",
                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                    border: `1px dotted ${colors.primary}`,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: 20,
                                            borderRadius: 2,
                                            bgcolor: color.name,
                                            border: `1px dotted ${colors.primary}`,
                                        }}
                                    ></Box>
                                    <FormControlLabel
                                        sx={{
                                            width: "100%",
                                            ml: 0,
                                            display: "flex",
                                            justifyContent: "center",
                                            p: 0,
                                        }}
                                        value={color.name}
                                        control={
                                            <Radio
                                                size="small"
                                                color="primary"
                                            />
                                        }
                                    />
                                </Box>
                            </RadioGroup>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default CartProductColor;
