import React, { useEffect, useState } from "react";
import { Box, Grid, styled } from "@mui/material";
import { colors } from "../../../assets/utils/colors";
import OrderInfobox from "../../../components/infoBoxes/OrderInfobox";
import MainOrderTable from "../../../components/tables/MainOrderTable";
import PageLayout from "../../layouts/PageLayout";
import OrdersSidebar from "../../../components/sidebars/OrdersSidebar";
import { useSelector } from "react-redux";
import { ordersSelector } from "../../../state/features/selectors";
import CustomFlexCard from "../../../components/cards/CustomFlexCard";
import { filter, isEmpty, size } from "lodash";
import NoContent from "../../../components/NoContent";
import OrderFilter from "../../../components/filters/OrderFilter";
import moment from "moment/moment";

// ################ MUI Styled ###############
const OrdersContainer = styled(Grid)(({ theme }) => ({}));

// !################# MAIN FUNC ###################
const Orders = () => {
    // ############### Component state ########################
    const [orders, setOrders] = useState([]);
    const [byDateFrom, setByDateFrom] = useState("");
    const [byDateTo, setByDateTo] = useState("");
    const [byPayment, setByPayment] = useState("");
    const [byStatus, setByStatus] = useState("");

    // ############### Redux state ########################
    const { orders: allOrders } = useSelector(ordersSelector);

    // ############### Filters ###########################
    // => Filter by date
    const filterByDateFrom = (date) => {
        setByDateFrom(date);
    };
    // => Filter by date
    const filterByDateTo = (date) => {
        setByDateTo(date);
    };
    // => Filter by payment
    const filterByPayment = (payment) => {
        setByPayment(payment);
    };
    // => Filter by status
    const filterByStatus = (status) => {
        setByStatus(status);
    };

    // ############## General Filter ###############
    const filterOrders = (dateFrom, dateTo, payment, status) => {
        let filteredOrders = [];
        var startDate = moment(dateFrom).format("YYYY-MM-DD");
        var endDate = moment(dateTo).format("YYYY-MM-DD");
        if (dateFrom !== "" || dateTo !== "") {
            filteredOrders = filter(
                allOrders,
                ({ createdAt }) =>
                    moment(createdAt).format("YYYY-MM-DD") >= startDate &&
                    moment(createdAt).format("YYYY-MM-DD") <= endDate
            );
        }
        if (payment !== "") {
            filteredOrders = filter(
                isEmpty(filteredOrders) ? allOrders : filteredOrders,
                ({ isPaid }) => isPaid === payment
            );
        }
        if (status !== "") {
            filteredOrders = filter(
                isEmpty(filteredOrders) ? allOrders : filteredOrders,
                ({ order_status }) => order_status === byStatus
            );
        }
        if (
            dateFrom !== "" ||
            dateTo !== "" ||
            payment !== "" ||
            status !== ""
        ) {
            return setOrders(filteredOrders);
        } else {
            return setOrders(allOrders);
        }
    };

    // => Reset filter
    const resetFilter = () => {
        setByDateFrom("");
        setByDateTo("");
        setByPayment("");
        setByStatus("");
    };

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            filterOrders(byDateFrom, byDateTo, byPayment, byStatus);
        }
        return () => {
            isSubscribed = false;
        };
    }, [byDateFrom, byDateTo, byPayment, byStatus]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setOrders(allOrders);
        }

        return () => {
            isSubscribed = false;
        };
    }, [allOrders]);

    return (
        <>
            <PageLayout
                pageHead={"Agiza africa orders"}
                sidebar={<OrdersSidebar />}
            >
                <Box>
                    <Grid container spacing={2} paddingBottom={1.5}>
                        <Grid item sm={3} xs={6}>
                            <OrderInfobox
                                title={"New"}
                                total={size(
                                    filter(orders, {
                                        order_status: 1,
                                    })
                                )}
                                bgColor={colors.bgColor1}
                                url={"/orders/status/new"}
                            />
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <OrderInfobox
                                title={"Processing"}
                                total={size(
                                    filter(orders, {
                                        order_status: 2,
                                    })
                                )}
                                bgColor={colors.primary}
                                textColor={colors.bgColor3}
                                url={"/orders/status/processing"}
                            />
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <OrderInfobox
                                title={"Shipped"}
                                total={size(
                                    filter(orders, {
                                        order_status: 3,
                                    })
                                )}
                                bgColor={colors.yellow}
                                url={"/orders/status/shipped"}
                            />
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <OrderInfobox
                                title={"Delivered"}
                                total={size(
                                    filter(orders, {
                                        order_status: 4,
                                    })
                                )}
                                bgColor={colors.green}
                                url={"/orders/status/delivered"}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <CustomFlexCard
                        title={"All orders"}
                        height={535}
                        action={
                            <OrderFilter
                                byDateFrom={byDateFrom}
                                byDateTo={byDateTo}
                                byPayment={byPayment}
                                byStatus={byStatus}
                                resetFilter={resetFilter}
                                filterByDateFrom={filterByDateFrom}
                                filterByDateTo={filterByDateTo}
                                filterByPayment={filterByPayment}
                                filterByStatus={filterByStatus}
                            />
                        }
                    >
                        <OrdersContainer container>
                            <Grid item sm={12} xs={12}>
                                {isEmpty(orders) ? (
                                    <>
                                        <NoContent
                                            message={"No order placed"}
                                        />
                                    </>
                                ) : (
                                    <MainOrderTable orders={orders} />
                                )}
                            </Grid>
                        </OrdersContainer>
                    </CustomFlexCard>
                </Box>
            </PageLayout>
        </>
    );
};

export default Orders;
