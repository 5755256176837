import { AddCircleOutline } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../assets/utils/colors";
import OrderDialog from "../dialogs/OrderDialog";
import POSCart from "../order/POSCart";

const CreateOrderBtn = () => {
    // ############# Comp State ###############
    const [dialogOpen, setDialogOpen] = useState(false);

    // ############### FUNC ########################
    // => handle confimation dialog open
    const handleDialogOpen = (type) => {
        setDialogOpen(true);
    };

    // => handle confimation dialog close
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <OrderDialog
                customWidth={"lg"}
                head={"Create POS order"}
                noDropClose
                openDialog={dialogOpen}
                handleDialogClose={handleDialogClose}
            >
                <POSCart
                    closePOSDialog={handleDialogClose}
                    closePOSCart={handleDialogClose}
                />
            </OrderDialog>
            <Button
                variant="contained"
                color="primary"
                sx={{
                    height: 100,
                    width: 100,
                    borderRadius: 50,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: `0px 0px 20px ${colors.primary} !important`,
                    transition: `.5s ease-in-out`,
                    border: `4px double ${colors.primary}`,
                    ":hover": {
                        transition: `.5s ease-in-out`,
                        transform: `scale(1.05)`,
                        border: `4px double ${colors.bgColor1}`,
                    },
                }}
                onClick={() => {
                    handleDialogOpen();
                }}
            >
                <AddCircleOutline sx={{ fontSize: 30 }} />
                <Typography
                    sx={{
                        fontSize: 9,
                        fontWeight: "bold",
                        mt: 1,
                    }}
                >
                    Create POS order
                </Typography>
            </Button>
        </>
    );
};

export default CreateOrderBtn;
