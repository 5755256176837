import {
    Adb,
    Apple,
    LocalShipping,
    PhoneAndroid,
    TravelExplore,
} from "@mui/icons-material";
import appIcon from "../media/images/app_icon.webp";
import vendorIcon from "../media/images/vendor_app.webp";
import deliveryIcon from "../media/images/delivery2.png";
import landingIcon from "../media/images/logo2.png";

export const agizaPlatforms = [
    {
        id: 1,
        name: "Agiza Africa Apple",
        icon: <Apple className="icon" />,
        appIcon: appIcon,
        url: `https://apps.apple.com/us/app/agiza-africa/id6447758488`,
    },
    {
        id: 2,
        name: "Agiza Africa Android",
        icon: <Adb className="icon" />,
        appIcon: appIcon,
        url: `https://play.google.com/store/apps/details?id=com.agiza.agizaapp&hl=en`,
    },
    {
        id: 2,
        name: "Agiza Africa Vendor",
        icon: <PhoneAndroid className="icon" />,
        appIcon: vendorIcon,
        url: `https://play.google.com/store/apps/details?id=com.agiza.agizavendor&hl=en`,
    },
    {
        id: 3,
        name: "Agiza Africa delivery",
        appIcon: deliveryIcon,
        icon: <LocalShipping className="icon" />,
        url: `https://delivery.agiza.co.tz`,
    },
    {
        id: 4,
        name: "Agiza Africa Landing",
        icon: <TravelExplore className="icon" />,
        appIcon: landingIcon,
        url: `https://agiza.africa`,
    },
];
