import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProductWidget from '../../../../components/widgets/ProductWidget';
import CustomCard from '../../../../components/cards/CustomCard';
import StoreProductInfobox from '../../../../components/infoBoxes/StoreProductInfobox';
import {
    Check,
    DangerousRounded,
    Verified,
    Warning,
} from '@mui/icons-material';
import { colors } from '../../../../assets/utils/colors';
import { filter, isEmpty, reverse, size } from 'lodash';
import NoContent from '../../../../components/NoContent';
import ProductFilter2 from '../../../../components/filters/ProductFilter2';
import CustomPagination from '../../../../components/paginations/CustomPagination';
import CountBadge from '../../../../components/CountBadge';
import { paginator } from '../../../../helpers/paginationHelper';

const StoreProducts = ({ products: allProducts }) => {
    // ############### Component state ########################
    const [products, setProducts] = useState([]);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ############### FUNC ###########################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Filter by product name
    const filterByProductName = (search) => {
        let filteredProducts = [];
        if (search) {
            filteredProducts = allProducts.filter((product) => {
                return product.title
                    .toLowerCase()
                    .includes(search.toLowerCase());
            });
            return setProducts(filteredProducts);
        }
        return setProducts(allProducts);
    };
    // => Get active products
    const activeProducts = () => {
        let activeProducts = filter(products, { isActive: true });
        return activeProducts;
    };
    const paginatedProducts = paginator(
        reverse(activeProducts()),
        page,
        perPage,
    );

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setProducts(allProducts);
        }

        return () => {
            isSubscribed = false;
        };
    }, [allProducts]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={9}>
                    <CustomPagination
                        height={'48vh'}
                        data={paginatedProducts}
                        handlePageChange={handlePageChange}
                        handlePerPageChage={handlePerPageChage}
                        title={`${size(products)} products`}
                        action={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ProductFilter2
                                    filterByProductName={filterByProductName}
                                />
                                <CountBadge
                                    total={size(
                                        filter(products, { isActive: true }),
                                    )}
                                    item={'products'}
                                />
                            </Box>
                        }
                    >
                        <Grid container spacing={2} padding={2}>
                            {isEmpty(products) ? (
                                <>
                                    <NoContent
                                        message={
                                            'Store do not have any product.'
                                        }
                                    />
                                </>
                            ) : (
                                paginatedProducts.data?.map(
                                    (product, index) => (
                                        <Grid item sm={4} xs={12} key={index}>
                                            <ProductWidget product={product} />
                                        </Grid>
                                    ),
                                )
                            )}
                        </Grid>
                    </CustomPagination>
                </Grid>
                <Grid
                    item
                    sm={3}
                    sx={{
                        borderRadius: 3,
                    }}
                >
                    <CustomCard height={250} bgColor={colors.bgColor1}>
                        <Box mt={2}>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                }}
                            >
                                Total products
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 22,
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    mt: 0.5,
                                }}
                                className="head-font"
                            >
                                {size(products)}
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <Grid container spacing={2} padding={2}>
                                <Grid item sm={6} xs={6}>
                                    <StoreProductInfobox
                                        title={'Approved'}
                                        total={size(
                                            filter(products, {
                                                isApproved: true,
                                            }),
                                        )}
                                        bgColor={colors.bgColor3}
                                        icon={
                                            <Verified
                                                sx={{
                                                    color: colors.primary,
                                                }}
                                                className="icon"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <StoreProductInfobox
                                        title={'Active'}
                                        total={size(
                                            filter(products, {
                                                isActive: true,
                                            }),
                                        )}
                                        bgColor={colors.green}
                                        icon={
                                            <Check
                                                sx={{
                                                    color: colors.primary,
                                                }}
                                                className="icon"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <StoreProductInfobox
                                        title={'Suspended'}
                                        total={size(
                                            filter(products, {
                                                isSuspended: true,
                                            }),
                                        )}
                                        bgColor={colors.yellow}
                                        icon={
                                            <Warning
                                                sx={{
                                                    color: colors.red,
                                                }}
                                                className="icon"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <StoreProductInfobox
                                        title={'Denied'}
                                        total={size(
                                            filter(products, {
                                                isDenied: true,
                                            }),
                                        )}
                                        bgColor={colors.red}
                                        icon={
                                            <DangerousRounded
                                                sx={{
                                                    color: colors.gray,
                                                }}
                                                className="icon"
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CustomCard>
                </Grid>
            </Grid>
        </>
    );
};

export default StoreProducts;
