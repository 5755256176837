import React from "react";
import { Box, Grid, styled } from "@mui/material";
import DashStoresStats from "../components/dash/DashStoresStats";
import DashRevenue from "../components/dash/DashRevenue";
import DashRevenueStats from "../components/dash/DashRevenueStats";
import { useSelector } from "react-redux";
import {
    customersSelector,
    deliveryGuysSelector,
    ordersSelector,
    productsSelector,
    storesSelector,
    vendorsSelector,
} from "../state/features/selectors";
import BeemWidget from "../components/widgets/BeemWidget";
import AgizaModeBtn from "../components/forms/AgizaModeBtn";
import DashLayout from "./layouts/DashLayout";
import DashSidebar from "../components/sidebars/DashSidebar";
import { colors } from "../assets/utils/colors";

// ################ MainContent ###############
const MainContent = styled(Box)(({ theme }) => ({
    "& .section-head": {
        fontSize: 16,
        fontWeight: "bold",
        position: "relative",
        marginBottom: 15,
        color: colors.primary,
        "&::before": {
            content: "''",
            height: 2.5,
            width: 50,
            position: "absolute",
            bottom: -3,
            borderRadius: 110,
            background: colors.primary,
        },
    },
}));

// ################ RevenueSection ###############
const RevenueSection = styled(Grid)(({ theme }) => ({
    borderRight: `1px dotted ${colors.primary}`,
    paddingRight: 20,
    [theme.breakpoints.down("md")]: {
        borderRight: `none`,
        paddingRight: 0,
    },
}));

const Dashboard = () => {
    // ############### Redux state ########################
    const { vendors } = useSelector(vendorsSelector);
    const { stores } = useSelector(storesSelector);
    const { products } = useSelector(productsSelector);
    const { orders } = useSelector(ordersSelector);
    const { totalCustomers, customers } = useSelector(customersSelector);
    const { deliveryGuys } = useSelector(deliveryGuysSelector);
    const revenueStats = {
        vendors,
        products,
        totalCustomers,
        deliveryGuys,
    };

    return (
        <React.Fragment>
            <DashLayout sidebar={<DashSidebar />}>
                <MainContent>
                    <Grid
                        container
                        spacing={2}
                        justifyContent={"space-between"}
                    >
                        {/* ########## Revenue & Statistics ########### */}
                        <Grid item sm={12} xs={12}>
                            <Box sx={{ display: { md: "none", xs: "block" } }}>
                                <Box sx={{ display: "flex" }}>
                                    <BeemWidget />
                                    <Box sx={{ ml: 5 }}>
                                        <AgizaModeBtn />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        {/* ########## Revenue & Statistics ########### */}
                        <RevenueSection item sm={6}>
                            <DashRevenue orders={orders} />
                            <DashRevenueStats stats={revenueStats} />
                        </RevenueSection>

                        {/* ########## Stores stats ########### */}
                        <Grid
                            item
                            sm={6}
                            sx={{
                                mt: { xs: 5, sm: 0, md: 0 },
                                borderLeft: `1px dotted ${colors.primary}`,
                            }}
                        >
                            <DashStoresStats stores={stores} />
                        </Grid>
                    </Grid>
                </MainContent>
            </DashLayout>
        </React.Fragment>
    );
};

export default Dashboard;
