import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Avatar, Grid } from '@mui/material';
import { Box } from '@mui/system';
import logo2 from '../../assets/media/images/logo2.png';
import logo1 from '../../assets/media/images/logo1.png';
import { colors } from '../../assets/utils/colors';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomTextField from '../forms/CustomTextField';
import CustomSubmitButton from '../forms/CustomSubmitButton';
import { useLoginMutation } from '../../api/authAPI';
import { useDispatch } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import { isEmpty } from 'lodash';
import { setUserProfile } from '../../state/features/auth/authSlice';
import HotToastfy from '../HotToastfy';
import { errorNotify } from '../../helpers/notify';

// ################### MUI CONSTANT ################
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiPaper-root': {
        borderRadius: 15,
        overflow: 'hidden',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

// ################### LoginModal ################
const LoginModal = styled(Grid)(({ theme }) => ({
    '& .left-content': {
        'height': '70vh',
        'width': '100vw',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'background': colors.bgColor3,
        'zIndex': 50,
        '& .fade-logo': {
            width: 250,
            height: 350,
            opacity: 0.1,
        },
        '& .sign-head': {
            fontSize: 30,
            fontWeight: 'bold',
        },
    },
    '& .right-content': {
        'height': '70vh',
        'width': '100vw',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'zIndex': 150,
        '& .input-sign-head': {
            zIndex: 100,
            fontWeight: 'bold',
        },
        '& .close-btn': {
            width: 35,
            height: 35,
            background: colors.bgColor2,
            position: 'absolute',
            top: 10,
            right: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50px',
        },
        '& .input-card': {
            width: 350,
            height: 300,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        '& .logo-con': {
            'width': '100%',
            'display': 'flex',
            'justifyContent': 'center',
            '& .logo': {
                width: 160,
                height: 66,
            },
        },
    },
    [theme.breakpoints.down('md')]: {
        '& .sign-head': {
            display: 'none',
        },
        '& .left-content': {
            position: 'absolute',
            width: '100%',
        },
        '& .input-card': {
            width: '90% !important',
            height: '100% !important',
            justifyContent: 'space-around !important',
        },
    },
}));

// ################### Validation ###############
const loginValidationSchema = Yup.object({
    login_id: Yup.string().required('Agiza id is a required field'),
    password: Yup.string().required(),
});

// !################## MAIN FUNC ####################
export default function LoginDialogy({ open, handleClose }) {
    // ################### CONST #####################
    const auth = useAuth();
    const dispatch = useDispatch();

    // ################### RTK #######################
    const [login, { isLoading }] = useLoginMutation();

    // => Handle login
    const handleLogin = async (payload) => {
        let res = await login(payload);
        if (!isEmpty(res.data)) {
            dispatch(setUserProfile(res.data));
            auth.saveAuthenticatedUser(res.data);
        } else if (res.error) {
            return errorNotify(
                res.error.data.message
                    ? res.error.data.message
                    : 'Temporary server error. Please try again letter.',
            );
        } else {
            let message = res.error.data.message;
            return errorNotify(message);
        }
    };

    return (
        <div>
            <HotToastfy />
            <BootstrapDialog
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                transitionDuration={500}
                maxWidth="md"
            >
                <DialogContent sx={{ padding: '0 !important' }}>
                    <LoginModal container>
                        {/* ################### LEFT ################## */}
                        <Grid item sm={6} className="left-content">
                            <Box
                                sx={{
                                    position: 'absolute',
                                    textAlign: 'center',
                                }}
                            >
                                <Typography className="head-font sign-head">
                                    Sign In
                                </Typography>
                                <Typography className="head-font sign-head">
                                    To
                                </Typography>
                                <Typography className="head-font sign-head">
                                    Agiza Africa
                                </Typography>
                            </Box>
                            <Box>
                                <Avatar
                                    alt="Agiza logo"
                                    src={logo2}
                                    className="fade-logo"
                                    variant="square"
                                />
                            </Box>
                        </Grid>

                        {/* ################### RIGHT ################## */}
                        <Grid item sm={6} className="right-content">
                            <Box className="close-btn">
                                <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Formik
                                initialValues={{
                                    login_id: '',
                                    password: '',
                                }}
                                validationSchema={loginValidationSchema}
                                onSubmit={(payload) => {
                                    handleLogin(payload);
                                }}
                            >
                                {(formik) => (
                                    <Box className="input-card">
                                        <Box className="logo-con">
                                            <Avatar
                                                src={logo1}
                                                alt="Agiza logo"
                                                className="logo"
                                            />
                                        </Box>
                                        <Box sx={{ mb: 2 }}>
                                            <Typography className="input-sign-head">
                                                Get in to your dashboard
                                            </Typography>
                                            <Box>
                                                <CustomTextField
                                                    type="text"
                                                    name="login_id"
                                                    label={'Type your Agiza ID'}
                                                />
                                                <CustomTextField
                                                    type="password"
                                                    name="password"
                                                    label={'Type your Password'}
                                                />
                                            </Box>
                                        </Box>
                                        <Box>
                                            <CustomSubmitButton
                                                loading={isLoading}
                                                onClick={formik.handleSubmit}
                                            >
                                                Sign in
                                            </CustomSubmitButton>
                                        </Box>
                                    </Box>
                                )}
                            </Formik>
                        </Grid>
                    </LoginModal>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
