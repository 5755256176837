import { Approval, Warning } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";

const ApproveAndDenyButton = ({ handleProductApprove, handleProductDeny }) => {
    return (
        <div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-around",
                }}
            >
                <Button
                    variant="contained"
                    size="small"
                    startIcon={<Approval />}
                    sx={{
                        boxShadow: "none",
                        mb: 1,
                        mr: 1,
                        fontSize: 10,
                        textTransform: "capitalize",
                        fontWeight: "bold",
                    }}
                    onClick={() => {
                        let type = "approve";
                        handleProductApprove(type);
                    }}
                >
                    Approve product
                </Button>

                <Button
                    variant="contained"
                    size="small"
                    color="error"
                    startIcon={<Warning />}
                    sx={{
                        boxShadow: "none",
                        mb: 1,
                        mr: 1,
                        fontSize: 10,
                        textTransform: "capitalize",
                        fontWeight: "bold",
                    }}
                    onClick={() => {
                        let type = "deny";
                        handleProductDeny(type);
                    }}
                >
                    Deny product
                </Button>
            </Box>
        </div>
    );
};

export default ApproveAndDenyButton;
