// => Get config
const GET_CONFIG = '/muted-status';

// => Enable agiza mode
const ENABLE_AGIZA_MODE = '/products/mute-unmute-non-agiza';

// => Disable agiza mode
const DISABLE_AGIZA_MODE = '/products/mute-unmute-non-agiza';

export const configEndpoints = {
    GET_CONFIG,
    ENABLE_AGIZA_MODE,
    DISABLE_AGIZA_MODE,
};
