import React from "react";
import { colors } from "../../assets/utils/colors";
import { ArrowCircleRightOutlined, Store } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Grid,
    ListItemText,
    Skeleton,
    Typography,
} from "@mui/material";
import { currencyFomater } from "../../helpers/currencyFomater";

const ProductCartWidget = ({ product, getProductDetails }, props) => {
    const { isLoading = false } = props;

    return (
        <>
            <Button
                fullWidth
                sx={{ p: 0 }}
                onClick={() => {
                    getProductDetails(product._id);
                }}
            >
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        borderRadius: 2,
                        overflow: "hidden",
                        width: "100%",
                        transition: ".2s all ease-in-out",
                        boxShadow: `1px 1px 5px 0px ${colors.bgColor1}`,
                        border: `1px solid ${colors.bgColor1}`,
                        "& .product-img": {
                            transition: ".2s all ease-in-out",
                            borderRadius: 2.5,
                            p: 1,
                            border: `1px solid ${colors.bgColor1}`,
                        },
                        "&:hover": {
                            transition: ".2s all ease-in-out",
                            border: `1px solid ${colors.secondary}`,
                            ".icon": {
                                right: 10,
                                top: 0,
                                transition: `.3s all ease-in-out`,
                                opacity: 1,
                            },
                        },
                        ".icon": {
                            position: "absolute",
                            right: 20,
                            top: 0,
                            opacity: 0,
                            transition: `.3s all ease-in-out`,
                        },
                    }}
                >
                    <Grid container padding={1} columnSpacing={1}>
                        <Grid item sm={3.5} xs={4}>
                            <Box
                                className="product-img"
                                sx={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                                }}
                            >
                                {isLoading ? (
                                    <Skeleton height={95} width={95} />
                                ) : (
                                    <Avatar
                                        variant="square"
                                        sx={{
                                            height: 50,
                                            width: 50,
                                            borderRadius: 2.5,
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                        }}
                                        src={product.banner_image}
                                    />
                                )}
                            </Box>
                        </Grid>
                        <Grid item sm={8.5} xs={8}>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ width: "100%" }}>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                noWrap
                                                className="head-font"
                                                sx={{
                                                    fontWeight: "bold",
                                                    color: colors.primary,
                                                    fontSize: 11,
                                                    textAlign: "left",
                                                }}
                                            >
                                                {product.title}
                                            </Typography>
                                        }
                                        secondary={
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Store
                                                    sx={{
                                                        fontSize: 14,
                                                        color: colors.primary,
                                                        mr: 0.5,
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontSize: 8,
                                                        fontWeight: "bold",
                                                        opacity: 0.8,
                                                    }}
                                                >
                                                    By {product.store_name}
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        mt: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        position: "relative",
                                    }}
                                >
                                    <Box>
                                        {product.discounted_price > 0 && (
                                            <Typography
                                                className="head-font"
                                                sx={{
                                                    fontSize: 9,
                                                    fontWeight: "bold",
                                                    color: colors.red,
                                                }}
                                            >
                                                <strike>
                                                    TZS
                                                    {currencyFomater(
                                                        (product.discounted_price *
                                                            product.price) /
                                                            100
                                                    )}
                                                </strike>
                                            </Typography>
                                        )}
                                        <Typography
                                            className="head-font"
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {currencyFomater(product.price)}
                                            <sup>TZS</sup>
                                        </Typography>
                                        <ArrowCircleRightOutlined className="icon" />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Button>
        </>
    );
};

export default ProductCartWidget;
