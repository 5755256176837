import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import CustomCard from "../cards/CustomCard";
import DashMainStoresCard from "../dash/DashMainStoresCard";
import StoreStatsWidget from "../widgets/StoreStatsWidget";
import { filter, size } from "lodash";

const StoresSidebar = ({ stores }) => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    p: 1,
                    px: 2,
                }}
            >
                <Typography
                    className="head-font"
                    sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        position: "relative",
                        color: colors.primary,
                        "&::before": {
                            content: "''",
                            height: 2.5,
                            width: 50,
                            position: "absolute",
                            bottom: -3,
                            borderRadius: 110,
                            background: colors.bgColor1,
                        },
                    }}
                >
                    Store statistics
                </Typography>
            </Box>
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    p: 1,
                }}
            >
                <DashMainStoresCard stores={stores} />
            </Box>
            <Box sx={{ flex: 1, p: 1 }}>
                <CustomCard height={150} bgColor={colors.bgColor3}>
                    <Grid container spacing={1} padding={2}>
                        <Grid item sm={6}>
                            <StoreStatsWidget
                                title={"Approved stores"}
                                total={size(
                                    filter(
                                        stores,
                                        ({ isApproved }) => isApproved === true
                                    )
                                )}
                                bdColor={colors.green}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <StoreStatsWidget
                                title={"Active store"}
                                total={size(
                                    filter(
                                        stores,
                                        ({ isActive }) => isActive === true
                                    )
                                )}
                                bdColor={colors.green}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <StoreStatsWidget
                                title={"Inactive store"}
                                total={size(
                                    filter(
                                        stores,
                                        ({ isActive }) => isActive === false
                                    )
                                )}
                                bdColor={colors.yellow}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <StoreStatsWidget
                                title={"Denied store"}
                                total={size(
                                    filter(
                                        stores,
                                        ({ isDenied }) => isDenied === true
                                    )
                                )}
                                bdColor={colors.red}
                            />
                        </Grid>
                    </Grid>
                </CustomCard>
            </Box>
        </>
    );
};

export default StoresSidebar;
