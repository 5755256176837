import React from "react";
import { Text, View } from "@react-pdf/renderer";

const ReportNoData = () => {
    return (
        <>
            <View
                style={{
                    height: "30vh",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Text style={{ textAlign: "center", opacity: 0.8 }}>
                    No data available
                </Text>
            </View>
        </>
    );
};

export default ReportNoData;
