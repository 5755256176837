// => GET all categories
const GET_ALL_CATEGORIES = '/categories';

// => CREATE category
const CREATE_CATEGORY = '/categories';

// => UPDATE category
const UPDATE_CATEGORY = '/categories/';

// => ACTIVATE category
const ACTIVATE_CATEGORY = '/categories/activate/';

// => DELETE category
const DELETE_CATEGORY = '/categories/';

export const categoriesEndpoints = {
    GET_ALL_CATEGORIES,
    CREATE_CATEGORY,
    UPDATE_CATEGORY,
    ACTIVATE_CATEGORY,
    DELETE_CATEGORY,
};
