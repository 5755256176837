import React from "react";
import {
    ArrowCircleRightOutlined,
    Category,
    Inventory2,
    LocalShipping,
    LocationOn,
    Unpublished,
    Verified,
} from "@mui/icons-material";
import { Box, Button, Chip, Rating, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { size } from "lodash";

const CartStoreWidget = ({ store, handler }) => {
    return (
        <>
            <Box
                sx={{
                    backgroundImage: `url(${store.store_image})`,
                    backgroundSize: `100% 190`,
                    backgroundRepeat: "no-repeat",
                    borderRadius: 2.5,
                    overflow: "hidden",
                    transition: ".3s all ease-in-out",
                    "&:hover": {
                        // transform: `scale(1.02)`,
                        transition: ".3s all ease-in-out",
                    },
                }}
            >
                <Box
                    sx={{
                        height: 150,
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        bgcolor: `rgba(125, 98, 212, .6)`,
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            height: 50,
                            bgcolor: `rgba(255, 255, 255, .5)`,
                            width: "95%",
                            borderRadius: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: 11,
                                width: 250,
                                fontWeight: "bold",
                                color: colors.primary,
                            }}
                        >
                            <LocationOn color="primary" sx={{ mx: 0.5 }} />
                            {store.location_name}
                        </Typography>
                        <Box
                            sx={{
                                height: 50,
                                borderRadius: 2,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    width: 10,
                                    height: 10,
                                    borderRadius: 50,
                                    bgcolor: store.isActive
                                        ? "green"
                                        : colors.red,
                                    mr: 1,
                                }}
                            ></Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            px: 2,
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ textAlign: "center" }}>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: colors.bgColor3,
                                }}
                            >
                                {size(store.store_products)}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    color: colors.bgColor3,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Inventory2 sx={{ fontSize: 10, mr: 0.4 }} />
                                Products
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: "center" }}>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: colors.bgColor3,
                                }}
                            >
                                {size(store.store_orders)}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    color: colors.bgColor3,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <LocalShipping sx={{ fontSize: 10, mr: 0.4 }} />
                                Orders
                            </Typography>
                        </Box>
                        <Box>
                            {store.isApproved ? (
                                <Chip
                                    label="Approved"
                                    size="small"
                                    sx={{
                                        fontSize: 9,
                                        fontWeight: "bold",
                                        bgcolor: colors.green,
                                        color: colors.primary,
                                    }}
                                />
                            ) : (
                                <Chip
                                    label="Not approved"
                                    size="small"
                                    sx={{
                                        fontSize: 9,
                                        fontWeight: "bold",
                                        bgcolor: colors.red,
                                        color: colors.bgColor3,
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        // height: 80,
                        background: `rgb(125, 98, 212, .9)`,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        p: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                                color: colors.gray,
                                textTransform: "uppercase",
                            }}
                        >
                            {store.title}
                        </Typography>
                        {store.verification_badge ? (
                            <Verified
                                color="primary"
                                sx={{
                                    bgcolor: colors.bgColor3,
                                    p: 0.4,
                                    borderRadius: 50,
                                    mb: 0.3,
                                }}
                            />
                        ) : (
                            <Unpublished
                                color="warning"
                                sx={{
                                    bgcolor: colors.bgColor3,
                                    p: 0.4,
                                    borderRadius: 50,
                                    mb: 0.3,
                                }}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                                color: colors.gray,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Category sx={{ mr: 1 }} />
                            {store.category_name}
                        </Typography>
                        <Rating
                            sx={{ fontSize: 18 }}
                            value={store.rating}
                            readOnly
                        />
                    </Box>
                    <Button
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{
                            p: 1,
                            mt: 2,
                            border: `1px solid ${colors.bgColor1}`,
                            ":hover": {
                                border: `1px solid ${colors.bgColor1}`,
                                ".icon": {
                                    right: 10,
                                    transition: `.3s all ease-in-out`,
                                    opacity: 1,
                                },
                                bgcolor: colors.bgColor3,
                            },
                            bgcolor: colors.bgColor3,
                            fontSize: 10,
                            fontWeight: "bold",
                            ".icon": {
                                position: "absolute",
                                right: 20,
                                opacity: 0,
                                transition: `.3s all ease-in-out`,
                            },
                        }}
                        onClick={() => {
                            handler(store?._id);
                        }}
                        className="animated-bg-3"
                    >
                        Select store
                        <ArrowCircleRightOutlined className="icon" />
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default CartStoreWidget;
