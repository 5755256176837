import { createSlice } from '@reduxjs/toolkit';

// => initial state
const initialState = {
    isLoading: false,
    products: [],
    productDetails: [],
};

const productSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {
        setProducts: (state, action) => {
            let products = action.payload.data;
            state.products = products;
        },
        setProductsIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setProductDetails: (state, action) => {
            let product = action.payload;
            state.productDetails = product;
        },
    },
});

export const {
    setProducts,
    setProductsIsLoading,
    setProductDetails,
} = productSlice.actions;
export default productSlice.reducer;
