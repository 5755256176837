import React from 'react';

// =============== MUI import ==============
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Avatar, Button, Container, styled } from '@mui/material';

// =============== Asset import ==============
import logo from '../assets/media/images/logo1.png';
import { radius } from '../assets/utils/radius';
import { colors } from '../assets/utils/colors';
import TimeWidget from './widgets/TimeWidget';

// ============= Styled MUI ==================
const TopBar = styled(AppBar)(({ theme }) => ({
    'boxShadow': 'none',
    'position': 'absolute',
    'backgroundImage': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
    '& .toolbar': {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundImage: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
    },
    '& .logo': {
        width: 150,
        height: 56,
    },
    '& .login-btn': {
        textTransform: 'capitalize',
        borderRadius: radius.btnRadius,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 30,
        paddingRight: 30,
        boxShadow: 'none',
    },
    [theme.breakpoints.down('md')]: {
        '.login-btn': {
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 15,
            paddingRight: 15,
            boxShadow: 'none',
        },
        '.toolbar-con': {
            background: 'red',
            padding: 0,
        },
        '.toolbar': {
            paddingTop: 10,
        },
    },
}));

const WelcomeTopbar = ({ handleClickOpen }) => {
    return (
        <>
            <TopBar position="fixed">
                <Container className="toolbar-con" maxWidth="xl">
                    <Toolbar className="toolbar">
                        {/* ################### LEFT ##################### */}
                        <Box>
                            <Avatar
                                alt="Agiza logo"
                                src={logo}
                                className="logo"
                            />
                        </Box>

                        {/* ################### CENTER ##################### */}
                        <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
                            <TimeWidget />
                        </Box>

                        {/* ################### RIGHT ##################### */}
                        <Box>
                            <Button
                                variant="contained"
                                className="login-btn app-btn"
                                onClick={() => {
                                    handleClickOpen();
                                }}
                            >
                                Go to dashboard
                            </Button>
                        </Box>
                    </Toolbar>
                </Container>
            </TopBar>
        </>
    );
};

export default WelcomeTopbar;
