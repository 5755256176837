import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import CustomCard from "../cards/CustomCard";
import DashOrdersStats from "../dash/DashOrdersStats";
import MostSellProductsTable from "../tables/MostSellProductsTable";
import { filter, size } from "lodash";

const ProductsSidebar = ({ products }) => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    p: 1,
                    px: 2,
                }}
            >
                <Typography
                    className="head-font"
                    sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        position: "relative",
                        color: colors.primary,
                        "&::before": {
                            content: "''",
                            height: 2.5,
                            width: 50,
                            position: "absolute",
                            bottom: -3,
                            borderRadius: 110,
                            background: colors.bgColor1,
                        },
                    }}
                >
                    Most selled products
                </Typography>
            </Box>
            <Box sx={{ flex: 1, p: 1 }}>
                <MostSellProductsTable />
            </Box>
            <Box sx={{ flex: 1, p: 1 }}>
                <CustomCard bgColor={colors.bgColor3}>
                    <Box mt={1.5}>
                        <Typography
                            sx={{
                                fontSize: 14,
                                textAlign: "center",
                                fontWeight: "bold",
                                color: colors.primary,
                            }}
                        >
                            Total products
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 22,
                                textAlign: "center",
                                fontWeight: "bold",
                                mt: 0.5,
                                opacity: 0.7,
                            }}
                            className="head-font"
                        >
                            {size(products)}
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={2} padding={1}>
                            <Grid item sm={6} xs={6}>
                                <DashOrdersStats
                                    title={"Approved"}
                                    total={size(
                                        filter(products, {
                                            isApproved: true,
                                        })
                                    )}
                                    bgColor={colors.primary}
                                    textColor={colors.bgColor3}
                                    type={"Products"}
                                />
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <DashOrdersStats
                                    title={"Active"}
                                    total={size(
                                        filter(products, {
                                            isActive: true,
                                        })
                                    )}
                                    bgColor={colors.green}
                                    type={"Products"}
                                />
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <DashOrdersStats
                                    title={"In-active"}
                                    total={size(
                                        filter(products, {
                                            isActive: false,
                                        })
                                    )}
                                    bgColor={colors.yellow}
                                    type={"Products"}
                                />
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <DashOrdersStats
                                    title={"Suspended"}
                                    total={size(
                                        filter(products, {
                                            isSuspended: true,
                                        })
                                    )}
                                    bgColor={"#000"}
                                    textColor={colors.bgColor3}
                                    type={"Products"}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            p: 1,
                            mt: 1,
                            textAlign: "center",
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            borderBottom: `2px solid ${colors.red}`,
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 13,
                                fontWeight: "bold",
                                opacity: 0.6,
                                color: "red",
                            }}
                        >
                            {size(filter(products, { isDenied: true }))} Denied
                            products
                        </Typography>
                    </Box>
                </CustomCard>
            </Box>
        </>
    );
};

export default ProductsSidebar;
