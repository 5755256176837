import { Box, Chip, Grid, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../../assets/utils/colors';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import { Add } from '@mui/icons-material';
import CreateCategory from './CreateCategory';
import ConfirmDelete from './ConfirmDelete';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import CategoryActionButtons from './CategoryActionButtons';
import { size } from 'lodash';

// ############## MUI styled #################
const ContentSection = styled(Box)(({ theme }) => ({
    'height': '75vh',
    'borderRadius': 5,
    'overflow': 'hidden',
    'boxShadow': '-1px 1px 2px 2px rgba(125, 98, 212, .1)',
    '& .categories-section': {
        height: '75vh',
        backgroundImage: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
    },
    '& .section-head': {
        'fontSize': 18,
        'fontWeight': 'bold',
        'color': colors.primary,
        'ml': 2,
        'position': 'relative',
        '&::before': {
            content: "''",
            height: 5,
            width: 100,
            background: colors.bgColor1,
            position: 'absolute',
            bottom: -8,
            borderRadius: 5,
        },
    },
    '& .table-con': {
        'background': 'transparent',
        'boxShadow': 'none',
        'height': 500,
        '& .table': {
            'width': '100%',
            '& tr': {
                borderBottom: `2px solid ${colors.bg}`,
            },
        },
        '& .thead-th': {
            fontWeight: 'bold',
            background: `${colors.bgColor1} !important`,
            opacity: 0.8,
            paddingTop: 10,
            paddingBottom: 10,
            borderBottom: `1px solid ${colors.bgColor1}`,
        },
    },
    '& .form-section': {
        'height': '75vh',
        'background': colors.bgColor1,
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        '& .input-sign-head': {
            fontWeight: 'bold',
            color: colors.primary,
        },
        '& .input-con': {
            height: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
    },
    [theme.breakpoints.down('md')]: {},
}));

function createData(
    s_n,
    name,
    total_sub,
    action,
    category_id,
    status,
    category_name,
    description,
) {
    return {
        s_n,
        name,
        total_sub,
        action,
        category_id,
        status,
        category_name,
        description,
    };
}

const MainCategories = ({ categories }) => {
    // ############## Table data #################
    const rows = categories?.map((category, index) =>
        createData(
            <Typography sx={{ fontWeight: 'bold', opacity: 0.6 }}>
                {++index}
            </Typography>,
            <Typography
                sx={{
                    fontSize: 14,
                    p: 1,
                    textAlign: 'center',
                    borderRadius: 2,
                    color: colors.primary,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                }}
            >
                {category.category_name}
            </Typography>,
            <Box sx={{ width: 250 }}>
                <Typography
                    sx={{
                        fontSize: 12,
                        mb: 0.5,
                        fontWeight: 'bold',
                        opacity: 0.6,
                        color: colors.primary
                    }}
                >
                    <Typography component={'span'} sx={{color: colors.green, fontWeight: 'bold', fontSize: 12}}>{size(category?.sub_categories)}</Typography> 
                    {" "} <sup>sub-category</sup>
                </Typography>
                {
                    category?.sub_categories.map((subCat) => (
                        <Chip
                            variant="outlined"
                            key={subCat._id}
                            label={subCat.title}
                            size="small"
                            sx={{
                                fontSize: 12,
                                mr: 1,
                                mb: 1,
                                color: colors.primary,
                                border: `1px dotted ${subCat.is_active?colors.green:colors.red}`,
                                backgroundImage: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                            }}
                        />
                    ))
                }
                {/* <Box sx={{ textAlign: 'center' }}>
                    <IconButton
                        size="small"
                        color="info"
                        sx={{
                            backgroundImage: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        }}
                    >
                        <Add />
                    </IconButton>
                </Box> */}
            </Box>,
            0,
            category._id,
            category.category_status,
            category.category_name,
            category.category_description,
        ),
    );

    // ############## Comp State #############
    const [categoryName, setCategoryName] = useState('');
    const [updateName, setUpdateName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [categoryID, setCategoryID] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);
    const [confimationDialogOpen, setConfimationDialogOpen] = useState(false);

    // ############## FUNC #################
    // => handle confimation dialog open
    const handleConfimationDialogOpen = () => {
        setConfimationDialogOpen(true);
    };

    // => handle confimation dialog close
    const handleConfimationDialogClose = () => {
        resetForm();
        setConfimationDialogOpen(false);
    };

    // => handle update category
    const handleUpdateCategory = (payload) => {
        setIsUpdate(true);
        setCategoryName(payload.name);
        setCategoryID(payload.category_id);
        setCategoryDescription(payload.description);
    };

    // => handle delete category
    const handleDeleteCategory = (category_id, categoryName) => {
        setCategoryID(category_id);
        setUpdateName(categoryName);
        handleConfimationDialogOpen();
    };

    // => handle add subcategory
    // const handleAddSubCategory = () => {};

    // => Reset form state
    const resetForm = () => {
        setIsUpdate(false);
        setCategoryName('');
        setCategoryID('');
        setCategoryDescription('');
    };

    return (
        <>
            {/* ############# Confimation dialogy ################ */}
            <ConfirmationDialog
                noDropClose
                openDialog={confimationDialogOpen}
                handleDialogClose={handleConfimationDialogClose}
            >
                <ConfirmDelete category={updateName} category_id={categoryID} />
            </ConfirmationDialog>
            <ContentSection>
                <Grid container>
                    <Grid item sm={8} xs={12}>
                        <Box className="categories-section">
                            {/* <Box sx={{ p: 2 }}>
                                <Typography className="head-font section-head">
                                    Agiza store main categories
                                </Typography>
                            </Box> */}
                            <Box
                                sx={{
                                    // px: 2,
                                    // py: 2,
                                }}
                            >
                                <TableContainer
                                    className="table-con"
                                    component={Paper}
                                >
                                    <Table
                                        className="table"
                                        stickyHeader
                                        size="small"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    className="head-font thead-th"
                                                    align="center"
                                                    width={10}
                                                >
                                                    S/N
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className="head-font thead-th"
                                                >
                                                    Category name
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className="head-font thead-th"
                                                >
                                                    Sub-categories
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className="head-font thead-th"
                                                >
                                                    Status
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className="head-font thead-th"
                                                >
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{
                                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {row.s_n}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.total_sub}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.status ? (
                                                            <Box
                                                                sx={{
                                                                    width: 15,
                                                                    height: 15,
                                                                    borderRadius: 50,
                                                                    bgcolor:
                                                                        'green',
                                                                    ml: 2,
                                                                }}
                                                            ></Box>
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                    width: 15,
                                                                    height: 15,
                                                                    borderRadius: 50,
                                                                    bgcolor:
                                                                        'red',
                                                                    ml: 2,
                                                                }}
                                                            ></Box>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Box
                                                            sx={{
                                                                ml: -6,
                                                            }}
                                                        >
                                                            <CategoryActionButtons
                                                                row={row}
                                                                handleUpdateCategory={
                                                                    handleUpdateCategory
                                                                }
                                                                handleDeleteCategory={
                                                                    handleDeleteCategory
                                                                }
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Grid>

                    {/* ######################## Form Input ###################### */}
                    <Grid item sm={4} xs={12}>
                        <CreateCategory
                            isUpdate={isUpdate}
                            resetForm={() => {
                                resetForm();
                            }}
                            categoryID={categoryID}
                            categoryName={categoryName}
                            categoryDescription={categoryDescription}
                        />
                    </Grid>
                </Grid>
            </ContentSection>
        </>
    );
};

export default MainCategories;
