import React, { useEffect } from "react";
import { Box, Grid, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import CustomTextField from "../../../components/forms/CustomTextField";
import CustomSelect from "../../../components/forms/CustomSelect";
import CustomSubmitButton from "../../../components/forms/CustomSubmitButton";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from "../../../helpers/RTKHelpers";
import { errorNotify, successNotify } from "../../../helpers/notify";
import SuccessFeedback from "../../../components/SuccessFeedback";
import { isEmpty } from "lodash";
import { useCreateCustomerMutation } from "../../../api/customerAPI";

// ############ ValidationC+Schema #############
const CreateCustomerValidation = Yup.object({
    first_name: Yup.string().required("first name is a required field"),
    last_name: Yup.string().required("last name is a required field"),
    gender: Yup.string().required(),
    phone_number: Yup.string().required("phone number is a required field"),
    country: Yup.string().required(),
    city: Yup.string().required(),
    address: Yup.string().required(),
    // password: Yup.string().required(),
});

const CreateCustomer = () => {
    // ############ CONST ###################
    const dispatch = useDispatch();

    // ############ RTK ###################
    const [createDeliveryGuy, { isLoading, isSuccess, isError, error, data }] =
        useCreateCustomerMutation();

    // ############ Handle create admin ################
    const handleCreateCustomer = (payload) => {
        createDeliveryGuy(payload);
    };
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                // dispatch(addDeliveryGuy(getRTKResponseData(data)));
                successNotify(getRTKSuccessMessage(data));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, data, error, dispatch]);

    return (
        <>
            {isSuccess ? (
                <>
                    <SuccessFeedback
                        message={
                            !isEmpty(data)
                                ? getRTKSuccessMessage(data)
                                : "Customer created successfully."
                        }
                    />
                </>
            ) : (
                <Box>
                    <Formik
                        initialValues={{
                            first_name: "",
                            last_name: "",
                            gender: "",
                            phone_number: "",
                            country: "",
                            city: "",
                            address: "",
                        }}
                        validationSchema={CreateCustomerValidation}
                        onSubmit={(payload) => {
                            let address = {
                                address: payload.address,
                                isPrimary: true,
                            };
                            let newPayload = { ...payload, address: address };
                            handleCreateCustomer(newPayload);
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Grid
                                    container
                                    columnSpacing={2}
                                    justifyContent={"center"}
                                >
                                    <Grid item sm={6}>
                                        <CustomTextField
                                            type={"text"}
                                            name="first_name"
                                            label={"First name"}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomTextField
                                            type={"text"}
                                            name="last_name"
                                            label={"Last name"}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomTextField
                                            type={"text"}
                                            name="phone_number"
                                            label={"Phone number"}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomSelect
                                            name="gender"
                                            label={"Gender"}
                                            formik={formik}
                                        >
                                            <MenuItem value="Male">
                                                Male
                                            </MenuItem>
                                            <MenuItem value="Female">
                                                Female
                                            </MenuItem>
                                        </CustomSelect>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <CustomTextField
                                            type={"text"}
                                            name="country"
                                            label={"Country"}
                                        />
                                    </Grid>
                                    <Grid item sm={4}>
                                        <CustomTextField
                                            type={"text"}
                                            name="city"
                                            label={"City"}
                                        />
                                    </Grid>
                                    <Grid item sm={4}>
                                        <CustomTextField
                                            type={"text"}
                                            name="address"
                                            label={"Location"}
                                        />
                                    </Grid>
                                    {/* <Grid item sm={6}>
                                        <CustomTextField
                                            type={"password"}
                                            name="password"
                                            label={"Password"}
                                        />
                                        <Typography
                                            sx={{ fontSize: 11, mt: 1 }}
                                        >
                                            Default password :{" "}
                                            <strong
                                                style={{
                                                    color: colors.primary,
                                                }}
                                            >
                                                buibui123
                                            </strong>
                                        </Typography>
                                    </Grid> */}
                                    <Grid item sm={6}>
                                        <Box sx={{ my: 2, mt: 5 }}>
                                            <CustomSubmitButton
                                                loading={isLoading}
                                                onClick={formik.handleSubmit}
                                            >
                                                Create customer
                                            </CustomSubmitButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            )}
        </>
    );
};

export default CreateCustomer;
