import React from "react";
import { CategoryOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";

const CategoryAssignButton = ({ handleProductApprove }) => {
    return (
        <div>
            <Button
                fullWidth
                className="app-btn-2"
                variant="contained"
                size="small"
                color="info"
                startIcon={<CategoryOutlined />}
                sx={{
                    boxShadow: "none",
                    mb: 1,
                    mr: 1,
                    fontSize: 10,
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                }}
                onClick={() => {
                    let type = "category";
                    handleProductApprove(type);
                }}
            >
                Sub - Category
            </Button>
        </div>
    );
};

export default CategoryAssignButton;
