import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { dashBaseUrl } from './baseUrl/baseURL';
import { beemEndpoints } from './endpoints/beemEndpoints';

export const beemAPI = createApi({
    reducerPath: 'beemAPI',
    baseQuery: fetchBaseQuery({ baseUrl: dashBaseUrl }),
    endpoints: (builder) => ({
        getBeemBalance: builder.query({
            query: () => beemEndpoints.BEEM_BALANCE,
        }),
    }),
});

export const { useGetBeemBalanceQuery } = beemAPI;
