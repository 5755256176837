import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colors } from '../../../assets/utils/colors';
import CustomCard from '../../../components/cards/CustomCard';
import VendorsSidebar from '../../../components/sidebars/VendorsSidebar';
import StoreWidget from '../../../components/widgets/StoreWidget';
import PageLayout from '../../layouts/PageLayout';
import VendorWidget4 from '../../../components/widgets/VendorWidget4';
import { useGetVendorByIdQuery } from '../../../api/vendorAPI';
import { useParams } from 'react-router-dom';
import ContentLoader from '../../../components/ContentLoader';
import { isEmpty } from 'lodash';
import CustomCard2 from '../../../components/cards/CustomCard2';
import NoContent from '../../../components/NoContent';
import { getRTKResponseData } from '../../../helpers/RTKHelpers';

const VendorDetails = () => {
    // ############### CONST ########################
    const { vendor_id } = useParams();
    const [vendor, setVendor] = useState({});

    // ############### RTK ########################
    const { isLoading, isSuccess, data: vendorDetails } = useGetVendorByIdQuery(
        vendor_id,
    );

    // ############### UseEffect ########################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                let vendor = getRTKResponseData(vendorDetails);
                setVendor(vendor);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, vendorDetails]);
    return (
        <>
            <PageLayout
                pageHead={
                    !isEmpty(vendor) &&
                    `Vendor details ( ${vendor.first_name} ${vendor.last_name} )`
                }
                sidebar={<VendorsSidebar />}
            >
                {isLoading ? (
                    <>
                        <CustomCard height={710}>
                            <ContentLoader />
                        </CustomCard>
                    </>
                ) : (
                    <>
                        <CustomCard2
                            title={`${vendor.first_name} ${vendor.last_name}`}
                            height={600}
                            action={
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        color: colors.primary,
                                        fontWeight: 'bold',
                                        bgcolor: colors.bgColor3,
                                        p: 1,
                                        borderRadius: 2,
                                    }}
                                >
                                    {vendor.vendor_id}
                                </Typography>
                            }
                        >
                            <Grid container>
                                <Grid item sm={12}>
                                    <VendorWidget4 vendor={vendor} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={4} padding={2}>
                                <Grid item sm={12} sx={{ mb: 2 }}>
                                    <Typography
                                        sx={{
                                            'fontSize': 16,
                                            'fontWeight': 'bold',
                                            'position': 'relative',
                                            'marginBottom': -10,
                                            'color': colors.primary,
                                            '&::before': {
                                                content: "''",
                                                height: 2.5,
                                                width: 50,
                                                position: 'absolute',
                                                bottom: -3,
                                                borderRadius: 110,
                                                background: colors.bgColor1,
                                            },
                                        }}
                                    >
                                        Vendor stores
                                    </Typography>
                                </Grid>
                                {isEmpty(vendor.vendor_stores) ? (
                                    <>
                                        <Grid item sm={12}>
                                            <NoContent
                                                message={'No store found'}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        {vendor.vendor_stores?.map((store) => (
                                            <Grid item sm={6} key={store._id}>
                                                <StoreWidget store={store} />
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </Grid>
                        </CustomCard2>
                    </>
                )}
            </PageLayout>
        </>
    );
};

export default VendorDetails;
