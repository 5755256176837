import {
    AppBar,
    Box,
    Chip,
    Grid,
    Typography,
} from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';
import { Inventory2 } from '@mui/icons-material';
import InnerPageTopbar from '../../components/InnerPageTopbar';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import MainCategories from './settingsComponents/MainCategories';
import { useSelector } from 'react-redux';
import { categoriesSelector, subCategoriesSelector } from '../../state/features/selectors';
import { size } from 'lodash';
import SubCategories from './settingsComponents/SubCategories';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        'id': `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

// !############# MAIN FUNC ##############
const Categories = () => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // ############### Redux state ################
    const { categories } = useSelector(categoriesSelector);
    const { subCategories } = useSelector(subCategoriesSelector);

    return (
        <>
            <Grid container>
                {/* ######### PAGE TITLE ############# */}
                <Grid item xs={12} sm={12} sx={{ px: 2 }}>
                    <InnerPageTopbar title="Agiza africa stores categories" />
                </Grid>
                {/* ######### PAGE TITLE ############# */}
                <Grid item xs={12} sm={12} sx={{ px: 2, mt: 0 }}>
                    <Box sx={{ bgcolor: 'background.paper' }}>
                        <AppBar position="static">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                aria-label="full width tabs example"
                            >
                                <Tab
                                    label={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: 12,
                                                    mr: 2,
                                                }}
                                            >
                                                <Inventory2
                                                    sx={{ mr: 1, fontSize: 16 }}
                                                />
                                                Main categories
                                            </Typography>
                                            <Chip
                                                sx={{ bgcolor: colors.green }}
                                                label={size(categories)}
                                                size="small"
                                            />
                                        </Box>
                                    }
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    label={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: 12,
                                                    mr: 2,
                                                }}
                                            >
                                                <Inventory2
                                                    sx={{ mr: 1, fontSize: 16 }}
                                                />
                                                Sub-categories
                                            </Typography>
                                            <Chip
                                                label={size(subCategories)}
                                                sx={{ bgcolor: colors.gray }}
                                                size="small"
                                            />
                                        </Box>
                                    }
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </AppBar>
                        <Box
                            sx={{
                                backgroundImage: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                            }}
                        >
                            {/* ######################## Categories ###################### */}
                            <TabPanel
                                value={value}
                                index={0}
                                dir={theme.direction}
                            >
                                <MainCategories categories={categories} />
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index={1}
                                dir={theme.direction}
                            >
                                <SubCategories categories={subCategories} />
                            </TabPanel>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Categories;
