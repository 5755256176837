import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { storesEndponts } from './endpoints/storesEndpoints';
import { baseURL } from './baseUrl/baseURL';
import { headers } from './baseUrl/setHeaders';

// !############## API ################
export const storeAPI = createApi({
    reducerPath: 'storeAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Stores'],
    endpoints: (builder) => ({
        // => GET all store
        getAllStores: builder.query({
            query: () => `${storesEndponts.GET_ALL_STORES}`,
            providesTags: ['Stores'],
        }),

        // => GET single store
        getSingleStoreById: builder.query({
            query: (payload) => `${storesEndponts.GET_SINGLE_STORE}` + payload,
        }),

        // => APPROVE store TIN
        approveStoreTIN: builder.mutation({
            query: (payload) => ({
                url: `${storesEndponts.APPROVE_TIN}` + payload,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: ['Stores'],
        }),
        // => DENY store TIN
        denyStoreTIN: builder.mutation({
            query: (payload) => ({
                url: `${storesEndponts.DENY_TIN}` + payload,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: ['Stores'],
        }),
        // => APPROVE business licence
        approveStoreBusinessLicence: builder.mutation({
            query: (payload) => ({
                url: `${storesEndponts.APPROVE_BUSINESS_LICENCE}` + payload,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: ['Stores'],
        }),
        // => DENY store business licence
        denyStoreBusinessLicence: builder.mutation({
            query: (payload) => ({
                url: `${storesEndponts.DENY_BUSINESS_LICENCE}` + payload,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: ['Stores'],
        }),
        // => VERIFY store
        verifyStore: builder.mutation({
            query: (payload) => ({
                url: `${storesEndponts.VERIFY_STORE}` + payload,
                method: `PUT`,
                body: ``,
            }),
            invalidatesTags: ['Stores'],
        }),
        // => SUSPEND store
        suspendStore: builder.mutation({
            query: (payload) => ({
                url: `${storesEndponts.SUSPEND_STORE}` + payload,
                method: `PUT`,
                body: ``,
            }),
            invalidatesTags: ['Stores'],
        }),
        // => RESUME store
        resumeStore: builder.mutation({
            query: (payload) => ({
                url: `${storesEndponts.RESUME_STORE}` + payload,
                method: `PUT`,
                body: ``,
            }),
            invalidatesTags: ['Stores'],
        }),
    }),
});

export const {
    useGetAllStoresQuery,
    useGetSingleStoreByIdQuery,
    useApproveStoreTINMutation,
    useDenyStoreTINMutation,
    useApproveStoreBusinessLicenceMutation,
    useDenyStoreBusinessLicenceMutation,
    useVerifyStoreMutation,
    useSuspendStoreMutation,
    useResumeStoreMutation,
} = storeAPI;
