import React, { useState } from 'react';
import { Pending, Verified, Warning } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { colors } from '../../../../assets/utils/colors';
import CustomDialog from '../../../../components/dialogs/CustomDialog';
import { isEmpty } from 'lodash';
import CustomCard2 from '../../../../components/cards/CustomCard2';
import NoContent from '../../../../components/NoContent';
import ConfirmationDialog from '../../../../components/dialogs/ConfirmationDialog';
import ApproveDocument from './ApproveDocument';
import DenyDocument from './DenyDocument';
import { getExtension } from '../../../../helpers/getFileExtension';

// !################# MAIN FUNC #####################
const StoreDocuments = ({ store }) => {
    // const [docsPreview, setDocsPreview] = useState('');
    const [previewType, setPreviewType] = useState('');
    const [opendDialog, setOpendDialog] = useState(false);
    const [actionType, setActionType] = useState('');
    const [confimationDialogOpen, setConfimationDialogOpen] = useState(false);
    const [documentType, setDocumentType] = useState('');

    // => handle dialogy open
    const handleDialogyOpen = (type) => {
        setPreviewType(type);
        setOpendDialog(true);
    };

    // => handle dialogy close
    const handleDialogyClose = () => {
        setOpendDialog(false);
    };

    // => handle confimation dialog open
    const handleConfimationDialogOpen = () => {
        setConfimationDialogOpen(true);
    };

    // => handle confimation dialog close
    const handleConfimationDialogClose = () => {
        setConfimationDialogOpen(false);
    };

    // => Handle document approve
    const handleDocsApprove = (document) => {
        setActionType('approve');
        setDocumentType(document);
        handleConfimationDialogOpen();
    };

    // => Handle document deny
    const handleDocsDeny = (document) => {
        setActionType('deny');
        setDocumentType(document);
        handleConfimationDialogOpen();
    };

    return (
        <>
            {/* ############# Preview dialogy ################ */}
            <CustomDialog
                openDialog={opendDialog}
                customWidth={'md'}
                handleDialogClose={handleDialogyClose}
                head={`${store.title} store ${
                    previewType === 'bl'
                        ? 'business lisence'
                        : 'TIN certificate'
                }`}
            >
                <Box
                    sx={{
                        bgcolor: colors.bgColor1,
                    }}
                >
                    {previewType === 'TIN' ? (
                        <>
                            {!isEmpty(store.tin_certificate) &&
                                (getExtension(store.tin_certificate) ===
                                'pdf' ? (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: 600,
                                        }}
                                    >
                                        <embed
                                            src={store.tin_certificate}
                                            type="application/pdf"
                                            style={{
                                                width: '100%',
                                                height: 600,
                                            }}
                                        ></embed>
                                    </Box>
                                ) : (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: 600,
                                        }}
                                    >
                                        <img
                                            src={store.tin_certificate}
                                            alt=""
                                            style={{
                                                width: '100%',
                                                height: 600,
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </Box>
                                ))}
                        </>
                    ) : (
                        <>
                            {!isEmpty(store.business_licence) &&
                            getExtension(store.business_licence) === 'pdf' ? (
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 600,
                                    }}
                                >
                                    <embed
                                        src={store.business_licence}
                                        type="application/pdf"
                                        style={{
                                            width: '100%',
                                            height: 600,
                                        }}
                                    ></embed>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 600,
                                    }}
                                >
                                    <img
                                        src={store.business_licence}
                                        alt=""
                                        style={{
                                            width: '100%',
                                            height: 600,
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </CustomDialog>

            {/* ############# Confimation dialogy ################ */}
            <ConfirmationDialog
                noDropClose
                openDialog={confimationDialogOpen}
                handleDialogClose={handleConfimationDialogClose}
            >
                {actionType === 'approve' ? (
                    <ApproveDocument document={documentType} store={store} />
                ) : (
                    <DenyDocument document={documentType} store={store} />
                )}
            </ConfirmationDialog>

            <Grid container spacing={10}>
                {/* #################### TIN ##################### */}
                <Grid item sm={6}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                sx={{
                                    'fontSize': 16,
                                    'fontWeight': 'bold',
                                    'position': 'relative',
                                    'marginBottom': 2,
                                    'color': colors.primary,
                                    '&::before': {
                                        content: "''",
                                        height: 2.5,
                                        width: 50,
                                        position: 'absolute',
                                        bottom: -3,
                                        borderRadius: 110,
                                        background: colors.bgColor1,
                                    },
                                }}
                            >
                                Store TIN Document
                            </Typography>
                            {store.tin_certificate && (
                                <Typography
                                    sx={{
                                        bgcolor: isEmpty(store.approval_id)
                                            ? colors.secondary
                                            : store.approval_id.status
                                                  .tin_certificate
                                            ? colors.green
                                            : store.approval_id.status
                                                  .tin_certificate === false
                                            ? colors.red
                                            : colors.secondary,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        px: 4,
                                        borderTopRightRadius: 15,
                                        borderTopLeftRadius: 15,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {isEmpty(store.approval_id) ? (
                                        <>
                                            <Pending
                                                sx={{
                                                    mr: 2,
                                                    color: colors.bgColor3,
                                                }}
                                            />
                                            Pending
                                        </>
                                    ) : store.approval_id.status
                                          .tin_certificate ? (
                                        <>
                                            <Verified
                                                sx={{
                                                    mr: 2,
                                                    color: colors.primary,
                                                }}
                                            />
                                            Approved
                                        </>
                                    ) : store.approval_id.status
                                          .tin_certificate === false ? (
                                        <>
                                            <Warning
                                                sx={{
                                                    mr: 2,
                                                    color: colors.bgColor3,
                                                }}
                                            />
                                            Denied
                                        </>
                                    ) : (
                                        <>
                                            <Pending
                                                sx={{
                                                    mr: 2,
                                                    color: colors.bgColor3,
                                                }}
                                            />
                                            Pending
                                        </>
                                    )}
                                </Typography>
                            )}
                        </Box>
                        {isEmpty(store.tin_certificate) ? (
                            <>
                                <CustomCard2 height={'430px'}>
                                    <NoContent
                                        message={'TIN certificate not uploaded'}
                                    />
                                </CustomCard2>
                            </>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        bgcolor: colors.bgColor1,
                                    }}
                                >
                                    {getExtension(store.tin_certificate) ===
                                    'pdf' ? (
                                        <embed
                                            src={store.tin_certificate}
                                            width={'100%'}
                                            height={'370px'}
                                            type="application/pdf"
                                        ></embed>
                                    ) : (
                                        <img
                                            src={store.tin_certificate}
                                            alt=""
                                            style={{
                                                width: '100%',
                                                height: '370px',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.primary})`,
                                        p: 1.5,
                                        borderBottomRightRadius: 10,
                                        borderBottomLeftRadius: 10,
                                    }}
                                >
                                    <Button
                                        className="app-btn"
                                        variant="contained"
                                        sx={{
                                            boxShadow: 'none',
                                            px: 5,
                                            textTransform: 'capitalize',
                                            opacity: 0.8,
                                            fontWeight: 'bold',
                                        }}
                                        onClick={() => {
                                            let type = 'TIN';
                                            handleDialogyOpen(type);
                                        }}
                                        color="info"
                                        size="small"
                                    >
                                        Preview
                                    </Button>
                                    {isEmpty(store.approval_id) ? (
                                        <Button
                                            className="app-btn"
                                            variant="contained"
                                            sx={{
                                                boxShadow: 'none',
                                                px: 5,
                                                textTransform: 'capitalize',
                                                opacity: 0.8,
                                                fontWeight: 'bold',
                                            }}
                                            color="success"
                                            size="small"
                                            onClick={() => {
                                                let document = 'TIN';
                                                handleDocsApprove(document);
                                            }}
                                        >
                                            Approve
                                        </Button>
                                    ) : !store.approval_id.status
                                          .tin_certificate ? (
                                        <Button
                                            className="app-btn"
                                            variant="contained"
                                            sx={{
                                                boxShadow: 'none',
                                                px: 5,
                                                textTransform: 'capitalize',
                                                opacity: 0.8,
                                                fontWeight: 'bold',
                                            }}
                                            color="success"
                                            size="small"
                                            onClick={() => {
                                                let document = 'TIN';
                                                handleDocsApprove(document);
                                            }}
                                        >
                                            Approve
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                    {isEmpty(store.approval_id) ? (
                                        <Button
                                            className="app-btn"
                                            variant="contained"
                                            sx={{
                                                boxShadow: 'none',
                                                px: 5,
                                                textTransform: 'capitalize',
                                                opacity: 0.8,
                                                fontWeight: 'bold',
                                            }}
                                            color="error"
                                            size="small"
                                            onClick={() => {
                                                let document = 'TIN';
                                                handleDocsDeny(document);
                                            }}
                                        >
                                            Deny
                                        </Button>
                                    ) : !store.approval_id.status
                                          .tin_certificate ? (
                                        <Button
                                            className="app-btn"
                                            variant="contained"
                                            sx={{
                                                boxShadow: 'none',
                                                px: 5,
                                                textTransform: 'capitalize',
                                                opacity: 0.8,
                                                fontWeight: 'bold',
                                            }}
                                            color="error"
                                            size="small"
                                            onClick={() => {
                                                let document = 'TIN';
                                                handleDocsDeny(document);
                                            }}
                                        >
                                            Deny
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                </Grid>
                {/* #################### BL ##################### */}
                <Grid item sm={6}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                sx={{
                                    'fontSize': 16,
                                    'fontWeight': 'bold',
                                    'position': 'relative',
                                    'marginBottom': 2,
                                    'color': colors.primary,
                                    '&::before': {
                                        content: "''",
                                        height: 2.5,
                                        width: 50,
                                        position: 'absolute',
                                        bottom: -3,
                                        borderRadius: 110,
                                        background: colors.bgColor1,
                                    },
                                }}
                            >
                                Store Business Licence
                            </Typography>
                            {store.business_licence && (
                                <Typography
                                    sx={{
                                        bgcolor: isEmpty(store.approval_id)
                                            ? colors.secondary
                                            : store.approval_id.status
                                                  .business_licence
                                            ? colors.green
                                            : store.approval_id.status
                                                  .business_licence === false
                                            ? colors.red
                                            : colors.secondary,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        px: 4,
                                        borderTopRightRadius: 15,
                                        borderTopLeftRadius: 15,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {isEmpty(store.approval_id) ? (
                                        <>
                                            <Pending
                                                sx={{
                                                    mr: 2,
                                                    color: colors.bgColor3,
                                                }}
                                            />
                                            Pending
                                        </>
                                    ) : store.approval_id.status
                                          .business_licence ? (
                                        <>
                                            <Verified
                                                sx={{
                                                    mr: 2,
                                                    color: colors.primary,
                                                }}
                                            />
                                            Approved
                                        </>
                                    ) : store.approval_id.status
                                          .business_licence === false ? (
                                        <>
                                            <Warning
                                                sx={{
                                                    mr: 2,
                                                    color: colors.bgColor3,
                                                }}
                                            />
                                            Denied
                                        </>
                                    ) : (
                                        <>
                                            <Pending
                                                sx={{
                                                    mr: 2,
                                                    color: colors.bgColor3,
                                                }}
                                            />
                                            Pending
                                        </>
                                    )}
                                </Typography>
                            )}
                        </Box>
                        {isEmpty(store.business_licence) ? (
                            <>
                                <CustomCard2 height={'430px'}>
                                    <NoContent
                                        message={
                                            'Business licence not uploaded'
                                        }
                                    />
                                </CustomCard2>
                            </>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        bgcolor: colors.bgColor1,
                                    }}
                                >
                                    {getExtension(store.business_licence) ===
                                    'pdf' ? (
                                        <embed
                                            src={store.business_licence}
                                            width={'100%'}
                                            height={'370px'}
                                            type="application/pdf"
                                        ></embed>
                                    ) : (
                                        <img
                                            src={store.business_licence}
                                            alt=""
                                            style={{
                                                width: '100%',
                                                height: '370px',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.primary})`,
                                        p: 1.5,
                                        borderBottomRightRadius: 10,
                                        borderBottomLeftRadius: 10,
                                    }}
                                >
                                    <Button
                                        className="app-btn"
                                        variant="contained"
                                        sx={{
                                            boxShadow: 'none',
                                            px: 5,
                                            textTransform: 'capitalize',
                                            opacity: 0.8,
                                            fontWeight: 'bold',
                                        }}
                                        onClick={() => {
                                            let type = 'bl';
                                            handleDialogyOpen(type);
                                        }}
                                        color="info"
                                        size="small"
                                    >
                                        Preview
                                    </Button>
                                    {isEmpty(store.approval_id) ? (
                                        <Button
                                            className="app-btn"
                                            variant="contained"
                                            sx={{
                                                boxShadow: 'none',
                                                px: 5,
                                                textTransform: 'capitalize',
                                                opacity: 0.8,
                                                fontWeight: 'bold',
                                            }}
                                            color="success"
                                            size="small"
                                            onClick={() => {
                                                let document =
                                                    'Business licence';
                                                handleDocsApprove(document);
                                            }}
                                        >
                                            Approve
                                        </Button>
                                    ) : !store.approval_id.status
                                          .business_licence ? (
                                        <Button
                                            className="app-btn"
                                            variant="contained"
                                            sx={{
                                                boxShadow: 'none',
                                                px: 5,
                                                textTransform: 'capitalize',
                                                opacity: 0.8,
                                                fontWeight: 'bold',
                                            }}
                                            color="success"
                                            size="small"
                                            onClick={() => {
                                                let document =
                                                    'Business licence';
                                                handleDocsApprove(document);
                                            }}
                                        >
                                            Approve
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                    {isEmpty(store.approval_id) ? (
                                        <Button
                                            className="app-btn"
                                            variant="contained"
                                            sx={{
                                                boxShadow: 'none',
                                                px: 5,
                                                textTransform: 'capitalize',
                                                opacity: 0.8,
                                                fontWeight: 'bold',
                                            }}
                                            color="error"
                                            size="small"
                                            onClick={() => {
                                                let document =
                                                    'Business licence';
                                                handleDocsDeny(document);
                                            }}
                                        >
                                            Deny
                                        </Button>
                                    ) : !store.approval_id.status
                                          .business_licence ? (
                                        <Button
                                            className="app-btn"
                                            variant="contained"
                                            sx={{
                                                boxShadow: 'none',
                                                px: 5,
                                                textTransform: 'capitalize',
                                                opacity: 0.8,
                                                fontWeight: 'bold',
                                            }}
                                            color="error"
                                            size="small"
                                            onClick={() => {
                                                let document =
                                                    'Business licence';
                                                handleDocsDeny(document);
                                            }}
                                        >
                                            Deny
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default StoreDocuments;
