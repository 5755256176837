import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../../assets/utils/colors";
import ProductWidget from "../../../components/widgets/ProductWidget";
import PageLayout from "../../layouts/PageLayout";
import ProductsSidebar from "../../../components/sidebars/ProductsSidebar";
import { useSelector } from "react-redux";
import { productsSelector } from "../../../state/features/selectors";
import { filter, isEmpty, reverse, size } from "lodash";
import { useParams } from "react-router-dom";
import NoContent from "../../../components/NoContent";
import { paginator } from "../../../helpers/paginationHelper";
import CustomPaginationTwo from "../../../components/paginations/CustomPaginationTwo";
import ProductFilter from "../../../components/filters/ProductFilter";
import CountBadge from "../../../components/CountBadge";

// !################# MAIN FUNC ###################
const Products = () => {
    // ############### Redux state ########################
    const { products } = useSelector(productsSelector);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ############### FUNC ###########################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => component state
    const [filteredProducts, setFilteredProducts] = useState([]);

    // => GET filtered products
    const { status } = useParams();
    const getFilteredProducts = () => {
        let filteredProducts = "";
        if (status === "approval request") {
            filteredProducts = filter(products, { isApproved: false });
        } else if (status === "approved") {
            filteredProducts = filter(products, { isApproved: true });
        } else if (status === "active") {
            filteredProducts = filter(products, { isActive: true });
        } else if (status === "in active") {
            filteredProducts = filter(products, { isActive: false });
        } else if (status === "suspended") {
            filteredProducts = filter(products, { isSuspended: true });
        } else if (status === "denied") {
            filteredProducts = filter(products, { isDenied: true });
        } else if (status === "featured") {
            filteredProducts = filter(products, { is_featured: true });
        } else if (status === "best-seller") {
            filteredProducts = filter(products, { is_best_seller: true });
        }
        return reverse(filteredProducts);
    };
    const newProducts = getFilteredProducts();
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setFilteredProducts(newProducts);
        }
        return () => {
            isSubscribed = false;
        };
    }, [newProducts]);
    const paginatedProducts = paginator(filteredProducts, page, perPage);

    return (
        <>
            <PageLayout
                pageHead={`Agiza africa ${status} products`}
                sidebar={<ProductsSidebar products={products} />}
                bp
            >
                <Box
                    sx={{
                        flex: 1,
                        position: "relative",
                        borderRadius: 3,
                        overflow: "hidden",
                    }}
                >
                    <CustomPaginationTwo
                        title={`${status} products`}
                        data={paginatedProducts}
                        handlePageChange={handlePageChange}
                        handlePerPageChage={handlePerPageChage}
                        action={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                {/* <ProductFilter
                                    filterByProductName={filterByProductName}
                                    filterByCategory={filterByCategory}
                                /> */}
                                <CountBadge
                                    total={size(filteredProducts)}
                                    item={"products"}
                                />
                            </Box>
                        }
                    >
                        <Grid container spacing={2} padding={2}>
                            {isEmpty(products) ? (
                                <>
                                    <NoContent
                                        message={`No ${status} products found`}
                                        height={400}
                                    />
                                </>
                            ) : (
                                paginatedProducts.data?.map(
                                    (product, index) => (
                                        <Grid item sm={4} xs={12} key={index}>
                                            <ProductWidget product={product} />
                                        </Grid>
                                    )
                                )
                            )}
                        </Grid>
                    </CustomPaginationTwo>
                </Box>
                {/* <CustomPagination
                    title={`${status} products`}
                    height={"70vh"}
                    data={paginatedProducts}
                    handlePageChange={handlePageChange}
                    handlePerPageChage={handlePerPageChage}
                    action={
                        <Typography
                            sx={{
                                p: 1,
                                borderRadius: 1,
                                fontSize: 13,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                            }}
                        >
                            {size(filteredProducts)} products
                        </Typography>
                    }
                >
                    <Grid container spacing={2} padding={2}>
                        {isEmpty(filteredProducts) ? (
                            <>
                                <NoContent
                                    message={`No ${status} products found`}
                                />
                            </>
                        ) : (
                            paginatedProducts.data?.map((product, index) => (
                                <Grid item sm={4} key={index}>
                                    <ProductWidget product={product} />
                                </Grid>
                            ))
                        )}
                    </Grid>
                </CustomPagination> */}
            </PageLayout>
        </>
    );
};

export default Products;
