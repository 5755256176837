import React, { useEffect, useState } from "react";
import { Box, Grid, styled } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import OrderInfobox from "../../components/infoBoxes/OrderInfobox";
import MainOrderTableTwo from "../../components/tables/MainOrderTableTwo";
import OrdersSidebar from "../../components/sidebars/OrdersSidebar";
import { useSelector } from "react-redux";
import { ordersSelector } from "../../state/features/selectors";
import CustomFlexCard from "../../components/cards/CustomFlexCard";
import { filter, isEmpty, size } from "lodash";
import NoContent from "../../components/NoContent";
import OrderFilter from "../../components/filters/OrderFilter";
import moment from "moment/moment";

// !################# MAIN FUNC ###################
const Orders = () => {
    // ############### Component state ########################
    const [orders, setOrders] = useState([]);
    const [byDateFrom, setByDateFrom] = useState("");
    const [byDateTo, setByDateTo] = useState("");
    const [byPayment, setByPayment] = useState("");
    const [byStatus, setByStatus] = useState("");

    // ############### Redux state ########################
    const { orders: allOrders } = useSelector(ordersSelector);

    // ############### Filters ###########################
    // => Filter by date
    const filterByDateFrom = (date) => {
        setByDateFrom(date);
    };
    // => Filter by date
    const filterByDateTo = (date) => {
        setByDateTo(date);
    };
    // => Filter by payment
    const filterByPayment = (payment) => {
        setByPayment(payment);
    };
    // => Filter by status
    const filterByStatus = (status) => {
        setByStatus(status);
    };

    // ############## General Filter ###############
    const filterOrders = (dateFrom, dateTo, payment, status) => {
        let filteredOrders = [];
        var startDate = moment(dateFrom).format("YYYY-MM-DD");
        var endDate = moment(dateTo).format("YYYY-MM-DD");
        if (dateFrom !== "" || dateTo !== "") {
            filteredOrders = filter(
                allOrders,
                ({ createdAt }) =>
                    moment(createdAt).format("YYYY-MM-DD") >= startDate &&
                    moment(createdAt).format("YYYY-MM-DD") <= endDate
            );
        }
        if (payment !== "") {
            filteredOrders = filter(
                isEmpty(filteredOrders) ? allOrders : filteredOrders,
                ({ isPaid }) => isPaid === payment
            );
        }
        if (status !== "") {
            filteredOrders = filter(
                isEmpty(filteredOrders) ? allOrders : filteredOrders,
                ({ order_status }) => order_status === byStatus
            );
        }
        if (
            dateFrom !== "" ||
            dateTo !== "" ||
            payment !== "" ||
            status !== ""
        ) {
            return setOrders(filteredOrders);
        } else {
            return setOrders(allOrders);
        }
    };

    // => Reset filter
    const resetFilter = () => {
        setByDateFrom("");
        setByDateTo("");
        setByPayment("");
        setByStatus("");
    };

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            filterOrders(byDateFrom, byDateTo, byPayment, byStatus);
        }
        return () => {
            isSubscribed = false;
        };
    }, [byDateFrom, byDateTo, byPayment, byStatus]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setOrders(allOrders);
        }

        return () => {
            isSubscribed = false;
        };
    }, [allOrders]);

    return (
        <>
            <CustomFlexCard
                title={"All orders"}
                height={"100%"}
                action={
                    <OrderFilter
                        byDateFrom={byDateFrom}
                        byDateTo={byDateTo}
                        byPayment={byPayment}
                        byStatus={byStatus}
                        resetFilter={resetFilter}
                        filterByDateFrom={filterByDateFrom}
                        filterByDateTo={filterByDateTo}
                        filterByPayment={filterByPayment}
                        filterByStatus={filterByStatus}
                    />
                }
            >
                {isEmpty(orders) ? (
                    <>
                        <NoContent message={"No order placed"} />
                    </>
                ) : (
                    <MainOrderTableTwo orders={orders} />
                )}
            </CustomFlexCard>
        </>
    );
};

export default Orders;
