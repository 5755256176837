import { CircularProgress, IconButton, Switch, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { colors } from '../../../assets/utils/colors';
import { DeleteForever, Edit } from '@mui/icons-material';
import { useActivateCategoryMutation } from '../../../api/categoryAPI';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../helpers/RTKHelpers';
import { errorNotify, successNotify } from '../../../helpers/notify';
import { setUpdatedCategories } from '../../../state/features/categories/categorySlice';
import { useDispatch } from 'react-redux';

const CategoryActionButtons = ({
    row,
    handleUpdateCategory,
    handleDeleteCategory,
}) => {
    // ############## CONST ################
    const dispatch = useDispatch();

    // ############## RTK #################
    const [
        activateCategory,
        {
            isLoading: activateLoading,
            isSuccess: activateIsSuccess,
            isError: activateIsError,
            error: activateError,
            data: activatedCategory,
        },
    ] = useActivateCategoryMutation();

    // ############## FUNC #################
    // => handle activate category
    const handleActivateCategory = (paylod) => {
        activateCategory(paylod);
    };

    // ############## useEffect ###############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (activateIsSuccess) {
                successNotify(getRTKSuccessMessage(activatedCategory));
                dispatch(
                    setUpdatedCategories(getRTKResponseData(activatedCategory)),
                );
            }
            if (activateIsError) {
                errorNotify(getRTKErrorMessage(activateError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        activateIsSuccess,
        activateIsError,
        activateError,
        activatedCategory,
        dispatch,
    ]);
    return (
        <>
            <Tooltip title={!row.status ? 'Activate' : 'Deactivate'} arrow>
                <IconButton
                    size="small"
                    sx={{ mr: activateLoading ? 1 : 0 }}
                    onClick={() => {
                        handleActivateCategory(row.category_id);
                    }}
                >
                    {activateLoading ? (
                        <CircularProgress size={18} />
                    ) : !row.status ? (
                        <Switch
                            checked={false}
                            size="small"
                            disabled={activateLoading ? true : false}
                        />
                    ) : (
                        <Switch
                            checked={true}
                            size="small"
                            disabled={activateLoading ? true : false}
                        />
                    )}
                </IconButton>
            </Tooltip>

            <Tooltip title={'Edit'} arrow>
                <IconButton
                    sx={{
                        p: 0.5,
                        bgcolor: colors.bgColor1,
                        color: colors.primary,
                        mr: 1,
                    }}
                    size="small"
                    onClick={() => {
                        let payload = {
                            category_id: row.category_id,
                            name: row.category_name,
                            description: row.description,
                        };
                        handleUpdateCategory(payload);
                    }}
                >
                    <Edit
                        sx={{
                            fontSize: 16,
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title={'Delete'} arrow>
                <IconButton
                    sx={{
                        p: 0.5,
                        bgcolor: colors.bgColor1,
                        color: colors.red,
                        ml: 1,
                    }}
                    size="small"
                    onClick={() => {
                        handleDeleteCategory(
                            row.category_id,
                            row.category_name,
                        );
                    }}
                >
                    <DeleteForever
                        sx={{
                            fontSize: 16,
                        }}
                    />
                </IconButton>
            </Tooltip>
        </>
    );
};

export default CategoryActionButtons;
