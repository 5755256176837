import { Box, IconButton } from "@mui/material";
import React from "react";
import InnerPageTopbar from "../../components/InnerPageTopbar";
import { useLocation } from "react-router-dom";
import { colors } from "../../assets/utils/colors";
import DashBottomNav from "../../components/dash/DashBottomNav";

const PageLayout = ({ sidebar, pageHead, bp, children }) => {
    const { pathname } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    p: 1,
                    position: "relative",
                    justifyContent: "center",
                }}
                gap={2}
            >
                <Box
                    sx={{
                        flex: 2.8,
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        borderRadius: 5,
                        overflow: "hidden",
                    }}
                    className="animated-bg"
                >
                    {pageHead && <InnerPageTopbar title={pageHead} />}
                    <Box
                        sx={{
                            flex: 1,
                            position: "relative",
                            height: `100%`,
                        }}
                    >
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                display: "flex",
                                flexDirection: "column",
                                p: bp ? 0 : 1,
                            }}
                        >
                            {children}
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        position: "relative",
                        borderRadius: 5,
                        overflow: "hidden",
                        border: `5px solid ${colors.bgColor1}`,
                    }}
                    className="animated-bg"
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            boxShadow: `0px 0px 10px ${colors.bgColor1}`,
                            border: `2px solid ${colors.bgColor1}`,
                            overflowY: "auto",
                            // p: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {sidebar}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PageLayout;
