import { baseURL } from "./baseUrl/baseURL";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { headers } from "./baseUrl/setHeaders";
import { productEndponts } from "./endpoints/productsEndpoints";

// !############## API ################
export const productAPI = createApi({
    reducerPath: "productAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ["Products", "Product"],
    endpoints: (builder) => ({
        // => GET all product
        getAllProducts: builder.query({
            query: () => `${productEndponts.GET_ALL_PRODUCTS}`,
            providesTags: ["Products"],
        }),

        // => GET product by id
        getProductById: builder.query({
            query: (payload) =>
                `${productEndponts.GET_SINGLE_PRODUCT}/` + payload,
        }),

        // => APPROVE product
        approveProduct: builder.mutation({
            query: (payload) => ({
                url: `${productEndponts.APPROVE_PRODUCT}` + payload,
                method: `PUT`,
                body: ``,
            }),
            invalidatesTags: ["Products"],
        }),

        // => DENY product
        denyProduct: builder.mutation({
            query: (payload) => ({
                url: `${productEndponts.DENY_PRODUCT}` + payload,
                method: `PUT`,
                body: ``,
            }),
            invalidatesTags: ["Products"],
        }),

        // => SUSPEND product
        suspendProduct: builder.mutation({
            query: (payload) => ({
                url: `${productEndponts.SUSPEND_PRODUCT}` + payload,
                method: `PUT`,
                body: ``,
            }),
            invalidatesTags: ["Products"],
        }),

        // => RESUME product
        resumeProduct: builder.mutation({
            query: (payload) => ({
                url: `${productEndponts.RESUME_PRODUCT}` + payload,
                method: `PUT`,
                body: ``,
            }),
            invalidatesTags: ["Products"],
        }),

        // => FEATURE product
        featureProduct: builder.mutation({
            query: (payload) => ({
                url: `${productEndponts.FEATURE_PRODUCT}` + payload,
                method: `PUT`,
                body: ``,
            }),
            invalidatesTags: ["Products"],
        }),

        // => BEST SELL product
        bestSellProduct: builder.mutation({
            query: (payload) => ({
                url: `${productEndponts.BEST_SELL_PRODUCT}` + payload,
                method: `PUT`,
                body: ``,
            }),
            invalidatesTags: ["Products"],
        }),

        // => ASSIGN Sub-category
        assignSubCategory: builder.mutation({
            query: (payload) => ({
                url:
                    `${productEndponts.ASSIGN_SUB_CATEGORY}` +
                    payload.product_id +
                    "/" +
                    payload.sub_category_id,
                method: `PUT`,
            }),
            invalidatesTags: ["Products", "Product"],
        }),

        // => CHANGE Product store
        changeProductStore: builder.mutation({
            query: (payload) => ({
                url:
                    `${productEndponts.CHANGE_PRODUCT_STORE}` +
                    payload.product_id,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: ["Products", "Product"],
        }),

        // => DELETE product
        deleteProduct: builder.mutation({
            query: (payload) => ({
                url: `${productEndponts.DELETE_PRODUCT}` + payload,
                method: `DELETE`,
                body: ``,
            }),
            invalidatesTags: ["Products"],
        }),
    }),
});

export const {
    useGetAllProductsQuery,
    useGetProductByIdQuery,
    useApproveProductMutation,
    useDenyProductMutation,
    useSuspendProductMutation,
    useResumeProductMutation,
    useFeatureProductMutation,
    useBestSellProductMutation,
    useAssignSubCategoryMutation,
    useDeleteProductMutation,
    useChangeProductStoreMutation,
} = productAPI;
