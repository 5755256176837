import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import { OutboundOutlined } from "@mui/icons-material";

const PlatformWidget = ({ platform }) => {
    return (
        <>
            <Box
                sx={{
                    height: 90,
                    display: "flex",
                    borderRadius: 2,
                    overflow: "hidden",
                }}
            >
                <Box
                    sx={{
                        flex: 1.2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    className="animated-bg-3"
                >
                    <Avatar
                        src={platform.appIcon}
                        variant="square"
                        sx={{
                            height: 70,
                            width: 70,
                            borderRadius: 5,
                            background: `linear-gradient(45deg, ${colors.primary}, ${colors.bgColor1})`,
                        }}
                    />
                </Box>
                <a
                    href={platform.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        flex: 1,
                        display: "flex",
                    }}
                >
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "space-around",
                            background: `linear-gradient(90deg, ${colors.primary}, ${colors.secondary})`,
                            ".icon": {
                                fontSize: 25,
                                mb: 1,
                            },
                            ".arrow": {
                                fontSize: 25,
                                ml: 2,
                                transition: `.3s all ease-in-out`,
                            },
                            ":hover": {
                                ".arrow": {
                                    transform: `scale(1.2) rotate(45deg)`,
                                    transition: `.3s all ease-in-out`,
                                },
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                flex: 1,
                            }}
                        >
                            {platform.icon}
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    textTransform: "capitalize",
                                }}
                            >
                                {platform.name}
                            </Typography>
                        </Box>
                        <OutboundOutlined className="arrow" />
                    </Button>
                </a>
            </Box>
        </>
    );
};

export default PlatformWidget;
