import React, { useState } from "react";
import PageLayout from "../layouts/PageLayout";
import { Box } from "@mui/material";
import CustomFlexCard from "../../components/cards/CustomFlexCard";
import ChartDataFilter from "../../components/ChartDataFilter";
import VendorChart from "../../components/charts/VendorChart";
import { colors } from "../../assets/utils/colors";
import { useSelector } from "react-redux";
import {
    customersSelector,
    storesSelector,
    vendorsSelector,
} from "../../state/features/selectors";
import StoreChart from "../../components/charts/StoreChart";
import CustomerChart from "../../components/charts/CustomerChart";
import StatsAndReportSidebar from "../../components/sidebars/StatsAndReportSidebar";

const StatisticsAndReportsPage = () => {
    // ############### Comp state ####################
    const [vendorDateFilter, setVendorDateFilter] = useState("");
    const [storeDateFilter, setStoreDateFilter] = useState("");
    const [customerDateFilter, setCustomerDateFilter] = useState("");

    // ############### Redux state ########################
    const { vendors } = useSelector(vendorsSelector);
    const { stores } = useSelector(storesSelector);
    const { customers } = useSelector(customersSelector);

    // ############### FUNC ########################
    // => Vendor chart filter
    const handleVendorDateFilter = (value) => {
        setVendorDateFilter(value);
    };
    // => Store chart filter
    const handleStoresDateFilter = (value) => {
        setStoreDateFilter(value);
    };
    // => Customer chart filter
    const handleCustomerDateFilter = (value) => {
        setCustomerDateFilter(value);
    };

    return (
        <>
            <PageLayout
                pageHead={"Agiza africa stats and reports"}
                sidebar={<StatsAndReportSidebar />}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                    gap={2}
                >
                    <Box sx={{ flex: 1, display: "flex" }} gap={2}>
                        <Box
                            sx={{
                                flex: 1,
                                position: "relative",
                                borderRadius: 2,
                                overflow: "hidden",
                            }}
                        >
                            <CustomFlexCard
                                bgColor={colors.bgColor3}
                                action={
                                    <ChartDataFilter
                                        handleFilter={handleVendorDateFilter}
                                    />
                                }
                                title={"Vendors stats"}
                            >
                                <VendorChart
                                    title={"Vendors"}
                                    chartData={vendors}
                                    dateRange={vendorDateFilter}
                                />
                            </CustomFlexCard>
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                position: "relative",
                                borderRadius: 2,
                                overflow: "hidden",
                            }}
                        >
                            <CustomFlexCard
                                bgColor={colors.bgColor3}
                                action={
                                    <ChartDataFilter
                                        handleFilter={handleCustomerDateFilter}
                                    />
                                }
                                title={"Customer stats"}
                            >
                                <CustomerChart
                                    title={"Customers"}
                                    chartData={customers}
                                    dateRange={customerDateFilter}
                                />
                            </CustomFlexCard>
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1, display: "flex" }} gap={2}>
                        <Box
                            sx={{
                                flex: 1,
                                position: "relative",
                                borderRadius: 2,
                                overflow: "hidden",
                            }}
                        >
                            <CustomFlexCard
                                bgColor={colors.bgColor3}
                                action={
                                    <ChartDataFilter
                                        handleFilter={handleStoresDateFilter}
                                    />
                                }
                                title={"Stores stats"}
                            >
                                <StoreChart
                                    title={"Stores"}
                                    chartData={stores}
                                    dateRange={storeDateFilter}
                                />
                            </CustomFlexCard>
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                position: "relative",
                                borderRadius: 2,
                                overflow: "hidden",
                            }}
                        >
                            <CustomFlexCard
                                bgColor={colors.bgColor3}
                                action={
                                    <ChartDataFilter
                                        handleFilter={handleVendorDateFilter}
                                    />
                                }
                                title={"Vendors stats"}
                            >
                                <VendorChart
                                    title={"Vendors"}
                                    chartData={vendors}
                                    dateRange={vendorDateFilter}
                                />
                            </CustomFlexCard>
                        </Box>
                    </Box>
                </Box>
            </PageLayout>
        </>
    );
};

export default StatisticsAndReportsPage;
