import React from "react";
import { PlayCircle } from "@mui/icons-material";
import { Button } from "@mui/material";

const ActivateButton = ({ handleProductApprove }) => {
    return (
        <div>
            <Button
                className="app-btn-2"
                variant="contained"
                size="small"
                color="success"
                startIcon={<PlayCircle />}
                sx={{
                    boxShadow: "none",
                    mb: 1,
                    mr: 1,
                    fontSize: 10,
                    textTransform: "capitalize",
                    fontWeight: "bold",
                }}
                onClick={() => {
                    let type = "activate";
                    handleProductApprove(type);
                }}
            >
                Activate product
            </Button>
        </div>
    );
};

export default ActivateButton;
