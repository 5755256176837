import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { colors } from "../assets/utils/colors";
import {
    AdminPanelSettings,
    Airplay,
    AssignmentInd,
    Category,
    Group,
    Inventory2,
    LocalShipping,
    LocationOn,
    MonetizationOn,
    Search,
    Store,
    TransferWithinAStation,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    productsSelector,
    storesSelector,
    vendorsSelector,
} from "../state/features/selectors";
import { filter, isEmpty, size } from "lodash";
import NoContent from "./NoContent";

const SearchBar = () => {
    const [searchModal, setSearchModal] = useState("none");
    const [searchResults, setSearchResults] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    const searchTag = [
        {
            _id: 1,
            name: "Orders",
            icon: <LocalShipping className="tag-icon" />,
            url: "/orders",
        },
        {
            _id: 2,
            name: "Stores",
            icon: <Store className="tag-icon" />,
            url: "/stores",
        },
        {
            _id: 3,
            name: "Products",
            icon: <Inventory2 className="tag-icon" />,
            url: "/products",
        },
        {
            _id: 4,
            name: "Promotions",
            icon: <Airplay className="tag-icon" />,
            url: "/promotions",
        },
        {
            _id: 5,
            name: "Finance",
            icon: <MonetizationOn className="tag-icon" />,
            url: "/finance",
        },
        {
            _id: 6,
            name: "Admins",
            icon: <AdminPanelSettings className="tag-icon" />,
            url: "/admins",
        },
        {
            _id: 7,
            name: "Vendors",
            icon: <AssignmentInd className="tag-icon" />,
            url: "/vendors",
        },
        {
            _id: 8,
            name: "Customers",
            icon: <Group className="tag-icon" />,
            url: "/customers",
        },
        {
            _id: 9,
            name: "Delivery guys",
            icon: <TransferWithinAStation className="tag-icon" />,
            url: "/delivery-guys",
        },
        {
            _id: 10,
            name: "Regions",
            icon: <LocationOn className="tag-icon" />,
            url: "/regions",
        },
        {
            _id: 11,
            name: "Categories",
            icon: <Category className="tag-icon" />,
            url: "/categories",
        },
    ];

    // ############### Redux state ########################
    const { products: allProducts } = useSelector(productsSelector);
    const { stores: allStores } = useSelector(storesSelector);
    const { vendors: allVendors } = useSelector(vendorsSelector);

    const handleSearch = (searchQuery) => {
        // => Search product
        const searchProduct = (searchQuery) => {
            let filteredProducts = [];
            if (searchQuery) {
                filteredProducts = allProducts.filter((product) => {
                    return product.title
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase());
                });
                return filteredProducts;
            }
            return filteredProducts;
        };
        // => Search Stores
        const searchStores = (searchQuery) => {
            let filteredStores = [];
            if (searchQuery) {
                filteredStores = allStores.filter((store) => {
                    return store.title
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase());
                });
                return filteredStores;
            }
            return filteredStores;
        };
        // => Search Vendors
        const searchVendors = (searchQuery) => {
            let vendors = [];
            if (searchQuery) {
                vendors = allVendors.filter((vendor) => {
                    return (
                        vendor.vendor_id
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                        vendor.first_name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                        vendor.first_name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                    );
                });
                return vendors;
            }
            return vendors;
        };
        const searchResults = [
            {
                _id: 1,
                name: "Vendors",
                icon: <Inventory2 className="search-icon" />,
                total: size(searchVendors(searchQuery)),
            },
            {
                _id: 2,
                name: "Stores",
                icon: <Inventory2 className="search-icon" />,
                total: size(searchStores(searchQuery)),
            },
            {
                _id: 3,
                name: "Products",
                icon: <Inventory2 className="search-icon" />,
                total: size(searchProduct(searchQuery)),
            },
        ];
        const noResult = filter(searchResults, ({ total }) => total > 0);
        if (isEmpty(noResult)) {
            setSearchResults(noResult);
        } else {
            setSearchResults(searchResults);
        }
    };
    return (
        <div>
            <Box
                sx={{
                    position: "relative",
                    borderRadius: 2,
                    border: `2px solid ${colors.bgColor2}`,
                    width: 350,
                    display: { xs: "none", sm: "block" },
                }}
            >
                <ClickAwayListener
                    onClickAway={() => {
                        setSearchModal("none");
                        setSearchValue("");
                        setSearchResults([]);
                    }}
                >
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                        variant="outlined"
                        size="small"
                        placeholder="Type what you want"
                        sx={{
                            "&.MuiFormControl-root": {
                                bgcolor: colors.bgColor2,
                            },
                            "& input": {
                                fontSize: 14,
                            },
                        }}
                        onFocus={() => {
                            setSearchModal("block");
                            setSearchValue("");
                            setSearchResults([]);
                        }}
                        onKeyUp={(e) => {
                            setSearchValue(e.target.value);
                            handleSearch(e.target.value);
                        }}
                    />
                </ClickAwayListener>
                <Box
                    sx={{
                        height: 300,
                        position: "absolute",
                        left: 0,
                        right: 0,
                        p: 2,
                        display: searchModal,
                        borderLeft: `.15rem dotted ${colors.primary}`,
                        borderRight: `.15rem dotted ${colors.primary}`,
                        borderBottom: `.15rem dotted ${colors.primary}`,
                        borderBottomLeftRadius: 15,
                        borderBottomRightRadius: 15,
                        boxShadow: `0px 0px 10px -5px ${colors.primary}`,
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    }}
                >
                    {searchValue !== "" && isEmpty(searchResults) && (
                        <Box>
                            <NoContent
                                message={"No result found"}
                                height={200}
                            />
                        </Box>
                    )}

                    {searchValue !== "" && (
                        <Box>
                            {searchResults.map(
                                (result) =>
                                    result.total > 0 && (
                                        <List
                                            key={result._id}
                                            sx={{
                                                borderBottom: `1px dotted ${colors.primary}`,
                                                borderRadius: 2,
                                                "& .search-icon": {
                                                    color: colors.primary,
                                                    opacity: 0.8,
                                                },
                                            }}
                                        >
                                            <ListItem
                                                sx={{ p: 0 }}
                                                secondaryAction={
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                                fontWeight:
                                                                    "bold",
                                                                color: colors.primary,
                                                            }}
                                                        >
                                                            {result.total}
                                                        </Typography>
                                                    </Box>
                                                }
                                            >
                                                <ListItemAvatar>
                                                    <Avatar
                                                        sx={{
                                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                                        }}
                                                    >
                                                        {result.icon}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            sx={{
                                                                color: colors.primary,
                                                            }}
                                                        >
                                                            {result.name}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    )
                            )}
                        </Box>
                    )}
                    {searchValue === "" && (
                        <Box
                            sx={{
                                justifyContent: "center",
                                "& .tag-icon": {
                                    fontSize: `15px !important`,
                                    color: `${colors.primary} !important`,
                                },
                            }}
                        >
                            {searchTag.map((tag) => (
                                <Link to={tag.url} key={tag._id}>
                                    <Chip
                                        icon={tag.icon}
                                        label={tag.name}
                                        size="small"
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: "bold",
                                            px: 1,
                                            cursor: "pointer",
                                            color: colors.primary,
                                            mr: 1,
                                            mb: 1,
                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                            border: `1px dotted ${colors.primary}`,
                                            transition: `.3s all ease-in-out`,
                                            ":hover": {
                                                transform: `scale(1.05)`,
                                                transition: `.1s all ease-in-out`,
                                            },
                                        }}
                                    />
                                </Link>
                            ))}
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default SearchBar;
