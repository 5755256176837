import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { colors } from "../assets/utils/colors";

const ChartDataFilter = ({ handleFilter }) => {
    // ############## Comp state ##################
    const [dateRange, setDateRange] = React.useState("");

    // ############## CONST ##################
    const date = new Date();
    const currDateRange = `Jan - Jun '${date.getFullYear()}`;

    // ############## FUNC ##################
    const handleChange = (event) => {
        let value = event.target.value;
        setDateRange(value);
        handleFilter(value);
    };

    // ########### useEffect ################
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            handleFilter(currDateRange);
        }
        return () => {
            isSubscribed = false;
        };
    }, [currDateRange]);

    // ############ GET date ranges ##############
    const getDateRange = () => {
        let range1 = `Jan - Jun`;
        let range2 = `Jul - Dec`;
        const startYear = 2022;
        const currYear = date.getFullYear();
        let yearRange = [];
        for (let range = startYear; range <= currYear; range++) {
            let dataRange1 = `${range1} '${range}`;
            let dataRange2 = `${range2} '${range}`;
            yearRange.push(dataRange1);
            yearRange.push(dataRange2);
        }
        return yearRange;
    };
    const allDateRange = getDateRange();

    // handleFilter
    // ############ useEffect ##############
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setDateRange(currDateRange);
        }
        return () => {
            isSubscribed = false;
        };
    }, [currDateRange, setDateRange]);

    return (
        <FormControl
            sx={{
                m: 1,
                width: 140,
                "& .MuiInputBase-root": {
                    height: 25,
                },
                "& .MuiSelect-select": {
                    fontSize: 12,
                },
                bgcolor: colors.bgColor1,
            }}
            size="small"
        >
            <Select value={dateRange} onChange={handleChange}>
                {allDateRange?.map((date, index) => (
                    <MenuItem sx={{ fontSize: 12 }} value={date} key={index}>
                        {date}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ChartDataFilter;
