import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
    Avatar,
    Badge,
    Box,
    Stack,
    Tooltip,
    styled,
    useTheme,
} from "@mui/material";
// import logo from "../assets/media/images/logo1.png";
import { colors } from "../assets/utils/colors";
import {
    Apps,
    BarChart,
    ChevronLeft,
    ChevronRight,
    Notifications,
} from "@mui/icons-material";
import { authSelector } from "../state/features/selectors";
import { useSelector } from "react-redux";
import BeemWidget from "./widgets/BeemWidget";
import AgizaModeBtn from "./forms/AgizaModeBtn";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";
import AgizaAppBar from "./AgizaAppBar";
// import DarkModeToggle from "./DarkModeToggle";

// ################# CONSTANT ###############
const drawerWidth = 250;

// ################# STYLED APPBAR ###############
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
    background: colors.bgColor2,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    backgroundColor: colors.primary,
}));

// ################# TOPBAR RIGHT ###############
const TopbarRight = styled(Stack)({});

// ################# TOPBAR RIGHT ###############
const TopbarLeft = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .drawer-toggle": {
        padding: 1,
        backgroundImage: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.primary})`,
        color: colors.bgColor3,
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: colors.primary,
            color: colors.bgColor3,
            fontWeight: "bold",
        },
    },
});

// !###################### MAIN FUNC ############################
const TopBar = ({
    mobileOpen,
    desktopOpen,
    handleDrawerOpen,
    handleDrawerClose,
    handleDrawerToggle,
}) => {
    const theme = useTheme();
    const [openAppBar, setOpenAppBar] = React.useState(false);

    // ######### Redux states #############
    const { user } = useSelector(authSelector);

    const toggleAppDrawer = () => {
        setOpenAppBar(true);
    };

    const closeAppDrawer = () => {
        setOpenAppBar(false);
    };

    return (
        <>
            <AppBar
                position="sticky"
                sx={{
                    width: "100%",
                    borderBottom: `1px solid ${colors.bgColor1}`,
                }}
            >
                {/* ############### Notificationbar ###############*/}
                <AgizaAppBar
                    openAppBar={openAppBar}
                    closeAppDrawer={closeAppDrawer}
                />
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                        bgcolor: colors.bgColor3,
                    }}
                    className="animated-bg"
                >
                    {/* ################### LEFT ################# */}
                    <TopbarLeft>
                        <Box sx={{ display: { xs: "none", sm: "block" } }}>
                            {desktopOpen ? (
                                <IconButton
                                    className="drawer-toggle"
                                    onClick={handleDrawerClose}
                                >
                                    {theme.direction === "rtl" ? (
                                        <ChevronRight />
                                    ) : (
                                        <ChevronLeft />
                                    )}
                                </IconButton>
                            ) : (
                                <IconButton
                                    className="drawer-toggle"
                                    onClick={handleDrawerOpen}
                                >
                                    {theme.direction === "rtl" ? (
                                        <ChevronRight />
                                    ) : (
                                        <ChevronRight />
                                    )}
                                </IconButton>
                            )}
                        </Box>
                        <Box sx={{ display: { xs: "block", sm: "none" } }}>
                            {mobileOpen ? (
                                <IconButton
                                    className="drawer-toggle"
                                    onClick={handleDrawerToggle}
                                >
                                    {theme.direction === "rtl" ? (
                                        <ChevronRight />
                                    ) : (
                                        <ChevronLeft />
                                    )}
                                </IconButton>
                            ) : (
                                <IconButton
                                    className="drawer-toggle"
                                    onClick={handleDrawerToggle}
                                >
                                    {theme.direction === "rtl" ? (
                                        <ChevronRight />
                                    ) : (
                                        <ChevronRight />
                                    )}
                                </IconButton>
                            )}
                        </Box>
                        <Box>
                            <Typography
                                noWrap
                                sx={{
                                    ml: 2,
                                    color: colors.primary,
                                    fontWeight: "bold",
                                    display: { xs: "none", sm: "block" },
                                }}
                            >
                                Welcome -{" "}
                                <span style={{ textTransform: "capitalize" }}>
                                    {user.first_name}
                                </span>
                            </Typography>
                            <Typography
                                noWrap
                                sx={{
                                    color: colors.primary,
                                    ml: 2,
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    display: { xs: "none", sm: "block" },
                                }}
                            >
                                Agiza africa dashboard
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                ml: 2,
                                color: colors.primary,
                                fontWeight: "bold",
                                display: { xs: "block", sm: "none" },
                            }}
                        >
                            Agiza Afrika
                        </Typography>
                    </TopbarLeft>

                    {/* ################### CENTER ################ */}
                    <Box sx={{ display: { md: "block", xs: "none" } }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <SearchBar />
                        </Box>
                    </Box>

                    {/* ################### RIGHT ################# */}
                    <TopbarRight
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Box sx={{ display: { md: "block", xs: "none" } }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Box>
                                    <BeemWidget />
                                </Box>
                                <Box sx={{ ml: 3 }}>
                                    <AgizaModeBtn />
                                </Box>
                            </Box>
                        </Box>
                        {/* <Box>
                            <DarkModeToggle desktopOpen={true} />
                        </Box> */}
                        <Box>
                            <Link to={"/profile"}>
                                <Tooltip title="Profile" arrow>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            p: 0,
                                            height: 40,
                                            borderRadius: 2,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                            transition: `.3s all ease-in-out`,
                                            ":hover": {
                                                transition: `.3s all ease-in-out`,
                                                boxShadow: `0px 0px 5px ${colors.primary}`,
                                            },
                                            // border: `1px dotted ${colors.primary}`,
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Avatar
                                            alt="DP"
                                            src={""}
                                            sx={{
                                                background: `transparent`,
                                            }}
                                            variant="square"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </Box>
                        <Box>
                            <Link to={"/statistics-and-reports"}>
                                <Tooltip title="Stats and reports" arrow>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            px: 1,
                                            py: 0,
                                            height: 40,
                                            borderRadius: 2,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                            transition: `.3s all ease-in-out`,
                                            ":hover": {
                                                transition: `.3s all ease-in-out`,
                                                boxShadow: `0px 0px 5px ${colors.primary}`,
                                            },
                                            // border: `1px dotted ${colors.primary}`,
                                        }}
                                    >
                                        <Badge color="primary" variant="dot">
                                            <BarChart color="primary" />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </Box>
                        <Box>
                            <Link to={"/notifications"}>
                                <Tooltip title="Notifications" arrow>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            px: 1,
                                            py: 0,
                                            height: 40,
                                            borderRadius: 2,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                            transition: `.3s all ease-in-out`,
                                            ":hover": {
                                                transition: `.3s all ease-in-out`,
                                                boxShadow: `0px 0px 5px ${colors.primary}`,
                                            },
                                            // border: `1px dotted ${colors.primary}`,
                                        }}
                                    >
                                        <Badge color="secondary" variant="dot">
                                            <Notifications color="primary" />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </Box>
                        <Box>
                            <Tooltip title="Agiza App's" arrow>
                                <IconButton
                                    size="small"
                                    sx={{
                                        px: 1,
                                        py: 0,
                                        height: 40,
                                        borderRadius: 2,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        background: `linear-gradient(180deg, ${colors.secondary}, ${colors.primary})`,
                                        transition: `.3s all ease-in-out`,
                                        ":hover": {
                                            transition: `.3s all ease-in-out`,
                                            boxShadow: `0px 0px 5px ${colors.primary}`,
                                        },
                                    }}
                                    onClick={() => toggleAppDrawer()}
                                    className="animated-bg-3"
                                >
                                    <Apps sx={{ color: colors.bgColor3 }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </TopbarRight>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default TopBar;
