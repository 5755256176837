export const colors = {
    primary: '#7D62D4',
    secondary: '#fca311',
    bgColor1: '#D7CCFB',
    bgColor2: '#E0D7FF',
    bgColor3: '#ECE7FF',
    bgColor4:'#fff4e3',
    yellow: '#FFD900',
    green: '#00EE76',
    red: '#F43249',
    gray: '#D9D9D9',
    blue: '#027BBD',
}