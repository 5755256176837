import { ArrowRightAltOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const StoreInfobox = ({ title, total, bgColor, textColor, url }) => {
    return (
        <>
            <Link to={url}>
                <Box
                    sx={{
                        'height': 50,
                        'bgcolor': bgColor,
                        'width': '100%',
                        'display': 'flex',
                        'justifyContent': 'center',
                        'alignItems': 'center',
                        'flexDirection': 'column',
                        'borderRadius': 2,
                        'position': 'relative',
                        '& .icon-con': {
                            position: 'absolute',
                            right: 10,
                            mt: 1,
                            transition: '.3s all ease-in-out',
                        },
                        '&:hover': {
                            'transition': '.3s all ease-in-out',
                            '& .icon-con': {
                                right: 6,
                                transition: '.3s all ease-in-out',
                            },
                        },
                    }}
                >
                    <Typography sx={{ fontSize: 10, color: textColor, fontWeight: 'bold' }}>{title}</Typography>
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: textColor
                        }}
                    >
                        ({total}) Stores
                    </Typography>
                    <Box sx={{}} className="icon-con">
                        <ArrowRightAltOutlined
                            sx={{ fontSize: 25, color: textColor }}
                        />
                    </Box>
                </Box>
            </Link>
        </>
    );
};

export default StoreInfobox;
