import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { configEndpoints } from './endpoints/configEndpoints';
import getEnv from '../helpers/getEnv';
import { headers } from './baseUrl/setHeaders';

const proBaseUrl2 = getEnv().REACT_APP_PRODUCTION_URL2;

export const configAPI = createApi({
    reducerPath: 'configAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://secure.agiza.africa/api/v2',
        prepareHeaders: headers,
    }),
    tagTypes: ['AgizaMode'],
    endpoints: (builder) => ({
        // => Get config agiza mode
        getSystemConfig: builder.query({
            query: () => configEndpoints.GET_CONFIG,
            providesTags: ['AgizaMode'],
        }),

        // => Enable agiza mode
        enableAgizaMode: builder.mutation({
            query: () => ({
                url: configEndpoints.ENABLE_AGIZA_MODE,
                method: 'PUT',
            }),
            invalidatesTags: (arg, error) => (error ? [] : ['AgizaMode']),
        }),

        // => Disable agiza mode
        disableAgizaMode: builder.mutation({
            query: () => ({
                url: configEndpoints.DISABLE_AGIZA_MODE,
                method: 'PUT',
            }),
        }),
    }),
});

export const {
    useGetSystemConfigQuery,
    useEnableAgizaModeMutation,
    useDisableAgizaModeMutation,
} = configAPI;
