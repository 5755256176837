import { Box } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import DashBottomNav from "../../components/dash/DashBottomNav";

const DashLayout = ({ children, sidebar }) => {
    return (
        <>
            <Box sx={{ flex: 1, display: "flex" }}>
                <Box
                    sx={{
                        flex: 2.5,
                        display: "flex",
                        flexDirection: "column",
                        p: 1.5,
                        position: "relative",
                        overflow: "auto",
                        zIndex: 100,
                    }}
                    gap={2}
                >
                    <Box
                        sx={{
                            flex: 1,
                            position: "relative",
                            px: 2,
                            borderRadius: 5,
                            boxShadow: `0px 0px 10px ${colors.bgColor1}`,
                        }}
                        className="animated-bg"
                    >
                        {children}
                    </Box>

                    {/* ################ Bottom Nav ################### */}
                    <Box sx={{ mb: 0.5, zIndex: 200 }}>
                        <DashBottomNav />
                    </Box>
                </Box>

                {/* ################# Sidebar ################# */}
                <Box sx={{ flex: 1, position: "relative" }}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: 10,
                            bottom: 10,
                            left: 10,
                            right: 10,
                            borderRadius: 5,
                            boxShadow: `0px 0px 10px ${colors.bgColor1}`,
                            border: `2px solid ${colors.bgColor1}`,
                            overflowY: "auto",
                            p: 1.5,
                            // backgroundImage: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                        }}
                        className="animated-bg"
                    >
                        {sidebar}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default DashLayout;
