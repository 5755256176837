import { People, TransferWithinAStation } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';
import DashStatsCard from './DashStatsCard';
import { filter, size } from 'lodash';

const DashRevenueStats = ({ stats }) => {
    return (
        <>
            <Box sx={{ height: 150, mt: 4 }}>
                <Typography className="head-font section-head">
                    Statistics
                </Typography>
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <DashStatsCard
                            title={'Registered vendors'}
                            // total={308}
                            total={size(stats.vendors)}
                            bdColor={colors.yellow}
                            icon={
                                <People
                                    sx={{
                                        fontSize: 14,
                                        mr: 1,
                                        color: colors.primary,
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <DashStatsCard
                            title={'Registered customers'}
                            total={stats.totalCustomers}
                            bdColor={colors.green}
                            icon={
                                <People
                                    sx={{
                                        fontSize: 14,
                                        mr: 1,
                                        color: colors.primary,
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <DashStatsCard
                            title={'Approved products'}
                            total={size(
                                filter(stats.products, { isApproved: true }),
                            )}
                            bdColor={colors.green}
                            icon={
                                <People
                                    sx={{
                                        fontSize: 14,
                                        mr: 1,
                                        color: colors.primary,
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <DashStatsCard
                            title={'Registered riders'}
                            total={size(stats.deliveryGuys)}
                            bdColor={colors.primary}
                            icon={
                                <TransferWithinAStation
                                    sx={{
                                        fontSize: 14,
                                        mr: 1,
                                        color: colors.primary,
                                    }}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default DashRevenueStats;
