import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import { colors } from "../assets/utils/colors";
import { Avatar, Box, Button, List, Typography } from "@mui/material";
import logo from "../assets/media/images/logo1.png";
import Loader from "react-js-loader";
import {
    AdminPanelSettingsOutlined,
    AirplayOutlined,
    AssignmentIndOutlined,
    CategoryOutlined,
    GroupOutlined,
    HomeOutlined,
    Inventory2Outlined,
    LocalShippingOutlined,
    LocationOnOutlined,
    LogoutOutlined,
    MonetizationOnOutlined,
    StoreOutlined,
    TransferWithinAStationOutlined,
    Home,
    LocalShipping,
    Store,
    Inventory2,
    Airplay,
    MonetizationOn,
    AdminPanelSettings,
    AssignmentInd,
    Group,
    TransferWithinAStation,
    LocationOn,
    Category,
} from "@mui/icons-material";
import CustomListItem from "./CustomListItem";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
// import DarkModeToggle from './DarkModeToggle';

// ############### CONSTANTS #################
const drawerWidth = 250;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

// ############### STYLED DESKTOP DRAWER #################
const DrawerDesktop = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    "& .MuiPaper-root": {
        // backgroundColor: colors.bgColor3,
        border: "none !important",
    },
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

// ################# DrawerContent ################
const DrawerContent = styled(Box)(({ theme }) => ({
    bgcolor: colors.bgColor3,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 15,
    paddingRight: 15,
    transition: ".3s all ease-in-out",
    "& .list-title": {
        fontWeight: "bold",
        fontSize: 12,
    },
    "& .list-icon": {
        fontSize: 18,
        color: colors.primary,
    },
}));

//! #################### MAIN FUNC ######################
const SideBar = ({ handleDrawerToggle, mobileOpen, desktopOpen }) => {
    const { window } = "props";
    const container =
        window !== undefined ? () => window().document.body : undefined;
    const [isLogout, setIsLogout] = useState(false);

    // *############# Drawer Content #############
    // -> Menu list
    const menuList = [
        {
            id: 1,
            text: "Dashboard",
            icon: <HomeOutlined className="list-icon" />,
            activeIcon: <Home className="list-icon" />,
            url: "/dashboard",
        },
        {
            id: 2,
            text: "Orders",
            icon: <LocalShippingOutlined className="list-icon" />,
            activeIcon: <LocalShipping className="list-icon" />,
            url: "/orders",
        },
        {
            id: 3,
            text: "Stores",
            icon: <StoreOutlined className="list-icon" />,
            activeIcon: <Store className="list-icon" />,
            url: "/stores",
        },
        {
            id: 4,
            text: "Products",
            icon: <Inventory2Outlined className="list-icon" />,
            activeIcon: <Inventory2 className="list-icon" />,
            url: "/products",
        },
        {
            id: 5,
            text: "Promotions",
            icon: <AirplayOutlined className="list-icon" />,
            activeIcon: <Airplay className="list-icon" />,
            url: "/promotions",
        },
        {
            id: 6,
            text: "Finance",
            icon: <MonetizationOnOutlined className="list-icon" />,
            activeIcon: <MonetizationOn className="list-icon" />,
            url: "/finance",
        },
    ];
    // -> User list
    const userList = [
        {
            id: 1,
            text: "Admins",
            icon: <AdminPanelSettingsOutlined className="list-icon" />,
            activeIcon: <AdminPanelSettings className="list-icon" />,
            url: "/admins",
        },
        {
            id: 2,
            text: "Vendors",
            icon: <AssignmentIndOutlined className="list-icon" />,
            activeIcon: <AssignmentInd className="list-icon" />,
            url: "/vendors",
        },
        {
            id: 3,
            text: "Customers",
            icon: <GroupOutlined className="list-icon" />,
            activeIcon: <Group className="list-icon" />,
            url: "/customers",
        },
        {
            id: 4,
            text: "Delivery guys",
            icon: <TransferWithinAStationOutlined className="list-icon" />,
            activeIcon: <TransferWithinAStation className="list-icon" />,
            url: "/delivery-guys",
        },
    ];
    // -> setting list
    const settingList = [
        // {
        //     id: 1,
        //     text: 'Roles',
        //     icon: <ManageAccounts className="list-icon" />,
        //     url: '/roles',
        // },
        {
            id: 2,
            text: "Regions",
            icon: <LocationOnOutlined className="list-icon" />,
            activeIcon: <LocationOn className="list-icon" />,
            url: "/regions",
        },
        {
            id: 3,
            text: "Categories",
            icon: <CategoryOutlined className="list-icon" />,
            activeIcon: <Category className="list-icon" />,
            url: "/categories",
        },
    ];

    // *############# CONST #############
    const auth = useAuth();

    // => Handle logout
    const handleLogout = () => {
        setIsLogout(true);
        setTimeout(() => {
            auth.removeAuthenticatedUser();
        }, 3000);
    };

    const drawer = (
        <React.Fragment>
            <DrawerContent
                sx={{
                    paddingLeft: desktopOpen ? 2 : 1,
                    bgcolor: colors.bgColor3,
                }}
                className="animated-bg"
            >
                <Box>
                    <Typography className="list-title">Menu</Typography>
                    <Box>
                        <List sx={{ p: 0 }}>
                            {menuList?.map((item) => (
                                <CustomListItem
                                    key={item.id}
                                    desktopOpen={desktopOpen}
                                    text={item.text}
                                    icon={item.icon}
                                    activeIcon={item.activeIcon}
                                    url={item.url}
                                />
                            ))}
                        </List>
                    </Box>
                </Box>
                <Box>
                    <Typography className="list-title">Users</Typography>
                    <Box>
                        <List sx={{ p: 0 }}>
                            {userList?.map((item) => (
                                <CustomListItem
                                    key={item.id}
                                    desktopOpen={desktopOpen}
                                    text={item.text}
                                    icon={item.icon}
                                    activeIcon={item.activeIcon}
                                    url={item.url}
                                />
                            ))}
                        </List>
                    </Box>
                </Box>
                <Box>
                    <Typography className="list-title">Settings</Typography>
                    <Box>
                        <List sx={{ p: 0 }}>
                            {settingList?.map((item) => (
                                <CustomListItem
                                    key={item.id}
                                    desktopOpen={desktopOpen}
                                    text={item.text}
                                    icon={item.icon}
                                    activeIcon={item.activeIcon}
                                    url={item.url}
                                />
                            ))}
                        </List>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        // display: 'flex',
                        // flexDirection: 'column',
                        // justifyContent: 'center',
                        // alignItems: 'center'
                    }}
                >
                    {/* <DarkModeToggle desktopOpen={desktopOpen} /> */}
                    <Box sx={{ mt: 1, width: "100%" }}>
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{
                                color: colors.red,
                                bgcolor: colors.bgColor2,
                                boxShadow: "none",
                                fontWeight: "bold",
                                textTransform: "capitalize",
                                "&:hover": {
                                    boxShadow: "none",
                                    bgcolor: colors.bgColor1,
                                },
                            }}
                            startIcon={<LogoutOutlined />}
                            onClick={handleLogout}
                        >
                            <Typography
                                sx={{
                                    display: desktopOpen ? "block" : "none",
                                    fontSize: 12,
                                    fontWeight: "bold",
                                }}
                            >
                                Sign out
                            </Typography>
                            {isLogout && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                    }}
                                >
                                    <Loader
                                        type="bubble-loop"
                                        bgColor={colors.red}
                                        color={colors.primary}
                                        size={50}
                                    />
                                </Box>
                            )}
                        </Button>
                    </Box>
                </Box>
            </DrawerContent>
        </React.Fragment>
    );

    return (
        <>
            {/* ##################### mobile ################# */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "100%", // Change the width of drawer in mobile
                        backgroundColor: colors.bgColor3,
                    },
                }}
            >
                <Toolbar />
                {drawer}
            </Drawer>

            {/* ##################### desktop ################ */}
            <DrawerDesktop
                variant="permanent"
                open={desktopOpen}
                sx={{
                    display: { xs: "none", sm: "block" },
                }}
            >
                <Box
                    sx={{
                        bgcolor: colors.bgColor1,
                        height: 72,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 0.3,
                    }}
                >
                    <Avatar
                        src={logo}
                        alt="Agiza logo"
                        sx={{
                            height: desktopOpen ? 60 : 50,
                            width: desktopOpen ? 150 : 55,
                            transition: ".3s all ease-in-out",
                        }}
                    />
                </Box>
                {drawer}
            </DrawerDesktop>
        </>
    );
};

export default SideBar;
