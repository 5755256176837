import { createSlice } from '@reduxjs/toolkit';

// => initial state
const initialState = {
    orders: [],
    orderDetails: {},
    isLoading: false,
};

const orderSlice = createSlice({
    name: 'orderSlice',
    initialState,
    reducers: {
        setOrders: (state, action) => {
            let orders = action.payload.data;
            state.orders = orders;
        },
        setOrderDetails: (state, action) => {
            let orderDetails = action.payload;
            state.orderDetails = orderDetails;
        },
        setOrdersIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

export const {
    setOrders,
    setOrderDetails,
    setOrdersIsLoading,
} = orderSlice.actions;
export default orderSlice.reducer;
