import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import VendorsSidebar from "../../../components/sidebars/VendorsSidebar";
import VendorWidget from "../../../components/widgets/VendorWidget";
import PageLayout from "../../layouts/PageLayout";
import VendosFilter from "../../../components/filters/VendosFilter";
import { useSelector } from "react-redux";
import { vendorsSelector } from "../../../state/features/selectors";
import CountBadge from "../../../components/CountBadge";
import { size } from "lodash";
import CustomPaginationTwo from "../../../components/paginations/CustomPaginationTwo";
import { paginator } from "../../../helpers/paginationHelper";
import { DocumentScanner } from "@mui/icons-material";
import { colors } from "../../../assets/utils/colors";
import ReportDialog from "../../../components/dialogs/ReportDialog";
import VendorReport from "../../reports/VendorReport";

const Vendors = () => {
    // ############### Component state ###################
    const [vendors, setVendors] = useState([]);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);
    const [reportDialogOpen, setReportDialogOpen] = useState(false);

    // ############### Redux state ########################
    const { vendors: allVendors } = useSelector(vendorsSelector);

    // ############### FUNC ###########################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Filter by name/id
    const filterByNameOrID = (search) => {
        let vendors = [];
        if (search) {
            vendors = allVendors.filter((vendor) => {
                return (
                    vendor.vendor_id
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    vendor.first_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    vendor.first_name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                );
            });
            return setVendors(vendors);
        }
        return setVendors(allVendors);
    };
    // => Handle dialog open
    const handleDialogOpen = () => {
        setReportDialogOpen(true);
    };
    // => Handle dialog close
    const handleDialogClose = () => {
        setReportDialogOpen(false);
    };

    // ############## useEffect ################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setVendors(allVendors);
        }
        return () => {
            isSubscribed = false;
        };
    }, [setVendors, allVendors]);
    const paginatedVendors = paginator(vendors, page, perPage);

    return (
        <>
            <ReportDialog
                head={"Vendor report"}
                customWidth={"md"}
                openDialog={reportDialogOpen}
                handleDialogClose={handleDialogClose}
            >
                <VendorReport data={vendors} />
            </ReportDialog>
            <PageLayout
                pageHead={"Agiza africa vendors"}
                sidebar={<VendorsSidebar />}
            >
                <CustomPaginationTwo
                    title={"Registered vendors"}
                    data={paginatedVendors}
                    handlePageChange={handlePageChange}
                    handlePerPageChage={handlePerPageChage}
                    action={
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: 450,
                            }}
                        >
                            <VendosFilter filterByNameOrID={filterByNameOrID} />
                            <CountBadge
                                total={size(allVendors)}
                                item={"Vendors"}
                            />
                            <Tooltip title={"Vendor report"} arrow>
                                <IconButton
                                    color="info"
                                    onClick={() => {
                                        handleDialogOpen();
                                    }}
                                    sx={{
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <DocumentScanner />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                >
                    <Grid container spacing={2} padding={2}>
                        {paginatedVendors.data?.map((vendor, index) => (
                            <Grid item sm={6} key={vendor.vendor_id}>
                                <VendorWidget vendor={vendor} />
                            </Grid>
                        ))}
                    </Grid>
                </CustomPaginationTwo>
            </PageLayout>
        </>
    );
};

export default Vendors;
