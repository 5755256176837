import { Box, Grid, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import CustomTextField from '../../../components/forms/CustomTextField';
import CustomSelect from '../../../components/forms/CustomSelect';
import CustomSubmitButton from '../../../components/forms/CustomSubmitButton';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../helpers/RTKHelpers';
import { errorNotify, successNotify } from '../../../helpers/notify';
import SuccessFeedback from '../../../components/SuccessFeedback';
import { isEmpty } from 'lodash';
import { colors } from '../../../assets/utils/colors';
import { useCreatePromotionMutation } from '../../../api/promotionAPI';
import CustomMultlineTextField from '../../../components/forms/CustomMultlineTextField';
import { storesSelector } from '../../../state/features/selectors';
import CustomFileInput from '../../../components/forms/CustomFileInput';
import { Image } from '@mui/icons-material';
import { addPromotion } from '../../../state/features/promotions/promotionsSlice';

// ############ ValidationC+Schema #############
const createPromotionValidation = Yup.object({
    start_date: Yup.date().required('start time is a required field'),
    end_date: Yup.date().required('end time is a required field'),
    description: Yup.string().required('description is a required field'),
    promo_image: Yup.string().required('promo image is required field'),
    store: Yup.string().required(),
    isActive: Yup.boolean().required(),
});

const CreatePromotion = () => {
    const [filePreview, setFilePreview] = useState('');

    // ############ CONST ###################
    const dispatch = useDispatch();

    // ############ Redux state #############
    const { stores } = useSelector(storesSelector);

    // ############ RTK ###################
    const [
        createPromotion,
        { isLoading, isSuccess, isError, error, data },
    ] = useCreatePromotionMutation();

    // ############ Handle create admin ################
    const handleCreatePromotion = (payload) => {
        createPromotion(payload);
    };
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(addPromotion(getRTKResponseData(data)));
                successNotify(getRTKSuccessMessage(data));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, data, error, dispatch]);

    return (
        <>
            {isSuccess ? (
                <>
                    <SuccessFeedback
                        message={
                            !isEmpty(data)
                                ? getRTKSuccessMessage(data)
                                : 'Promotion created successfully.'
                        }
                    />
                </>
            ) : (
                <Box>
                    <Formik
                        initialValues={{
                            start_date: '',
                            end_date: '',
                            store: '',
                            promo_image: '',
                            description: '',
                            isActive: false,
                        }}
                        validationSchema={createPromotionValidation}
                        onSubmit={(payload) => {
                            let store_id = payload.store;
                            const data = new FormData();
                            data.append('store', store_id);
                            data.append('start_date', payload.start_date);
                            data.append('end_date', payload.end_date);
                            data.append('description', payload.description);
                            data.append('isActive', payload.isActive);
                            data.append('promo_image', payload.promo_image);
                            handleCreatePromotion(data);
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Grid
                                    container
                                    columnSpacing={2}
                                    justifyContent={'center'}
                                >
                                    <Grid item sm={12}>
                                        <Box
                                            sx={{
                                                height: 180,
                                                borderRadius: 3,
                                                overflow: 'hidden',
                                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                            }}
                                        >
                                            {filePreview ? (
                                                <>
                                                    <img
                                                        src={filePreview}
                                                        alt="img"
                                                        style={{
                                                            width: '100%',
                                                            height: '180px',
                                                            objectFit:
                                                                'contain',
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Image
                                                        sx={{
                                                            width: '100%',
                                                            height: '7em',
                                                            objectFit:
                                                                'contain',
                                                            opacity: 0.7,
                                                            color:
                                                                colors.bgColor1,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomFileInput
                                            type={'file'}
                                            name="promo_image"
                                            inputName={'promo_image'}
                                            label={'Promo image'}
                                            formik={formik}
                                            accept="image/*"
                                            setFilePreview={setFilePreview}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomSelect
                                            name="store"
                                            label={'Store'}
                                            formik={formik}
                                        >
                                            {stores?.map((store) => (
                                                <MenuItem
                                                    value={store._id}
                                                    key={store._id}
                                                >
                                                    {store.title}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomTextField
                                            type={'date'}
                                            name="start_date"
                                            label={'Start date'}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomTextField
                                            type={'date'}
                                            name="end_date"
                                            label={'End date'}
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <CustomMultlineTextField
                                            type={'text'}
                                            name="description"
                                            label={'Description'}
                                            rows={2}
                                        />
                                    </Grid>

                                    <Grid item sm={6}>
                                        <Box sx={{ my: 2, mt: 5 }}>
                                            <CustomSubmitButton
                                                loading={isLoading}
                                                onClick={formik.handleSubmit}
                                            >
                                                Create promotion
                                            </CustomSubmitButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            )}
        </>
    );
};

export default CreatePromotion;
