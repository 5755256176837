import * as React from 'react';
import { colors } from '../../assets/utils/colors';
import {
    Avatar,
    Box,
    Grid,
    IconButton,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    Inventory2,
    LocalShipping,
    LocationOn,
    Message,
    Phone,
    RemoveRedEye,
    StoreOutlined,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { size } from 'lodash';

// !############### MAIN FUNC #####################
const VendorWidget = ({ vendor, height }) => {
    // => Get total products
    const getTotalProducts = () => {
        let totalProducs = 0;
        vendor.vendor_stores.forEach((stores) => {
            totalProducs += size(stores.store_products);
        });
        return totalProducs;
    };

    // => Get total orders
    const getTotalOrders = () => {
        let totalOrders = 0;
        vendor.vendor_stores.forEach((stores) => {
            totalOrders += size(stores.store_orders);
        });
        return totalOrders;
    };
    return (
        <React.Fragment>
            <Box
                sx={{
                    overflow: 'hidden',
                    borderRadius: 4,
                    boxShadow: `1px 1px 5px 0px ${colors.bgColor1}`,
                    border: `1px solid ${colors.bgColor1}`,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                }}
            >
                <Grid container>
                    <Grid item sm={12}>
                        <Box
                            sx={{
                                p: 1.5,
                                textAlign: 'center',
                                boxShadow: `1px 1px 5px 0px ${colors.bgColor4}`,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                }}
                            >
                                {vendor.vendor_id}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                            }}
                        >
                            <Avatar
                                src={vendor.profile_picture}
                                sx={{
                                    height: 90,
                                    width: 90,
                                    borderRadius: 50,
                                    border: `3px solid ${colors.bgColor1}`,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={7} xs={12}>
                        <Box sx={{ p: 1 }}>
                            <Box>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography
                                                className="head-font"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: colors.primary,
                                                    fontSize: 14,
                                                }}
                                            >
                                                {vendor.first_name +
                                                    ' ' +
                                                    vendor.last_name}
                                            </Typography>
                                        </Box>
                                    }
                                    secondary={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <LocationOn
                                                sx={{
                                                    fontSize: 15,
                                                    color: colors.primary,
                                                    mr: 0.5,
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: 11,
                                                    fontWeight: 'bold',
                                                    opacity: 0.8,
                                                }}
                                            >
                                                {`${vendor.city}, ${vendor.nationality}`}
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </Box>
                            <Box sx={{ mb: 2, mt: 2 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        position: 'relative',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: 40,
                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            p: 2,
                                            borderRadius: 2.5,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                opacity: 0.8,
                                            }}
                                        >
                                            {size(vendor.vendor_stores)}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: colors.primary,
                                            }}
                                        >
                                            <StoreOutlined
                                                sx={{
                                                    fontSize: 10,
                                                    mr: 0.5,
                                                }}
                                            />
                                            Stores
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            height: 40,
                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            p: 2,
                                            borderRadius: 2.5,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                opacity: 0.8,
                                            }}
                                        >
                                            {getTotalProducts()}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: colors.primary,
                                            }}
                                        >
                                            <Inventory2
                                                sx={{
                                                    fontSize: 10,
                                                    mr: 0.5,
                                                }}
                                            />
                                            Products
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            height: 40,
                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            p: 2,
                                            borderRadius: 2.5,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                opacity: 0.8,
                                            }}
                                        >
                                            {getTotalOrders()}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: colors.primary,
                                            }}
                                        >
                                            <LocalShipping
                                                sx={{
                                                    fontSize: 10,
                                                    mr: 0.5,
                                                }}
                                            />
                                            Orders
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    {/* ################ Action buttons ################# */}
                    <Grid item sm={2} xs={12}>
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                p: 1,
                            }}
                        >
                            <Tooltip title={vendor.phone_number} arrow>
                                <IconButton
                                    sx={{
                                        p: 0.8,
                                        bgcolor: colors.bgColor1,
                                        color: colors.primary,
                                        ml: 1,
                                    }}
                                    size="small"
                                >
                                    <Phone
                                        sx={{
                                            fontSize: 16,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={vendor.email} arrow>
                                <IconButton
                                    sx={{
                                        p: 0.8,
                                        bgcolor: colors.bgColor1,
                                        color: colors.primary,
                                        ml: 1,
                                    }}
                                    size="small"
                                >
                                    <Message
                                        sx={{
                                            fontSize: 16,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Vendor details" arrow>
                                <Link to={`/vendors/details/${vendor._id}`}>
                                    <IconButton
                                        sx={{
                                            p: 0.8,
                                            bgcolor: colors.bgColor1,
                                            color: colors.primary,
                                            ml: 1,
                                        }}
                                        size="small"
                                    >
                                        <RemoveRedEye
                                            sx={{
                                                fontSize: 16,
                                            }}
                                        />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};

export default VendorWidget;
