import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { colors } from "../../../assets/utils/colors";
import StoreInfobox from "../../../components/infoBoxes/StoreInfobox";
import StoreWidget from "../../../components/widgets/StoreWidget";
import PageLayout from "../../layouts/PageLayout";
import StoresSidebar from "../../../components/sidebars/StoresSidebar";
import StoresFilter from "../../../components/filters/StoresFilter";
import { useSelector } from "react-redux";
import { storesSelector } from "../../../state/features/selectors";
import { filter, isEmpty, size } from "lodash";
import NoContent from "../../../components/NoContent";
import { paginator } from "../../../helpers/paginationHelper";
import CustomPaginationTwo from "../../../components/paginations/CustomPaginationTwo";

// !################# MAIN FUNC ###################
const Stores = () => {
    // ############### Component state ########################
    const [stores, setStores] = useState([]);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ############### Redux state ########################
    const { stores: allStores } = useSelector(storesSelector);

    // ############### FUNC ###########################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Filter by store name
    const filterByStoreName = (search) => {
        let filteredStores = [];
        if (search) {
            filteredStores = allStores.filter((store) => {
                return store.title.toLowerCase().includes(search.toLowerCase());
            });
            return setStores(filteredStores);
        }
        return setStores(allStores);
    };
    // => Filter by category
    const filterByCategory = (category_id) => {
        let filteredStores = [];
        if (category_id) {
            filteredStores = allStores.filter((store) => {
                return store.category_id
                    .toLowerCase()
                    .includes(category_id.toLowerCase());
            });
            return setStores(filteredStores);
        }
        return setStores(allStores);
    };

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setStores(allStores);
        }

        return () => {
            isSubscribed = false;
        };
    }, [allStores]);

    // => Store status infobox
    const storeStatus = [
        {
            title: "On-progress",
            total: size(
                filter(
                    stores,
                    ({ isApproved, tin_certificate, business_licence }) =>
                        (isApproved === false && tin_certificate === null) ||
                        business_licence === null
                )
            ),
            bgColor: colors.gray,
            textColor: colors.primary,
            url: "status/on progress",
        },
        {
            title: "Approval request",
            total: size(
                filter(
                    stores,
                    ({ isApproved, tin_certificate, business_licence }) =>
                        isApproved === false &&
                        tin_certificate !== null &&
                        business_licence !== null
                )
            ),
            bgColor: colors.primary,
            textColor: colors.bgColor3,
            url: "status/approval request",
        },
        {
            title: "Approved",
            total: size(filter(stores, { isApproved: true })),
            bgColor: colors.green,
            textColor: "#000",
            url: "status/approved",
        },
        {
            title: "Active",
            total: size(filter(stores, { isActive: true })),
            bgColor: `green`,
            textColor: colors.bgColor3,
            url: "status/active",
        },
        {
            title: "Inactive",
            total: size(filter(stores, { isActive: false })),
            bgColor: colors.yellow,
            textColor: colors.primary,
            url: "status/inactive",
        },
        {
            title: "Suspended",
            total: size(filter(stores, { isSuspended: true })),
            bgColor: "#000",
            textColor: colors.bgColor3,
            url: "status/suspended",
        },
        {
            title: "Denied",
            total: size(filter(stores, { isDenied: true })),
            bgColor: colors.red,
            textColor: colors.bgColor3,
            url: "status/denied",
        },
    ];
    const paginatedStores = paginator(stores, page, perPage);

    return (
        <>
            <PageLayout
                pageHead={"Agiza africa stores"}
                sidebar={<StoresSidebar stores={stores} />}
            >
                <Grid container spacing={2} paddingBottom={2}>
                    {storeStatus?.map((info, index) => (
                        <Grid item sm={3} xs={6} key={index}>
                            <StoreInfobox
                                textColor={info.textColor}
                                title={info.title}
                                total={info.total}
                                bgColor={info.bgColor}
                                url={info.url}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Box
                    sx={{
                        flex: 1,
                        position: "relative",
                        borderRadius: 3,
                        overflow: "hidden",
                    }}
                >
                    <CustomPaginationTwo
                        title={"Registered stores"}
                        height={"53vh"}
                        data={paginatedStores}
                        handlePageChange={handlePageChange}
                        handlePerPageChage={handlePerPageChage}
                        action={
                            <StoresFilter
                                filterByStoreName={filterByStoreName}
                                filterByCategory={filterByCategory}
                            />
                        }
                    >
                        {isEmpty(stores) ? (
                            <>
                                <NoContent message={"No store found!"} />
                            </>
                        ) : (
                            <Grid container spacing={3} padding={2}>
                                {paginatedStores.data?.map((store) => (
                                    <Grid item sm={4} xs={12} key={store._id}>
                                        <StoreWidget store={store} />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </CustomPaginationTwo>
                </Box>
            </PageLayout>
        </>
    );
};

export default Stores;
