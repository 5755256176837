import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "./baseUrl/baseURL";
import { headers } from "./baseUrl/setHeaders";
import { adminsEndpoints } from "./endpoints/adminsEndpoints";

export const adminsAPI = createApi({
    reducerPath: "adminsAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    endpoints: (builder) => ({
        getAdmins: builder.query({
            query: () => `${adminsEndpoints.GET_ALL_ADMINS}`,
        }),
        createAdmin: builder.mutation({
            query: (payload) => ({
                url: `${adminsEndpoints.CREATE_ADMIN}`,
                method: `POST`,
                body: payload,
            }),
        }),
        getSingleAdmin: builder.query({
            query: (payload) =>
                `${adminsEndpoints.GET_SINGLE_ADMIN}/` + payload,
        }),
    }),
});

export const {
    useGetAdminsQuery,
    useCreateAdminMutation,
    useGetSingleAdminQuery,
} = adminsAPI;
