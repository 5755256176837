import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { colors } from "../../assets/utils/colors";
import { Avatar, Box, Grid, ListItemText, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { LocalShipping, Store } from "@mui/icons-material";
import product1 from "../../assets/media/images/product.webp";

const columns = [{ id: "s_n", label: "S/N", minWidth: 0 }];

function createData(s_n) {
    return {
        s_n,
    };
}

// !############### MAIN FUNC #####################
const MostSellProductsTable = ({ height }) => {
    const [page] = React.useState(0);
    const [rowsPerPage] = React.useState(5);

    const rows = [1, 2]?.map((job) => {
        return createData(
            <Link>
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        overflow: "hidden",
                        transition: ".2s all ease-in-out",
                        borderRadius: 2.5,
                        "& .product-img": {
                            transition: ".2s all ease-in-out",
                            borderRadius: 2.5,
                            border: `1px solid ${colors.bgColor1}`,
                        },
                        "&:hover": {
                            transition: ".2s all ease-in-out",
                            "& .product-img": {
                                transform: `scale(1.04)`,
                                transition: ".5s all ease-in-out",
                                border: `1px solid ${colors.secondary}`,
                            },
                        },
                    }}
                >
                    <Grid container padding={0.5}>
                        <Grid item sm={3.5} xs={12}>
                            <Box
                                className="product-img"
                                sx={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                                }}
                            >
                                <Avatar
                                    variant="square"
                                    sx={{
                                        height: 60,
                                        width: 60,
                                        borderRadius: 2.5,
                                    }}
                                    src={product1}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <Box sx={{ width: "100%", pl: 1 }}>
                                <Box sx={{ width: "100%" }}>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                className="head-font"
                                                sx={{
                                                    fontWeight: "bold",
                                                    color: colors.primary,
                                                    fontSize: 13,
                                                }}
                                            >
                                                Mens trouser
                                            </Typography>
                                        }
                                        secondary={
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Store
                                                    sx={{
                                                        fontSize: 16,
                                                        color: colors.primary,
                                                        mr: 0.5,
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontSize: 10,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    By Ayo store
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        className="head-font"
                                        sx={{
                                            fontSize: 11,
                                            fontWeight: "bold",
                                            mt: 1.5,
                                        }}
                                    >
                                        500,000
                                        <sup>TZS</sup>
                                    </Typography>
                                    <Box sx={{ textAlign: "center" }}>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                color: colors.primary,
                                            }}
                                        >
                                            4
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                                color: colors.primary,
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <LocalShipping
                                                sx={{ fontSize: 10, mr: 0.4 }}
                                            />
                                            Ordered
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Link>
        );
    });

    return (
        <React.Fragment>
            <Paper
                sx={{
                    px: 0.5,
                    width: "100%",
                    borderRadius: 3,
                    boxShadow: "none",
                    overflow: "hidden",
                    borderTop: `5px solid ${colors.bgColor3}`,
                    borderBottom: `5px solid ${colors.bgColor3}`,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                }}
            >
                <TableContainer sx={{}}>
                    <Table stickyHeader>
                        <TableBody>
                            {rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        sx={{
                                                            p: "5px !important",
                                                        }}
                                                    >
                                                        {column.format &&
                                                        typeof value ===
                                                            "number"
                                                            ? column.format(
                                                                  value
                                                              )
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </React.Fragment>
    );
};

export default MostSellProductsTable;
