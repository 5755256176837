import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storesSelector } from "../../../state/features/selectors";
import { isEmpty } from "lodash";
import { setStoreDetails } from "../../../state/features/stores/storeSlice";
import { getRTKResponseData } from "../../../helpers/RTKHelpers";
import { useGetSingleStoreByIdQuery } from "../../../api/storeAPI";
import ProductsPagnation from "./ProductsPagnation";
import InnerContentLoader from "../../InnerContentLoader";
import { Box } from "@mui/material";

const StoreProducts = ({ storeId, getProductDetails }) => {
    // ############# Component state #######
    const [value, setValue] = React.useState(0);

    // ############ Redux state #############
    const { storeDetails: store } = useSelector(storesSelector);
    const vendorID = !isEmpty(store) && store.vendor_id._id;

    // ###### Distructure store data #########
    const { store_products } = store;

    // ############# CONST ##############
    const dispatch = useDispatch();

    // ############# RTK ###############
    const {
        isLoading,
        isSuccess,
        data: storeDetails,
    } = useGetSingleStoreByIdQuery(storeId);

    // ############ UseEffect #############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(setStoreDetails(getRTKResponseData(storeDetails)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, storeDetails, dispatch]);
    return (
        <div>
            {isLoading ? (
                <>
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                        }}
                    >
                        <InnerContentLoader height={"100%"} />
                    </Box>
                </>
            ) : (
                <ProductsPagnation
                    getProductDetails={getProductDetails}
                    products={store_products}
                />
            )}
        </div>
    );
};

export default StoreProducts;
