import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notificationHistory: [],
};

const notificationSlice = createSlice({
    name: "notificationsSlice",
    initialState,
    reducers: {
        setNotifications: (state, actions) => {
            state.notificationHistory = actions.payload;
        },
    },
});

export const { setNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
