import React from "react";
import { Category, Unpublished, Verified } from "@mui/icons-material";
import { Box, Rating, Typography } from "@mui/material";
import banner from "../../assets/media/images/banner1.webp";
import { colors } from "../../assets/utils/colors";

const StoreWidgetBottom = ({ store, height }) => {
    return (
        <>
                <Box
                    sx={{
                        backgroundImage: `url(${banner})`,
                        backgroundSize: `100% 190`,
                        backgroundRepeat: "no-repeat",
                        overflow: "hidden",
                        transition: ".2s all ease-in-out",
                        "&:hover": {
                            // transform: `scale(1.01)`,
                            transition: ".3s all ease-in-out",
                        },
                    }}
                >
                    <Box
                        sx={{
                            height: height ? height : 70,
                            background: `rgb(125, 98, 212, .9)`,
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            p: 1.5,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    color: colors.gray,
                                }}
                            >
                                {store.title}
                            </Typography>
                            {store.verification_badge ? (
                                <Verified
                                    color="primary"
                                    sx={{
                                        bgcolor: colors.bgColor3,
                                        p: 0.4,
                                        borderRadius: 50,
                                        mb: 0.3,
                                    }}
                                />
                            ) : (
                                <Unpublished
                                    color="warning"
                                    sx={{
                                        bgcolor: colors.bgColor3,
                                        p: 0.4,
                                        borderRadius: 50,
                                        mb: 0.3,
                                    }}
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: colors.gray,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Category sx={{ mr: 1 }} />
                                {store.category_name}
                            </Typography>
                            {store.rating !== undefined && (
                                <Rating
                                    sx={{ fontSize: 18 }}
                                    value={store.rating}
                                    readOnly
                                    defaultValue={0}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
        </>
    );
};

export default StoreWidgetBottom;
