import { ArrowCircleRightOutlined, LocationOn } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";

const CartCustomerWidgetTwo = ({ customer, handler }) => {
    return (
        <>
            <Box
                sx={{
                    borderTop: `4px solid ${colors.bgColor1}`,
                    borderLeft: `4px solid ${colors.bgColor1}`,
                    borderRight: `4px solid ${colors.bgColor1}`,
                    borderRadius: 2.5,
                    // height: "100%",
                    width: "100%",
                    transition: ".5s all ease-in-out",
                    boxShadow: `0px 0px 2px ${colors.primary}`,
                    "& .card-top": {
                        transition: ".3s all ease-in-out",
                    },
                    "&:hover": {
                        // border: `4px solid ${colors.primary}`,
                        transition: ".5s all ease-in-out",
                        "& .card-top": {
                            // transform: "scale(1.04)",
                            transition: ".3s all ease-in-out",
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                        },
                    },
                }}
            >
                <Box className="card-top">
                    <List
                        sx={{
                            width: "100%",
                            // height: "100%",
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            p: 0,
                        }}
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar
                                    src={customer.profile_picture}
                                    sx={{
                                        height: 90,
                                        width: 90,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                        p: 0.5,
                                        mr: 1,
                                        img: {
                                            objectFit: "cover !important",
                                            borderRadius: 50,
                                        },
                                    }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                disableTypography
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                }}
                                secondaryTypographyProps={{
                                    fontSize: 11,
                                }}
                                primary={
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            color: colors.primary,
                                            fontWeight: "bold",
                                        }}
                                    >{`${customer.first_name} ${customer.last_name}`}</Typography>
                                }
                                secondary={
                                    <>
                                        <Typography
                                            sx={{
                                                my: 1,
                                                fontSize: 13,
                                                display: "flex",
                                                alignItems: "center",
                                                color: colors.primary,
                                            }}
                                        >
                                            <LocationOn sx={{ fontSize: 13 }} />
                                            {`${customer.city}, ${customer.country}`}
                                        </Typography>
                                    </>
                                }
                            />
                        </ListItem>
                    </List>
                </Box>
                <Box sx={{ p: 1 }}>
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            color: colors.primary,
                            textAlign: "center",
                        }}
                    >
                        {customer?.customer_id}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        mt: 1,
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{
                            p: 1,
                            border: `1px solid ${colors.bgColor1}`,
                            ":hover": {
                                border: `1px solid ${colors.bgColor1}`,
                                ".icon": {
                                    right: 10,
                                    transition: `.3s all ease-in-out`,
                                    opacity: 1,
                                },
                            },
                            fontSize: 10,
                            fontWeight: "bold",
                            ".icon": {
                                position: "absolute",
                                right: 20,
                                opacity: 0,
                                transition: `.3s all ease-in-out`,
                            },
                        }}
                        onClick={() => {
                            handler(customer);
                        }}
                        className="animated-bg-3"
                    >
                        Select customer
                        <ArrowCircleRightOutlined className="icon" />
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default CartCustomerWidgetTwo;
