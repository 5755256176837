import { createSlice } from '@reduxjs/toolkit';

// => initialState
const initialState = {
    vendors: [],
};

const vendorSlice = createSlice({
    name: 'vendorSlice',
    initialState,
    reducers: {
        setVendors: (state, action) => {
            let vendors = action.payload.data;
            state.vendors = vendors;
        },
    },
});

export const { setVendors } = vendorSlice.actions;
export default vendorSlice.reducer;
