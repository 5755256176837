import * as React from 'react';
import { colors } from '../../assets/utils/colors';
import user from '../../assets/media/images/user.png';
import {
    Avatar,
    Box,
    Grid,
    ListItemText,
    Rating,
    Typography,
} from '@mui/material';
import { LocationOn } from '@mui/icons-material';

// !############### MAIN FUNC #####################
const CustomerReviewWidget = ({ review, height }) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    borderRadius: 2,
                    mb: 5,
                }}
            >
                <Grid container>
                    <Grid item sm={3} xs={12}>
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                            }}
                        >
                            <Avatar
                                sx={{
                                    height: 35,
                                    width: 35,
                                    borderRadius: 5,
                                }}
                                src={user}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box>
                            <ListItemText
                                disableTypography
                                primary={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            className="head-font"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: colors.primary,
                                                fontSize: 12,
                                            }}
                                        >
                                            {review.customer_name}
                                        </Typography>
                                    </Box>
                                }
                                secondary={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LocationOn
                                            sx={{
                                                fontSize: 18,
                                                color: colors.primary,
                                                mr: 0.5,
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Tanga, Mjini
                                        </Typography>
                                    </Box>
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    height: 50,
                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    p: 2,
                                    borderRadius: 5,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 10,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    6 days ago
                                    {review.rating_value && (
                                        <Rating
                                            sx={{ fontSize: 13 }}
                                            value={review.rating_value}
                                            readOnly
                                        />
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        <Typography sx={{ fontSize: 12, pl: 2.5, pb: 1 }}>
                            {review.comment}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};

export default CustomerReviewWidget;
