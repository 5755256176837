import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../../assets/utils/colors";
import { ShoppingCartOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { posOrderSelector } from "../../../state/features/selectors";
import { size } from "lodash";

const CartCount = () => {
    // ################ Comp state ###################
    const [cartProductCount, setCartProductCount] = useState(0);

    // ################ Redux state ##################
    const { cartItems } = useSelector(posOrderSelector);

    // ############### useEffect ###################
    useEffect(() => {
        setCartProductCount(size(cartItems));
    }, [cartItems]);

    return (
        <>
            <Box
                sx={{
                    width: 43,
                    height: 43,
                    borderRadius: 50,
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: `linear-gradient(180deg, ${colors.secondary}, ${colors.primary})`,
                    border: `1px solid ${colors.bgColor3}`,
                }}
            >
                <ShoppingCartOutlined
                    sx={{
                        fontSize: 20,
                        color: colors.bgColor3,
                    }}
                />
                <Box
                    sx={{
                        width: 15,
                        height: 15,
                        bgcolor: "black",
                        borderRadius: 50,
                        position: "absolute",
                        top: -3,
                        right: -3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: `linear-gradient(180deg, ${colors.primary}, ${colors.secondary})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            color: colors.bgColor4,
                            fontSize: 10,
                        }}
                    >
                        {cartProductCount}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default CartCount;
