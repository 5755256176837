import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { colors } from "../../assets/utils/colors";
import { Box, Chip, IconButton, Typography } from "@mui/material";
import { Inventory, RemoveRedEye } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { currencyFomater } from "../../helpers/currencyFomater";
import { isEmpty, orderBy } from "lodash";
import moment from "moment";

const columns = [
    { id: "sn", label: "S/N", align: "center" },
    { id: "order_id", label: "OrderID" },
    { id: "order_type", label: "Type" },
    { id: "store", label: "Store", minWidth: 150 },
    // { id: "date", label: "Date" },
    // {
    //     id: "address",
    //     label: "S-Address",
    // },
    {
        id: "status",
        label: "Status",
        align: "left",
    },
    {
        id: "price",
        label: (
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                Price
                <sup>TZS</sup>
            </Typography>
        ),
    },
    {
        id: "action",
        label: "",
        align: "left",
    },
];

function createData(
    sn,
    order_id,
    order_type,
    store,
    // date,
    // address,
    price,
    status,
    action
) {
    return {
        sn,
        order_id,
        order_type,
        store,
        // date,
        // address,
        price,
        status,
        action,
    };
}

const orderRow = (order, index) =>
    createData(
        <Typography
            className="head-font"
            noWrap
            sx={{ fontWeight: "bold", fontSize: 12 }}
        >
            {++index}
        </Typography>,
        <Typography
            className="head-font"
            noWrap
            sx={{ fontWeight: "bold", fontSize: 12, opacity: 0.8 }}
        >
            {order.order_number}
        </Typography>,
        <Chip
            size="small"
            label={order?.isPosOrder !== false ? "POS" : "Customer"}
            color={order?.isPosOrder !== false ? "warning" : "info"}
            sx={{ opacity: 0.8, fontSize: 10, p: 0.5, fontWeight: "bold" }}
        />,
        <Box>
            {isEmpty(order.store_id) ? (
                <>
                    <Typography
                        sx={{
                            pl: 0.5,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: colors.red,
                            display: "flex",
                            alignItems: "center",
                            background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        }}
                    >
                        <Inventory sx={{ fontSize: 12, mr: 1 }} />
                        {"Store not available"}
                    </Typography>
                </>
            ) : (
                <Link to={`/stores/details/${order.store_id?._id}`}>
                    <Typography
                        sx={{
                            pl: 0.5,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: colors.primary,
                            display: "flex",
                            alignItems: "center",
                            background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        }}
                    >
                        <Inventory sx={{ fontSize: 12, mr: 1 }} />
                        {order.store_id?.title}
                    </Typography>
                </Link>
            )}
        </Box>,
        // <Typography sx={{ fontSize: 12, fontWeight: "bold", opacity: 0.8 }}>
        //     {moment(order.createdAt).format("LLL")}
        // </Typography>,
        // <Typography sx={{ fontSize: 13 }}>{order.shipping_address}</Typography>,
        <Typography
            sx={{ fontSize: 14, fontWeight: "bold", color: colors.primary }}
        >
            {currencyFomater(order.total_amount)}
        </Typography>,
        <Chip
            label={
                order.order_status === 1
                    ? "Placed"
                    : order.order_status === 2
                    ? "Accepted"
                    : order.order_status === 3
                    ? "Shipped"
                    : order.order_status === 4
                    ? "Delivered"
                    : order.order_status === 5
                    ? "Cancelled"
                    : "ForShip"
            }
            color={
                order.order_status === 1
                    ? "primary"
                    : order.order_status === 2
                    ? "info"
                    : order.order_status === 3
                    ? "warning"
                    : order.order_status === 4
                    ? "success"
                    : order.order_status === 5
                    ? "error"
                    : "secondary"
            }
            size="small"
            sx={{ fontSize: 10, fontWeight: "bold" }}
        />,
        <Link to={`/orders/details/${order._id}`}>
            <IconButton
                sx={{
                    p: 0.8,
                    bgcolor: colors.bgColor1,
                    color: colors.primary,
                    ml: 1,
                }}
                size="small"
            >
                <RemoveRedEye
                    sx={{
                        fontSize: 16,
                    }}
                />
            </IconButton>
        </Link>
    );

// !################ MAIN FUNC ######################
const MainOrderTableTwo = ({ orders, height }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // ############# oreders ###############
    const storeOrders = orderBy(orders, ["createdAt"], ["desc"]).map(
        (order, index) => orderRow(order, index)
    );

    return (
        <Paper
            sx={{
                boxShadow: "none",
                border: "none",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    overflow: "auto",
                }}
            >
                <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{
                        flex: 1,
                        overflow: "auto",
                    }}
                >
                    <TableHead sx={{}}>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    key={index}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    sx={{
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        bgcolor: colors.bgColor1,
                                        opacity: 0.8,
                                        display: column.display,
                                        whiteSpace: 'nowrap'
                                    }}
                                    className="head-font"
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{}}>
                        {storeOrders
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                        sx={{
                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        {columns.map((column, index) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={index}
                                                    align={column.align}
                                                    sx={{
                                                        display: column.display,
                                                    }}
                                                >
                                                    {column.format &&
                                                    typeof value === "number"
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </Box>
            <Box sx={{}}>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={orders.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ bgcolor: colors.bgColor1 }}
                />
            </Box>
        </Paper>
    );
};

export default MainOrderTableTwo;
