import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { colors } from '../../../../assets/utils/colors';
import CustomerReviewWidget from '../../../../components/widgets/CustomerReviewWidget';
import VendorWidget2 from '../../../../components/widgets/VendorWidget2';
import { isEmpty, size } from 'lodash';
import CustomCard2 from '../../../../components/cards/CustomCard2';
import NoContent from '../../../../components/NoContent';
import { useGetVendorByIdQuery } from '../../../../api/vendorAPI';
import InnerContentLoader from '../../../../components/InnerContentLoader';
import ConfirmationDialog from '../../../../components/dialogs/ConfirmationDialog';
import VerifyStore from './VerifyStore';
import SuspendStore from './SuspendStore';
import ResumeStore from './ResumeStore';
import CountBadge from '../../../../components/CountBadge';
import { getRTKResponseData } from '../../../../helpers/RTKHelpers';

const StoreManagements = ({ store, storeReviews, vendorID }) => {
    const [vendor, setVendor] = React.useState({});
    const [actionType, setActionType] = useState('');
    const [confimationDialogOpen, setConfimationDialogOpen] = useState(false);

    // ############### FUNC ########################
    // => handle confimation dialog open
    const handleConfimationDialogOpen = (type) => {
        setActionType(type);
        setConfimationDialogOpen(true);
    };

    // => handle confimation dialog close
    const handleConfimationDialogClose = () => {
        setConfimationDialogOpen(false);
    };

    // ############### RTK ########################
    const { isLoading, isSuccess, data: vendorDetails } = useGetVendorByIdQuery(
        vendorID,
    );

    // ############### UseEffect ########################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                setVendor(getRTKResponseData(vendorDetails));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, vendorDetails]);
    return (
        <>
            {/* ############# Confimation dialogy ################ */}
            <ConfirmationDialog
                noDropClose
                openDialog={confimationDialogOpen}
                handleDialogClose={handleConfimationDialogClose}
            >
                {actionType === 'verify' ? (
                    <VerifyStore store={store} />
                ) : actionType === 'suspend' ? (
                    <SuspendStore store={store} />
                ) : actionType === 'resume' ? (
                    <ResumeStore store={store} />
                ) : (
                    ''
                )}
            </ConfirmationDialog>

            {/* ############# CONTENT ################ */}
            <Grid container spacing={2}>
                <Grid item sm={9}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} sx={{ position: 'relative' }}>
                            {isLoading ? (
                                <>
                                    <InnerContentLoader height={175} />
                                </>
                            ) : !isEmpty(vendor) ? (
                                <VendorWidget2 vendor={vendor} />
                            ) : (
                                <>
                                    <InnerContentLoader />
                                </>
                            )}
                        </Grid>
                        <Grid item sm={8}>
                            <CustomCard2
                                title={'Store promotions'}
                                height={230}
                                action={
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                        }}
                                    >
                                        4 promo
                                    </Typography>
                                }
                            >
                                <Box sx={{ p: 2 }}>
                                    {[1, 2, 3, 4, 5].map((index) => (
                                        <></>
                                        // <StorePromoWidget key={index} />
                                    ))}
                                </Box>
                            </CustomCard2>
                        </Grid>
                        <Grid item sm={4}>
                            <Box
                                sx={{
                                    height: 290,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    borderRadius: 5,
                                }}
                            >
                                <Button
                                    className="app-btn"
                                    color="success"
                                    variant="contained"
                                    sx={{
                                        boxShadow: 'none',
                                        px: 5,
                                    }}
                                    onClick={() => {
                                        let type = 'verify';
                                        handleConfimationDialogOpen(type);
                                    }}
                                >
                                    Verify store
                                </Button>
                                {store.isSuspended ? (
                                    <Button
                                        className="app-btn"
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            boxShadow: 'none',
                                            px: 5,
                                        }}
                                        onClick={() => {
                                            let type = 'resume';
                                            handleConfimationDialogOpen(type);
                                        }}
                                    >
                                        Resume store
                                    </Button>
                                ) : (
                                    <Button
                                        className="app-btn"
                                        color="warning"
                                        variant="contained"
                                        sx={{
                                            boxShadow: 'none',
                                            px: 5,
                                        }}
                                        onClick={() => {
                                            let type = 'suspend';
                                            handleConfimationDialogOpen(type);
                                        }}
                                    >
                                        Suspend store
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={3}>
                    <CustomCard2
                        title={'Store review'}
                        height={420}
                        action={
                            <CountBadge
                                total={size(storeReviews)}
                                item={'reviews'}
                            />
                        }
                    >
                        {isEmpty(storeReviews) ? (
                            <>
                                <NoContent message={'No posted reviews'} />
                            </>
                        ) : (
                            storeReviews?.map((review, index) => (
                                <Box key={index}>
                                    <CustomerReviewWidget review={review} />
                                </Box>
                            ))
                        )}
                    </CustomCard2>
                </Grid>
            </Grid>
        </>
    );
};

export default StoreManagements;
