import { Box, Typography } from '@mui/material';
import React from 'react';

const StoreProductInfobox = ({ title, total, bgColor, textColor, icon }) => {
    return (
        <>
            <Box
                sx={{
                    'height': 50,
                    'bgcolor': bgColor,
                    'width': '100%',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'borderRadius': 2,
                    'position': 'relative',
                    '& .icon-con': {
                        position: 'absolute',
                        right: 10,
                        mt: 1,
                        transition: '.3s all ease-in-out',
                    },
                    '&:hover': {
                        'transition': '.3s all ease-in-out',
                        '& .icon-con': {
                            right: 6,
                            transition: '.3s all ease-in-out',
                        },
                    },
                    '& .icon': {
                        fontSize: 16,
                        mr: 1,
                    }
                }}
            >
                <Box>
                    {icon}
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 10,
                            color: textColor,
                            fontWeight: 'bold',
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: textColor,
                        }}
                    >
                        {total} Products
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default StoreProductInfobox;
