import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { storesSelector } from "../../state/features/selectors";
import CustomPaginationTwo from "../../components/paginations/CustomPaginationTwo";
import { isEmpty } from "lodash";
import { Button, Grid } from "@mui/material";
import NoContent from "../NoContent";
import { paginator } from "../../helpers/paginationHelper";
import StoresFilter from "../filters/StoresFilter";
import CartStoreWidget from "../widgets/CartStoreWidget";

const SelectStore = ({ getStore }) => {
    // ############### Component state ########################
    const [stores, setStores] = useState([]);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ############### Redux state ########################
    const { stores: allStores } = useSelector(storesSelector);

    // ############### FUNC ###########################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Filter by store name
    const filterByStoreName = (search) => {
        let filteredStores = [];
        if (search) {
            filteredStores = allStores.filter((store) => {
                return store.title.toLowerCase().includes(search.toLowerCase());
            });
            return setStores(filteredStores);
        }
        return setStores(allStores);
    };
    // => Filter by category
    const filterByCategory = (category_id) => {
        let filteredStores = [];
        if (category_id) {
            filteredStores = allStores.filter((store) => {
                return store.category_id
                    .toLowerCase()
                    .includes(category_id.toLowerCase());
            });
            return setStores(filteredStores);
        }
        return setStores(allStores);
    };

    const paginatedStores = paginator(stores, page, perPage);

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setStores(allStores);
        }

        return () => {
            isSubscribed = false;
        };
    }, [allStores]);

    return (
        <>
            <CustomPaginationTwo
                title={"Select store"}
                height={"53vh"}
                data={paginatedStores}
                handlePageChange={handlePageChange}
                handlePerPageChage={handlePerPageChage}
                action={
                    <StoresFilter
                        filterByStoreName={filterByStoreName}
                        filterByCategory={filterByCategory}
                    />
                }
            >
                {isEmpty(stores) ? (
                    <>{/* <NoContent message={"No store found!"} /> */}</>
                ) : (
                    <Grid container spacing={3} padding={2}>
                        {paginatedStores.data?.map((store) => (
                            <Grid item sm={4} xs={12} key={store._id}>
                                <CartStoreWidget
                                    store={store}
                                    handler={getStore}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </CustomPaginationTwo>
        </>
    );
};

export default SelectStore;
