import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authEndpoints } from "./endpoints/authEndpoints";
import { baseURL2 } from "./baseUrl/baseURL";

export const authAPI = createApi({
    reducerPath: "authAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL2 }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.LOGIN}`,
                method: "POST",
                body: payload,
            }),
        }),
        logout: builder.mutation({
            query: (payload) => ({
                url: ``,
                method: ``,
                body: payload,
            }),
        }),
    }),
});

export const { useLoginMutation, useLogoutMutation } = authAPI;
