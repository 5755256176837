import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../assets/utils/colors";
import CreateOrderBtn from "../buttons/CreateOrderBtn";
import DashBottomNavBtn from "../buttons/DashBottomNavBtn";
import {
    Group,
    Inventory2Outlined,
    LocalMallOutlined,
    StoreOutlined,
} from "@mui/icons-material";
import OrderDialog from "../dialogs/OrderDialog";
import DashStores from "./DashStores";
import DashProducts from "./DashProducts";
import DashOrders from "./DashOrders";
import DashCustomers from "./DashCustomers";

const DashBottomNav = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState("");

    // ############### FUNC ########################
    // => handle confimation dialog open
    const handleDialogOpen = (type) => {
        setDialogOpen(true);
        setDialogType(type);
    };

    // => handle confimation dialog close
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const leftLinks = [
        {
            id: 1,
            name: "Stores",
            icon: <StoreOutlined className="icon" />,
            handler: () => handleDialogOpen("stores"),
        },
        {
            id: 2,
            name: "Products",
            icon: <Inventory2Outlined className="icon" />,
            handler: () => handleDialogOpen("products"),
        },
    ];
    const rightLinks = [
        {
            id: 1,
            name: "Orders",
            icon: <LocalMallOutlined className="icon" />,
            handler: () => handleDialogOpen("orders"),
        },
        {
            id: 2,
            name: "Customers",
            icon: <Group className="icon" />,
            handler: () => handleDialogOpen("customers"),
        },
    ];
    return (
        <>
            <OrderDialog
                customWidth={"lg"}
                head={
                    dialogType === "stores"
                        ? "Agiza stores"
                        : dialogType === "products"
                        ? "Agiza products"
                        : dialogType === "orders"
                        ? "Agiza orders"
                        : dialogType === "customers"
                        ? "Agiza customers"
                        : ""
                }
                noDropClose
                openDialog={dialogOpen}
                handleDialogClose={handleDialogClose}
            >
                {dialogType === "stores" ? (
                    <>
                        <DashStores />
                    </>
                ) : dialogType === "products" ? (
                    <>
                        <DashProducts />
                    </>
                ) : dialogType === "orders" ? (
                    <>
                        <DashOrders />
                    </>
                ) : dialogType === "customers" ? (
                    <>
                        <DashCustomers />
                    </>
                ) : (
                    ""
                )}
            </OrderDialog>
            <Box
                sx={{
                    height: 80,
                    borderRadius: 2.5,
                    boxShadow: `0px 0px 10px ${colors.bgColor1}`,
                    border: `1px dotted ${colors.primary}`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                className="animated-bg"
            >
                <Grid container gap={2} alignItems={"center"} sx={{ px: 1 }}>
                    {leftLinks.map((data) => (
                        <Grid key={data.id} item xs>
                            <DashBottomNavBtn data={data} />
                        </Grid>
                    ))}
                    <Grid
                        item
                        xs
                        sx={{
                            display: "flex",
                            borderRadius: 50,
                            justifyContent: "center",
                            bgcolor: colors.bgColor2,
                            borderRight: `1px dotted ${colors.primary}`,
                            borderLeft: `1px dotted ${colors.primary}`,
                        }}
                    >
                        <CreateOrderBtn />
                    </Grid>
                    {rightLinks.map((data) => (
                        <Grid key={data.id} item xs>
                            <DashBottomNavBtn data={data} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default DashBottomNav;
