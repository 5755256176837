import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { colors } from "../assets/utils/colors";

const CustomListItem = ({ desktopOpen, text, icon, activeIcon, url }) => {
    return (
        <React.Fragment>
            <Tooltip title={text} arrow placement="right">
                <NavLink to={url}>
                    {({ isActive }) => (
                        <ListItem disablePadding>
                            <ListItemButton
                                sx={{
                                    borderRight: isActive
                                        ? `4px solid ${colors.primary}`
                                        : undefined,
                                    height: 38,
                                    justifyContent: desktopOpen
                                        ? "initial"
                                        : "center",
                                    px: 2.5,
                                    borderBottom: `1px solid ${colors.bgColor1}`,
                                    "&:hover": {
                                        bgcolor: colors.bgColor2,
                                    },
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: {
                                            md: desktopOpen ? 3 : "auto",
                                            xs: 3,
                                        },
                                        justifyContent: "center",
                                    }}
                                >
                                    {isActive ? activeIcon : icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={text}
                                    sx={{
                                        opacity: {
                                            md: desktopOpen ? 1 : 0,
                                            xs: 1,
                                        },
                                        "& .MuiTypography-root": {
                                            fontSize: 12,
                                            fontWeight: "bold",
                                        },
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    )}
                </NavLink>
            </Tooltip>
        </React.Fragment>
    );
};

export default CustomListItem;
