import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from './baseUrl/baseURL';
import { headers } from './baseUrl/setHeaders';
import { promotionsEndpoints } from './endpoints/promotionsEndpoints';

export const promotionAPI = createApi({
    reducerPath: 'promotionAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    endpoints: (builder) => ({
        // => Get all promotion
        getAllPromotion: builder.query({
            query: () => `${promotionsEndpoints.GET_ALL_PROMOTIONS}`,
        }),
        // => Create promotion
        createPromotion: builder.mutation({
            query: (payload) => ({
                url: `${promotionsEndpoints.CREATE_PROMOTION}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Update promotion
        updatePromotion: builder.mutation({
            query: (payload) => ({
                url:
                    `${promotionsEndpoints.UPDATE_PROMOTION}/` +
                    payload.promotion_id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => Activate promotion
        activatePromotion: builder.mutation({
            query: (payload) => ({
                method: "PUT",
                url:
                    `${promotionsEndpoints.ACTIVATE_PROMOTION}/` +
                    payload,
            }),
        }),
        // => Delete promotion
        deletePromotion: builder.mutation({
            query: (payload) => ({
                url: `${promotionsEndpoints.DELETE_PROMOTION}/` + payload,
                method: `DELETE`,
            }),
        }),
    }),
});

export const {
    useGetAllPromotionQuery,
    useActivatePromotionMutation,
    useCreatePromotionMutation,
    useUpdatePromotionMutation,
    useDeletePromotionMutation,
} = promotionAPI;
