import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../../../assets/utils/colors';
import { currencyFomater } from '../../../../helpers/currencyFomater';

const ProductPrice = ({ product }) => {
    return (
        <>
            <Box
                sx={{
                    height: 60,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: colors.primary,
                    }}
                >
                    {currencyFomater(product.price)}
                    <sup
                        style={{
                            fontSize: 10.5,
                        }}
                    >
                        TZS
                    </sup>
                </Typography>
                {product.discounted_price > 0 && (
                    <Typography
                        sx={{
                            position: 'relative',
                            fontSize: 12,
                            color: colors.red,
                        }}
                    >
                        <strike>
                            TZS{' '}
                            {currencyFomater(
                                (product.discounted_price * product.price) /
                                    100,
                            )}
                        </strike>
                    </Typography>
                )}
            </Box>
        </>
    );
};

export default ProductPrice;
