import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import CustomTextField from "../../../components/forms/CustomTextField";
import CustomSubmitButton from "../../../components/forms/CustomSubmitButton";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from "../../../helpers/RTKHelpers";
import { errorNotify, successNotify } from "../../../helpers/notify";
import SuccessFeedback from "../../../components/SuccessFeedback";
import { isEmpty } from "lodash";
import { posOrderSelector } from "../../../state/features/selectors";
import { useCreatePOSOrderMutation } from "../../../api/orderAPI";
import { clearPOSCart } from "../../../state/features/orders/posOrderSlice";

// ############ ValidationC+Schema #############
const createOrderValidation = Yup.object({
    shipping_address: Yup.string().required(
        "shipping address is a required field"
    ),
});

const PlaceOrder = ({ closePOSCart }) => {
    // ############ CONST ###################
    const dispatch = useDispatch();

    // ############ Redux state #############
    const { cartPayload } = useSelector(posOrderSelector);

    // ############ RTK ###################
    const [createPOS, { isLoading, isSuccess, isError, error, data }] =
        useCreatePOSOrderMutation();

    // ############ Handle create order ################
    const handleCreatePOSOrder = (payload) => {
        let newPayload = {
            ...cartPayload,
            ...payload,
        };
        createPOS(newPayload);
    };
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(clearPOSCart());
                successNotify(getRTKSuccessMessage(data));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, data, error, dispatch]);

    return (
        <>
            {isSuccess ? (
                <>
                    <SuccessFeedback
                        message={
                            !isEmpty(data)
                                ? getRTKSuccessMessage(data)
                                : "Order created successfully."
                        }
                    />
                </>
            ) : (
                <Box>
                    <Formik
                        initialValues={{
                            shipping_address: "",
                        }}
                        validationSchema={createOrderValidation}
                        onSubmit={(payload) => {
                            handleCreatePOSOrder(payload);
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Grid
                                    container
                                    padding={4}
                                    justifyContent={"center"}
                                >
                                    <Grid item sm={12} xs={12}>
                                        <CustomTextField
                                            type={"text"}
                                            name="shipping_address"
                                            label={"Shipping address"}
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <Box sx={{ my: 2, mt: 5 }}>
                                            <CustomSubmitButton
                                                loading={isLoading}
                                                onClick={formik.handleSubmit}
                                            >
                                                Place order
                                            </CustomSubmitButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            )}
        </>
    );
};

export default PlaceOrder;
