import React, { useEffect, useState } from 'react';
import { Box, Grid, RadioGroup } from '@mui/material';
import CustomSubmitButton from '../../../../components/forms/CustomSubmitButton';
import SuccessFeedback from '../../../../components/SuccessFeedback';
import { TransferWithinAStation } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useShipOrderMutation } from '../../../../api/orderAPI';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../../helpers/RTKHelpers';
import { errorNotify, successNotify } from '../../../../helpers/notify';
import CustomCard2 from '../../../../components/cards/CustomCard2';
import CountBadge from '../../../../components/CountBadge';
import { deliveryGuysSelector } from '../../../../state/features/selectors';
import { isEmpty, size } from 'lodash';
import NoContent from '../../../../components/NoContent';
import DeliveryGuyWidget2 from '../../../../components/widgets/DeliveryGuyWidget2';
import { setOrderDetails } from '../../../../state/features/orders/orderSlice';
import DeliveryGuysFilter from '../../../../components/filters/DeliveryGuysFilter';

// !##################### MAIN FUNC ######################
const ShipOrder = ({ orderID, orderNumber }) => {
    // ############## Comp states ##############
    const [selectedValue, setSelectedValue] = useState('');
    const [deliveryGuys, setDeliveryGuys] = useState([]);

    // ############## CONST ##############
    const dispatch = useDispatch();

    // ######### Redux states #############
    const { deliveryGuys: alldDeliveryGuys } = useSelector(
        deliveryGuysSelector,
    );

    // ############## RTK ################
    // => Ship order
    const [
        shipOrder,
        { isLoading, isSuccess, isError, error, data: shipedOrder },
    ] = useShipOrderMutation();

    // ############### FUNC ###############
    // => Handle ship order
    const handleShipOrder = (payload) => {
        shipOrder(payload);
    };

    const handleChange = (e) => {
        setSelectedValue(e.target.value);
    };

    // ############## FILTER ###################
    // => Filter by name/id
    const filterByNameOrID = (search) => {
        let deliveryGuys = [];
        if (search) {
            deliveryGuys = alldDeliveryGuys.filter((delivery_guy) => {
                return (
                    delivery_guy.registration_number
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    delivery_guy.first_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    delivery_guy.last_name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                );
            });
            return setDeliveryGuys(deliveryGuys);
        }
        return setDeliveryGuys(alldDeliveryGuys);
    };

    // ############### useEffect ###########
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                successNotify(getRTKSuccessMessage(shipedOrder));
                dispatch(setOrderDetails(getRTKResponseData(shipedOrder)));
            }
            if (error) {
                return errorNotify(
                    error.data.message
                        ? getRTKErrorMessage(error)
                        : 'Temporary server error. Please try again letter.',
                );
            }

            setDeliveryGuys(alldDeliveryGuys);
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, shipedOrder, error, alldDeliveryGuys, dispatch]);

    return (
        <>
            <Box
                sx={{
                    pt: 2,
                    borderRadius: 2,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                {isSuccess ? (
                    <SuccessFeedback
                        height={400}
                        message={'Order shipped successfully.'}
                    />
                ) : (
                    <>
                        <Box sx={{ width: '100%' }}>
                            <CustomCard2
                                title={'Select delivery guys'}
                                height={450}
                                action={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: 400,
                                        }}
                                    >
                                        <DeliveryGuysFilter
                                            filterByNameOrID={filterByNameOrID}
                                        />
                                        <CountBadge
                                            total={size(deliveryGuys)}
                                            item={'Delivery guys'}
                                        />
                                    </Box>
                                }
                            >
                                {isEmpty(deliveryGuys) ? (
                                    <>
                                        <NoContent
                                            message={'No delivery guy added'}
                                        />
                                    </>
                                ) : (
                                    <Grid container spacing={2} padding={2}>
                                        {deliveryGuys?.map((delivery_guy) => (
                                            <Grid
                                                item
                                                sm={4}
                                                key={delivery_guy._id}
                                            >
                                                <RadioGroup
                                                    value={selectedValue}
                                                    onChange={handleChange}
                                                >
                                                    <DeliveryGuyWidget2
                                                        deliveryGuy={
                                                            delivery_guy
                                                        }
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </CustomCard2>
                        </Box>
                        <Box sx={{ width: '50%', mt: 1 }}>
                            <CustomSubmitButton
                                loading={isLoading}
                                startIcon={<TransferWithinAStation />}
                                onClick={() => {
                                    let payload = {
                                        order_id: orderID,
                                        delivery_guy_id: selectedValue,
                                    };
                                    if (isEmpty(selectedValue)) {
                                        errorNotify(
                                            'Please select delivery guy.',
                                        );
                                    } else {
                                        handleShipOrder(payload);
                                    }
                                }}
                            >
                                Ship order
                            </CustomSubmitButton>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

export default ShipOrder;
