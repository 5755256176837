// => GET all delivery guys
const GET_ALL_DELIVERY_GUYS = '/delivery-guys';

// => CREATE delivery guy
const CREATE_DELIVERY_GUY = '/delivery-guys';

// => Get single delivery guy
const GET_SINGLE_DELIVERY_GUY = '/delivery-guys';

export const deliveryGuyEndpoints = {
    GET_ALL_DELIVERY_GUYS,
    CREATE_DELIVERY_GUY,
    GET_SINGLE_DELIVERY_GUY,
};
