import { createSlice } from "@reduxjs/toolkit";

// => initialState
const initialState = {
    customers: [],
    subscribersCustomers: [],
    paginatedCustomers: [],
    totalCustomers: 0,
};

// !############# SLICE ################
export const customersSlice = createSlice({
    name: "customersSlice",
    initialState,
    reducers: {
        setCustomers: (state, action) => {
            let customers = action.payload.data;
            state.customers = customers;
            state.totalCustomers = action.payload.total;
            state.paginatedCustomers = action.payload;
        },
        setSubscribersCustomers: (state, action) => {
            let customers = action.payload.data;
            state.subscribersCustomers = customers;
        },
    },
});

export const { setCustomers, setSubscribersCustomers } = customersSlice.actions;
export default customersSlice.reducer;
