import { PersonAddAlt } from '@mui/icons-material';
import { Box, Button, Grid, styled } from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../assets/utils/colors';
import CustomDialog from '../dialogs/CustomDialog';
import CreatePromotion from '../../pages/menu/promotions/CreatePromotion';

// ################ PromoBar ###############
const PromoBar = styled(Box)(({ theme }) => ({
    '& .section-head': {
        'fontSize': 16,
        'fontWeight': 'bold',
        'position': 'relative',
        'marginBottom': 15,
        'color': colors.primary,
        '&::before': {
            content: "''",
            height: 2.5,
            width: 50,
            position: 'absolute',
            bottom: -3,
            borderRadius: 110,
            background: colors.bgColor1,
        },
    },
}));

const PromoSidebar = () => {
    const [openDialog, setOpenDialog] = useState(false);

    // ############ FUNC ###############
    const handleDialogyOpen = () => {
        setOpenDialog(true);
    };
    const handleDialogyClose = () => {
        setOpenDialog(false);
    };
    return (
        <>
            <PromoBar>
                <CustomDialog
                    openDialog={openDialog}
                    handleDialogClose={handleDialogyClose}
                    head={'Add store promotion'}
                >
                    <CreatePromotion />
                </CustomDialog>
                <Grid container>
                    <Grid item sm={12}>
                        <Box>
                            <Button
                                startIcon={<PersonAddAlt />}
                                variant="contained"
                                fullWidth
                                onClick={handleDialogyOpen}
                                sx={{
                                    'borderRadius': 2,
                                    'height': 80,
                                    'textTransform': 'capitalize',
                                    'border': `10px solid ${colors.bgColor1}`,
                                    'color': colors.primary,
                                    'fontWeight': 'bold',
                                    'boxShadow': 'none !important',
                                    'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                                    ':hover': {
                                        color: colors.secondary,
                                    },
                                }}
                            >
                                Add new promotion
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </PromoBar>
        </>
    );
};

export default PromoSidebar;
