import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';
import DashRevenueCard from './DashRevenueCard';
import { getCommission, getRevenue } from '../../helpers/financeHelper';

const DashRevenue = ({ orders, noTitle }) => {
    const revenue = getRevenue(orders);
    const commission = getCommission(orders);
    return (
        <>
            <Box sx={{ height: 190 }}>
                {!noTitle && (
                    <Typography className="head-font section-head">
                        Revenue
                    </Typography>
                )}

                <Grid container mt={2} columnSpacing={3}>
                    <Grid item sm={6} xs={6}>
                        <DashRevenueCard
                            bdColor={colors.yellow}
                            title={'Total revenue'}
                            amount={revenue.collected}
                        />
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <DashRevenueCard
                            bdColor={colors.green}
                            title={'Total commission'}
                            amount={commission.collected}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default DashRevenue;
