import { Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../assets/utils/colors";

const NoContent = ({ message, height }) => {
    return (
        <>
            <Box
                sx={{
                    height: height ? height : 200,
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        opacity: 0.8,
                        textAlign: "center",
                        color: colors.primary,
                    }}
                >
                    {message}
                </Typography>
            </Box>
        </>
    );
};

export default NoContent;
