import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import CustomTextField from '../../../components/forms/CustomTextField';
import CustomSelect from '../../../components/forms/CustomSelect';
import CustomSubmitButton from '../../../components/forms/CustomSubmitButton';
import * as Yup from 'yup';
import { useCreateAdminMutation } from '../../../api/adminsAPI';
import { useDispatch } from 'react-redux';
import { addAdmin } from '../../../state/features/admins/adminsSlice';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../helpers/RTKHelpers';
import { errorNotify, successNotify } from '../../../helpers/notify';
import SuccessFeedback from '../../../components/SuccessFeedback';
import { isEmpty } from 'lodash';
import { colors } from '../../../assets/utils/colors';

// ############ ValidationC+Schema #############
const createAdminValidation = Yup.object({
    first_name: Yup.string().required('first name is a required field'),
    last_name: Yup.string().required('last name is a required field'),
    phone_number: Yup.string().required('phone number is a required field'),
    email: Yup.string().required(),
    gender: Yup.string().required(),
    password: Yup.string().required(),
});

const CreateAdmin = () => {
    // ############ CONST ###################
    const dispatch = useDispatch();

    // ############ RTK ###################
    const [
        createAdmin,
        { isLoading, isSuccess, isError, error, data },
    ] = useCreateAdminMutation();

    // ############ Handle create admin ################
    const handleCreateAdmin = (payload) => {
        createAdmin(payload);
    };
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(addAdmin(getRTKResponseData(data)));
                successNotify(getRTKSuccessMessage(data));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, data, error, dispatch]);

    return (
        <>
            {isSuccess ? (
                <>
                    <SuccessFeedback
                        message={
                            !isEmpty(data)
                                ? getRTKSuccessMessage(data)
                                : 'Admin created successfully.'
                        }
                    />
                </>
            ) : (
                <Box>
                    <Formik
                        initialValues={{
                            first_name: '',
                            last_name: '',
                            phone_number: '',
                            email: '',
                            gender: '',
                            password: 'buibui123',
                        }}
                        validationSchema={createAdminValidation}
                        onSubmit={(payload) => {
                            handleCreateAdmin(payload);
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Grid
                                    container
                                    columnSpacing={2}
                                    justifyContent={'center'}
                                >
                                    <Grid item sm={6}>
                                        <CustomTextField
                                            type={'text'}
                                            name="first_name"
                                            label={'First name'}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomTextField
                                            type={'text'}
                                            name="last_name"
                                            label={'Last name'}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomTextField
                                            type={'text'}
                                            name="phone_number"
                                            label={'Phone number'}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomTextField
                                            type={'email'}
                                            name="email"
                                            label={'Email'}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomSelect
                                            name="gender"
                                            label={'Gender'}
                                            formik={formik}
                                        >
                                            <MenuItem value="Male">
                                                Male
                                            </MenuItem>
                                            <MenuItem value="Female">
                                                Female
                                            </MenuItem>
                                        </CustomSelect>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomTextField
                                            type={'password'}
                                            name="password"
                                            label={'Password'}
                                        />
                                        <Typography
                                            sx={{ fontSize: 11, mt: 1 }}
                                        >
                                            Default password :{' '}
                                            <strong
                                                style={{
                                                    color: colors.primary,
                                                }}
                                            >
                                                buibui123
                                            </strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Box sx={{ my: 2, mt: 5 }}>
                                            <CustomSubmitButton
                                                loading={isLoading}
                                                onClick={formik.handleSubmit}
                                            >
                                                Create admin
                                            </CustomSubmitButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            )}
        </>
    );
};

export default CreateAdmin;
