import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../../../assets/utils/colors';
import CustomSubmitButton from '../../../../components/forms/CustomSubmitButton';
import { useSuspendProductMutation } from '../../../../api/productAPI';
import { useDispatch } from 'react-redux';
import { setProductDetails } from '../../../../state/features/products/productSlice';
import { errorNotify, successNotify } from '../../../../helpers/notify';
import SuccessFeedback from '../../../../components/SuccessFeedback';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../../helpers/RTKHelpers';

// !############## MAIN FUNC ################
const SuspendProduct = ({ product }) => {
    // ############## CONST ##############
    const dispatch = useDispatch();

    // ############## RTK ################
    // => Approve product
    const [
        suspendProduct,
        { isLoading, isSuccess, isError, error, data: productDetails },
    ] = useSuspendProductMutation();

    // ############### FUNC ###############
    // => Handle product suspend
    const handleProductSuspend = async () => {
        suspendProduct(product._id);
    };

    // ############### useEffect ###########
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                successNotify(getRTKSuccessMessage(productDetails));
                dispatch(setProductDetails(getRTKResponseData(productDetails)));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, error, productDetails, dispatch]);

    return (
        <>
            <Box
                sx={{
                    p: 2,
                    borderRadius: 2,
                    height: 300,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                {isSuccess ? (
                    <>
                        <SuccessFeedback message={productDetails.message} />
                    </>
                ) : (
                    <>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 22,
                                    color: colors.secondary,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                }}
                            >
                                {product.title} product suspend
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontSize: 14, opacity: 0.8 }}>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Reiciendis labore illo
                                aspernatur aliquid? Adipisci consequuntur, ipsum
                                id culpa natus minus numquam eaque pariatur
                                veniam cupiditate ratione quis accusamus! Ut,
                                vitae?
                            </Typography>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                            <CustomSubmitButton
                                    loading={isLoading}
                                    btnColor={'warning'}
                                onClick={() => {
                                    handleProductSuspend();
                                }}
                            >
                                Suspend product
                            </CustomSubmitButton>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

export default SuspendProduct;
