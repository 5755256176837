import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { colors } from "../../../assets/utils/colors";
import ProductInfobox from "../../../components/infoBoxes/ProductInfobox";
import PageLayout from "../../layouts/PageLayout";
import ProductsSidebar from "../../../components/sidebars/ProductsSidebar";
import { useSelector } from "react-redux";
import { productsSelector } from "../../../state/features/selectors";
import { filter, isEmpty, reverse, size } from "lodash";
import CountBadge from "../../../components/CountBadge";
import ProductFilter from "../../../components/filters/ProductFilter";
import { paginator } from "../../../helpers/paginationHelper";
import NoContent from "../../../components/NoContent";
import ProductWidget from "../../../components/widgets/ProductWidget";
import CustomPaginationTwo from "../../../components/paginations/CustomPaginationTwo";

// !################# MAIN FUNC ###################
const Products = () => {
    // ############### Component state ########################
    const [products, setProducts] = useState([]);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ############### Redux state ########################
    const { products: allProducts } = useSelector(productsSelector);

    // ############### FUNC ###########################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Filter by product name
    const filterByProductName = (search) => {
        let filteredProducts = [];
        if (search) {
            filteredProducts = allProducts.filter((product) => {
                return product.title
                    .toLowerCase()
                    .includes(search.toLowerCase());
            });
            return setProducts(filteredProducts);
        }
        return setProducts(allProducts);
    };
    // => Filter by category
    const filterByCategory = (category_id) => {
        let filteredProducts = [];
        if (category_id) {
            filteredProducts = allProducts.filter((product) => {
                return product.category_id
                    .toLowerCase()
                    .includes(category_id.toLowerCase());
            });
            return setProducts(filteredProducts);
        }
        return setProducts(allProducts);
    };
    // => Get active products
    const activeProducts = () => {
        let activeProducts = filter(products, { isActive: true });
        return activeProducts;
    };
    const paginatedProducts = paginator(
        reverse(activeProducts()),
        page,
        perPage
    );

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setProducts(allProducts);
        }

        return () => {
            isSubscribed = false;
        };
    }, [allProducts]);

    return (
        <>
            <PageLayout
                pageHead={"Agiza africa products"}
                sidebar={<ProductsSidebar products={products} />}
            >
                <Grid container spacing={2} paddingBottom={2}>
                    <Grid item sm={3} xs={6}>
                        <ProductInfobox
                            title={"Featured"}
                            total={size(
                                filter(products, { is_featured: true })
                            )}
                            bgColor={colors.primary}
                            textColor={colors.bgColor3}
                            borderBottom={colors.green}
                            url={"/products/status/featured"}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <ProductInfobox
                            title={"Best seller"}
                            total={size(
                                filter(products, { is_best_seller: true })
                            )}
                            bgColor={colors.green}
                            textColor={"blue"}
                            borderBottom={colors.primary}
                            url={"/products/status/best-seller"}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <ProductInfobox
                            title={"Pending approved"}
                            total={size(
                                filter(products, {
                                    isApproved: false,
                                    isDenied: false,
                                })
                            )}
                            bgColor={colors.bgColor1}
                            url={"/products/status/approval request"}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <ProductInfobox
                            title={"Approved"}
                            total={size(filter(products, { isApproved: true }))}
                            bgColor={colors.primary}
                            textColor={colors.bgColor3}
                            url={"/products/status/approved"}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <ProductInfobox
                            title={"Active"}
                            total={size(filter(products, { isActive: true }))}
                            bgColor={colors.green}
                            url={"/products/status/active"}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <ProductInfobox
                            title={"In-active"}
                            total={size(filter(products, { isActive: false }))}
                            bgColor={colors.yellow}
                            url={"/products/status/in active"}
                        />
                    </Grid>

                    <Grid item sm={3} xs={6}>
                        <ProductInfobox
                            title={"Suspended"}
                            total={size(
                                filter(products, { isSuspended: true })
                            )}
                            bgColor={"#000"}
                            textColor={colors.bgColor3}
                            url={"/products/status/suspended"}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <ProductInfobox
                            title={"Denied"}
                            total={size(filter(products, { isDenied: true }))}
                            bgColor={colors.red}
                            textColor={colors.bgColor3}
                            url={"/products/status/denied"}
                        />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        flex: 1,
                        position: "relative",
                        borderRadius: 3,
                        overflow: "hidden",
                    }}
                >
                    <CustomPaginationTwo
                        title={"Active products"}
                        data={paginatedProducts}
                        handlePageChange={handlePageChange}
                        handlePerPageChage={handlePerPageChage}
                        action={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <ProductFilter
                                    filterByProductName={filterByProductName}
                                    filterByCategory={filterByCategory}
                                />
                                <CountBadge
                                    total={size(
                                        filter(products, { isActive: true })
                                    )}
                                    item={"products"}
                                />
                            </Box>
                        }
                    >
                        <Grid container spacing={2} padding={2}>
                            {isEmpty(products) ? (
                                <>
                                    <NoContent
                                        message={"No product available"}
                                        height={400}
                                    />
                                </>
                            ) : (
                                paginatedProducts.data?.map(
                                    (product, index) => (
                                        <Grid item sm={4} xs={12} key={index}>
                                            <ProductWidget product={product} />
                                        </Grid>
                                    )
                                )
                            )}
                        </Grid>
                    </CustomPaginationTwo>
                </Box>
            </PageLayout>
        </>
    );
};

export default Products;
