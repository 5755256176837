import { PaidOutlined, Pin, TransferWithinAStation } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import ShipOrder from "./ShipOrder";
import DeliveryPin from "./DeliveryPin";
import ConfirmOrderPayment from "./ConfirmOrderPayment";

// !################# MAIN FUNC ###################
const ActionButtons = ({ orderID, orderNumber, status, order }) => {
    const [actionType, setActionType] = useState("");
    const [confimationDialogOpen, setConfimationDialogOpen] = useState(false);

    // ############### FUNC ########################
    // => handle confimation dialog open
    const handleConfimationDialogOpen = (type) => {
        setActionType(type);
        setConfimationDialogOpen(true);
    };

    // => handle confimation dialog close
    const handleConfimationDialogClose = () => {
        setConfimationDialogOpen(false);
    };

    return (
        <>
            {/* ############# Confimation dialogy ################ */}
            <ConfirmationDialog
                customWidth={actionType === "ship" ? "md" : "sm"}
                head={
                    actionType === "ship"
                        ? `Ship ${orderNumber} order`
                        : actionType === "payment"
                        ? "Confirm order payment"
                        : `Delivery pin for ${orderNumber} order`
                }
                noDropClose
                openDialog={confimationDialogOpen}
                handleDialogClose={handleConfimationDialogClose}
            >
                {actionType === "ship" ? (
                    <>
                        <ShipOrder
                            orderID={orderID}
                            orderNumber={orderNumber}
                        />
                    </>
                ) : actionType === "payment" ? (
                    <>
                        <ConfirmOrderPayment orderID={orderID} />
                    </>
                ) : (
                    <>
                        <DeliveryPin orderID={orderID} />
                    </>
                )}
            </ConfirmationDialog>

            <Box
                sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-around",
                }}
            >
                {status === 2 && (
                    <Button
                        className="app-btn"
                        variant="contained"
                        size="small"
                        fullWidth
                        startIcon={<TransferWithinAStation />}
                        sx={{
                            boxShadow: "none",
                            mb: 1,
                            mr: 1,
                            fontSize: 12,
                            textTransform: "capitalize",
                            fontWeight: "bold",
                        }}
                        onClick={() => {
                            let type = "ship";
                            handleConfimationDialogOpen(type);
                        }}
                    >
                        Ship order
                    </Button>
                )}
                {status === 3 && (
                    <Button
                        className="app-btn"
                        variant="contained"
                        size="small"
                        color="success"
                        fullWidth
                        startIcon={<Pin />}
                        sx={{
                            boxShadow: "none",
                            mb: 1,
                            mr: 1,
                            fontSize: 12,
                            textTransform: "capitalize",
                            fontWeight: "bold",
                        }}
                        onClick={() => {
                            handleConfimationDialogOpen();
                        }}
                    >
                        Get delivery pin
                    </Button>
                )}
                {status === 1 &&
                    (!order.isPaid && (
                        <Button
                            className="app-btn"
                            variant="contained"
                            size="small"
                            color="success"
                            fullWidth
                            startIcon={<PaidOutlined />}
                            sx={{
                                boxShadow: "none",
                                mb: 1,
                                mr: 1,
                                fontSize: 12,
                                textTransform: "capitalize",
                                fontWeight: "bold",
                            }}
                            onClick={() => {
                                handleConfimationDialogOpen("payment");
                            }}
                        >
                            Cash payment
                        </Button>
                    ))}
            </Box>
        </>
    );
};

export default ActionButtons;
