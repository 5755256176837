import { configureStore } from "@reduxjs/toolkit";
import { RTKmiddleware } from "./RTKmiddlewares";

// => SLICE reducers
import authReducer from "../features/auth/authSlice";
import vendorReducer from "../features/vendors/vendorsSlice";
import storeReducer from "../features/stores/storeSlice";
import orderReducer from "../features/orders/orderSlice";
import productReducer from "../features/products/productSlice";
import categoryReducer from "../features/categories/categorySlice";
import adminsReducer from "../features/admins/adminsSlice";
import deliveryGuysReducer from "../features/deliveryGuys/deliveryGuysSlice";
import promotionsReducer from "../features/promotions/promotionsSlice";
import systemConfigReducer from "../features/systemConfig/systemConfigSlice";
import customersReducer from "../features/customers/customersSlice";
import beemReducer from "../features/beem/beemSlice";
import subCategoryReducer from "../features/categories/subCategorySlice";
import notificationsReducer from "../features/notifications/notificationsSlice";
import posOrderReducer from "../features/orders/posOrderSlice";

// => RTK reducers
import { vendorAPI } from "../../api/vendorAPI";
import { storeAPI } from "../../api/storeAPI";
import { authAPI } from "../../api/authAPI";
import { orderAPI } from "../../api/orderAPI";
import { productAPI } from "../../api/productAPI";
import { categoryAPI } from "../../api/categoryAPI";
import { adminsAPI } from "../../api/adminsAPI";
import { deliveryGuyAPI } from "../../api/deliveryGuyAPI";
import { promotionAPI } from "../../api/promotionAPI";
import { customerAPI } from "../../api/customerAPI";
import { beemAPI } from "../../api/beemAPI";
import { configAPI } from "../../api/configAPI";
import { subCategoryAPI } from "../../api/subCategoryAPI";
import { notificationAPI } from "../../api/notificationAPI";

export const store = configureStore({
    reducer: {
        [authAPI.reducerPath]: authAPI.reducer,
        [vendorAPI.reducerPath]: vendorAPI.reducer,
        [storeAPI.reducerPath]: storeAPI.reducer,
        [orderAPI.reducerPath]: orderAPI.reducer,
        [productAPI.reducerPath]: productAPI.reducer,
        [categoryAPI.reducerPath]: categoryAPI.reducer,
        [adminsAPI.reducerPath]: adminsAPI.reducer,
        [deliveryGuyAPI.reducerPath]: deliveryGuyAPI.reducer,
        [promotionAPI.reducerPath]: promotionAPI.reducer,
        [customerAPI.reducerPath]: customerAPI.reducer,
        [beemAPI.reducerPath]: beemAPI.reducer,
        [configAPI.reducerPath]: configAPI.reducer,
        [subCategoryAPI.reducerPath]: subCategoryAPI.reducer,
        [notificationAPI.reducerPath]: notificationAPI.reducer,
        authReducer,
        adminsReducer,
        vendorReducer,
        storeReducer,
        orderReducer,
        posOrderReducer,
        productReducer,
        categoryReducer,
        subCategoryReducer,
        deliveryGuysReducer,
        promotionsReducer,
        systemConfigReducer,
        customersReducer,
        beemReducer,
        notificationsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(RTKmiddleware),
});
