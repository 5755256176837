import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import PromoSidebar from '../../../components/sidebars/PromoSidebar';
import StorePromoWidget from '../../../components/widgets/StorePromoWidget';
import PageLayout from '../../layouts/PageLayout';
import CustomPaginationTwo from '../../../components/paginations/CustomPaginationTwo';
import CountBadge from '../../../components/CountBadge';
import { promotionsSelector } from '../../../state/features/selectors';
import { useSelector } from 'react-redux';
import { isEmpty, size } from 'lodash';
import NoContent from '../../../components/NoContent';
import { paginator } from '../../../helpers/paginationHelper';

const Promotions = () => {
    // ############### Component state ###################
    const [promotions, setPromotions] = useState([]);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ######### Redux states #############
    const { promotions: allPromotions } = useSelector(promotionsSelector);

    // ############## FILTER ###################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Filter by name/id
    // const filterByNameOrID = (search) => {
    //     let promotions = [];
    //     if (search) {
    //         promotions = allPromotions.filter((delivery_guy) => {
    //             return (
    //                 delivery_guy.login_id
    //                     .toLowerCase()
    //                     .includes(search.toLowerCase()) ||
    //                 delivery_guy.first_name
    //                     .toLowerCase()
    //                     .includes(search.toLowerCase()) ||
    //                 delivery_guy.last_name
    //                     .toLowerCase()
    //                     .includes(search.toLowerCase())
    //             );
    //         });
    //         return setPromotions(promotions);
    //     }
    //     return setPromotions(allPromotions);
    // };

    // ############## useEffect ################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setPromotions(allPromotions);
        }
        return () => {
            isSubscribed = false;
        };
    }, [setPromotions, allPromotions]);
    const paginatedPromotions = paginator(promotions, page, perPage);

    return (
        <>
            <PageLayout
                sidebar={<PromoSidebar />}
                pageHead={"Agiza africa store promotions"}
            >
                <CustomPaginationTwo
                    title={"Registered promotions"}
                    data={paginatedPromotions}
                    handlePageChange={handlePageChange}
                    handlePerPageChage={handlePerPageChage}
                    action={
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: 120,
                            }}
                        >
                            <CountBadge
                                total={size(promotions)}
                                item={"Promotions"}
                            />
                        </Box>
                    }
                >
                    {isEmpty(promotions) ? (
                        <>
                            <NoContent message={"No admin added"} />
                        </>
                    ) : (
                        <Grid container spacing={3} padding={2}>
                            {promotions?.map((promotion) => (
                                <Grid item sm={6} key={promotion._id}>
                                    <StorePromoWidget promotion={promotion} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </CustomPaginationTwo>
            </PageLayout>
        </>
    );
};

export default Promotions;
