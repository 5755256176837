import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CartProductWidget from "../../widgets/CartProductWidget";
import { colors } from "../../../assets/utils/colors";
import {
    RemoveShoppingCartOutlined,
    ShoppingCartCheckoutOutlined,
} from "@mui/icons-material";
import CustomSubmitButton from "../../forms/CustomSubmitButton";
import { posOrderSelector } from "../../../state/features/selectors";
import { useDispatch, useSelector } from "react-redux";
import CartCount from "./CartCount";
import NoContent from "../../NoContent";
import { isEmpty } from "lodash";
import {
    clearCart,
    getTotals,
    setCartPayload,
} from "../../../state/features/orders/posOrderSlice";
import { currencyFomater } from "../../../helpers/currencyFomater";
import ConfirmationDialog from "../../dialogs/ConfirmationDialog";
import PlaceOrder from "./PlaceOrder";

const Cart = ({ store, customer, closePOSCart }) => {
    // ################ CONST #####################
    const dispatch = useDispatch();

    // ############### Comp state ####################
    const [dialogOpen, setDialogOpen] = useState(false);

    // ################ Redux state ##################
    const { cartItems, cartTotalQuantity, cartTotalAmount, cartPayload } =
        useSelector(posOrderSelector);

    // ############## create cart payload #################
    const cartData = {
        ordered_products: cartItems,
        store_id: store?._id,
        customer: customer?._id,
        total_amount: cartTotalAmount,
        shipping_address: "",
        description: "POS order",
    };

    // ############### FUNC ########################
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    // ################ useEffect #################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (!isEmpty(cartItems)) {
                dispatch(getTotals());
                dispatch(setCartPayload(cartData));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [cartItems, cartTotalAmount]);

    return (
        <>
            <ConfirmationDialog
                // customWidth={actionType === "ship" ? "md" : "sm"}
                head="Provide shipping address"
                noDropClose
                openDialog={dialogOpen}
                handleDialogClose={handleDialogClose}
            >
                <PlaceOrder closePOSCart={closePOSCart} />
            </ConfirmationDialog>
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    borderTopLeftRadius: 15,
                    borderBottomLeftRadius: 15,
                    overflow: "hidden",
                }}
                className="animated-bg-3"
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 1,
                        background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            color: colors.bgColor3,
                            pl: 1,
                            fontSize: 14,
                        }}
                    >
                        Cart items
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CartCount />
                        {!isEmpty(cartItems) && (
                            <IconButton
                                onClick={() => {
                                    dispatch(clearCart());
                                }}
                                size="small"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyItems: "center",
                                    flexDirection: "column",
                                    px: 2,
                                    borderRadius: 2,
                                    ml: 2,
                                    border: `1px solid ${colors.bgColor3}`,
                                    background: `linear-gradient(180deg, ${colors.secondary}, ${colors.primary})`,
                                }}
                            >
                                <RemoveShoppingCartOutlined
                                    sx={{
                                        fontSize: 15,
                                        color: colors.bgColor3,
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: 10,
                                        color: colors.bgColor3,
                                    }}
                                >
                                    Clear cart
                                </Typography>
                            </IconButton>
                        )}
                    </Box>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            overflow: "auto",
                            flex: 1,
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }}
                    >
                        {isEmpty(cartItems) ? (
                            <NoContent
                                message={"No item added"}
                                height={"100%"}
                            />
                        ) : (
                            <Grid container spacing={2} padding={1}>
                                {cartItems.map((item, index) => (
                                    <Grid item sm={12} key={index}>
                                        <CartProductWidget product={item} />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Box>
                </Box>
                {!isEmpty(cartItems) && (
                    <Box
                        sx={{
                            p: 1,
                            display: "flex",
                            background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                        }}
                        gap={2}
                    >
                        <Box
                            sx={{
                                flex: 1.2,
                                p: 1,
                                px: 2,
                                borderRadius: 2,
                                border: `1px dotted ${colors.bgColor4}`,
                                background: `linear-gradient(180deg, ${colors.secondary}, ${colors.primary})`,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                        color: colors.bgColor3,
                                    }}
                                >
                                    Total item
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        color: colors.bgColor3,
                                    }}
                                >
                                    {cartTotalQuantity}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    mt: 1,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                        color: colors.bgColor3,
                                    }}
                                >
                                    Total amount
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        color: colors.bgColor3,
                                    }}
                                >
                                    {currencyFomater(cartTotalAmount)}
                                    <sup style={{ fontSize: 8 }}>TZS</sup>
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                height: "100%",
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <CustomSubmitButton
                                startIcon={<ShoppingCartCheckoutOutlined />}
                                btnColor={"success"}
                                onClick={() => {
                                    handleDialogOpen();
                                }}
                            >
                                Place order
                            </CustomSubmitButton>
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default Cart;
