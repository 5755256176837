import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';

// => initialstate
const initialState = {
    categories: [],
};

//! ############ SLICE ##############
const categorySlice = createSlice({
    name: 'categorySlice',
    initialState,
    reducers: {
        setCategories: (state, action) => {
            let categories = action.payload.data;
            state.categories = categories;
        },
        setUpdatedCategories: (state, action) => {
            let categories = action.payload;
            state.categories = categories;
        },
        addCategory: (state, action) => {
            let category = action.payload;
            state.categories = [category, ...state.categories];
        },
        removeCategory: (state, action) => {
            let category = action.payload;
            let category_id = category._id;
            let newCategories = filter(
                state.categories,
                ({ _id }) => _id !== category_id,
            );
            state.categories = newCategories;
        },
    },
});

export const {
    setCategories,
    addCategory,
    removeCategory,
    setUpdatedCategories,
} = categorySlice.actions;
export default categorySlice.reducer;
