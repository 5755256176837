import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useGetDeliveryPinQuery } from '../../../../api/orderAPI';
import InnerContentLoader from '../../../../components/InnerContentLoader';

const DeliveryPin = ({ orderID }) => {
    // ############# Comp state ##########
    const [deliveryPin, setDeliveryPin] = useState('');

    // ############# RTK #############
    const { isLoading, isSuccess, isError, data } = useGetDeliveryPinQuery(
        orderID,
    );

    // ########### UseEffect #########
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                setDeliveryPin(data.data.delivery_pin);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, data]);

    return (
        <>
            <Box
                sx={{
                    height: 300,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {isLoading ? (
                    <InnerContentLoader height={300} />
                ) : (
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 22,
                                color: 'green',
                                fontWeight: 'bold',
                                opacity: 0.8,
                            }}
                        >
                            {deliveryPin}
                        </Typography>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default DeliveryPin;
