import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import DashOrdersStats from "../dash/DashOrdersStats";
import { ordersSelector } from "../../state/features/selectors";
import { useSelector } from "react-redux";
import { filter, orderBy, size, take } from "lodash";
import OrdersTableTwo from "../tables/OrdersTableTwo";

const DashSidebar = () => {
    // ############### Redux state ########################
    const { orders, isLoading } = useSelector(ordersSelector);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                }}
                gap={2}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Typography
                        className="head-font"
                        sx={{
                            fontSize: 15,
                            fontWeight: "bold",
                            position: "relative",
                            color: colors.primary,
                            "&::before": {
                                content: "''",
                                height: 2.5,
                                width: 50,
                                position: "absolute",
                                bottom: -3,
                                borderRadius: 110,
                                background: colors.bgColor1,
                            },
                        }}
                    >
                        Latest orders
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 10,
                            fontWeight: "bold",
                            color: colors.primary,
                            py: 0.5,
                            px: 2,
                            borderRadius: 3,
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        }}
                    >
                        {size(take(orders, 10))} orders
                    </Typography>
                </Box>
                <Box sx={{ flex: 1.2 }}>
                    <OrdersTableTwo
                        isLoading={isLoading}
                        orders={take(
                            orderBy(orders, ["createdAt"], ["desc"]),
                            10
                        )}
                    />
                </Box>
                <Box sx={{ flex: 1, position: "relative" }}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            borderRadius: 4,
                            p: 1,
                            display: "flex",
                            alignItems: "center",
                            boxShadow: `0px 0px 2px ${colors.primary}`,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                flex: 1,
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Box sx={{ p: 1 }}>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: colors.primary,
                                    }}
                                >
                                    Total orders
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        mt: 0.5,
                                        opacity: 0.7,
                                    }}
                                    className="head-font"
                                >
                                    {size(orders)}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item sm={6} md={6} xs={6}>
                                        <DashOrdersStats
                                            title={"New"}
                                            total={size(
                                                filter(orders, {
                                                    order_status: 1,
                                                })
                                            )}
                                            bgColor={colors.bgColor1}
                                        />
                                    </Grid>
                                    <Grid item sm={6} md={6} xs={6}>
                                        <DashOrdersStats
                                            title={"Processing"}
                                            total={size(
                                                filter(orders, {
                                                    order_status: 2,
                                                })
                                            )}
                                            bgColor={colors.gray}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={6}>
                                        <DashOrdersStats
                                            title={"Shipped"}
                                            total={size(
                                                filter(orders, {
                                                    order_status: 3,
                                                })
                                            )}
                                            bgColor={colors.yellow}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={6}>
                                        <DashOrdersStats
                                            title={"Delivered"}
                                            total={size(
                                                filter(orders, {
                                                    order_status: 4,
                                                })
                                            )}
                                            bgColor={colors.green}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                sx={{
                                    p: 1,
                                    mt: 1,
                                    textAlign: "center",
                                    borderBottomRightRadius: 10,
                                    borderBottomLeftRadius: 10,
                                    borderBottom: `2px solid ${colors.red}`,
                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        opacity: 0.6,
                                        color: "red",
                                    }}
                                >
                                    {size(filter(orders, { isPaid: false }))}{" "}
                                    Unpaid orders
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default DashSidebar;
