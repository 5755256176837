import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { colors } from "../../assets/utils/colors";
import OrdersCard from "../cards/OrdersCard";
import { isEmpty, orderBy } from "lodash";
import NoContent from "../NoContent";
import InnerContentLoader from "../InnerContentLoader";

const columns = [{ id: "s_n", label: "S/N", minWidth: 0 }];

function createData(s_n) {
    return {
        s_n,
    };
}

// !############### MAIN FUNC #####################
const OrdersTableTwo = ({ orders, isLoading, height }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = orderBy(orders, ["createdAt"], ["desc"])?.map((order) => {
        return createData(<OrdersCard order={order} />);
    });

    return (
        <React.Fragment>
            <Paper
                sx={{
                    boxShadow: "none",
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: 5,
                    display: "flex",
                    flexDirection: "column",
                    bgcolor: colors.bgColor3,
                    position: "relative",
                    height: "100%",
                    border: `5px solid ${colors.bgColor1}`,
                    boxShadow: `0px 0px 2px ${colors.primary}`,
                }}
            >
                <TableContainer
                    sx={{
                        flex: 1,
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        overflowY: "auto",
                    }}
                >
                    {isLoading ? (
                        <>
                            <InnerContentLoader height={250} />
                        </>
                    ) : isEmpty(orders) ? (
                        <>
                            <NoContent message={"No order available"} />
                        </>
                    ) : (
                        <Table stickyHeader>
                            <TableBody sx={{ overflowX: "hidden" }}>
                                {rows
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                {columns.map((column) => {
                                                    const value =
                                                        row[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            sx={{
                                                                p: "0 !important",
                                                            }}
                                                        >
                                                            {column.format &&
                                                            typeof value ===
                                                                "number"
                                                                ? column.format(
                                                                      value
                                                                  )
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[1, 2, 5, 10]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        bgcolor: colors.bgColor1,
                        overflowX: "hidden",
                        "& .MuiTablePagination-selectLabel": {
                            display: "none",
                        },
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                />
            </Paper>
        </React.Fragment>
    );
};

export default OrdersTableTwo;
