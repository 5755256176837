import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../../../assets/utils/colors';
import CustomSubmitButton from '../../../../components/forms/CustomSubmitButton';
import { useApproveStoreTINMutation } from '../../../../api/storeAPI';
import { useDispatch } from 'react-redux';
import { setStoreDetails } from '../../../../state/features/stores/storeSlice';
import SuccessFeedback from '../../../../components/SuccessFeedback';
import { useApproveStoreBusinessLicenceMutation } from '../../../../api/storeAPI';
import { errorNotify, successNotify } from '../../../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../../helpers/RTKHelpers';

const ApproveDocument = ({ document, store }) => {
    // ############## CONST ##############
    const dispatch = useDispatch();

    // ############## RTK ################
    // => Approve TIN
    const [
        approveStoreTIN,
        {
            isLoading: tinLoading,
            isSuccess: tinSuccess,
            isError: tinIsError,
            error: tinError,
            data: tinApprovedStore,
        },
    ] = useApproveStoreTINMutation();
    // => Approve BL
    const [
        approveStoreBusinessLicence,
        {
            isLoading: blLoading,
            isSuccess: blSuccess,
            isError: blIsError,
            error: blError,
            data: blApprovedStore,
        },
    ] = useApproveStoreBusinessLicenceMutation();

    // ############### FUNC ###############
    // => Handle approve TIN
    const handleApproveTIN = () => {
        approveStoreTIN(store._id);
    };

    // => Handle approve BL
    const handleApproveBL = () => {
        approveStoreBusinessLicence(store._id);
    };

    // ############### useEffect ###########
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (tinSuccess) {
                successNotify(getRTKSuccessMessage(tinApprovedStore));
                dispatch(setStoreDetails(getRTKResponseData(tinApprovedStore)));
            }
            if (blSuccess) {
                successNotify(getRTKSuccessMessage(blApprovedStore));
                dispatch(setStoreDetails(getRTKResponseData(blApprovedStore)));
            }
            if (tinIsError) {
                errorNotify(getRTKErrorMessage(tinError));
            }
            if (blIsError) {
                errorNotify(getRTKErrorMessage(blError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        tinSuccess,
        blSuccess,
        blIsError,
        tinIsError,
        tinApprovedStore,
        blApprovedStore,
        tinError,
        blError,
        dispatch,
    ]);

    return (
        <>
            <Box
                sx={{
                    p: 2,
                    borderRadius: 2,
                    height: 300,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                {tinSuccess || blSuccess ? (
                    <SuccessFeedback
                        message={'Document approved successfully'}
                    />
                ) : (
                    <>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 22,
                                    color: colors.primary,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                }}
                            >
                                Store {document} document approval
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontSize: 14, opacity: 0.8 }}>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Reiciendis labore illo
                                aspernatur aliquid? Adipisci consequuntur, ipsum
                                id culpa natus minus numquam eaque pariatur
                                veniam cupiditate ratione quis accusamus! Ut,
                                vitae?
                            </Typography>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                            <CustomSubmitButton
                                loading={tinLoading ? tinLoading : blLoading}
                                onClick={() => {
                                    if (document === 'TIN') {
                                        handleApproveTIN();
                                    } else {
                                        handleApproveBL();
                                    }
                                }}
                            >
                                Approve document
                            </CustomSubmitButton>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

export default ApproveDocument;
