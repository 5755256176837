import React from "react";
import { Box, Grid } from "@mui/material";
import { colors } from "../../../../assets/utils/colors";
import ApproveAndDenyButton from "./actionButtons/ApproveAndDenyButton";
import SuspendButton from "./actionButtons/SuspendButton";
import ActivateButton from "./actionButtons/ActivateButton";
import DeleteButton from "./actionButtons/DeleteButton";
import CategoryAssignButton from "./actionButtons/CategoryAssignButton";
import ChangeStoreButton from "./actionButtons/ChangeStoreButton";

function ProductActionButtons({
    product,
    handleProductApprove,
    handleProductDeny,
}) {
    return (
        <div>
            <Box
                sx={{
                    py: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                }}
            >
                <Grid container padding={0.5} columnSpacing={1}>
                    {!product.isApproved && !product.isDenied && (
                        <Grid item sm={6}>
                            <ApproveAndDenyButton
                                handleProductApprove={handleProductApprove}
                                handleProductDeny={handleProductDeny}
                            />
                        </Grid>
                    )}
                    {product.isApproved && (
                        <>
                            {!product.isSuspended ? (
                                <Grid item sm={6}>
                                    <SuspendButton
                                        handleProductApprove={
                                            handleProductApprove
                                        }
                                    />
                                </Grid>
                            ) : (
                                <>
                                    <Grid item sm={6}>
                                        <ActivateButton
                                            handleProductApprove={
                                                handleProductApprove
                                            }
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <DeleteButton
                                            handleProductApprove={
                                                handleProductApprove
                                            }
                                        />
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                    {!product.isActive && (
                        <Grid item sm={6}>
                            <DeleteButton
                                handleProductApprove={handleProductApprove}
                            />
                        </Grid>
                    )}
                    <Grid item sm={6}>
                        <ChangeStoreButton
                            handleProductApprove={handleProductApprove}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <CategoryAssignButton
                            handleProductApprove={handleProductApprove}
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default ProductActionButtons;
