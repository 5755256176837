import { PersonAddAlt } from "@mui/icons-material";
import { Box, Button, Grid, styled } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../assets/utils/colors";
import CustomDialog from "../dialogs/CustomDialog";
import CreateAdmin from "../../pages/users/admins/CreateAdmin";

const AdminsSidebar = () => {
    const [openDialog, setOpenDialog] = useState(false);

    // ############ FUNC ###############
    const handleDialogyOpen = () => {
        setOpenDialog(true);
    };
    const handleDialogyClose = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Box sx={{ display: "flex", width: "100%" }}>
                <CustomDialog
                    openDialog={openDialog}
                    handleDialogClose={handleDialogyClose}
                    head={"Add agiza africa admin"}
                >
                    <CreateAdmin />
                </CustomDialog>
                <Box sx={{ flex: 1 }}>
                    <Button
                        startIcon={<PersonAddAlt />}
                        variant="contained"
                        fullWidth
                        onClick={handleDialogyOpen}
                        sx={{
                            borderRadius: 2,
                            height: 80,
                            textTransform: "capitalize",
                            border: `10px solid ${colors.bgColor1}`,
                            color: colors.primary,
                            fontWeight: "bold",
                            boxShadow: "none !important",
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                            ":hover": {
                                color: colors.secondary,
                            },
                        }}
                    >
                        Add new admin
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default AdminsSidebar;
