// => GET all vendors
const GET_ALL_VENDORS = '/admins/vendors';

// => GET all vendors
const GET_SINGLE_VENDOR = '/admins/vendors/';

export const vendorsEndpoints = {
    GET_ALL_VENDORS,
    GET_SINGLE_VENDOR,
};
