import React, { useEffect, useState } from "react";
import {
    AddShoppingCartOutlined,
    ArrowCircleRightOutlined,
    ShoppingCartCheckoutOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import CartProductImages from "./CartProductImages";
import { colors } from "../../../assets/utils/colors";
import CustomSubmitButton from "../../forms/CustomSubmitButton";
import CartProductColor from "./CartProductColor";
import CartProductSize from "./CartProductSize";
import { isEmpty, random, size } from "lodash";
import { addCartItem } from "../../../state/features/orders/posOrderSlice";
import { useDispatch } from "react-redux";
import { currencyFomater } from "../../../helpers/currencyFomater";
import { errorNotify } from "../../../helpers/notify";

const CartProductDetails = ({ productDetails, close }) => {
    // ############### CONST ###############
    const dispatch = useDispatch();

    // ############### Comp state ##############
    const [cartProduct, setCartProduct] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);
    const [additionDetails, setAdditionDetails] = useState(null);

    // ###### Distructure product data #########
    const available_colors = productDetails?.available_colors;
    const available_sizes = productDetails?.available_sizes;
    const moreImages = productDetails?.more_images;
    const bannerImage = productDetails?.banner_image;

    // ############## FUNC ####################
    const addSelectedColor = (value) => {
        let currValue = selectedColor;
        setSelectedColor({ ...currValue, selected_color: value });
    };

    const addSelectedSize = (value) => {
        let currValue = selectedSize;
        setSelectedSize({ ...currValue, selected_size: value });
    };

    // ############# useEffect  #################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setAdditionDetails({ ...selectedColor, ...selectedSize });
        }
        return () => {
            isSubscribed = false;
        };
    }, [selectedColor, selectedSize]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setCartProduct(productDetails);
        }
        return () => {
            isSubscribed = false;
        };
    }, [productDetails]);

    return (
        <>
            <Box
                sx={{
                    transition: `.3s all ease-in-out`,
                    width: productDetails ? "100%" : 0,
                    position: "absolute",
                    overflow: "hidden",
                    zIndex: 999,
                    top: productDetails ? 0 : 0,
                    bottom: productDetails ? 0 : 0,
                    right: 0,
                    left: productDetails ? 0 : "100%",
                    borderTopLeftRadius: 15,
                    borderBottomLeftRadius: 15,
                    boxShadow: `0px 0px 5px ${colors.primary}`,
                    display: "flex",
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                    flex: 1,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {isEmpty(productDetails) ? (
                        ""
                    ) : (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    p: 1,
                                    background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        color: colors.bgColor3,
                                        fontSize: 14,
                                        pl: 1,
                                    }}
                                >
                                    Product details
                                </Typography>
                                <IconButton
                                    onClick={() => {
                                        close(null);
                                    }}
                                >
                                    <ArrowCircleRightOutlined
                                        sx={{ color: colors.bgColor3 }}
                                    />
                                </IconButton>
                            </Box>
                            <Box sx={{ flex: 1, position: "relative" }}>
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        display: "flex",
                                        overflow: "outo",
                                        flexDirection: "column",
                                    }}
                                    // gap={2}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            p: 1,
                                            px: 2,
                                            justifyContent: "space-between",
                                            bgcolor: colors.bgColor1,
                                        }}
                                        className="animated-bg"
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "bold",
                                                color: colors.primary,
                                                fontSize: 14,
                                            }}
                                        >
                                            {productDetails.title}
                                        </Typography>
                                        <Box>
                                            {productDetails.discounted_price >
                                                0 && (
                                                <Typography
                                                    className="head-font"
                                                    sx={{
                                                        fontSize: 9,
                                                        fontWeight: "bold",
                                                        color: colors.red,
                                                    }}
                                                >
                                                    <strike>
                                                        TZS
                                                        {currencyFomater(
                                                            (productDetails.discounted_price *
                                                                productDetails.price) /
                                                                100
                                                        )}
                                                    </strike>
                                                </Typography>
                                            )}
                                            <Typography
                                                className="head-font"
                                                sx={{
                                                    fontSize: 16,
                                                    fontWeight: "bold",
                                                    color: colors.primary,
                                                }}
                                            >
                                                {currencyFomater(
                                                    productDetails.price
                                                )}
                                                <sup>TZS</sup>
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <CartProductImages
                                            bannerImage={bannerImage}
                                            moreImages={moreImages}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            flex: 1,
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                        gap={2}
                                    >
                                        <Box
                                            sx={{
                                                flex: 1,
                                                display: "flex",
                                                px: 1,
                                            }}
                                            gap={1}
                                        >
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                    p: 1,
                                                    borderRadius: 2,
                                                    position: "relative",
                                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                                }}
                                            >
                                                <CartProductColor
                                                    availableColors={
                                                        available_colors
                                                    }
                                                    handler={addSelectedColor}
                                                    product={productDetails}
                                                />
                                            </Box>
                                            {!isEmpty(available_sizes) ? (
                                                <Box
                                                    sx={{
                                                        flex: 1,
                                                        p: 1,
                                                        borderRadius: 2,
                                                        position: "relative",
                                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                                    }}
                                                >
                                                    <CartProductSize
                                                        sizes={available_sizes}
                                                        handler={
                                                            addSelectedSize
                                                        }
                                                        product={productDetails}
                                                    />
                                                </Box>
                                            ) : (
                                                ""
                                            )}
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                p: 2,
                                                background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                                            }}
                                            gap={2}
                                        >
                                            {/* <CustomSubmitButton
                                                startIcon={
                                                    <ShoppingCartCheckoutOutlined />
                                                }
                                                btnColor={"success"}
                                            >
                                                Place order
                                            </CustomSubmitButton> */}
                                            <CustomSubmitButton
                                                startIcon={
                                                    <AddShoppingCartOutlined />
                                                }
                                                onClick={() => {
                                                    if (
                                                        size(available_colors) >
                                                            0 &&
                                                        selectedColor.selected_color ===
                                                            null
                                                    ) {
                                                        return errorNotify(
                                                            "Please select product color"
                                                        );
                                                    }
                                                    if (
                                                        size(available_sizes) >
                                                            0 &&
                                                        selectedSize.selected_size ===
                                                            null
                                                    ) {
                                                        return errorNotify(
                                                            "Please select product size"
                                                        );
                                                    }
                                                    dispatch(
                                                        addCartItem({
                                                            ...{
                                                                cart_id: random(
                                                                    100,
                                                                    200
                                                                ),
                                                            },
                                                            ...cartProduct,
                                                            ...additionDetails,
                                                        })
                                                    );
                                                    setSelectedColor(null);
                                                    setSelectedSize(null);
                                                    setAdditionDetails(null);
                                                    close();
                                                }}
                                            >
                                                Add to cart
                                            </CustomSubmitButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default CartProductDetails;
