import React from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { ScreenLockPortrait } from "@mui/icons-material";
import logo from "../../assets/media/images/logo1.png";

const MobDetect = () => {
    return (
        <>
            <Box
                sx={{
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                }}
            >
                <Grid container justifyContent={"center"}>
                    <Grid item sm={5} xs={11}>
                        <Box
                            sx={{
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                alignItems: "center",
                                borderRadius: 5,
                                py: 5,
                                px: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    mb: 4,
                                }}
                            >
                                <ScreenLockPortrait
                                    color="error"
                                    sx={{
                                        fontSize: 70,
                                        fontWeight: "bold",
                                        opacity: 0.8,
                                    }}
                                />
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        color: colors.red,
                                        textAlign: "center",
                                        fontSize: 14,
                                    }}
                                >
                                    Opps! Mobile device not allowed!
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        color: colors.primary,
                                        fontSize: 13,
                                        textAlign: "center",
                                        p: 3,
                                    }}
                                >
                                    Sorry! It seems like your using mobile phone
                                    to try to access dashboard. We advice you to
                                    use Computer or Laptop for convinient
                                    management.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    "& .logo": {
                                        width: 160,
                                        height: 66,
                                    },
                                }}
                            >
                                <Avatar
                                    src={logo}
                                    alt="Agiza logo"
                                    className="logo"
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default MobDetect;
