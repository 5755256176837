import { createSlice } from '@reduxjs/toolkit';

// => initialState
const initialState = {
    stores: [],
    storeDetails: [],
};

const storeSlice = createSlice({
    name: 'storeSlice',
    initialState,
    reducers: {
        setStores: (state, action) => {
            let stores = action.payload.data;
            state.stores = stores;
        },
        setStoreDetails: (state, action) => {
            let storeDetails = action.payload;
            state.storeDetails = storeDetails;
        },
    },
});

export const { setStores, setStoreDetails } = storeSlice.actions;
export default storeSlice.reducer;
