import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { colors } from "../../../assets/utils/colors";

const CartProductImages = ({ bannerImage, moreImages }) => {
    // => Image preview state
    const [imagePreview, setImagePreview] = useState("");

    // => Func
    const handleImagePreview = (image) => {
        setImagePreview(image);
    };

    // => useEffect
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setImagePreview(bannerImage);
        }
        return () => {
            isSubscribed = false;
        };
    }, [bannerImage]);

    return (
        <>
            <Box
                sx={{
                    p: 1,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    overflow: "hidden",
                    position: "relative",
                    display: "flex",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                }}
                gap={1}
            >
                <Box
                    sx={{
                        flex: 3,
                    }}
                >
                    <img
                        src={imagePreview}
                        alt=""
                        style={{
                            height: 200,
                            width: "100%",
                            objectFit: "cover",
                            borderRadius: 15,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        pt: 1,
                        pr: 2,
                        borderRadius: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <Button
                                size="small"
                                sx={{
                                    padding: 0,
                                    transition: `.3s all ease-in-out`,
                                    ":hover": {
                                        transition: `.3s all ease-in-out`,
                                        transform: `scale(1.05)`,
                                    },
                                }}
                                onClick={() => {
                                    handleImagePreview(bannerImage);
                                }}
                            >
                                <img
                                    src={bannerImage}
                                    alt=""
                                    style={{
                                        height: 50,
                                        width: 50,
                                        objectFit: "cover",
                                        borderRadius: 5,
                                    }}
                                />
                            </Button>
                        </Grid>
                        {moreImages?.map((image, index) => (
                            <Grid item sm={6} key={index}>
                                <Button
                                    size="small"
                                    sx={{
                                        padding: 0,
                                        transition: `.3s all ease-in-out`,
                                        ":hover": {
                                            transition: `.3s all ease-in-out`,
                                            transform: `scale(1.05)`,
                                        },
                                    }}
                                    onClick={() => {
                                        handleImagePreview(image.filePath);
                                    }}
                                >
                                    <img
                                        src={image.filePath}
                                        alt=""
                                        style={{
                                            height: 50,
                                            width: 50,
                                            objectFit: "cover",
                                            borderRadius: 5,
                                        }}
                                    />
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default CartProductImages;
