import { createSlice } from '@reduxjs/toolkit';

// => initialState
const initialState = {
    deliveryGuys: [],
};

// !############# SLICE ################
export const deliveryGuysSlice = createSlice({
    name: 'deliveryGuysSlice',
    initialState,
    reducers: {
        setDeliveryGuys: (state, action) => {
            let deliveryGuys = action.payload.data;
            state.deliveryGuys = deliveryGuys;
        },
        addDeliveryGuy: (state, action) => {
            let deliveryGuy = action.payload;
            state.deliveryGuys = [...state.deliveryGuys, deliveryGuy];
        },
    },
});

export const { setDeliveryGuys, addDeliveryGuy } = deliveryGuysSlice.actions;
export default deliveryGuysSlice.reducer;
