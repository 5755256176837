import React, { useEffect, useState } from 'react';
import {
    Box,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/colors';
import moment from 'moment/moment';
import { DeleteForever } from '@mui/icons-material';
import { useActivatePromotionMutation, useDeletePromotionMutation } from '../../api/promotionAPI';
import { useDispatch } from 'react-redux';
import { removePromotion, setPromotions } from '../../state/features/promotions/promotionsSlice';
import { errorNotify, successNotify } from '../../helpers/notify';
import { getRTKErrorMessage, getRTKSuccessMessage } from '../../helpers/RTKHelpers';

const StorePromoWidget = ({ promotion }) => {
    const [promoID, setPromoID] = useState('');
    // ############# Const #############
    const dispatch = useDispatch();

    // ############# RTK #############
    const [
        deletePromotion,
        { isLoading, isSuccess, isError, data },
    ] = useDeletePromotionMutation();

    // => Activate/Deactivate promotion
    const [
        activateDeactivatePromotion,
        {
            isLoading: activateIsLoading,
            isSuccess: activateIsSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivatePromotionMutation();


    // ############# FUNC ##############
    const handleDeletePromotion = (payload) => {
        setPromoID(payload);
        deletePromotion(payload);
    };

    // ############# use Effect ##############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                successNotify(getRTKSuccessMessage(data));
                dispatch(removePromotion(promoID));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, data, promoID, dispatch]);
    // => Activate promotion
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (activateIsSuccess) {
                successNotify(getRTKSuccessMessage(activateData));
                dispatch(setPromotions(activateData));
            }
        }

         if (activateIsError) {
            errorNotify(getRTKErrorMessage(activateError));
        }

        return () => {
            isSubscribed = false;
        };
    }, [activateIsSuccess, activateIsError, activateData, activateError, dispatch]);

    return (
        <>
            <Box
                sx={{
                    height: '100%',
                    borderRadius: 4,
                    border: `1px solid ${colors.bgColor1}`,
                    overflow: 'hidden',
                    position: 'relative',
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(${promotion.promo_image})`,
                }}
            >
                <Box
                    sx={{
                        background: `rgb(125, 98, 212, .1)`,
                        position: 'absolute',
                        zIndex: 100,
                        left: 365,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        borderTopLeftRadius: 100,
                    }}
                ></Box>
                <Box
                    sx={{
                        background: `rgb(125, 98, 212, .1)`,
                        position: 'absolute',
                        zIndex: 50,
                        left: 320,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        borderTopLeftRadius: 100,
                    }}
                ></Box>
                <Grid container>
                    <Grid item sm={8} sx={{ borderRadius: 2 }}>
                        <Box sx={{ p: 2 }}>
                            <Box>
                                <Typography
                                    sx={{ fontSize: 14, fontWeight: 'bold' }}
                                >
                                    Agiza store
                                </Typography>
                                <Typography sx={{ fontSize: 12, mt: 0.5 }}>
                                    {promotion.description}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-ar',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    From :{' '}
                                    {moment(promotion.start_date).format(
                                        'Do  MMM  YYYY',
                                    )}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: colors.red,
                                        ml: 2,
                                    }}
                                >
                                    To :{' '}
                                    {moment(promotion.end_date).format(
                                        'Do  MMM  YYYY',
                                    )}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <Chip
                                    label={
                                        promotion.isActive
                                            ? 'Active'
                                            : 'inActive'
                                    }
                                    color={
                                        promotion.isActive
                                            ? 'success'
                                            : 'warning'
                                    }
                                    size="small"
                                />
                                <Tooltip title="Deactivate promotion" arrow>
                                    <IconButton size="small" color="error" onClick={() => {
                                        activateDeactivatePromotion(promotion._id)
                                    }}>
                                        {activateIsLoading ? (
                                            <CircularProgress size={18} sx={{ml: 1}} />
                                        ) : (
                                            <Switch
                                                sx={{ zIndex: 999 }}
                                                checked={
                                                    promotion.isActive
                                                        ? true
                                                        : false
                                                }
                                                size="small"
                                            />
                                        )}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete promotion" arrow>
                                    <IconButton
                                        size="small"
                                        color="error"
                                        disabled={isLoading ? true : false}
                                        onClick={() => {
                                            handleDeletePromotion(
                                                promotion._id,
                                            );
                                        }}
                                    >
                                        {isLoading ? (
                                            <CircularProgress size={18} />
                                        ) : (
                                            <DeleteForever />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default StorePromoWidget;
