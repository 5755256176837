import React from 'react';
import { Box, MenuItem } from '@mui/material';
import CustomSelectFilter from '../forms/CustomSelectFilter';
import CustomTextFieldFilter from '../forms/CustomTextFieldFilter';
import { useSelector } from 'react-redux';
import { categoriesSelector } from '../../state/features/selectors';

const ProductFilter = ({ filterByCategory, filterByProductName }) => {
    // ############### Redux state ################
    const { categories } = useSelector(categoriesSelector);

    return (
        <>
            <Box
                sx={{
                    height: `100%`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <CustomTextFieldFilter
                    width={200}
                    label={'Product name'}
                    handleFilter={filterByProductName}
                />
                <CustomSelectFilter
                    width={200}
                    handleFilter={filterByCategory}
                    compValue={''}
                >
                    <MenuItem sx={{ fontSize: 12 }} value="">
                        <em>All products</em>
                    </MenuItem>
                    {categories?.map((category) => (
                        <MenuItem
                            sx={{ fontSize: 12 }}
                            value={category._id}
                            key={category._id}
                        >
                            {category.category_name}
                        </MenuItem>
                    ))}
                </CustomSelectFilter>
            </Box>
        </>
    );
};

export default ProductFilter;
