import {
    Box,
    Button,
    Grid,
    Typography,
} from "@mui/material";
import React, {  useState } from "react";
import StoreProducts from "./components/StoreProducts";
import StoreWidgetBottom from "../widgets/StoreWidgetBottom";
import {
    customersSelector,
    productsSelector,
    storesSelector,
} from "../../state/features/selectors";
import { useDispatch, useSelector } from "react-redux";
import { filter, isEmpty } from "lodash";
import CustomerMiniWidget from "../widgets/CustomerMiniWidget";
import { ArrowCircleLeftOutlined } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import CartProductDetails from "./components/CartProductDetails";
import Cart from "./components/Cart";
import SelectStore from "./SelectStore";
import CustomerSelect from "./CustomerSelect";

const POSCart = ({ closePOSCart }) => {
    // ############# Const ################
    const dispatch = useDispatch();

    // ################ Comp State ####################
    const [storeDetails, setStoreDetails] = useState(null);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [productDetails, setProductDetails] = useState(null);
    const [storeId, setStoreId] = useState(null);

    // ############### Redux state ########################
    const { stores: allStores } = useSelector(storesSelector);
    const { products } = useSelector(productsSelector);
    const { customers } = useSelector(customersSelector);

    // ############### FUNC ########################
    // => Get store
    const getStore = (store_id) => {
        let store = filter(allStores, ({ _id }) => _id === store_id);
        setStoreId(store_id);
        setStoreDetails(store[0]);
    };
    // => Get customer
    const setCustomer = (customer) => {
        setCustomerDetails(customer);
    };
    // => Get product details
    const getProductDetails = (product_id) => {
        let product = filter(products, ({ _id }) => _id === product_id);
        let productDetails = product[0];
        setProductDetails(productDetails);
    };

    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        position: "relative",
                    }}
                    gap={2}
                >
                    <Box
                        sx={{
                            flex: 2.05,
                            position: "relative",
                            borderRadius: 3,
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            py: 1,
                            pl: 1,
                            zIndex: 50,
                        }}
                    >
                        {isEmpty(customerDetails) ? (
                            <>
                                <Box
                                    sx={{
                                        position: "relative",
                                        height: "100%",
                                        borderRadius: 2,
                                        overflow: "hidden",
                                    }}
                                >
                                    <CustomerSelect setCustomer={setCustomer} />
                                    {/* <SelectCustomer getCustomer={getCustomer} /> */}
                                </Box>
                            </>
                        ) : isEmpty(storeDetails) ? (
                            <>
                                <Box
                                    sx={{
                                        position: "relative",
                                        height: "100%",
                                        borderRadius: 2,
                                        overflow: "hidden",
                                    }}
                                >
                                    <SelectStore getStore={getStore} />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Grid container spacing={2} sx={{ pb: 2 }}>
                                    <Grid item sm={6}>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                textTransform: "capitalize",
                                                pb: 1,
                                                color: colors.primary,
                                            }}
                                        >
                                            Selected store
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "100%",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    borderRadius: 2,
                                                    overflow: "hidden",
                                                    flex: 2,
                                                }}
                                            >
                                                {storeDetails && (
                                                    <StoreWidgetBottom
                                                        height={85}
                                                        store={
                                                            !isEmpty(
                                                                storeDetails
                                                            ) && storeDetails
                                                        }
                                                    />
                                                )}
                                            </Box>
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                    height: 82,
                                                    borderRadius: 2,
                                                    overflow: "hidden",
                                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                                }}
                                            >
                                                <Button
                                                    size="small"
                                                    color={"primary"}
                                                    fullWidth
                                                    sx={{ height: "100%" }}
                                                    onClick={() => {
                                                        setStoreDetails(null);
                                                    }}
                                                    endIcon={
                                                        <ArrowCircleLeftOutlined />
                                                    }
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 12,
                                                            fontWeight: "bold",
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        Change <br /> store
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                textTransform: "capitalize",
                                                pb: 1,
                                                color: colors.primary,
                                            }}
                                        >
                                            Selected Customer
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "100%",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    flex: 2,
                                                    height: "100%",
                                                }}
                                            >
                                                <CustomerMiniWidget
                                                    customer={
                                                        !isEmpty(
                                                            customerDetails
                                                        ) && customerDetails
                                                    }
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                    height: 82,
                                                    borderRadius: 2,
                                                    overflow: "hidden",
                                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                                }}
                                            >
                                                <Button
                                                    size="small"
                                                    color={"primary"}
                                                    fullWidth
                                                    sx={{ height: "100%" }}
                                                    onClick={() => {
                                                        setCustomerDetails(
                                                            null
                                                        );
                                                    }}
                                                    endIcon={
                                                        <ArrowCircleLeftOutlined />
                                                    }
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 12,
                                                            fontWeight: "bold",
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        Change <br /> customer
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box sx={{ flex: 1, position: "relative" }}>
                                    <StoreProducts
                                        storeId={storeId}
                                        getProductDetails={getProductDetails}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            position: "relative",
                            height: "100%",
                            display: "flex",
                        }}
                    >
                        <Cart
                            store={storeDetails}
                            customer={customerDetails}
                            closePOSCart={closePOSCart}
                        />
                        <CartProductDetails
                            productDetails={productDetails}
                            close={() => setProductDetails(null)}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default POSCart;
