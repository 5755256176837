import styled from "@emotion/styled";
import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import { filter, isEmpty, size } from "lodash";
import { useSelector } from "react-redux";
import {
    customersSelector,
    vendorsSelector,
} from "../../state/features/selectors";
import CustomerWidget5 from "../widgets/CustomerWidget5";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Person } from "@mui/icons-material";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        p: 1,
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const NotificationsSidebar = () => {
    // ############# Component state #######
    const [value, setValue] = React.useState(0);

    // => Handle tab change
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { vendors } = useSelector(vendorsSelector);
    const { subscribersCustomers: subscribedCustomers } =
        useSelector(customersSelector);

    const getSubscribedVendors = () => {
        let subscribedUsers = filter(
            vendors,
            ({ vendor_tokens }) => !isEmpty(vendor_tokens)
        );
        return subscribedUsers;
    };

    const subscribedVendors = getSubscribedVendors();

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    p: 1,
                    px: 2,
                }}
            >
                <Typography
                    className="head-font"
                    sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        position: "relative",
                        color: colors.primary,
                        "&::before": {
                            content: "''",
                            height: 2.5,
                            width: 50,
                            position: "absolute",
                            bottom: -3,
                            borderRadius: 110,
                            background: colors.bgColor1,
                        },
                    }}
                >
                    Subscribed users
                </Typography>
            </Box>
            <Box sx={{ flex: 1, overflowY: "auto" }}>
                <AppBar
                    position="sticky"
                    sx={{
                        position: "sticky",
                        top: -8,
                        zIndex: 999,
                        mt: -8,
                    }}
                    elevation={2}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                    >
                        <Tab
                            label={
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: 10,
                                            mr: 1,
                                        }}
                                    >
                                        <Person
                                            sx={{
                                                fontSize: 14,
                                            }}
                                        />{" "}
                                        Customers
                                    </Typography>
                                    <Chip
                                        color="secondary"
                                        label={size(subscribedCustomers)}
                                        size="small"
                                        sx={{ fontSize: 10 }}
                                    />
                                </Box>
                            }
                            {...a11yProps(0)}
                        />
                        <Tab
                            label={
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: 10,
                                            mr: 1,
                                        }}
                                    >
                                        <Person
                                            sx={{
                                                fontSize: 14,
                                            }}
                                        />{" "}
                                        Vendors
                                    </Typography>
                                    <Chip
                                        label={size(subscribedVendors)}
                                        sx={{
                                            bgcolor: colors.green,
                                            fontSize: 10,
                                        }}
                                        size="small"
                                    />
                                </Box>
                            }
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </AppBar>
                <Box sx={{ mt: 8 }}>
                    {/* ################ Customer ################ */}
                    <TabPanel value={value} index={0}>
                        {isEmpty(subscribedCustomers) ? (
                            <></>
                        ) : (
                            subscribedCustomers.map((customer, index) => (
                                <CustomerWidget5
                                    key={index}
                                    customer={customer}
                                    type={"Customer"}
                                    total={size(customer.customer_orders)}
                                />
                            ))
                        )}
                    </TabPanel>
                    {/* ################ Vendors ################ */}
                    <TabPanel value={value} index={1}>
                        {isEmpty(subscribedVendors) ? (
                            <></>
                        ) : (
                            subscribedVendors.map((customer, index) => (
                                <CustomerWidget5
                                    key={index}
                                    customer={customer}
                                    type={"Vendor"}
                                    total={size(customer.customer_orders)}
                                />
                            ))
                        )}
                    </TabPanel>
                </Box>
            </Box>
        </>
    );
};

export default NotificationsSidebar;
