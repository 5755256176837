import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';

// => initialstate
const initialState = {
    subCategories: [],
};

//! ############ SLICE ##############
const subCategorySlice = createSlice({
    name: 'subCategorySlice',
    initialState,
    reducers: {
        setSubCategories: (state, action) => {
            let categories = action.payload.data;
            state.subCategories = categories;
        },
        setSubUpdatedCategories: (state, action) => {
            let categories = action.payload;
            state.subCategories = categories;
        },
        addSubCategory: (state, action) => {
            let category = action.payload;
            state.subCategories = [category, ...state.subCategories];
        },
        removeSubCategory: (state, action) => {
            let category = action.payload;
            let category_id = category._id;
            let newSubCategories = filter(
                state.subCategories,
                ({ _id }) => _id !== category_id,
            );
            state.subCategories = newSubCategories;
        },
    },
});

export const {
    setSubCategories,
    addSubCategory,
    removeSubCategory,
    setSubUpdatedCategories,
} = subCategorySlice.actions;
export default subCategorySlice.reducer;
