import React from "react";
import { colors } from "../../assets/utils/colors";
import { Box, Button, Typography } from "@mui/material";

const DashBottomNavBtn = ({ data }) => {
    return (
        <Button fullWidth onClick={data.handler}>
            <Box
                sx={{
                    width: "100%",
                    height: 60,
                    borderRadius: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: colors.primary,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                    transition: `.3s ease-in-out`,
                    border: `3px double ${colors.bgColor1}`,
                    ".icon": {
                        mr: 2,
                    },
                    ":hover": {
                        transition: `.3s ease-in-out`,
                        transform: `scale(1.05)`,
                        border: `3px double ${colors.primary}`,
                    },
                }}
            >
                {data.icon}
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                    }}
                >
                    {data.name}
                </Typography>
            </Box>
        </Button>
    );
};

export default DashBottomNavBtn;
