import React, { useEffect, useState } from 'react';
import { Grid, styled } from '@mui/material';
import MainOrderTable from '../../../components/tables/MainOrderTable';
import PageLayout from '../../layouts/PageLayout';
import OrdersSidebar from '../../../components/sidebars/OrdersSidebar';
import { useSelector } from 'react-redux';
import { ordersSelector } from '../../../state/features/selectors';
import CustomCard2 from '../../../components/cards/CustomCard2';
import { filter, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import NoContent from '../../../components/NoContent';

// ################ MUI Styled ###############
const OrdersContainer = styled(Grid)(({ theme }) => ({}));

// !################# MAIN FUNC ###################
const OrdersByStatus = () => {
    // ############### Redux state #################
    const { orders } = useSelector(ordersSelector);

    // => component state
    const [filteredOrders, setFilteredOrders] = useState([]);

    // => GET filtered orders
    const { status } = useParams();
    const getFilteredOrders = () => {
        let filteredOrders = '';
        if (status === 'new') {
            filteredOrders = filter(orders, { order_status: 1 });
        } else if (status === 'processing') {
            filteredOrders = filter(orders, { order_status: 2 });
        } else if (status === 'shipped') {
            filteredOrders = filter(orders, { order_status: 3 });
        } else if (status === 'delivered') {
            filteredOrders = filter(orders, { order_status: 4 });
        }
        return filteredOrders;
    };
    const newOrders = getFilteredOrders();
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setFilteredOrders(newOrders);
        }
        return () => {
            isSubscribed = false;
        };
    }, [newOrders]);

    return (
        <>
            <PageLayout
                pageHead={`Agiza africa ${status} orders`}
                sidebar={<OrdersSidebar />}
            >
                <CustomCard2 title={`All ${status} orders`} height={600}>
                    <OrdersContainer container>
                        {isEmpty(filteredOrders) ? (
                            <>
                                <NoContent message={`No ${status} orders`} />
                            </>
                        ) : (
                            <>
                                <Grid item sm={12} xs={12}>
                                    <MainOrderTable
                                        orders={filteredOrders}
                                        height={548}
                                    />
                                </Grid>
                            </>
                        )}
                    </OrdersContainer>
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default OrdersByStatus;
