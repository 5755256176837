import React from 'react';
import {
    Avatar,
    Box,
    FormControlLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Radio,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/colors';
import { size } from 'lodash';

const DeliveryGuyWidget2 = ({ deliveryGuy }) => {
    return (
        <>
            <Box
                sx={{
                    'borderRadius': 2.5,
                    'overflow': 'hidden',
                    '& .card-top': {
                        transition: '.3s all ease-in-out',
                    },
                    'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                }}
            >
                <Box className="card-top">
                    <List
                        sx={{
                            width: '100%',
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                            p: 0,
                        }}
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar
                                    src={''}
                                    sx={{
                                        height: 60,
                                        width: 60,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                        p: 0.5,
                                        mr: 1,
                                    }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                disableTypography
                                secondaryTypographyProps={{
                                    fontSize: 11,
                                }}
                                primary={
                                    <Typography
                                        noWrap
                                        sx={{
                                            fontSize: 13,
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: colors.primary,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {`${deliveryGuy.first_name} ${deliveryGuy.last_name}`}
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={{
                                            fontSize: 10,
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: colors.primary,
                                            mt: 0.5,
                                        }}
                                    >
                                        {deliveryGuy.registration_number}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            borderRadius: 2.5,
                            textAlign: 'center',
                            border: `1px solid ${colors.secondary}`,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.yellow})`,
                        }}
                    >
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                            {size(deliveryGuy.running_orders)}
                        </Typography>
                        <Typography sx={{ fontSize: 10 }}>
                            Running orders
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            textAlign: 'center',
                            border: `1px solid ${colors.green}`,
                            borderRadius: 2.5,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.green})`,
                        }}
                    >
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                            {size(deliveryGuy.order_completed)}
                        </Typography>
                        <Typography sx={{ fontSize: 10 }}>
                            Completed orders
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                    }}
                >
                    <FormControlLabel
                        value={deliveryGuy._id}
                        control={<Radio size="medium" color="primary" />}
                        sx={{ ml: 2 }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default DeliveryGuyWidget2;
