import React, { useEffect } from "react";
import { LocationOn, LockClock } from "@mui/icons-material";
import { Box, Chip, Grid, styled, Typography } from "@mui/material";
import { colors } from "../../../assets/utils/colors";
import OrdersSidebar from "../../../components/sidebars/OrdersSidebar";
import PageLayout from "../../layouts/PageLayout";
import { useParams } from "react-router-dom";
import { useGetOrderByIdQuery } from "../../../api/orderAPI";
import moment from "moment";
import { currencyFomater } from "../../../helpers/currencyFomater";
import { isEmpty, size } from "lodash";
import CustomFlexCard from "../../../components/cards/CustomFlexCard";
import InnerContentLoader from "../../../components/InnerContentLoader";
import InnerDataFetchingError from "../../../components/InnerDataFetchingError";
import { getRTKResponseData } from "../../../helpers/RTKHelpers";
import OrderedProducts from "./orderComponents/OrderedProducts";
import ActionButtons from "./orderComponents/ActionButtons";
import UserWidget1 from "../../../components/widgets/UserWidget1";
import NoContent from "../../../components/NoContent";
import { setOrderDetails } from "../../../state/features/orders/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { ordersSelector } from "../../../state/features/selectors";
import OrderNotify from "./orderComponents/OrderNotify";
import ShippingCover from "./orderComponents/ShippingCover";
import OrderQrcode from "../../../components/qrCode/OrderQrcode";

// ############### MUI Styled ###################
const OrderContainer = styled(Box)(({ theme }) => ({
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
    borderRadius: 5,
    display: "flex",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    flexDirection: "column",
    "& .order-header": {
        height: 50,
        background: colors.bgColor1,
        overflow: "hidden",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 15,
        paddingRight: 20,
        "& .head-font": {
            fontWeight: "bold",
        },
    },
    "& .order-content": {
        flex: 1,
        overflow: "auto",
    },
}));

// !############## MAIN FUNC ################
const OrderDetails = () => {
    // ############# Component state #######
    const { orderDetails: order } = useSelector(ordersSelector);

    // ############# CONST ##############
    const { order_id } = useParams();
    const dispatch = useDispatch();

    // ###### Distructure orders data #########
    const { customer } = order;
    const { ordered_products: products } = order;
    const { total_amount: amount } = order;
    const { order_status: status } = order;
    const { shipping_address: shippingAddress } = order;
    const { order_number: orderID } = order;
    const { delivered_by: deliveredBy } = order;
    const { createdAt } = order;

    // ############# RTK ###############
    const {
        isLoading,
        isSuccess,
        isError,
        data: fetchedData,
    } = useGetOrderByIdQuery(order_id);

    // ############ UseEffect #############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(setOrderDetails(getRTKResponseData(fetchedData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, fetchedData, dispatch]);

    return (
        <>
            <PageLayout
                pageHead={"Order details"}
                sidebar={<OrdersSidebar />}
                bp
            >
                {isError ? (
                    <>
                        <InnerDataFetchingError
                            height={650}
                            content={"order "}
                        />
                    </>
                ) : (
                    <>
                        {isLoading ? (
                            <>
                                <CustomFlexCard>
                                    <InnerContentLoader height={"100%"} />
                                </CustomFlexCard>
                            </>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        flex: 1,
                                        position: "relative",
                                    }}
                                >
                                    <OrderQrcode orderNo={orderID} />
                                    <OrderContainer>
                                        {/* ######### Header ############ */}
                                        <Box className="order-header">
                                            <Typography
                                                className="head-font"
                                                sx={{ opacity: 0.7 }}
                                            >
                                                {orderID}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography
                                                    className="head-font"
                                                    sx={{
                                                        opacity: 0.7,
                                                        fontSize: 14,
                                                        fontWeight: "bold",
                                                        color: colors.primary,
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <LockClock />
                                                    {moment(createdAt).format(
                                                        "ll, h:mm a"
                                                    )}
                                                </Typography>
                                                <OrderNotify order={order} />
                                                {order.order_status === 3 && (
                                                    <ShippingCover
                                                        order={order}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                        {/* ########## Content ############ */}
                                        <Box className="order-content">
                                            <Grid
                                                container
                                                padding={2}
                                                spacing={2}
                                            >
                                                {/* ############ Ordered products ############ */}
                                                <Grid item sm={8}>
                                                    <OrderedProducts
                                                        products={products}
                                                    />
                                                </Grid>

                                                {/* ############ Ordered details ############ */}
                                                <Grid item sm={4} xs={12}>
                                                    <Box
                                                        sx={{
                                                            borderBottom: `2px solid ${colors.bgColor1}`,
                                                            pb: 1,
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontWeight:
                                                                    "bold",
                                                                opacity: 0.8,
                                                            }}
                                                        >
                                                            Order summary
                                                        </Typography>
                                                        {order.isPaid ? (
                                                            <Chip
                                                                label="Paid"
                                                                sx={{
                                                                    bgcolor:
                                                                        colors.green,
                                                                    border: `3px dotted ${colors.red}`,
                                                                    color: "000",
                                                                    px: 1,
                                                                }}
                                                            />
                                                        ) : (
                                                            <Chip
                                                                label="Unpaid"
                                                                sx={{
                                                                    bgcolor:
                                                                        colors.secondary,
                                                                    border: `3px dotted ${colors.red}`,
                                                                    color: "000",
                                                                    px: 1,
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            height: {
                                                                md: 500,
                                                                xs: "100%",
                                                            },
                                                            overflow: "auto",
                                                            mt: 2,
                                                            bgcolor:
                                                                colors.bgColor1,
                                                        }}
                                                    >
                                                        {/* ################### status #################### */}
                                                        <Box
                                                            sx={{
                                                                height: 40,
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                display: "flex",
                                                                borderRadius: 1,
                                                                bgcolor:
                                                                    status === 1
                                                                        ? "primary"
                                                                        : status ===
                                                                          2
                                                                        ? colors.blue
                                                                        : status ===
                                                                          3
                                                                        ? colors.secondary
                                                                        : status ===
                                                                          4
                                                                        ? colors.green
                                                                        : status ===
                                                                          5
                                                                        ? colors.red
                                                                        : "secondary",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color:
                                                                        status ===
                                                                        1
                                                                            ? "primary"
                                                                            : status ===
                                                                              2
                                                                            ? colors.bgColor3
                                                                            : status ===
                                                                              3
                                                                            ? ""
                                                                            : status ===
                                                                              4
                                                                            ? ""
                                                                            : status ===
                                                                              5
                                                                            ? colors.bgColor3
                                                                            : "secondary",
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                {status === 1
                                                                    ? "Placed"
                                                                    : status ===
                                                                      2
                                                                    ? "Accepted"
                                                                    : status ===
                                                                      3
                                                                    ? "Shipped"
                                                                    : status ===
                                                                      4
                                                                    ? "Delivered"
                                                                    : status ===
                                                                      5
                                                                    ? "Cancelled"
                                                                    : "ForShip"}
                                                            </Typography>
                                                        </Box>

                                                        {/* ################### shipping #################### */}
                                                        <Box
                                                            sx={{
                                                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                                                p: 2,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 14,
                                                                    fontWeight:
                                                                        "bold",
                                                                    color: colors.primary,
                                                                }}
                                                            >
                                                                Shipping address
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                <LocationOn
                                                                    sx={{
                                                                        fontSize: 14,
                                                                        color: colors.primary,
                                                                        mr: 0.5,
                                                                    }}
                                                                />
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 10,
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    {
                                                                        shippingAddress
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </Box>

                                                        {/* ################### customer #################### */}
                                                        <Box
                                                            sx={{
                                                                p: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontWeight:
                                                                        "bold",
                                                                    pb: 0.5,
                                                                    fontSize: 12,
                                                                }}
                                                            >
                                                                Customer
                                                            </Typography>
                                                            <UserWidget1
                                                                user={
                                                                    !isEmpty(
                                                                        customer
                                                                    ) &&
                                                                    customer
                                                                }
                                                                userType={
                                                                    "customer"
                                                                }
                                                            />
                                                        </Box>

                                                        {/* ################### Delivery guy #################### */}

                                                        <Box
                                                            sx={{
                                                                p: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontWeight:
                                                                        "bold",
                                                                    pb: 0.5,
                                                                    fontSize: 12,
                                                                }}
                                                            >
                                                                Delivery guy
                                                            </Typography>
                                                            {deliveredBy ? (
                                                                <>
                                                                    <UserWidget1
                                                                        user={
                                                                            !isEmpty(
                                                                                deliveredBy
                                                                            ) &&
                                                                            deliveredBy
                                                                        }
                                                                        userType={
                                                                            "delivery"
                                                                        }
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <NoContent
                                                                        height={
                                                                            30
                                                                        }
                                                                        message={
                                                                            "No delivery guy"
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </Box>

                                                        {/* ################### Total items and action links #################### */}
                                                        <Box
                                                            sx={{
                                                                px: 1.5,
                                                                display: "flex",
                                                                flexDirection:
                                                                    "column",
                                                                justifyContent:
                                                                    "space-around",
                                                                mt: 2,
                                                            }}
                                                        >
                                                            {/* ################### Amount #################### */}
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "space-between",
                                                                    borderBottom: `2px solid ${colors.primary}`,
                                                                    py: 1,
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    Total items
                                                                </Typography>
                                                                <Typography>
                                                                    {size(
                                                                        products
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "space-between",
                                                                    borderBottom: `2px solid ${colors.primary}`,
                                                                    py: 1,
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    Total Price
                                                                </Typography>
                                                                <Typography
                                                                    noWrap
                                                                    className="head-font"
                                                                    sx={{
                                                                        fontSize: 14,
                                                                        fontWeight:
                                                                            "bold",
                                                                        color: colors.primary,
                                                                    }}
                                                                >
                                                                    {currencyFomater(
                                                                        !isEmpty(
                                                                            amount
                                                                        ) &&
                                                                            amount
                                                                    )}
                                                                    <sup
                                                                        style={{
                                                                            fontSize: 9,
                                                                            color: colors.primary,
                                                                        }}
                                                                    >
                                                                        TZS
                                                                    </sup>
                                                                </Typography>
                                                            </Box>

                                                            {/* ################### Action buttons #################### */}
                                                            <Box>
                                                                <ActionButtons
                                                                    status={
                                                                        status
                                                                    }
                                                                    orderID={
                                                                        order_id
                                                                    }
                                                                    orderNumber={
                                                                        orderID
                                                                    }
                                                                    order={
                                                                        order
                                                                    }
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </OrderContainer>
                                </Box>
                            </>
                        )}
                    </>
                )}
            </PageLayout>
        </>
    );
};

export default OrderDetails;
