import React from 'react';
import { Message, Phone, RemoveRedEye } from '@mui/icons-material';
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/colors';
import { size } from 'lodash';

const DeliveryGuyWidget = ({ deliveryGuy }) => {
    return (
        <>
            <Box
                sx={{
                    'borderRadius': 2.5,
                    'overflow': 'hidden',
                    '& .card-top': {
                        transition: '.3s all ease-in-out',
                    },
                    'border': `1px solid ${colors.bgColor1}`,
                    'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                }}
            >
                <Box className="card-top">
                    <List
                        sx={{
                            width: '100%',
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                            p: 0,
                        }}
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar
                                    src={''}
                                    sx={{
                                        height: 60,
                                        width: 60,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                        p: 0.5,
                                        mr: 1,
                                    }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                disableTypography
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                }}
                                secondaryTypographyProps={{
                                    fontSize: 11,
                                }}
                                primary={
                                    <Typography
                                        sx={{
                                            fontSize: 15,
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: colors.primary,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {`${deliveryGuy.first_name} ${deliveryGuy.last_name}`}
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: colors.primary,
                                            mt: 0.5,
                                        }}
                                    >
                                        {deliveryGuy.registration_number}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                </Box>
                <Box
                    sx={{
                        p: 1.5,
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        borderRadius: 2.5,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                    }}
                >
                    <Tooltip title={deliveryGuy.phone_number} arrow>
                        <a href={`tel:${deliveryGuy.phone_number}`}>
                            <IconButton
                                sx={{
                                    ml: 1,
                                    p: 0.8,
                                    color: colors.primary,
                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                }}
                                size="small"
                            >
                                <Phone
                                    sx={{
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        </a>
                    </Tooltip>
                    <Tooltip title={deliveryGuy.email} arrow>
                        <IconButton
                            sx={{
                                ml: 1,
                                p: 0.8,
                                color: colors.primary,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                            }}
                            size="small"
                        >
                            <Message
                                sx={{
                                    fontSize: 16,
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="More details" arrow>
                        <Link to={`/delivery-guys/details/${deliveryGuy._id}`}>
                            <IconButton
                                sx={{
                                    ml: 1,
                                    p: 0.8,
                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                    color: colors.primary,
                                }}
                                size="small"
                            >
                                <RemoveRedEye
                                    sx={{
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            borderRadius: 2.5,
                            textAlign: 'center',
                            border: `1px solid ${colors.secondary}`,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.yellow})`,
                        }}
                    >
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                            {size(deliveryGuy.running_orders)}
                        </Typography>
                        <Typography sx={{ fontSize: 10 }}>
                            Running orders
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                            textAlign: 'center',
                            border: `1px solid ${colors.green}`,
                            borderRadius: 2.5,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.green})`,
                        }}
                    >
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                            {size(deliveryGuy.order_completed)}
                        </Typography>
                        <Typography sx={{ fontSize: 10 }}>
                            Completed orders
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default DeliveryGuyWidget;
