import React, { useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Grid } from "@mui/material";
import { ordersSelector } from "../../../state/features/selectors";
import { useSelector } from "react-redux";
import DashRevenueCard from "../../../components/dash/DashRevenueCard";
import { colors } from "../../../assets/utils/colors";
import { filter, size } from "lodash";
import CustomTabs from "../../../components/tabs/CustomTabs";
import {
    DoneAll,
    PendingActions,
    RemoveFromQueueRounded,
} from "@mui/icons-material";
import CustomFlexCard from "../../../components/cards/CustomFlexCard";
import MainOrderTable from "../../../components/tables/MainOrderTable";
import FinanceSidebar from "../../../components/sidebars/FinanceSidebar";
import { getCommission, getRevenue } from "../../../helpers/financeHelper";
import FinanceOrderFilter from "../../../components/filters/FinanceOrderFilter";
import moment from "moment";

const Finance = () => {
    // ############# Comp State ################
    const [allOrders, setAllOrders] = useState([]);
    const [byDateFrom, setByDateFrom] = useState("");
    const [byDateTo, setByDateTo] = useState("");

    // ############ Redux state ################
    const { orders } = useSelector(ordersSelector);
    const revenue = getRevenue(allOrders);
    const commission = getCommission(allOrders);

    // ############### Filters ###########################
    // => Filter by date
    const filterByDateFrom = (date) => {
        setByDateFrom(date);
    };
    // => Filter by date
    const filterByDateTo = (date) => {
        setByDateTo(date);
    };
    // => General Filter
    const filterOrders = (dateFrom, dateTo) => {
        let filteredOrders = [];
        var startDate = moment(dateFrom).format("YYYY-MM-DD");
        var endDate = moment(dateTo).format("YYYY-MM-DD");
        if (dateFrom !== "" || dateTo !== "") {
            filteredOrders = filter(
                orders,
                ({ createdAt }) =>
                    moment(createdAt).format("YYYY-MM-DD") >= startDate &&
                    moment(createdAt).format("YYYY-MM-DD") <= endDate
            );
        }
        if (dateFrom !== "" || dateTo !== "") {
            return setAllOrders(filteredOrders);
        } else {
            return setAllOrders(orders);
        }
    };

    // => Reset filter
    const resetFilter = () => {
        setByDateFrom("");
        setByDateTo("");
    };

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            filterOrders(byDateFrom, byDateTo);
        }
        return () => {
            isSubscribed = false;
        };
    }, [byDateFrom, byDateTo]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setAllOrders(orders);
        }

        return () => {
            isSubscribed = false;
        };
    }, [orders]);

    // ##################### Tabs ###############
    const tabs = [
        {
            id: 1,
            title: "Completed orders",
            icon: <DoneAll />,
            chipColor: "success",
            total: size(
                filter(allOrders, {
                    order_status: 4,
                })
            ),
            tabsContent: (
                <MainOrderTable
                    height={345}
                    orders={filter(allOrders, {
                        order_status: 4,
                    })}
                />
            ),
        },
        {
            id: 2,
            title: "Pending orders",
            icon: <PendingActions />,
            chipColor: "warning",
            total: size(
                filter(
                    allOrders,
                    ({ order_status, isPaid }) =>
                        order_status === 2 ||
                        order_status === 3 ||
                        (order_status === 1 && isPaid === true)
                )
            ),
            tabsContent: (
                <MainOrderTable
                    height={345}
                    orders={filter(
                        allOrders,
                        ({ order_status, isPaid }) =>
                            order_status === 2 ||
                            order_status === 3 ||
                            (order_status === 1 && isPaid === true)
                    )}
                />
            ),
        },
        {
            id: 3,
            title: "Refunded orders",
            icon: <RemoveFromQueueRounded />,
            chipColor: "error",
            total: size(
                filter(
                    allOrders,
                    ({ order_status, isPaid }) =>
                        order_status === 6 && isPaid === true
                )
            ),
            tabsContent: (
                <MainOrderTable
                    height={345}
                    orders={filter(
                        allOrders,
                        ({ order_status, isPaid }) =>
                            order_status === 6 && isPaid === true
                    )}
                />
            ),
        },
    ];

    return (
        <>
            <PageLayout
                pageHead={"Agiza africa finance managements"}
                sidebar={<FinanceSidebar orders={allOrders} />}
            >
                <Grid container spacing={3}>
                    <Grid item sm={4} xs={4}>
                        <DashRevenueCard
                            bdColor={colors.yellow}
                            title={"Total revenue"}
                            amount={revenue.collected}
                        />
                    </Grid>
                    <Grid item sm={4} xs={4}>
                        <DashRevenueCard
                            bdColor={colors.green}
                            title={"Total commission"}
                            amount={commission.collected}
                        />
                    </Grid>
                    <Grid item sm={4} xs={4}>
                        <DashRevenueCard
                            bdColor={colors.red}
                            title={"Total Re-funded"}
                            amount={revenue.refunded}
                        />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        flex: 1,
                        position: "relative",
                        mt: 2,
                        borderRadius: 3,
                        overflow: "hidden",
                    }}
                >
                    <CustomFlexCard
                        title={`Revenue generation orders`}
                        action={
                            <FinanceOrderFilter
                                byDateFrom={byDateFrom}
                                byDateTo={byDateTo}
                                resetFilter={resetFilter}
                                filterByDateFrom={filterByDateFrom}
                                filterByDateTo={filterByDateTo}
                            />
                        }
                    >
                        <CustomTabs tabs={tabs} />
                    </CustomFlexCard>
                </Box>
            </PageLayout>
        </>
    );
};

export default Finance;
