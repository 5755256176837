import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import CustomCard2 from '../../../components/cards/CustomCard2';
import CustomTabs from '../../../components/tabs/CustomTabs';
import { Category, LocationOn, PinDropOutlined } from '@mui/icons-material';
import { colors } from '../../../assets/utils/colors';
import { isEmpty, size } from 'lodash';
import NoContent from '../../../components/NoContent';
import StoreWidget from '../../../components/widgets/StoreWidget';
import CountBadge from '../../../components/CountBadge';

const CustomerManagement = ({ customer, stores }) => {
    // ############### Comp state ####################
    const addresses = customer.address;
    const categories = customer.favorite_categories;

    const tabs = [
        {
            id: 1,
            title: 'Favorite categories',
            icon: <Category />,
            chipColor: 'info',
            total: size(categories),
            tabsContent: (
                <>
                    <Grid container padding={2} spacing={2}>
                        {categories?.map((category, index) => (
                            <Grid item sm={4} key={index}>
                                <Box
                                    sx={{
                                        p: 1,
                                        height: 30,
                                        borderRadius: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                    }}
                                >
                                    <Category
                                        sx={{ fontSize: 14, mr: 1 }}
                                        color={'info'}
                                    />
                                    <Typography sx={{ fontSize: 12 }} noWrap>
                                        {category.category_name}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </>
            ),
        },
        {
            id: 2,
            title: 'Addresses',
            icon: <PinDropOutlined />,
            chipColor: 'info',
            total: size(addresses),
            tabsContent: (
                <>
                    <Grid container padding={2} spacing={2}>
                        {addresses?.map((address, index) => (
                            <Grid item sm={6} key={index}>
                                <Box
                                    sx={{
                                        p: 1,
                                        height: 30,
                                        borderRadius: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                    }}
                                >
                                    <LocationOn
                                        sx={{ fontSize: 12 }}
                                        color={
                                            address.isPrimary
                                                ? 'warning'
                                                : 'info'
                                        }
                                    />
                                    <Typography sx={{ fontSize: 12 }} noWrap>
                                        {address.location}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </>
            ),
        },
    ];
    return (
        <>
            <Box>
                <Grid container spacing={2} padding={2}>
                    <Grid item sm={6}>
                        <CustomCard2
                            title={'Favorite stores'}
                            height={374}
                            action={
                                <CountBadge
                                    total={size(stores)}
                                    item={'Stores'}
                                />
                            }
                        >
                            <Grid container spacing={2} padding={2}>
                                {isEmpty(stores) ? (
                                    <>
                                        <Grid item sm={12}>
                                            <NoContent
                                                message={'No favorite store'}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        {stores?.map((store) => (
                                            <Grid item sm={12} key={store._id}>
                                                <StoreWidget store={store} />
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </Grid>
                        </CustomCard2>
                    </Grid>
                    <Grid item sm={6}>
                        <CustomCard2 height={200}>
                            <CustomTabs tabs={tabs} />
                        </CustomCard2>
                        <Box
                            sx={{
                                height: 209,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="warning"
                                className="app-btn"
                            >
                                Suspend customer
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                className="app-btn"
                            >
                                Delete customer
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default CustomerManagement;
