import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colors } from '../../../assets/utils/colors';
import CustomCard from '../../../components/cards/CustomCard';
import PageLayout from '../../layouts/PageLayout';
import { useParams } from 'react-router-dom';
import ContentLoader from '../../../components/ContentLoader';
import { isEmpty, size } from 'lodash';
import CustomCard2 from '../../../components/cards/CustomCard2';
import { getRTKResponseData } from '../../../helpers/RTKHelpers';
import DeliveryGuysSidebar from '../../../components/sidebars/DeliveryGuysSidebar';
import { useGetSingleDeliveryGuyQuery } from '../../../api/deliveryGuyAPI';
import DeliveryGuyWidget3 from '../../../components/widgets/DeliveryGuyWidget3';
import CustomTabs from '../../../components/tabs/CustomTabs';
import { DoneAll, PendingActions } from '@mui/icons-material';
import MainOrderTable from '../../../components/tables/MainOrderTable';

const DeliveryGuyDetails = () => {
    // ############### CONST ########################
    const { delivery_guy_id } = useParams();
    const [deliveryGuy, setDeliveryGuy] = useState({});
    const [runningOrders, setRunningOrders] = useState([]);
    const [completedOrders, setCompletedOrders] = useState([]);

    // ############### RTK ########################
    const {
        isLoading,
        isSuccess,
        data: deliveryGuyDetails,
    } = useGetSingleDeliveryGuyQuery(delivery_guy_id);

    // ############### UseEffect ########################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                let deliveryGuy = getRTKResponseData(deliveryGuyDetails);
                setDeliveryGuy(deliveryGuy);
                setRunningOrders(deliveryGuy.running_orders);
                setCompletedOrders(deliveryGuy.order_completed);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, deliveryGuyDetails]);

    // #################################################
    const tabs = [
        {
            id: 1,
            title: 'Running orders',
            icon: <PendingActions />,
            chipColor: 'warning',
            total: size(runningOrders),
            tabsContent: <MainOrderTable height={422} orders={runningOrders} />,
        },
        {
            id: 2,
            title: 'Completed orders',
            icon: <DoneAll />,
            chipColor: 'success',
            total: size(completedOrders),
            tabsContent: (
                <MainOrderTable height={422} orders={completedOrders} />
            ),
        },
    ];
    return (
        <>
            <PageLayout
                pageHead={
                    !isEmpty(deliveryGuy) &&
                    `Delivery guy details ( ${deliveryGuy.first_name} ${deliveryGuy.last_name} )`
                }
                sidebar={<DeliveryGuysSidebar />}
            >
                {isLoading ? (
                    <>
                        <CustomCard height={710}>
                            <ContentLoader />
                        </CustomCard>
                    </>
                ) : (
                    <>
                        <CustomCard2
                            title={`${deliveryGuy.first_name} ${deliveryGuy.last_name}`}
                            height={600}
                            action={
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        color: colors.primary,
                                        fontWeight: 'bold',
                                        bgcolor: colors.bgColor3,
                                        p: 1,
                                        borderRadius: 2,
                                    }}
                                >
                                    {deliveryGuy.registration_number}
                                </Typography>
                            }
                        >
                            <Grid container>
                                <Grid item sm={12}>
                                    <DeliveryGuyWidget3
                                        deliveryGuy={deliveryGuy}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item sm={12}>
                                    <CustomTabs tabs={tabs} />
                                </Grid>
                            </Grid>
                        </CustomCard2>
                    </>
                )}
            </PageLayout>
        </>
    );
};

export default DeliveryGuyDetails;
