import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import NoContent from '../NoContent';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        'id': `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

// !################ MAIN FUNC ##################
const CustomTabs = ({ tabs, tabsContent }) => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{}}>
            <AppBar
                position="sticky"
                sx={{ position: 'sticky', top: 0, zIndex: 999 }}
                elevation={2}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                >
                    {tabs?.map((tab, index) => (
                        <Tab
                            key={index}
                            label={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: 12,
                                            mr: 2,
                                        }}
                                    >
                                        {tab.icon}
                                        <span>&nbsp;&nbsp;&nbsp;</span>
                                        {tab.title}
                                    </Typography>
                                    {tab.chipColor && (
                                        <Chip
                                            color={tab.chipColor}
                                            label={tab.total}
                                            size="small"
                                        />
                                    )}
                                </Box>
                            }
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </AppBar>
            {tabs?.map((tab, index) => (
                <TabPanel
                    key={index}
                    value={value}
                    index={index}
                    dir={theme.direction}
                >
                    {tab.total === 0 ? (
                        <>
                            <NoContent message={`No ${tab.title}`} height={200} />
                        </>
                    ) : (
                        tab.tabsContent
                    )}
                </TabPanel>
            ))}
        </Box>
    );
};

export default CustomTabs;
