import { Store } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Chip,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';
import { Link } from 'react-router-dom';
import { currencyFomater } from '../../helpers/currencyFomater';
import { isEmpty } from 'lodash';

const OrdersCard = ({ order }) => {
    const { customer, total_amount: amount, store_id: store } = order;
    return (
        <>
            <List
                sx={{
                    bgcolor: colors.bgColor3,
                    p: 0,
                    boxShadow: '-1px 1px 2px 2px rgba(125, 98, 212, .1)',
                }}
            >
                <ListItem sx={{ pl: 0.4, py: 0.4 }}>
                    <ListItemAvatar>
                        <Avatar
                            alt="DP"
                            src={customer.profile_picture}
                            sx={{
                                'padding': 0.2,
                                '& img': {
                                    borderRadius: 50,
                                    opacity: 0.8,
                                },
                                'height': 46,
                                'width': 46,
                                'border': `1px solid ${colors.primary}`,
                                'background': `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                'p': 0.5,
                                'mr': 0.5,
                            }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        disableTypography
                        primary={
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    noWrap
                                    className="head-font"
                                    sx={{
                                        fontSize: 11,
                                        mr: 0.5,
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                        color: colors.primary,
                                    }}
                                >
                                    {`${customer.first_name} ${customer.last_name}`}
                                </Typography>
                                <Link to={`/orders/details/${order._id}`}>
                                    <Chip
                                        label={
                                            order.order_status === 1
                                                ? 'Placed'
                                                : order.order_status === 2
                                                ? 'Accepted'
                                                : order.order_status === 3
                                                ? 'Shipped'
                                                : order.order_status === 4
                                                ? 'Delivered'
                                                : order.order_status === 5
                                                ? 'Cancelled'
                                                : 'ForShip'
                                        }
                                        color={
                                            order.order_status === 1
                                                ? 'primary'
                                                : order.order_status === 2
                                                ? 'info'
                                                : order.order_status === 3
                                                ? 'warning'
                                                : order.order_status === 4
                                                ? 'success'
                                                : order.order_status === 5
                                                ? 'error'
                                                : 'secondary'
                                        }
                                        size="small"
                                        sx={{
                                            fontSize: 9,
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            height: 18,
                                        }}
                                    />
                                </Link>
                            </Box>
                        }
                        secondary={
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {!isEmpty(store) ? (
                                    <>
                                        <Link
                                            to={`/stores/details/${store._id}`}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Store
                                                    sx={{
                                                        fontSize: 12,
                                                        color: colors.primary,
                                                        mr: 0.5,
                                                    }}
                                                />
                                                <Typography
                                                    noWrap
                                                    sx={{
                                                        fontSize: 9,
                                                        color: colors.primary,
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {store.title}
                                                </Typography>
                                            </Box>
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Store
                                                sx={{
                                                    fontSize: 12,
                                                    color: colors.primary,
                                                    mr: 0.5,
                                                }}
                                            />
                                            <Typography
                                                noWrap
                                                sx={{
                                                    fontSize: 9,
                                                    color: 'red',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Store not found
                                            </Typography>
                                        </Box>
                                    </>
                                )}

                                <Box>
                                    <Link to={`/orders/details/${order._id}`}>
                                        <Typography
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: 'bold',
                                                color: colors.red,
                                            }}
                                        >
                                            {currencyFomater(amount)}
                                            <sup
                                                style={{
                                                    color: colors.primary,
                                                }}
                                            >
                                                TZS
                                            </sup>
                                        </Typography>
                                    </Link>
                                </Box>
                            </Box>
                        }
                    />
                </ListItem>
            </List>
        </>
    );
};

export default OrdersCard;
