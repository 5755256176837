import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { colors } from "../../../assets/utils/colors";
import StoreWidgetBottom from "../../../components/widgets/StoreWidgetBottom";
import ProductsSidebar from "../../../components/sidebars/ProductsSidebar";
import PageLayout from "../../layouts/PageLayout";
import { useParams } from "react-router-dom";
import { useGetProductByIdQuery } from "../../../api/productAPI";
import InnerDataFetchingError from "../../../components/InnerDataFetchingError";
import InnerContentLoader from "../../../components/InnerContentLoader";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { productsSelector } from "../../../state/features/selectors";
import AvailableSize from "./productComponents/AvailableSize";
import AvailableColor from "./productComponents/AvailableColor";
import ProductPrice from "./productComponents/ProductPrice";
import ProductImages from "./productComponents/ProductImages";
import ProductStatus from "./productComponents/ProductStatus";
import ApprovalActiveStatus from "./productComponents/ApprovalActiveStatus";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import ApproveProduct from "./productComponents/ApproveProduct";
import DenyProdut from "./productComponents/DenyProdut";
import { setProductDetails } from "../../../state/features/products/productSlice";
import SuspendProduct from "./productComponents/SuspendProduct";
import ActivateProduct from "./productComponents/ActivateProduct";
import { getRTKResponseData } from "../../../helpers/RTKHelpers";
import DeleteProduct from "./productComponents/DeleteProduct";
import FeaturedBestSeller from "./productComponents/FeaturedBestSeller";
import ProductActionButtons from "./productComponents/ProductActionButtons";
import AssignSubCategory from "./productComponents/AssignSubCategory";
import ChangeProductStore from "./productComponents/ChangeProductStore";
import CustomFlexCard from "../../../components/cards/CustomFlexCard";

const ProductDetails = () => {
    // ############# Component state #######
    const [actionType, setActionType] = useState("");
    const [confimationDialogOpen, setConfimationDialogOpen] = useState(false);

    // => handle confimation dialog open
    const handleConfimationDialogOpen = () => {
        setConfimationDialogOpen(true);
    };

    // => handle confimation dialog close
    const handleConfimationDialogClose = () => {
        setConfimationDialogOpen(false);
    };

    // => handle product approve
    const handleProductApprove = (type) => {
        setActionType(type);
        handleConfimationDialogOpen();
    };

    // => handle product deny
    const handleProductDeny = (type) => {
        setActionType(type);
        handleConfimationDialogOpen();
    };

    // ############# CONST ##############
    const { product_id } = useParams();
    const dispatch = useDispatch();

    // ############### Redux state ########################
    const { products, productDetails: product } = useSelector(productsSelector);

    // ###### Distructure product data #########
    const { store_id: store } = product;
    const { available_colors } = product;
    const { available_sizes } = product;
    const { more_images: moreImages } = product;
    const { banner_image: bannerImage } = product;

    // ############# RTK ###############
    const {
        isLoading,
        isSuccess,
        isError,
        data: productDetails,
        refetch,
    } = useGetProductByIdQuery(product_id);

    // ############ UseEffect #############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(setProductDetails(getRTKResponseData(productDetails)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, productDetails, dispatch]);

    // ######### FUNC'S #################
    const handleDataRefetch = () => {
        refetch();
    };

    return (
        <>
            {/* ############# Confimation dialogy ################ */}
            <ConfirmationDialog
                noDropClose
                openDialog={confimationDialogOpen}
                handleDialogClose={handleConfimationDialogClose}
            >
                {actionType === "approve" ? (
                    <ApproveProduct product={product} />
                ) : actionType === "deny" ? (
                    <DenyProdut product={product} />
                ) : actionType === "suspend" ? (
                    <SuspendProduct product={product} />
                ) : actionType === "delete" ? (
                    <DeleteProduct product={product} />
                ) : actionType === "store" ? (
                    <ChangeProductStore
                        product={product}
                        store={!isEmpty(store) && store}
                    />
                ) : actionType === "category" ? (
                    <AssignSubCategory
                        product={product}
                        store={!isEmpty(store) && store}
                    />
                ) : (
                    <ActivateProduct product={product} />
                )}
            </ConfirmationDialog>

            <PageLayout
                pageHead={!isEmpty(product.title) && `${product.title} details`}
                sidebar={<ProductsSidebar products={products} />}
            >
                {isError ? (
                    <>
                        <InnerDataFetchingError
                            height={650}
                            content={"product "}
                            handleDataRefetch={handleDataRefetch}
                        />
                    </>
                ) : (
                    <>
                        {isLoading ? (
                            <>
                                <CustomFlexCard height={700}>
                                    <InnerContentLoader height={"100%"} />
                                </CustomFlexCard>
                            </>
                        ) : (
                            <>
                                {!isEmpty(product) && (
                                    <Box
                                        sx={{
                                            flex: 1,
                                            position: "relative",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                overflowY: "auto",
                                            }}
                                        >
                                            <Grid container spacing={1} paddingRight={1}>
                                                {/* ############## PRODUCT STATUS AND IMAGES ############## */}
                                                <Grid item sm={7.5}>
                                                    <Box>
                                                        <ProductStatus
                                                            product={product}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <ProductImages
                                                            bannerImage={
                                                                bannerImage
                                                            }
                                                            moreImages={
                                                                moreImages
                                                            }
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 15,
                                                                fontWeight:
                                                                    "bold",
                                                                color: colors.primary,
                                                                mt: 2,
                                                                position:
                                                                    "relative",
                                                                "&::before": {
                                                                    content:
                                                                        "''",
                                                                    height: 4,
                                                                    width: 100,
                                                                    background:
                                                                        colors.bgColor2,
                                                                    position:
                                                                        "absolute",
                                                                    bottom: -6,
                                                                    borderRadius: 5,
                                                                },
                                                            }}
                                                        >
                                                            Product description
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                p: 2,
                                                                mt: 0.7,
                                                                borderBottom: `4px solid ${colors.bgColor2}`,
                                                                borderLeft: `4px solid ${colors.bgColor2}`,
                                                                borderRight: `4px solid ${colors.bgColor2}`,
                                                                borderBottomLeftRadius: 15,
                                                                borderBottomRightRadius: 10,
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            {
                                                                product.description
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={4.5}>
                                                    <Box>
                                                        <StoreWidgetBottom
                                                            store={
                                                                !isEmpty(
                                                                    store
                                                                ) && store
                                                            }
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <ApprovalActiveStatus
                                                            product={product}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <FeaturedBestSeller
                                                            product={product}
                                                        />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                                                            px: 1,
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            justifyContent:
                                                                "space-around",
                                                        }}
                                                    >
                                                        {/* ############# PRICE ############### */}
                                                        <ProductPrice
                                                            product={product}
                                                        />

                                                        {/* ############# AVAILABLE SIZE & COLORS ############### */}
                                                        <Box>
                                                            {!isEmpty(
                                                                available_sizes
                                                            ) && (
                                                                <AvailableSize
                                                                    product={
                                                                        product
                                                                    }
                                                                    available_sizes={
                                                                        available_sizes
                                                                    }
                                                                />
                                                            )}
                                                            {!isEmpty(
                                                                available_colors
                                                            ) && (
                                                                <AvailableColor
                                                                    available_colors={
                                                                        available_colors
                                                                    }
                                                                />
                                                            )}
                                                        </Box>

                                                        {/* ############# ACTION BUTTONS ############### */}
                                                        <ProductActionButtons
                                                            product={product}
                                                            handleProductApprove={
                                                                handleProductApprove
                                                            }
                                                            handleProductDeny={
                                                                handleProductDeny
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                )}
                            </>
                        )}
                    </>
                )}
            </PageLayout>
        </>
    );
};

export default ProductDetails;
