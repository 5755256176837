// => GET all categories
const GET_ALL_CATEGORIES = '/sub-categories';

// => CREATE category
const CREATE_CATEGORY = '/sub-categories';

// => UPDATE category
const UPDATE_CATEGORY = '/sub-categories/update/';

// => ACTIVATE category
const ACTIVATE_CATEGORY = '/sub-categories/activation-status/';

// => DELETE category
const DELETE_CATEGORY = '/sub-categories/';

export const subCategoriesEndpoints = {
    GET_ALL_CATEGORIES,
    CREATE_CATEGORY,
    UPDATE_CATEGORY,
    ACTIVATE_CATEGORY,
    DELETE_CATEGORY,
};
