import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colors } from '../../../assets/utils/colors';
import CustomCard from '../../../components/cards/CustomCard';
import PageLayout from '../../layouts/PageLayout';
import { useParams } from 'react-router-dom';
import ContentLoader from '../../../components/ContentLoader';
import { isEmpty, size } from 'lodash';
import CustomCard2 from '../../../components/cards/CustomCard2';
import { getRTKResponseData } from '../../../helpers/RTKHelpers';
import CustomTabs from '../../../components/tabs/CustomTabs';
import {
    LocalShippingOutlined,
    ManageAccounts,
    PlaylistAddCheckCircle,
} from '@mui/icons-material';
import { useGetSingleCustomerQuery } from '../../../api/customerAPI';
import CustomersSidebar from '../../../components/sidebars/CustomersSidebar';
import CustomerWidget2 from '../../../components/widgets/CustomerWidget2';
import CustomerManagement from './CustomerManagement';
import MainOrderTable from '../../../components/tables/MainOrderTable';
import CustomerWishlist from './CustomerWishlist';

const CustomerDetails = () => {
    // ############### CONST ########################
    const { customer_id } = useParams();
    const [customer, setCustomer] = useState({});
    const [customerOrders, setCustomerOrders] = useState([]);
    const [customerWishList, setCustomerWishList] = useState([]);
    const [favoriteStore, setFavoriteStore] = useState([]);
    console.log(favoriteStore);

    // ############### RTK ########################
    const {
        isLoading,
        isSuccess,
        data: customerDetails,
    } = useGetSingleCustomerQuery(customer_id);

    // ############### UseEffect ########################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                let customer = getRTKResponseData(customerDetails);
                setCustomer(customer);
                setCustomerOrders(customer.customer_orders);
                setCustomerWishList(customer.customer_wishlist);
                setFavoriteStore(customer.favorite_stores);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, customerDetails]);

    // #################################################
    const tabs = [
        {
            id: 1,
            title: 'Management',
            icon: <ManageAccounts />,
            total: 1,
            tabsContent: (
                <CustomerManagement
                    customer={customer}
                    stores={favoriteStore}
                />
            ),
        },
        {
            id: 2,
            title: 'Orders',
            icon: <LocalShippingOutlined />,
            chipColor: 'info',
            total: size(customerOrders),
            tabsContent: (
                <MainOrderTable height={418} orders={customerOrders} />
            ),
        },
        {
            id: 3,
            title: 'Wishlist',
            icon: <PlaylistAddCheckCircle />,
            chipColor: 'warning',
            total: size(customerWishList),
            tabsContent: <CustomerWishlist products={customerWishList} />,
        },
    ];
    return (
        <>
            <PageLayout
                pageHead={
                    !isEmpty(customer) &&
                    `Customer details ( ${customer.first_name} ${customer.last_name} )`
                }
                sidebar={<CustomersSidebar />}
            >
                {isLoading ? (
                    <>
                        <CustomCard height={710}>
                            <ContentLoader />
                        </CustomCard>
                    </>
                ) : (
                    <>
                        <CustomCard2
                            title={`${customer.first_name} ${customer.last_name}`}
                            height={600}
                            action={
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        color: colors.primary,
                                        fontWeight: 'bold',
                                        bgcolor: colors.bgColor3,
                                        p: 1,
                                        borderRadius: 2,
                                    }}
                                >
                                    {customer.customer_id}
                                </Typography>
                            }
                        >
                            <Grid container>
                                <Grid item sm={12}>
                                    <CustomerWidget2 customer={customer} />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item sm={12}>
                                    <CustomTabs tabs={tabs} />
                                </Grid>
                            </Grid>
                        </CustomCard2>
                    </>
                )}
            </PageLayout>
        </>
    );
};

export default CustomerDetails;
