import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colors } from '../../../assets/utils/colors';
import CustomCard from '../../../components/cards/CustomCard';
import PageLayout from '../../layouts/PageLayout';
import { useParams } from 'react-router-dom';
import ContentLoader from '../../../components/ContentLoader';
import { isEmpty } from 'lodash';
import CustomCard2 from '../../../components/cards/CustomCard2';
import { getRTKResponseData } from '../../../helpers/RTKHelpers';
import { useGetSingleAdminQuery } from '../../../api/adminsAPI';
import AdminsSidebar from '../../../components/sidebars/AdminsSidebar';
import { DeleteForever, ManageAccounts } from '@mui/icons-material';
import InnerDataFetchingError from '../../../components/InnerDataFetchingError';

const AdminDetails = () => {
    // ############### CONST ########################
    const { admin_id } = useParams();
    const [admin, setAdmin] = useState({});

    // ############### RTK ########################
    const {
        isLoading,
        isSuccess,
        isError,
        refetch: adminRefetch,
        data: adminDetails,
    } = useGetSingleAdminQuery(admin_id);

    // ############### UseEffect ########################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                let admin = getRTKResponseData(adminDetails);
                setAdmin(admin);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, adminDetails]);

    return (
        <>
            <PageLayout
                pageHead={
                    !isEmpty(admin) &&
                    `Admin details ( ${admin.first_name} ${admin.last_name} )`
                }
                sidebar={<AdminsSidebar />}
            >
                {isLoading ? (
                    <>
                        <CustomCard height={710}>
                            <ContentLoader />
                        </CustomCard>
                    </>
                ) : (
                    <>
                        {isError ? (
                            <>
                                <InnerDataFetchingError
                                    height={700}
                                    handleDataRefetch={adminRefetch}
                                />
                            </>
                        ) : (
                            <CustomCard2
                                title={`${admin.first_name} ${admin.last_name}`}
                                height={600}
                                action={
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            color: colors.primary,
                                            fontWeight: 'bold',
                                            bgcolor: colors.bgColor3,
                                            p: 1,
                                            borderRadius: 2,
                                        }}
                                    >
                                        {admin.registration_number}
                                    </Typography>
                                }
                            >
                                <Grid container spacing={2}>
                                    <Grid item sm={12} xs={12}>
                                        <Box
                                            sx={{
                                                height: '100%',
                                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Grid
                                                container
                                                sx={{ px: 2, pb: 2 }}
                                            >
                                                <Grid item sm={12} xs={12}>
                                                    <Box
                                                        sx={{
                                                            p: 2,
                                                            textAlign: 'center',
                                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: 20,
                                                                fontWeight:
                                                                    'bold',
                                                                color:
                                                                    colors.primary,
                                                            }}
                                                        >
                                                            {
                                                                admin.registration_number
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={4} xs={12}>
                                                    <Box sx={{ pt: 2 }}>
                                                        <Avatar
                                                            src={''}
                                                            variant="square"
                                                            sx={{
                                                                height: 270,
                                                                width: '100%',
                                                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                                                p: 0.5,
                                                                mr: 1,
                                                                borderRadius: 5,
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={8} xs={12}>
                                                    <Box
                                                        sx={{
                                                            p: 2,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                'fontSize': 16,
                                                                'fontWeight':
                                                                    'bold',
                                                                'color':
                                                                    colors.primary,
                                                                'position':
                                                                    'relative',
                                                                '&::before': {
                                                                    content:
                                                                        "''",
                                                                    height: 10,
                                                                    width:
                                                                        '100%',
                                                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                                                    position:
                                                                        'absolute',
                                                                    bottom: -20,
                                                                    borderRadius: 5,
                                                                },
                                                            }}
                                                        >
                                                            Issac Adam
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ mt: 2 }}>
                                                        <Grid
                                                            container
                                                            padding={2}
                                                            spacing={2}
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            sx={{
                                                                '& .head': {
                                                                    fontWeight:
                                                                        'bold',
                                                                    opacity: 0.7,
                                                                },
                                                                '& .value': {
                                                                    fontWeight:
                                                                        'bold',
                                                                    opacity: 0.7,
                                                                    fontSize: 14,
                                                                    color:
                                                                        colors.primary,
                                                                },
                                                            }}
                                                        >
                                                            <Grid
                                                                item
                                                                sm={6}
                                                                xs={6}
                                                            >
                                                                <Box>
                                                                    <Typography className="head">
                                                                        First
                                                                        name
                                                                    </Typography>
                                                                    <Typography className="value">
                                                                        {
                                                                            admin.first_name
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sm={6}
                                                                xs={6}
                                                            >
                                                                <Box>
                                                                    <Typography className="head">
                                                                        Last
                                                                        name
                                                                    </Typography>
                                                                    <Typography className="value">
                                                                        {
                                                                            admin.last_name
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sm={6}
                                                                xs={6}
                                                            >
                                                                <Box>
                                                                    <Typography className="head">
                                                                        Phone
                                                                        number
                                                                    </Typography>
                                                                    <Typography className="value">
                                                                        {
                                                                            admin.phone_number
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sm={6}
                                                                xs={6}
                                                            >
                                                                <Box>
                                                                    <Typography className="head">
                                                                        Gender
                                                                    </Typography>
                                                                    <Typography className="value">
                                                                        {
                                                                            admin.gender
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sm={5}
                                                                sx={{ mt: 1 }}
                                                            >
                                                                <Button
                                                                    fullWidth
                                                                    variant="contained"
                                                                    className="app-btn-2"
                                                                    startIcon={
                                                                        <ManageAccounts />
                                                                    }
                                                                >
                                                                    Edit profile
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: 200,
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Button
                                                color="warning"
                                                variant="contained"
                                                className="app-btn"
                                                startIcon={<DeleteForever />}
                                            >
                                                Reset password
                                            </Button>
                                            <Button
                                                color="error"
                                                variant="contained"
                                                className="app-btn"
                                                startIcon={<DeleteForever />}
                                            >
                                                Delete account
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CustomCard2>
                        )}
                    </>
                )}
            </PageLayout>
        </>
    );
};

export default AdminDetails;
