import { baseURL2 } from './baseUrl/baseURL';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { vendorsEndpoints } from './endpoints/vendorsEndpoints';
import { headers } from './baseUrl/setHeaders';

// !############## API ################
export const vendorAPI = createApi({
    reducerPath: 'vendorAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL2,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        // => GET all vendors
        getAllVendors: builder.query({
            query: () => `${vendorsEndpoints.GET_ALL_VENDORS}`,
        }),

        // => GET vendor by id
        getVendorById: builder.query({
            query: (payload) =>
                `${vendorsEndpoints.GET_SINGLE_VENDOR}` + payload,
        }),
    }),
});

export const { useGetAllVendorsQuery, useGetVendorByIdQuery } = vendorAPI;
