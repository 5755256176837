import {
    Avatar,
    Badge,
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../../../assets/utils/colors";
import { NotificationsActive } from "@mui/icons-material";
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import logo1 from "../../../../assets/media/images/logo1.png";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomMultlineTextField from "../../../../components/forms/CustomMultlineTextField";
import CustomSubmitButton from "../../../../components/forms/CustomSubmitButton";
import { filter, isEmpty } from "lodash";
import CustomTextField from "../../../../components/forms/CustomTextField";
import { errorNotify, successNotify } from "../../../../helpers/notify";
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from "../../../../helpers/RTKHelpers";
import { useSendCustomerNotificationMutation } from "../../../../api/notificationAPI";

// ############### VALIDATION ###############
const orderNotifyingValidationSchema = Yup.object({
    customers: Yup.array().required("notification user is required field"),
    title: Yup.string().required("notification title is a required field"),
    message: Yup.string().required("notification body is a required field"),
});

const OrderNotify = ({ order }) => {
    // ############# Component state #######
    const [dialogOpen, setDialogOpen] = useState(false);
    const [customMessage, setCustomMessage] = useState("");
    const [setselectedMessageType, setSetselectedMessageType] =
        useState("custom");

    // ###### Distructure orders data #########
    const { customer } = order;
    const { ordered_products: products } = order;
    const { total_amount: amount } = order;
    const { order_status: status } = order;
    const { shipping_address: shippingAddress } = order;
    const { order_number: orderID } = order;
    const { delivered_by: deliveredBy } = order;
    const { createdAt } = order;

    const customMessages = [
        {
            id: 1,
            type: "custom",
            title: "",
            message: "",
        },
        {
            id: 2,
            type: "payment",
            title: "Pay your order boss",
            message: `payment reminder for order number : ${orderID}`,
        },
        {
            id: 3,
            type: "delivery",
            title: "Thanks for shopping with Agiza Africa",
            message: "delivery reminder",
        },
    ];
    const customNotificationRadio = [
        {
            id: 1,
            value: "custom",
            label: "Custom message",
        },
        {
            id: 2,
            value: "payment",
            label: "Payment reminder",
        },
        {
            id: 3,
            value: "delivery",
            label: "Order delivery",
        },
    ];

    // ############### FUNC ###################
    // => handle confimation dialog open
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    // => Get custom message
    const getCustomMessage = (e, formik) => {
        let selectedType = e.target.value;
        let customMessage = filter(customMessages, { type: selectedType });
        setSetselectedMessageType(selectedType);
        if (selectedType === "custom") {
            formik.setFieldValue("title", "");
            formik.setFieldValue("message", "");
            setCustomMessage("");
        } else {
            let cuMess = customMessage[0];
            formik.setFieldValue("title", cuMess.title);
            formik.setFieldValue("message", cuMess.message);
            setCustomMessage(cuMess);
        }
    };

    // ############# RTK #################
    // => CREATE category
    const [
        sendNotification,
        { isLoading, isSuccess, isError, error, data: notificationsData },
    ] = useSendCustomerNotificationMutation();

    // ############## useEffect ###############
    // => Dispatch created data
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                handleDialogClose();
                successNotify(getRTKSuccessMessage(notificationsData));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, error, notificationsData]);

    return (
        <>
            {/* ############# Confimation dialogy ################ */}
            <ConfirmationDialog
                noDropClose
                openDialog={dialogOpen}
                handleDialogClose={handleDialogClose}
            >
                <Box className="form-section">
                    <Formik
                        initialValues={{
                            // customers: ["645ea8d140ef84a6bc69f474"],
                            customers: [customer?._id],
                            title: customMessage?.title,
                            message: customMessage?.message,
                        }}
                        enaleReinitialize={true}
                        validationSchema={orderNotifyingValidationSchema}
                        onSubmit={(payload) => {
                            sendNotification(payload);
                        }}
                    >
                        {(formik) => (
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} sm={10}>
                                    <Box className="input-con">
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Avatar
                                                src={logo1}
                                                alt="Agiza logo"
                                                sx={{
                                                    width: 200,
                                                    height: 80,
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography className="input-sign-head">
                                                User order notify
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs>
                                                    <CustomTextField
                                                        type="text"
                                                        name="title"
                                                        label={
                                                            "Notification title"
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box>
                                                <CustomMultlineTextField
                                                    rows={5}
                                                    type="text"
                                                    name="message"
                                                    label={"Notification body"}
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{ mt: 2 }}>
                                            <FormControl>
                                                <FormLabel>
                                                    Select message template
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    name="message_type"
                                                    value={
                                                        setselectedMessageType
                                                    }
                                                    onChange={(e) => {
                                                        getCustomMessage(
                                                            e,
                                                            formik
                                                        );
                                                    }}
                                                    sx={{ fontSize: 10 }}
                                                >
                                                    {customNotificationRadio.map(
                                                        (radio) => (
                                                            <FormControlLabel
                                                                key={radio.id}
                                                                value={
                                                                    radio.value
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label={
                                                                    radio.label
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                        <Box sx={{ mt: 4 }}>
                                            <CustomSubmitButton
                                                loading={isLoading}
                                                onClick={formik.handleSubmit}
                                            >
                                                Send notification
                                            </CustomSubmitButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                </Box>
            </ConfirmationDialog>

            <IconButton
                size="small"
                sx={{
                    px: 1,
                    py: 0,
                    height: 40,
                    ml: 3,
                    borderRadius: 2,
                    border: `3px dotted ${colors.bgColor2}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                    transition: `.3s all ease-in-out`,
                    ":hover": {
                        transition: `.3s all ease-in-out`,
                        boxShadow: `0px 0px 5px ${colors.primary}`,
                    },
                }}
                onClick={handleDialogOpen}
            >
                <Badge color="primary" variant="dot">
                    <NotificationsActive color="primary" />
                </Badge>
            </IconButton>
        </>
    );
};

export default OrderNotify;
