import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CustomSubmitButton from '../../../../components/forms/CustomSubmitButton';
import { useDispatch } from 'react-redux';
import { useResumeStoreMutation } from '../../../../api/storeAPI';
import { setStoreDetails } from '../../../../state/features/stores/storeSlice';
import { errorNotify, successNotify } from '../../../../helpers/notify';
import SuccessFeedback from '../../../../components/SuccessFeedback';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../../helpers/RTKHelpers';

const ResumeStore = ({ store }) => {
    // ############## CONST ##############
    const dispatch = useDispatch();

    // ############## RTK ################
    // => Resume store
    const [
        resumeStore,
        { isLoading, isSuccess, isError, error, data: resumedStore },
    ] = useResumeStoreMutation();

    // ############### FUNC ###############
    // => Handle store resume
    const handleStoreResume = () => {
        resumeStore(store._id);
    };

    // ############### useEffect ###########
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                successNotify(getRTKSuccessMessage(resumedStore));
                dispatch(setStoreDetails(getRTKResponseData(resumedStore)));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, resumedStore, error, dispatch]);

    return (
        <>
            <Box
                sx={{
                    p: 2,
                    borderRadius: 2,
                    height: 300,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                {isSuccess ? (
                    <>
                        <SuccessFeedback message={resumedStore.message} />
                    </>
                ) : (
                    <>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 22,
                                    color: 'green',
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                }}
                            >
                                {store.title} resume
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontSize: 14, opacity: 0.8 }}>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Reiciendis labore illo
                                aspernatur aliquid? Adipisci consequuntur, ipsum
                                id culpa natus minus numquam eaque pariatur
                                veniam cupiditate ratione quis accusamus! Ut,
                                vitae?
                            </Typography>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                            <CustomSubmitButton
                                loading={isLoading}
                                onClick={() => {
                                    handleStoreResume();
                                }}
                            >
                                Resume store
                            </CustomSubmitButton>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

export default ResumeStore;
