import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import StoreWidget from '../../../components/widgets/StoreWidget';
import PageLayout from '../../layouts/PageLayout';
import StoresSidebar from '../../../components/sidebars/StoresSidebar';
import CustomCard2 from '../../../components/cards/CustomCard2';
import StoresFilter from '../../../components/filters/StoresFilter';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { storesSelector } from '../../../state/features/selectors';
import { filter, isEmpty } from 'lodash';
import NoContent from '../../../components/NoContent';

// !################# MAIN FUNC ###################
const StoresByStatus = () => {
    // => component state
    const [filteredStores, setFilteredStores] = useState([]);
    const [isFiltered, setIsFiltered] = useState(false);

    // ############### Redux state ########################
    const { stores } = useSelector(storesSelector);

    // ############### Filters ###########################
    // => GET filtered stores
    const { status } = useParams();
    const getFilteredStores = () => {
        let filteredStores = '';
        if (status === 'on progress') {
            filteredStores = filter(
                stores,
                ({ isApproved, tin_certificate, business_licence }) =>
                    isApproved === false &&
                    (tin_certificate === null || business_licence === null),
            );
        } else if (status === 'approval request') {
            filteredStores = filter(
                stores,
                ({ isApproved, tin_certificate, business_licence }) =>
                    isApproved === false &&
                    tin_certificate !== null &&
                    business_licence !== null,
            );
        } else if (status === 'approved') {
            filteredStores = filter(stores, { isApproved: true });
        } else if (status === 'active') {
            filteredStores = filter(stores, { isActive: true });
        } else if (status === 'inactive') {
            filteredStores = filter(stores, { isActive: false });
        } else if (status === 'denied') {
            filteredStores = filter(stores, { isDenied: true });
        } else if (status === 'suspended') {
            filteredStores = filter(stores, { isSuspended: true });
        }
        return filteredStores;
    };
    const newStores = getFilteredStores();

    // => Filter by store name
    const filterByStoreName = (search) => {
        let filteredStores = [];
        if (search) {
            filteredStores = newStores.filter((store) => {
                return store.title.toLowerCase().includes(search.toLowerCase());
            });
            setIsFiltered(true);
            return setFilteredStores(filteredStores);
        }
        return setFilteredStores(newStores);
    };
    // => Filter by category
    const filterByCategory = (category_id) => {
        let filteredStores = [];
        if (category_id) {
            filteredStores = newStores.filter((store) => {
                return store.category_id
                    .toLowerCase()
                    .includes(category_id.toLowerCase());
            });
            setIsFiltered(true);
            return setFilteredStores(filteredStores);
        }
        return setFilteredStores(newStores);
    };

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (!isFiltered) {
                setFilteredStores(newStores);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isFiltered, newStores]);

    return (
        <>
            <PageLayout
                pageHead={`Agiza africa ${status} stores`}
                sidebar={<StoresSidebar stores={stores} />}
            >
                <CustomCard2
                    title={`${status} stores`}
                    height={600}
                    action={
                        <StoresFilter
                            filterByStoreName={filterByStoreName}
                            filterByCategory={filterByCategory}
                        />
                    }
                >
                    {isEmpty(filteredStores) ? (
                        <>
                            <NoContent message={`No ${status} stores`} />
                        </>
                    ) : (
                        <>
                            <Grid container spacing={3} padding={2}>
                                {filteredStores?.map((store) => (
                                    <Grid item sm={4} key={store._id}>
                                        <StoreWidget store={store} />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default StoresByStatus;
