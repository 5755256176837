// => Get notification history
const GET_NOTIFICATION_HISTORY = "";

// => Send notification
const SEND_NOTIFICATION_ALL = "/notifications/general-notifications";

// => Send notification
const SEND_NOTIFICATION_CUSTOMER = "/notifications/send-notifications";

// => Send notification
const SEND_NOTIFICATION_ALL_VENDOR = "/notifications/vendor-notifications";

// => Send user notification
const SEND_USER_NOTIFICATION = "/notifications/customers";

export const notificationEndpoints = {
    GET_NOTIFICATION_HISTORY,
    SEND_NOTIFICATION_ALL,
    SEND_NOTIFICATION_CUSTOMER,
    SEND_NOTIFICATION_ALL_VENDOR,
    SEND_USER_NOTIFICATION,
};
