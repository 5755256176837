// => GET all stores
const GET_ALL_STORES = '/admins/stores';

// => GET single store
const GET_SINGLE_STORE = '/admins/stores/';

// => APPROVE TIN
const APPROVE_TIN = '/admins/stores/approve-tin/';

// => DENY TIN
const DENY_TIN = '/admins/stores/deny-TIN/';

// => APPROVE BUSINESS LICENCE
const APPROVE_BUSINESS_LICENCE = '/admins/stores/approve-license/';

// => DENY BUSINESS LICENCE
const DENY_BUSINESS_LICENCE = '/admins/stores/deny-license/';

// => VERIFY store
const VERIFY_STORE = '/admins/stores/verify/';

// => SUSPEND store
const SUSPEND_STORE = '/admins/stores/suspend/';

// => RESUME store
const RESUME_STORE = '/admins/stores/resume/';

export const storesEndponts = {
    GET_ALL_STORES,
    GET_SINGLE_STORE,
    APPROVE_TIN,
    DENY_TIN,
    APPROVE_BUSINESS_LICENCE,
    DENY_BUSINESS_LICENCE,
    VERIFY_STORE,
    SUSPEND_STORE,
    RESUME_STORE,
};
