import { DocumentScanner, Inventory2, Settings } from "@mui/icons-material";
import { Box, Chip, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { colors } from "../../../assets/utils/colors";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import StoreWidget2 from "../../../components/widgets/StoreWidget2";
import { useParams } from "react-router-dom";
import { useGetSingleStoreByIdQuery } from "../../../api/storeAPI";
import { isEmpty, size } from "lodash";
import PageLoader from "../../../components/PageLoader";
import StoreProducts from "./storeComponents/StoreProducts";
import StoreOrders from "./storeComponents/StoreOrders";
import StoreDocuments from "./storeComponents/StoreDocuments";
import StoreManagements from "./storeComponents/StoreManagements";
import { useDispatch, useSelector } from "react-redux";
import { setStoreDetails } from "../../../state/features/stores/storeSlice";
import { storesSelector } from "../../../state/features/selectors";
import { getRTKResponseData } from "../../../helpers/RTKHelpers";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        p: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

// !################### MAIN FUNC ####################
const StoreDetails = () => {
    // ############# Component state #######
    const [value, setValue] = React.useState(0);

    // ############ Redux state #############
    const { storeDetails: store } = useSelector(storesSelector);
    const vendorID = !isEmpty(store) && store.vendor_id._id;

    // ###### Distructure store data #########
    const { store_products } = store;
    const { store_orders } = store;
    const { store_reviews } = store;

    // => Handle tab change
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // ############# CONST ##############
    const theme = useTheme();
    const dispatch = useDispatch();
    const { store_id } = useParams();

    // ############# RTK ###############
    const {
        isLoading,
        isSuccess,
        data: storeDetails,
    } = useGetSingleStoreByIdQuery(store_id);

    // ############ UseEffect #############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(setStoreDetails(getRTKResponseData(storeDetails)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, storeDetails, dispatch]);

    return (
        <>
            {isLoading ? (
                <>
                    <PageLoader />
                </>
            ) : (
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box sx={{}}>
                        <StoreWidget2 store={store} />
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{}}>
                            <AppBar
                                position="sticky"
                                sx={{ position: "sticky", top: 0, zIndex: 999 }}
                                elevation={2}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="fullWidth"
                                >
                                    <Tab
                                        label={
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        fontSize: 12,
                                                        mr: 2,
                                                    }}
                                                >
                                                    <Inventory2
                                                        sx={{ mr: 2 }}
                                                    />{" "}
                                                    Store products
                                                </Typography>
                                                <Chip
                                                    color="secondary"
                                                    label={size(store_products)}
                                                    size="small"
                                                />
                                            </Box>
                                        }
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        label={
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        fontSize: 12,
                                                        mr: 2,
                                                    }}
                                                >
                                                    <Inventory2
                                                        sx={{ mr: 2 }}
                                                    />{" "}
                                                    Store orders
                                                </Typography>
                                                <Chip
                                                    label={size(store_orders)}
                                                    sx={{
                                                        bgcolor: colors.green,
                                                    }}
                                                    size="small"
                                                />
                                            </Box>
                                        }
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        label={
                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: 12,
                                                }}
                                            >
                                                <DocumentScanner
                                                    sx={{ mr: 2 }}
                                                />{" "}
                                                Store documents
                                            </Typography>
                                        }
                                        {...a11yProps(2)}
                                    />
                                    <Tab
                                        label={
                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: 12,
                                                }}
                                            >
                                                <Settings sx={{ mr: 2 }} />{" "}
                                                Store management
                                            </Typography>
                                        }
                                        {...a11yProps(2)}
                                    />
                                </Tabs>
                            </AppBar>
                        </Box>
                        <Box sx={{ flex: 1, position: "relative" }}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    overflowY: "auto",
                                }}
                            >
                                {/* ################ Store products ################ */}
                                <TabPanel
                                    value={value}
                                    index={0}
                                    dir={theme.direction}
                                >
                                    <StoreProducts products={store_products} />
                                </TabPanel>
                                {/* ################ Store orders ################ */}
                                <TabPanel
                                    value={value}
                                    index={1}
                                    dir={theme.direction}
                                >
                                    <StoreOrders orders={store_orders} />
                                </TabPanel>
                                {/* ################ Store documents ################ */}
                                <TabPanel
                                    value={value}
                                    index={2}
                                    dir={theme.direction}
                                >
                                    <StoreDocuments store={store} />
                                </TabPanel>
                                {/* ################ Store managements ################ */}
                                <TabPanel
                                    value={value}
                                    index={3}
                                    dir={theme.direction}
                                >
                                    <StoreManagements
                                        store={store}
                                        storeReviews={store_reviews}
                                        vendorID={vendorID}
                                    />
                                </TabPanel>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default StoreDetails;
