import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import PageLayout from "../../layouts/PageLayout";
import { customersSelector } from "../../../state/features/selectors";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import CountBadge from "../../../components/CountBadge";
import NoContent from "../../../components/NoContent";
import CustomersSidebar from "../../../components/sidebars/CustomersSidebar";
// import CustomersFilter from '../../../components/filters/CustomersFilter';
import CustomerWidget from "../../../components/widgets/CustomerWidget";
import CustomPaginationTwo from "../../../components/paginations/CustomPaginationTwo";
// import { paginator } from '../../../helpers/paginationHelper';
import { useLazyGetAllCustomersQuery } from "../../../api/customerAPI";
import CustomersFilter from "../../../components/filters/CustomersFilter";
// import CustomCard from '../../../components/cards/CustomCard';

const Customers = () => {
    // ############### CONST ####################
    const dispatch = useDispatch();

    // ############### Component state ###################
    const [customers, setCustomers] = useState([]);
    // const [page, setPage] = React.useState(1);
    // const [perPage, setPerPage] = React.useState(25);

    // ######### Redux states #############
    const {
        customers: allCustomers,
        paginatedCustomers,
        totalCustomers,
    } = useSelector(customersSelector);
    console.log(paginatedCustomers);

    // ############### RTK #########################
    const [
        getCustomers,
        {
            // isLoading: customerLoading,
            isSuccess: customerSuccess,
            data: customersData,
        },
    ] = useLazyGetAllCustomersQuery();

    // ############### FUNC ###########################
    // => handle page change
    const handlePageChange = (page) => {
        getCustomers(page);
    };
    // => handle per page change
    // const handlePerPageChage = (perPage) => {
    //     setPerPage(perPage);
    // };
    // => Filter by name/id
    const filterByNameOrID = (search) => {
        let customers = [];
        if (search) {
            customers = allCustomers.filter((customer) => {
                return (
                    customer.customer_id
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    customer.first_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    customer.last_name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                );
            });
            return setCustomers(customers);
        }
        return setCustomers(allCustomers);
    };

    // ############## useEffect ################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setCustomers(allCustomers);
        }
        return () => {
            isSubscribed = false;
        };
    }, [setCustomers, allCustomers]);
    // const paginatedCustomers = paginator(allCustomers, page, perPage);

    // => Dispatch main data
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (customerSuccess) {
                // console.log(customersData);
                // dispatch(setCustomers(customersData));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [customerSuccess, customersData, dispatch]);

    return (
        <>
            <PageLayout
                sidebar={<CustomersSidebar />}
                pageHead={"Agiza africa customers"}
            >
                <CustomPaginationTwo
                    title={"Registered customers"}
                    data={paginatedCustomers}
                    handlePageChange={handlePageChange}
                    action={
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: 400,
                            }}
                        >
                            <CountBadge
                                total={totalCustomers}
                                item={"customers"}
                            />
                            <CustomersFilter
                                filterByNameOrID={filterByNameOrID}
                            />
                        </Box>
                    }
                >
                    {isEmpty(paginatedCustomers) ? (
                        <>
                            <NoContent message={"No customer available"} />
                        </>
                    ) : (
                        <Grid container spacing={3} padding={2}>
                            {paginatedCustomers.data?.map((customer) => (
                                <Grid item sm={4} key={customer._id}>
                                    <CustomerWidget customer={customer} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </CustomPaginationTwo>
            </PageLayout>
        </>
    );
};

export default Customers;
