import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../../../assets/utils/colors';
import CustomSubmitButton from '../../../../components/forms/CustomSubmitButton';
import { useDispatch } from 'react-redux';
import { useVerifyStoreMutation } from '../../../../api/storeAPI';
import { setStoreDetails } from '../../../../state/features/stores/storeSlice';
import { errorNotify, successNotify } from '../../../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../../helpers/RTKHelpers';

const VerifyStore = ({ store }) => {
    // ############## CONST ##############
    const dispatch = useDispatch();

    // ############## RTK ################
    // => Verify store
    const [
        verifyStore,
        { isLoading, isSuccess, isError, error, data: verifyiedStore },
    ] = useVerifyStoreMutation();

    // ############### FUNC ###############
    // => Handle store verification
    const handleStoreVerification = () => {
        verifyStore(store._id);
    };

    // ############### useEffect ###########
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                successNotify(getRTKSuccessMessage(verifyiedStore));
                dispatch(setStoreDetails(getRTKResponseData(verifyiedStore)));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, error, verifyiedStore, dispatch]);

    return (
        <>
            <Box
                sx={{
                    p: 2,
                    borderRadius: 2,
                    height: 300,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                <>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 22,
                                color: colors.primary,
                                fontWeight: 'bold',
                                opacity: 0.8,
                            }}
                        >
                            {store.title} verification
                        </Typography>
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: 14, opacity: 0.8 }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Reiciendis labore illo aspernatur aliquid?
                            Adipisci consequuntur, ipsum id culpa natus minus
                            numquam eaque pariatur veniam cupiditate ratione
                            quis accusamus! Ut, vitae?
                        </Typography>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <CustomSubmitButton
                            loading={isLoading}
                            onClick={() => {
                                handleStoreVerification();
                            }}
                        >
                            Verify store
                        </CustomSubmitButton>
                    </Box>
                </>
            </Box>
        </>
    );
};

export default VerifyStore;
