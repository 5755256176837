import React from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../../../assets/utils/colors';
import { currencyFomater } from '../../../../helpers/currencyFomater';
import { isEmpty } from 'lodash';

const AvailableSize = ({ available_sizes, product }) => {
    return (
        <>
            <Box
                sx={{
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        py: 1,
                        textAlign: 'center',
                    }}
                >
                    Available Size
                </Typography>
                <Box
                    sx={{
                        // display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderRadius: 2,
                        overflowX: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                        }}
                    >
                        {available_sizes?.map((size, index) => (
                            <Box
                                key={index}
                                sx={{
                                    mx: 1,
                                    borderRadius: 1,
                                    border: `1px solid ${colors.bgColor1}`,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                        borderBottom: `2px solid ${colors.primary}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                        p: 0.5,
                                        px: 1.5,
                                    }}
                                >
                                    {size.name}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                        p: 1.5,
                                    }}
                                >
                                    {currencyFomater(
                                        !isEmpty(size.price)
                                            ? size.price
                                            : product.price,
                                    )}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AvailableSize;
