import { Store } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import bg1 from "../../assets/media/images/bg1.png";
import { colors } from "../../assets/utils/colors";
import { size } from "lodash";

const DashMainStoresCard = ({ stores }) => {
    return (
        <>
            <Box
                sx={{
                    borderRadius: 50,
                    height: 180,
                    width: 180,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                className="animated-bg-2"
            >
                <Box
                    sx={{
                        borderRadius: 50,
                        backgroundImage: `url(${bg1})`,
                        backgroundPosition: "top, center",
                        backgroundSize: 200,
                        height: 160,
                        width: 160,
                    }}
                >
                    <Box
                        sx={{
                            height: 160,
                            width: 160,
                            borderRadius: 50,
                            background: `rgba(125, 98, 212, .8);`,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Store
                            sx={{
                                fontSize: 25,
                                color: colors.bgColor3,
                                fontWeight: "bold",
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: 16,
                                color: colors.bgColor3,
                                fontWeight: "bold",
                            }}
                        >
                            Stores
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 28,
                                color: colors.bgColor3,
                                fontWeight: "bold",
                                my: 2,
                            }}
                        >
                            {size(stores)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default DashMainStoresCard;
