// => GET all products
const GET_ALL_PRODUCTS = "/admins/products";

// => GET single product
const GET_SINGLE_PRODUCT = "/admins/products";

// => APPROVE product
const APPROVE_PRODUCT = "/admins/products/approve/";

// => DENY product
const DENY_PRODUCT = "/admins/products/deny/";

// => SUSPEND product
const SUSPEND_PRODUCT = "/admins/products/suspend/";

// => RESUME product
const RESUME_PRODUCT = "/admins/products/resume/";

// => DELETE product
const DELETE_PRODUCT = "/products/";

// => Feture product
const FEATURE_PRODUCT = "/products/featured/";

// => BestSell product
const BEST_SELL_PRODUCT = "/products/best-seller/";

// => Assign sub-category
const ASSIGN_SUB_CATEGORY = "/products/assign-subcategory/";

// => Change product store
const CHANGE_PRODUCT_STORE = "/products/update-category/";

export const productEndponts = {
    GET_ALL_PRODUCTS,
    GET_SINGLE_PRODUCT,
    APPROVE_PRODUCT,
    DENY_PRODUCT,
    SUSPEND_PRODUCT,
    RESUME_PRODUCT,
    DELETE_PRODUCT,
    FEATURE_PRODUCT,
    BEST_SELL_PRODUCT,
    ASSIGN_SUB_CATEGORY,
    CHANGE_PRODUCT_STORE,
};
