import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from './baseUrl/baseURL';
import { headers } from './baseUrl/setHeaders';
import { subCategoriesEndpoints } from './endpoints/subCategoriesEndpoints';
import { categoryAPI } from './categoryAPI';

export const subCategoryAPI = createApi({
    reducerPath: 'subCategoryAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    tagTypes: ['SubCategories'],
    endpoints: (builder) => ({
        // => GET all categories
        getAllSubCategories: builder.query({
            query: () => `${subCategoriesEndpoints.GET_ALL_CATEGORIES}`,
            providesTags: ['SubCategories']
        }),
        // => CREATE category
        createSubCategory: builder.mutation({
            query: (payload) => ({
                url: `${subCategoriesEndpoints.GET_ALL_CATEGORIES}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                await queryFulfilled
                dispatch(subCategoryAPI.util.invalidateTags(['SubCategories']))
                dispatch(categoryAPI.util.invalidateTags(['Categories']))
            },
        }),
        // => UPDATE category
        updateSubCategory: builder.mutation({
            query: (payload) => ({
                url:
                    `${subCategoriesEndpoints.UPDATE_CATEGORY}` +
                    payload._id,
                method: `PUT`,
                body: payload,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                await queryFulfilled
                dispatch(subCategoryAPI.util.invalidateTags(['SubCategories']))
                dispatch(categoryAPI.util.invalidateTags(['Categories']))
            },
        }),
        // => ACTIVATE category
        activateSubCategory: builder.mutation({
            query: (payload) => ({
                url: `${subCategoriesEndpoints.ACTIVATE_CATEGORY}` + payload,
                method: `PUT`,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                await queryFulfilled
                dispatch(subCategoryAPI.util.invalidateTags(['SubCategories']))
                dispatch(categoryAPI.util.invalidateTags(['Categories']))
            },
        }),
        // => DELETE category
        deleteSubCategory: builder.mutation({
            query: (payload) => ({
                url: `${subCategoriesEndpoints.DELETE_CATEGORY}` + payload,
                method: `DELETE`,
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                await queryFulfilled
                dispatch(subCategoryAPI.util.invalidateTags(['SubCategories']))
                dispatch(categoryAPI.util.invalidateTags(['Categories']))
            },
        }),
    }),
});

export const {
    useGetAllSubCategoriesQuery,
    useCreateSubCategoryMutation,
    useUpdateSubCategoryMutation,
    useActivateSubCategoryMutation,
    useDeleteSubCategoryMutation,
} = subCategoryAPI;
