import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Box, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Slide, Tooltip } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { PDFViewer } from "@react-pdf/renderer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(0),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(0),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            <Tooltip title={"Close dialog"} arrow>
                <IconButton
                    className="neo-bulge"
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: colors.secondary,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ReportDialog = ({
    openDialog,
    handleDialogClose,
    head,
    actionForm,
    noDropClose,
    children,
    customWidth,
}) => {
    return (
        <>
            <BootstrapDialog
                className="neo-bulge"
                onClose={noDropClose ? undefined : handleDialogClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                TransitionComponent={Transition}
                fullScreen
                maxWidth={customWidth && customWidth}
                sx={{
                    " .MuiPaper-root": {
                        display: "flex",
                        flexDirection: "column",
                        boxShadow:
                            "-8px -8px 12px 0 rgba(0, 0, 0, .1) inset !important",
                        border: `10px solid ${colors.bgColor2}`,
                    },
                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleDialogClose}
                    sx={{
                        fontSize: 16,
                        fontWeight: "bold",
                        backgroundColor: head ? colors.bgColor2 : "transparent",
                        color: colors.primary,
                        textTransform: "uppercase",
                    }}
                >
                    {head}
                </BootstrapDialogTitle>
                <DialogContent
                    sx={{
                        flex: 1,
                        display: "flex",
                        overflow: "hidden",
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                    }}
                >
                    <Box sx={{ flex: 2, position: "relative" }}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                overflowY: "hidden",
                            }}
                        >
                            <PDFViewer
                                style={{ height: "100%", width: "100%" }}
                            >
                                {children}
                            </PDFViewer>
                        </Box>
                    </Box>
                    {actionForm && (
                        <Box sx={{ flex: 1 }} item sm={4}>
                            {actionForm}
                        </Box>
                    )}
                </DialogContent>
            </BootstrapDialog>
        </>
    );
};

export default ReportDialog;
