import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../../../assets/utils/colors';

const AvailableColor = ({ available_colors }) => {
    return (
        <>
            <Box
                sx={{
                    height: 70,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    borderRadius: 2,
                    mt: 2,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        py: 1,
                    }}
                >
                    Available color
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '90%',
                        pb: 1,
                    }}
                >
                    {available_colors?.map((color, index) => (
                        <Box
                            key={index}
                            sx={{
                                height: 20,
                                width: 20,
                                borderRadius: 50,
                                bgcolor: color.name,
                            }}
                        ></Box>
                    ))}
                </Box>
            </Box>
        </>
    );
};

export default AvailableColor;
