import React from 'react';
import InnerPageTopbar from '../../components/InnerPageTopbar';
import {
    Avatar,
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    styled,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/colors';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Formik } from 'formik';
import CustomTextField from '../../components/forms/CustomTextField';
import logo1 from '../../assets/media/images/logo1.png';
import { Link } from 'react-router-dom';
import { Add, DeleteForever, Edit, LocationOn } from '@mui/icons-material';

function createData(s_n, name, district, coodinate, action) {
    return { s_n, name, district, coodinate, action };
}

const rows = [
    createData(
        1,
        'Arusha',
        <Box sx={{ width: 250 }}>
            <Box>
                <Typography sx={{ fontSize: 12, mb: 0.5, fontWeight: 'bold' }}>
                    6 district
                </Typography>
            </Box>
            <Box>
                <Chip
                    variant="outlined"
                    label={'Computers'}
                    size="small"
                    sx={{ fontSize: 10, mr: 0.4, mb: 0.4 }}
                />
                <Chip
                    variant="outlined"
                    label={'Computers'}
                    size="small"
                    sx={{ fontSize: 10, mr: 0.4, mb: 0.4 }}
                />
                <Chip
                    variant="outlined"
                    label={'Computers'}
                    size="small"
                    sx={{ fontSize: 10, mr: 0.4, mb: 0.4 }}
                />
                <Chip
                    variant="outlined"
                    label={'Computers'}
                    size="small"
                    sx={{ fontSize: 10, mr: 0.4, mb: 0.4 }}
                />
                <Chip
                    variant="outlined"
                    label={'Computers'}
                    size="small"
                    sx={{ fontSize: 10, mr: 0.4, mb: 0.4 }}
                />
                <Chip
                    variant="outlined"
                    label={'Computers'}
                    size="small"
                    sx={{ fontSize: 10, mr: 0.4, mb: 0.4 }}
                />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
                <IconButton>
                    <Tooltip title="Add district">
                        <Add
                            sx={{ fontWeight: 'bold', color: colors.primary }}
                        />
                    </Tooltip>
                </IconButton>
            </Box>
        </Box>,
        <IconButton>
            <LocationOn sx={{ color: colors.primary }} />
        </IconButton>,
    ),
    createData(
        2,
        'Dar es saalam',
        <Box sx={{ width: 250 }}>
            <Box>
                <Typography sx={{ fontSize: 12, mb: 0.5, fontWeight: 'bold' }}>
                    7 district
                </Typography>
            </Box>
            <Box>
                <Chip
                    variant="outlined"
                    label={'Computers'}
                    size="small"
                    sx={{ fontSize: 10, mr: 0.4, mb: 0.4 }}
                />
                <Chip
                    variant="outlined"
                    label={'Computers'}
                    size="small"
                    sx={{ fontSize: 10, mr: 0.4, mb: 0.4 }}
                />
                <Chip
                    variant="outlined"
                    label={'Computers'}
                    size="small"
                    sx={{ fontSize: 10, mr: 0.4, mb: 0.4 }}
                />
                <Chip
                    variant="outlined"
                    label={'Computers'}
                    size="small"
                    sx={{ fontSize: 10, mr: 0.4, mb: 0.4 }}
                />
                <Chip
                    variant="outlined"
                    label={'Computers'}
                    size="small"
                    sx={{ fontSize: 10, mr: 0.4, mb: 0.4 }}
                />
                <Chip
                    variant="outlined"
                    label={'Computers'}
                    size="small"
                    sx={{ fontSize: 10, mr: 0.4, mb: 0.4 }}
                />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
                <IconButton>
                    <Tooltip title="Add district">
                        <Add
                            sx={{ fontWeight: 'bold', color: colors.primary }}
                        />
                    </Tooltip>
                </IconButton>
            </Box>
        </Box>,
        <IconButton>
            <LocationOn sx={{ color: colors.primary }} />
        </IconButton>,
    ),
];

const ContentSection = styled(Box)(({ theme }) => ({
    'height': 650,
    'borderRadius': 5,
    'overflow': 'hidden',
    'boxShadow': '-1px 1px 2px 2px rgba(125, 98, 212, .1)',
    '& .categories-section': {
        height: 650,
        backgroundImage: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
    },
    '& .section-head': {
        'fontSize': 18,
        'fontWeight': 'bold',
        'color': colors.primary,
        'ml': 2,
        'position': 'relative',
        '&::before': {
            content: "''",
            height: 5,
            width: 100,
            background: colors.bgColor1,
            position: 'absolute',
            bottom: -8,
            borderRadius: 5,
        },
    },
    '& .table-con': {
        'background': 'transparent',
        'boxShadow': 'none',
        'height': 400,
        '& .table': {
            'width': '100%',
            '& tr': {
                borderBottom: `2px solid ${colors.bgColor1}`,
            },
        },
        '& .thead-th': {
            fontWeight: 'bold',
            background: 'transparent !important',
            borderBottom: `1px solid ${colors.bgColor1}`,
        },
    },
    '& .form-section': {
        'height': 650,
        'background': colors.bgColor1,
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        '& .input-con': {
            height: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
    },
    [theme.breakpoints.down('md')]: {},
}));

const Regions = () => {
    return (
        <>
            <Grid container>
                {/* ######### PAGE TITLE ############# */}
                <Grid item xs={12} sm={12} sx={{ px: 2 }}>
                    <InnerPageTopbar title="Agiza africa oparation regions" />
                </Grid>
                {/* ######### PAGE TITLE ############# */}
                <Grid item xs={12} sm={12} sx={{ px: 2, mt: 2 }}>
                    <ContentSection>
                        <Grid container>
                            {/* ######################## Categories ###################### */}
                            <Grid item sm={8} xs={12}>
                                <Box className="categories-section" sx={{}}>
                                    {/* <Box sx={{ p: 2 }}>
                                        <Typography className="head-font section-head">
                                            Agiza africa oparation regions
                                        </Typography>
                                    </Box> */}
                                    <Box sx={{ px: 4, py: 2 }}>
                                        <TableContainer
                                            className="table-con"
                                            component={Paper}
                                        >
                                            <Table
                                                className="table"
                                                stickyHeader
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className="head-font thead-th">
                                                            S/N
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className="head-font thead-th"
                                                        >
                                                            Region
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className="head-font thead-th"
                                                        >
                                                            Districts
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className="head-font thead-th"
                                                        >
                                                            Coodinates
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className="head-font thead-th"
                                                        >
                                                            Action
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={{
                                                    '& .MuiTableRow-root': {
                                                        borderBottom: '1px solid red !important'
                                                    }
                                                }}>
                                                    {rows.map((row) => (
                                                        <TableRow
                                                            key={row.s_n}
                                                            sx={{
                                                                '&:last-child td, &:last-child th': {
                                                                    border: 0,
                                                                },
                                                            }}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                align="left"
                                                                sx={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                {row.s_n}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.district}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {row.coodinate}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Box
                                                                    sx={{
                                                                        ml: -6,
                                                                    }}
                                                                >
                                                                    <IconButton
                                                                        sx={{}}
                                                                        size="small"
                                                                    >
                                                                        <Switch
                                                                            defaultChecked
                                                                            size="small"
                                                                        />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        sx={{
                                                                            p: 0.5,
                                                                            bgcolor:
                                                                                colors.bgColor1,
                                                                            color:
                                                                                colors.primary,
                                                                            mr: 1,
                                                                        }}
                                                                        size="small"
                                                                    >
                                                                        <Edit
                                                                            sx={{
                                                                                fontSize: 15,
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        sx={{
                                                                            p: 0.5,
                                                                            bgcolor:
                                                                                colors.bgColor1,
                                                                            color:
                                                                                colors.red,
                                                                            ml: 1,
                                                                        }}
                                                                        size="small"
                                                                    >
                                                                        <DeleteForever
                                                                            sx={{
                                                                                fontSize: 15,
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Box>
                            </Grid>

                            {/* ######################## Form Input ###################### */}
                            <Grid item sm={4} xs={12}>
                                <Box className="form-section">
                                    <Formik
                                        initialValues={{
                                            adminID: '',
                                            password: '',
                                        }}
                                    >
                                        {(formik) => (
                                            <Grid
                                                container
                                                justifyContent={'center'}
                                            >
                                                <Grid item xs={12} sm={10}>
                                                    <Box className="input-con">
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Avatar
                                                                src={logo1}
                                                                alt="Agiza logo"
                                                                sx={{
                                                                    width: 160,
                                                                    height: 66,
                                                                }}
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <Typography className="input-sign-head">
                                                                Add oparation
                                                                region
                                                            </Typography>
                                                            <Box>
                                                                <CustomTextField
                                                                    type="text"
                                                                    name="adminID"
                                                                    label={
                                                                        'Region name'
                                                                    }
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    bgcolor:
                                                                        colors.bgColor2,
                                                                    mt: 2,
                                                                    p: 2,
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 12,
                                                                        fontWeight:
                                                                            'bold',
                                                                    }}
                                                                >
                                                                    Region
                                                                    coodinate
                                                                </Typography>
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        sm={6}
                                                                    >
                                                                        <CustomTextField
                                                                            type="text"
                                                                            name="adminID"
                                                                            label={
                                                                                'Latitude'
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        sm={6}
                                                                    >
                                                                        <CustomTextField
                                                                            type="text"
                                                                            name="adminID"
                                                                            label={
                                                                                'Longitude'
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            <Link
                                                                to={
                                                                    '/dashboard'
                                                                }
                                                            >
                                                                <Button
                                                                    fullWidth
                                                                    variant="contained"
                                                                    className="app-btn"
                                                                >
                                                                    Save region
                                                                </Button>
                                                            </Link>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Formik>
                                </Box>
                            </Grid>
                        </Grid>
                    </ContentSection>
                </Grid>
            </Grid>
        </>
    );
};

export default Regions;
