import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, Replay } from '@mui/icons-material';
import { colors } from '../assets/utils/colors';

const InnerDataFetchingError = ({ height, content, handleDataRefetch }) => {
    const navigate = useNavigate();
    return (
        <>
            <Box
                sx={{
                    height: height,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid container justifyContent={'center'}>
                    <Grid item sm={6}>
                        <Box
                            sx={{
                                height: 300,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                borderRadius: 5,
                                py: 5,
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: 55,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    400
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        color: colors.red,
                                    }}
                                >
                                    Opps! Data fetching error
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                        fontSize: 13,
                                        textAlign: 'center',
                                        p: 3,
                                    }}
                                >
                                    Sorry! application fail to fetch {content}
                                    details. Try to refetch data, If problem
                                    persist contact our support team.
                                </Typography>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    size="small"
                                    startIcon={<Replay />}
                                    sx={{
                                        my: 2,
                                        mx: 2,
                                        boxShadow: 'none',
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={() => {
                                        handleDataRefetch();
                                    }}
                                >
                                    Try to refresh
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    startIcon={<ArrowLeftOutlined />}
                                    sx={{
                                        my: 2,
                                        mx: 2,
                                        boxShadow: 'none',
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                >
                                    Go back
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default InnerDataFetchingError;
