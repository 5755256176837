import React from 'react';
import { Box, IconButton, MenuItem, Typography } from '@mui/material';
import CustomSelectFilter from '../forms/CustomSelectFilter';
import CustomDateFilter from '../forms/CustomDateFilter';

const OrderFilter = ({
    byDateFrom,
    byDateTo,
    byPayment,
    byStatus,
    filterByDateFrom,
    filterByDateTo,
    filterByPayment,
    filterByStatus,
    resetFilter,
}) => {
    return (
        <>
            <Box
                sx={{
                    height: `100%`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                    <IconButton sx={{ cursor: 'default' }} color="info">
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                            From
                        </Typography>
                    </IconButton>
                    <CustomDateFilter
                        value={byDateFrom}
                        width={150}
                        type={'date'}
                        handleFilter={filterByDateFrom}
                    />
                </Box>
                <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                    <IconButton sx={{ cursor: 'default' }} color="info">
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                            To
                        </Typography>
                    </IconButton>
                    <CustomDateFilter
                        value={byDateTo}
                        width={150}
                        type={'date'}
                        handleFilter={filterByDateTo}
                    />
                </Box>
                <CustomSelectFilter
                    width={150}
                    handleFilter={filterByPayment}
                    compValue={byPayment}
                >
                    <MenuItem sx={{ fontSize: 12 }} value={''}>
                        All orders
                    </MenuItem>
                    <MenuItem sx={{ fontSize: 12 }} value={true}>
                        Paid
                    </MenuItem>
                    <MenuItem sx={{ fontSize: 12 }} value={false}>
                        Not Paid
                    </MenuItem>
                </CustomSelectFilter>
                <Box>
                    <CustomSelectFilter
                        width={150}
                        handleFilter={filterByStatus}
                        compValue={byStatus}
                    >
                        <MenuItem sx={{ fontSize: 12 }} value={''}>
                            All orders
                        </MenuItem>
                        <MenuItem sx={{ fontSize: 12 }} value={1}>
                            Placed
                        </MenuItem>
                        <MenuItem sx={{ fontSize: 12 }} value={2}>
                            Accepted
                        </MenuItem>
                        <MenuItem sx={{ fontSize: 12 }} value={3}>
                            Shipped
                        </MenuItem>
                        <MenuItem sx={{ fontSize: 12 }} value={4}>
                            Delivered
                        </MenuItem>
                        <MenuItem sx={{ fontSize: 12 }} value={5}>
                            Canceled
                        </MenuItem>
                        <MenuItem sx={{ fontSize: 12 }} value={6}>
                            Refunded
                        </MenuItem>
                    </CustomSelectFilter>
                </Box>
                <IconButton color="info" onClick={() => resetFilter()}>
                    <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                        Reset
                    </Typography>
                </IconButton>
            </Box>
        </>
    );
};

export default OrderFilter;
