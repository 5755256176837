import { Box, Button } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import Loader from "react-js-loader";

const CustomSubmitButton = ({
    loading,
    startIcon,
    onClick,
    btnColor,
    children,
}) => {
    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Button
                    variant="contained"
                    onClick={onClick}
                    {...(loading ? "" : "")}
                    disabled={loading ? true : false}
                    startIcon={startIcon}
                    color={btnColor}
                    className="app-btn"
                    fullWidth
                    sx={{ position: "relative" }}
                >
                    {children}
                    {loading && (
                        <Box
                            sx={{
                                position: "absolute",
                                left: 0,
                                right: 0,
                            }}
                        >
                            <Loader
                                type="bubble-loop"
                                bgColor={colors.primary}
                                color={colors.primary}
                                size={50}
                            />
                        </Box>
                    )}
                </Button>
            </Box>
        </>
    );
};

export default CustomSubmitButton;
