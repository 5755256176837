import React from 'react';
import { Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import NoContent from '../../../components/NoContent';
import ProductWidget from '../../../components/widgets/ProductWidget';

const CustomerWishlist = ({ products }) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    {isEmpty(products) ? (
                        <>
                            <NoContent
                                message={'Customer do not have any wishlist.'}
                            />
                        </>
                    ) : (
                        <Grid container spacing={2} padding={2}>
                            {products?.map((product) => (
                                <Grid item sm={4} key={product._id}>
                                    <ProductWidget product={product} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default CustomerWishlist;
