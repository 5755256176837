import React from "react";
import { StyleSheet } from "@react-pdf/renderer";
import { colors } from "../../assets/utils/colors";
import { isEmpty, size } from "lodash";
import {
    CuReportTable,
    CuTableBody,
    CuTableColumn,
    CuTableHead,
    CuTableHeader,
    CuTableRow,
    CuTableSN,
} from "../../components/tables/ReportTableComp";
import ReportNoData from "../../components/ReportNoData";

const VendorReport = ({ data }) => (
    <>
        {isEmpty(data) ? (
            <>
                <ReportNoData />
            </>
        ) : (
            <CuReportTable>
                <CuTableHeader>
                    <CuTableRow>
                        <CuTableSN value={"S/N"} />
                        <CuTableHead value={"Vendor name"} />
                        <CuTableHead value={"Location"} />
                        <CuTableHead value={"Store"} />
                        <CuTableHead value={"Phone number"} />
                    </CuTableRow>
                </CuTableHeader>
                <CuTableBody>
                    {data?.map((vendor, index) => (
                        <CuTableRow key={index}>
                            <CuTableColumn value={++index} />
                            <CuTableColumn
                                value={
                                    vendor.first_name + " " + vendor.last_name
                                }
                            />
                            <CuTableColumn
                                value={`${vendor.city}, ${vendor.nationality}`}
                            />
                            <CuTableColumn value={size(vendor.vendor_stores)} />
                            <CuTableColumn value={vendor.phone_number} />
                        </CuTableRow>
                    ))}
                </CuTableBody>
            </CuReportTable>
        )}
    </>
);

export default VendorReport;
