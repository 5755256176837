import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TopBar from "../../components/TopBar";
import SideBar from "../../components/SideBar";
import { colors } from "../../assets/utils/colors";
import logo2 from "../../assets/media/images/logo2.png";
import { useGetAllVendorsQuery } from "../../api/vendorAPI";
import FullAppLoader from "../../components/FullAppLoader";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setVendors } from "../../state/features/vendors/vendorsSlice";
import { useGetAllStoresQuery } from "../../api/storeAPI";
import { setStores } from "../../state/features/stores/storeSlice";
import DataFetchError from "../errors/DataFetchError";
import { useGetAllOrdersQuery } from "../../api/orderAPI";
import {
    setOrders,
    setOrdersIsLoading,
} from "../../state/features/orders/orderSlice";
import { useGetAllProductsQuery } from "../../api/productAPI";
import { setProducts } from "../../state/features/products/productSlice";
import useNetwork from "../../hooks/useNetwork";
import NoNetworkError from "../errors/NoNetworkError";
import HotToastfy from "../../components/HotToastfy";
import { useGetAllCategoriesQuery } from "../../api/categoryAPI";
import { setCategories } from "../../state/features/categories/categorySlice";
import { useGetAdminsQuery } from "../../api/adminsAPI";
import { setAdmins } from "../../state/features/admins/adminsSlice";
import { setDeliveryGuys } from "../../state/features/deliveryGuys/deliveryGuysSlice";
import { useGetDeliveryGuysQuery } from "../../api/deliveryGuyAPI";
import { useGetAllPromotionQuery } from "../../api/promotionAPI";
import { setPromotions } from "../../state/features/promotions/promotionsSlice";
import {
    useGetAllCustomersQuery,
    useGetAllSubscribersCustomersQuery,
} from "../../api/customerAPI";
import {
    setCustomers,
    setSubscribersCustomers,
} from "../../state/features/customers/customersSlice";
import { useGetSystemConfigQuery } from "../../api/configAPI";
import { setAgizaMode } from "../../state/features/systemConfig/systemConfigSlice";
import { useGetAllSubCategoriesQuery } from "../../api/subCategoryAPI";
import { setSubCategories } from "../../state/features/categories/subCategorySlice";
import { useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

// ########## MUI Custom ############
const PageBgImageCon = styled(Box)({
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
    zIndex: -1,
});

// !################# MAIN FUNC ################
const AppLayout = ({ children }) => {
    const { pathname } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // => Drawer controller
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const [desktopOpen, setDesktopOpen] = useState(false);
    const handleDrawerOpen = () => {
        setDesktopOpen(true);
    };
    const handleDrawerClose = () => {
        setDesktopOpen(false);
    };

    // ############### CONST ########################
    const dispatch = useDispatch();
    const checkNetwork = useNetwork();

    // *############# CONST #############
    const auth = useAuth();

    // => Handle logout
    const handleLogout = () => {
        auth.removeAuthenticatedUser();
    };

    // ################ Fetch main Data ##################
    // => Fetch vendors
    const {
        // isLoading: vendorsLoding,
        isSuccess: vendorsSuccess,
        // isError: vendorsError,
        data: vendors,
    } = useGetAllVendorsQuery();

    // => Fetch stores
    const {
        // isLoading: storesLoding,
        isSuccess: storesSuccess,
        isError: storesError,
        data: stores,
    } = useGetAllStoresQuery();

    // => Fetch products
    const {
        // isLoading: productsLoding,
        isSuccess: productsSuccess,
        // isError: productsError,
        data: products,
    } = useGetAllProductsQuery();

    // => Fetch orders
    const {
        isLoading: ordersLoding,
        isSuccess: ordersSuccess,
        isError: ordersError,
        data: orders,
    } = useGetAllOrdersQuery();

    // => Fetch categories
    const {
        // isLoading: categoriesLoding,
        isSuccess: categoriesSuccess,
        // isError: categoriesError,
        data: categories,
    } = useGetAllCategoriesQuery();

    // => Fetch sub categories
    const {
        // isLoading: categoriesLoding,
        isSuccess: subCategoriesSuccess,
        // isError: categoriesError,
        data: subCategories,
    } = useGetAllSubCategoriesQuery();

    // => Fetch admins
    const { isSuccess: adminsSuccess, data: adminsData } = useGetAdminsQuery();

    // => Fetch delivery guys
    const { isSuccess: deliveryGuySuccess, data: deliveryGuysData } =
        useGetDeliveryGuysQuery();

    // => Fetch customers
    const { isSuccess: customerSuccess, data: customersData } =
        useGetAllCustomersQuery(1);

    // => Fetch customers
    const {
        isSuccess: subscriberCustomerSuccess,
        data: subscriberCustomerData,
    } = useGetAllSubscribersCustomersQuery(1);

    // => Fetch promotions
    const { isSuccess: promotionsSuccess, data: promotionsData } =
        useGetAllPromotionQuery();

    // => Fetch config
    const {
        isLoading: configLoding,
        isSuccess: configSuccess,
        isError: configIsError,
        error: configError,
        data: configData,
    } = useGetSystemConfigQuery();

    // => Dispatch main data
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (vendorsSuccess) {
                dispatch(setVendors(vendors));
            }
            if (storesSuccess) {
                dispatch(setStores(stores));
            }
            if (productsSuccess) {
                dispatch(setProducts(products));
            }
            if (ordersSuccess) {
                dispatch(setOrders(orders));
                dispatch(setOrdersIsLoading(false));
            }
            if (ordersLoding) {
                dispatch(setOrdersIsLoading(true));
            }
            if (ordersError) {
                dispatch(setOrdersIsLoading(false));
            }
            if (categoriesSuccess) {
                dispatch(setCategories(categories));
            }
            if (subCategoriesSuccess) {
                dispatch(setSubCategories(subCategories));
            }
            if (categoriesSuccess) {
                dispatch(setCategories(categories));
            }
            if (adminsSuccess) {
                dispatch(setAdmins(adminsData));
            }
            if (deliveryGuySuccess) {
                dispatch(setDeliveryGuys(deliveryGuysData));
            }
            if (customerSuccess) {
                dispatch(setCustomers(customersData));
            }
            if (subscriberCustomerSuccess) {
                dispatch(setSubscribersCustomers(subscriberCustomerData));
            }
            if (promotionsSuccess) {
                dispatch(setPromotions(promotionsData));
            }
            if (configSuccess) {
                dispatch(setAgizaMode(configData?.data));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        orders,
        stores,
        vendors,
        products,
        categories,
        subCategories,
        subCategoriesSuccess,
        vendorsSuccess,
        storesSuccess,
        ordersSuccess,
        productsSuccess,
        ordersError,
        categoriesSuccess,
        ordersLoding,
        adminsSuccess,
        adminsData,
        deliveryGuySuccess,
        deliveryGuysData,
        promotionsSuccess,
        promotionsData,
        customerSuccess,
        customersData,
        configSuccess,
        configData,
        dispatch,
    ]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (configIsError) {
                if (configError.status === 401) {
                    handleLogout();
                }
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [configIsError, configError]);

    return (
        <>
            {/* {vendorsLoding || storesLoding || ordersLoding || productsLoding ? ( */}
            {ordersLoding || configLoding ? (
                <>
                    <FullAppLoader />
                </>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                    }}
                >
                    {/* ############### Toast ############### */}
                    <HotToastfy />

                    {/* ############### sidebar ###############*/}
                    <SideBar
                        mobileOpen={mobileOpen}
                        desktopOpen={desktopOpen}
                    />

                    {/* ############### Main contents ###############*/}
                    <Box
                        component="main"
                        sx={{
                            height: {
                                sm: "100%",
                                md: "100%",
                            },
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            overflowX: "hidden",
                        }}
                    >
                        {/* ############### topbar ############### */}
                        <TopBar
                            mobileOpen={mobileOpen}
                            desktopOpen={desktopOpen}
                            handleDrawerOpen={handleDrawerOpen}
                            handleDrawerClose={handleDrawerClose}
                            handleDrawerToggle={handleDrawerToggle}
                        />

                        {/* =========== Page background image =========== */}
                        <PageBgImageCon>
                            <img src={logo2} alt="" style={{ opacity: 0.08 }} />
                        </PageBgImageCon>

                        {/* =========== Contents =========== */}
                        {!checkNetwork ? (
                            <>
                                <NoNetworkError />
                            </>
                        ) : (
                            <>
                                {storesError || ordersError ? (
                                    <DataFetchError />
                                ) : (
                                    children
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            )}
        </>
    );
};

export default AppLayout;
