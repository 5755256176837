import { LocationOn } from "@mui/icons-material";
import {
    Avatar,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";

const CustomerMiniWidget = ({ customer }) => {
    return (
        <>
            <Box
                sx={{
                    border: `4px solid ${colors.bgColor1}`,
                    borderRadius: 2.5,
                    overflow: "hidden",
                    transition: ".5s all ease-in-out",
                    "& .card-top": {
                        transition: ".3s all ease-in-out",
                    },
                    // "&:hover": {
                    //     border: `4px solid ${colors.primary}`,
                    //     transition: ".5s all ease-in-out",
                    //     "& .card-top": {
                    //         transform: "scale(1.04)",
                    //         transition: ".3s all ease-in-out",
                    //         background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                    //     },
                    // },
                }}
            >
                <Box className="card-top">
                    <List
                        sx={{
                            width: "100%",
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                            p: 0,
                        }}
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar
                                    src={customer.profile_picture}
                                    sx={{
                                        height: 60,
                                        width: 60,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                        p: 0.5,
                                        mr: 1,
                                        img: {
                                            objectFit: "cover !important",
                                            borderRadius: 50,
                                        },
                                    }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                disableTypography
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                }}
                                secondaryTypographyProps={{
                                    fontSize: 11,
                                }}
                                primary={
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            color: colors.primary,
                                            fontWeight: "bold",
                                        }}
                                    >{`${customer.first_name} ${customer.last_name}`}</Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={{
                                            fontSize: 11,
                                            display: "flex",
                                            alignItems: "center",
                                            color: colors.primary,
                                        }}
                                    >
                                        <LocationOn sx={{ fontSize: 11 }} />
                                        {`${customer.city}, ${customer.country}`}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        position: "relative",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography>{customer?.registration_number}</Typography>
                </Box>
            </Box>
        </>
    );
};

export default CustomerMiniWidget;
