import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductCartWidget from "../../../components/widgets/ProductCartWidget";
import { filter, isEmpty, reverse, size } from "lodash";
import NoContent from "../../../components/NoContent";
import ProductFilter2 from "../../../components/filters/ProductFilter2";
import CustomPaginationTwo from "../../../components/paginations/CustomPaginationTwo";
import CountBadge from "../../../components/CountBadge";
import { paginator } from "../../../helpers/paginationHelper";

const ProductsPagnation = ({ products: allProducts, getProductDetails }) => {
    // ############### Component state ########################
    const [products, setProducts] = useState([]);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ############### FUNC ###########################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Filter by product name
    const filterByProductName = (search) => {
        let filteredProducts = [];
        if (search) {
            filteredProducts = allProducts.filter((product) => {
                return product.title
                    .toLowerCase()
                    .includes(search.toLowerCase());
            });
            return setProducts(filteredProducts);
        }
        return setProducts(allProducts);
    };
    // => Get active products
    const activeProducts = () => {
        let activeProducts = filter(products, { isActive: true });
        return activeProducts;
    };
    const paginatedProducts = paginator(
        reverse(activeProducts()),
        page,
        perPage
    );

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setProducts(allProducts);
        }

        return () => {
            isSubscribed = false;
        };
    }, [allProducts]);

    return (
        <>
            <CustomPaginationTwo
                data={paginatedProducts}
                handlePageChange={handlePageChange}
                handlePerPageChage={handlePerPageChage}
                title={`${size(products)} products`}
                action={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ProductFilter2
                            filterByProductName={filterByProductName}
                        />
                        <CountBadge
                            total={size(filter(products, { isActive: true }))}
                            item={"products"}
                        />
                    </Box>
                }
            >
                <Grid container spacing={2} padding={2}>
                    {isEmpty(products) ? (
                        <>
                            <NoContent
                                message={"Store do not have any product."}
                            />
                        </>
                    ) : (
                        paginatedProducts.data?.map((product, index) => (
                            <Grid item sm={4} xs={12} key={index}>
                                <ProductCartWidget
                                    product={product}
                                    getProductDetails={getProductDetails}
                                />
                            </Grid>
                        ))
                    )}
                </Grid>
            </CustomPaginationTwo>
        </>
    );
};

export default ProductsPagnation;
