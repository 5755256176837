import { createSlice } from '@reduxjs/toolkit';

// => initialState
const initialState = {
    darkMode: false,
    agizaMode: false,
};
export const systemConfigSlice = createSlice({
    name: 'systemConfigSlice',
    initialState,
    reducers: {
        setDarkMode: (state, action) => {
            state.darkMode = action.payload;
        },
        setAgizaMode: (state, action) => {
            let payload = action.payload;
            let status = payload.muted_status;
            state.agizaMode = status;
        },
    },
});

export const { setDarkMode, setAgizaMode } = systemConfigSlice.actions;
export default systemConfigSlice.reducer;
