import getEnv from "../../helpers/getEnv";

// => Local base url
const localBaseUrl_2 = getEnv().REACT_APP_LOCAL_URL;

// => Dev base url
const devBaseUrl = getEnv().REACT_APP_DEV_URL;
const devBaseUrl_2 = getEnv().REACT_APP_DEV_URL;

// => Pro base url
const proBaseUrl = getEnv().REACT_APP_PRODUCTION_URL;
export const proBaseUrl_2 = getEnv().REACT_APP_PRODUCTION_URL_2;

// => Dashboard backend url
export const dashBaseUrl = getEnv().REACT_APP_DASH_URL;

const getBaseURL = () => {
    let env = process.env.REACT_APP_ENV;
    if (env === "pro") {
        return proBaseUrl_2;
    }
    if (env === "local") {
        return localBaseUrl_2;
    }
    return devBaseUrl;
};

const getBaseURL2 = () => {
    let env = process.env.REACT_APP_ENV;
    if (env === "pro") {
        return proBaseUrl_2;
    }
    if (env === "local") {
        return localBaseUrl_2;
    }
    return devBaseUrl_2;
};

export const baseURL = getBaseURL();
export const baseURL2 = getBaseURL2();
