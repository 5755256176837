import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from './baseUrl/baseURL';
import { headers } from './baseUrl/setHeaders';
import { categoriesEndpoints } from './endpoints/categoriesEndpoints';

export const categoryAPI = createApi({
    reducerPath: 'categoryAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    tagTypes: ['Categories'],
    endpoints: (builder) => ({
        // => GET all categories
        getAllCategories: builder.query({
            query: () => `${categoriesEndpoints.GET_ALL_CATEGORIES}`,
            providesTags: ['Categories']
        }),
        // => CREATE category
        createCategory: builder.mutation({
            query: (payload) => ({
                url: `${categoriesEndpoints.GET_ALL_CATEGORIES}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => UPDATE category
        updateCategory: builder.mutation({
            query: (payload) => ({
                url:
                    `${categoriesEndpoints.UPDATE_CATEGORY}` +
                    payload.category_id,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => ACTIVATE category
        activateCategory: builder.mutation({
            query: (payload) => ({
                url: `${categoriesEndpoints.ACTIVATE_CATEGORY}` + payload,
                method: `PUT`,
            }),
        }),
        // => DELETE category
        deleteCategory: builder.mutation({
            query: (payload) => ({
                url: `${categoriesEndpoints.DELETE_CATEGORY}` + payload,
                method: `DELETE`,
            }),
        }),
    }),
});

export const {
    useGetAllCategoriesQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useActivateCategoryMutation,
    useDeleteCategoryMutation,
} = categoryAPI;
