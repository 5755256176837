import React, { useEffect } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Switch,
    Typography,
} from '@mui/material';
import { colors } from '../../../../assets/utils/colors';
import { useBestSellProductMutation, useFeatureProductMutation } from '../../../../api/productAPI';
import { setProductDetails } from '../../../../state/features/products/productSlice';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../../../helpers/RTKHelpers';
import { useDispatch } from 'react-redux';
import { errorNotify, successNotify } from '../../../../helpers/notify';

const FeaturedBestSeller = ({ product }) => {
    // ############## CONST ##############
    const dispatch = useDispatch();

    // ############## RTK ################
    // => Feature product
    const [
        featureProduct,
        {
            isLoading: featuredIsLoading,
            isSuccess: featuredIsSuccess,
            isError: featuredIsError,
            error: featuredError,
            data: featuredProductDetails,
        },
    ] = useFeatureProductMutation();
    // => Best sell product
    const [
        bestSellProduct,
        {
            isLoading: bestSellIsLoading,
            isSuccess: bestSellIsSuccess,
            isError: bestSellIsError,
            error: bestSellError,
            data: bestSellProductDetails,
        },
    ] = useBestSellProductMutation();

    // ############### FUNC ###############
    // => Handle product feature
    const handleProductFeature = async () => {
        featureProduct(product._id);
    };
    // => Handle product best sell
    const handleProductBestSell = async () => {
        bestSellProduct(product._id);
    };

    // ############### useEffect ###########
    // => Featured
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (featuredIsSuccess) {
                successNotify(getRTKSuccessMessage(featuredProductDetails));
                dispatch(
                    setProductDetails(
                        getRTKResponseData(featuredProductDetails),
                    ),
                );
            }
            if (featuredIsError) {
                errorNotify(getRTKErrorMessage(featuredError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        featuredIsSuccess,
        featuredIsError,
        featuredError,
        featuredProductDetails,
        dispatch,
    ]);
    // => Best sell
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (bestSellIsSuccess) {
                successNotify(getRTKSuccessMessage(bestSellProductDetails));
                dispatch(
                    setProductDetails(
                        getRTKResponseData(bestSellProductDetails),
                    ),
                );
            }
            if (bestSellIsError) {
                errorNotify(getRTKErrorMessage(bestSellError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        bestSellIsSuccess,
        bestSellIsError,
        bestSellError,
        bestSellProductDetails,
        dispatch,
    ]);

    return (
        <>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                    my: 1,
                }}
            >
                <Grid container>
                    <Grid item sm={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${colors.bgColor1}`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    textTransform: 'uppercase',
                                    p: 1,
                                    width: '100%',
                                    textAlign: 'center',
                                    border: `1px solid ${colors.bgColor1}`,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                    bgcolor: colors.yellow,
                                }}
                            >
                                Featured
                            </Typography>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    bgcolor: colors.bgColor3,
                                }}
                            >
                                <Button
                                    fullWidth
                                    color="error"
                                    sx={{
                                        boxShadow: 'none',
                                        textTransform: 'capitalize',
                                        fontWeight: 'bold',
                                    }}
                                    onClick={() => {
                                        handleProductFeature();
                                    }}
                                >
                                    {featuredIsLoading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        <Switch
                                            size="small"
                                            checked={product?.isFeatured}
                                        />
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${colors.bgColor1}`,
                                bgcolor: colors.blue,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    textTransform: 'uppercase',
                                    p: 1,
                                    width: '100%',
                                    textAlign: 'center',
                                    border: `1px solid ${colors.bgColor1}`,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                    color: colors.bgColor3,
                                }}
                            >
                                Best seller
                            </Typography>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    bgcolor: colors.bgColor3,
                                }}
                            >
                                <Button
                                    fullWidth
                                    color="error"
                                    sx={{
                                        boxShadow: 'none',
                                        textTransform: 'capitalize',
                                        fontWeight: 'bold',
                                    }}
                                    onClick={() => {
                                        handleProductBestSell();
                                    }}
                                >
                                    {bestSellIsLoading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        <Switch
                                            size="small"
                                            checked={product?.isBestSeller}
                                        />
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default FeaturedBestSeller;
