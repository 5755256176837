import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
} from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';
import { Phone, RemoveRedEye } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const UserWidget1 = ({ user, userType }) => {
    return (
        <>
            <List
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    borderRadius: 2,
                    p: 0.5,
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <ListItem
                    sx={{
                        py: 0,
                        ml: -2,
                    }}
                >
                    <ListItemAvatar>
                        <Avatar
                            src={user.profile_picture}
                            variant="square"
                            sx={{
                                height: 60,
                                width: 60,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                p: 0.5,
                                mr: 1,
                                borderRadius: 2,
                                img: {
                                    height: 60,
                                    width: 60,
                                    objectFit: 'cover',
                                },
                            }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${user.first_name} ${user.last_name}`}
                        secondary={`${user.city}, ${user.country}`}
                        primaryTypographyProps={{
                            fontSize: 14,
                        }}
                        secondaryTypographyProps={{
                            fontSize: 10,
                            fontWeight: 'bold',
                            opacity: 0.8,
                            color: colors.primary,
                            fontStyle: 'italic',
                        }}
                    />
                </ListItem>
                <Box
                    sx={{
                        position: 'absolute',
                        right: 10,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <a href={`tel:` + user.phone_number}>
                        <Tooltip arrow title={`${user.phone_number}`}>
                            <IconButton
                                sx={{
                                    p: 0.8,
                                    bgcolor: colors.bgColor1,
                                    color: colors.primary,
                                    ml: 1,
                                    mb: 0.5,
                                }}
                                size="small"
                            >
                                <Phone
                                    sx={{
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </a>
                    <Link
                        to={
                            userType === 'customer'
                                ? `/customers/details/${user._id}`
                                : userType === 'delivery'
                                ? `/delivery-guys/details/${user._id}`
                                : ''
                        }
                    >
                        <Tooltip arrow title={'Customer details'}>
                            <IconButton
                                sx={{
                                    p: 0.8,
                                    bgcolor: colors.bgColor1,
                                    color: colors.primary,
                                    ml: 1,
                                }}
                                size="small"
                            >
                                <RemoveRedEye
                                    sx={{
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Link>
                </Box>
            </List>
        </>
    );
};

export default UserWidget1;
