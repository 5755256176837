import {
    Box,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../../assets/utils/colors";
import { size } from "lodash";

const CartProductSize = ({ sizes, handler, product }) => {
    // ############### Comp state ####################
    const [selectedSize, setSelectedSize] = useState(null);

    // ############## FUNC #######################
    const getSelectedSize = (e) => {
        setSelectedSize(e.target.value);
    };

    // ############## useEffect ##################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            handler(selectedSize);
            if (size(sizes) === 1) {
                let size = sizes[0];
                setSelectedSize(size.name);
                handler(selectedSize);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [selectedSize]);
    // }, [sizes, handler, selectedSize, product]);

    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    overflow: "auto",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: 12,
                        color: colors.primary,
                        pt: 1,
                        px: 1,
                    }}
                >
                    Select size
                </Typography>
                <Grid container spacing={1} padding={1}>
                    {sizes.map((size, index) => (
                        <Grid item xs key={index}>
                            <RadioGroup
                                value={selectedSize}
                                onChange={(e) => {
                                    getSelectedSize(e);
                                }}
                                sx={{
                                    p: 1,
                                    pb: 0,
                                    borderRadius: 2,
                                    width: "100%",
                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                    border: `1px dotted ${colors.primary}`,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            borderRadius: 2,
                                            p: 1,
                                            border: `1px dotted ${colors.primary}`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                                color: colors.primary,
                                            }}
                                        >
                                            {size.name}
                                        </Typography>
                                    </Box>
                                    <FormControlLabel
                                        sx={{
                                            width: "100%",
                                            ml: 0,
                                            display: "flex",
                                            justifyContent: "center",
                                            p: 0,
                                        }}
                                        value={size.name}
                                        control={
                                            <Radio
                                                size="small"
                                                color="primary"
                                            />
                                        }
                                    />
                                </Box>
                            </RadioGroup>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default CartProductSize;
