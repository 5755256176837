import * as React from "react";
import { colors } from "../../assets/utils/colors";
import { Avatar, Box, Grid, ListItemText, Typography } from "@mui/material";
import { LocationOn, Person2 } from "@mui/icons-material";
import { Link } from "react-router-dom";

// !############### MAIN FUNC #####################
const CustomerWidget5 = ({ customer, type }) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                    borderRadius: 2,
                    mb: 1,
                }}
            >
                <Link to={`/customers/details/${customer._id}`}>
                    <Grid container>
                        <Grid item sm={3} xs={12}>
                            <Box
                                sx={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "relative",
                                }}
                            >
                                <Avatar
                                    src={customer.profile_picture}
                                    sx={{
                                        height: 50,
                                        width: 50,
                                        borderRadius: 50,
                                        border: `2px dotted ${colors.primary}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={9} xs={12}>
                            <Box sx={{ p: 1 }}>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                className="head-font"
                                                sx={{
                                                    fontWeight: "bold",
                                                    color: colors.primary,
                                                    fontSize: 12,
                                                }}
                                            >
                                                {`${customer.first_name} ${customer.last_name}`}
                                            </Typography>
                                        </Box>
                                    }
                                    secondary={
                                        <Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <LocationOn
                                                    sx={{
                                                        fontSize: 13,
                                                        color: colors.primary,
                                                        mr: 0.5,
                                                    }}
                                                />
                                                <Typography
                                                    noWrap
                                                    sx={{
                                                        fontSize: 11,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {`${customer.city}, ${customer?.nationality}`}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                        "space-around",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: 10,
                                                        fontWeight: "bold",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        color: colors.secondary,
                                                    }}
                                                >
                                                    <Person2
                                                        sx={{
                                                            fontSize: 14,
                                                            mr: 0.5,
                                                        }}
                                                    />
                                                    {type}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    }
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Link>
            </Box>
        </React.Fragment>
    );
};

export default CustomerWidget5;
