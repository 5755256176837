import React from "react";
import { Box, Grid, Rating, Typography } from "@mui/material";
import { colors } from "../../../../assets/utils/colors";
import { Remove } from "@mui/icons-material";

const ProductStatus = ({ product }) => {
    return (
        <>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                    mb: 2,
                }}
            >
                <Grid container>
                    <Grid item xs>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                border: `1px solid ${colors.bgColor1}`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    textTransform: "uppercase",
                                    p: 1,
                                    width: "100%",
                                    textAlign: "center",
                                    border: `1px solid ${colors.bgColor1}`,
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                            >
                                Condition
                            </Typography>
                            <Typography
                                sx={{
                                    p: 1,
                                    fontSize: 12,
                                    textTransform: "capitalize",
                                    color: "green",
                                    fontWeight: "bold",
                                }}
                            >
                                {product.condition}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                border: `1px solid ${colors.bgColor1}`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    textTransform: "uppercase",
                                    p: 1,
                                    width: "100%",
                                    textAlign: "center",
                                    border: `1px solid ${colors.bgColor1}`,
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                            >
                                Quantity
                            </Typography>
                            <Typography
                                sx={{
                                    p: 1,
                                    fontSize: 12,
                                }}
                            >
                                {product.quantity}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                border: `1px solid ${colors.bgColor1}`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    textTransform: "uppercase",
                                    p: 1,
                                    width: "100%",
                                    textAlign: "center",
                                    border: `1px solid ${colors.bgColor1}`,
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                            >
                                Whishlisted
                            </Typography>
                            <Typography
                                sx={{
                                    p: 1,
                                    fontSize: 12,
                                }}
                            >
                                {product.wishlist_count}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                border: `1px solid ${colors.bgColor1}`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    textTransform: "uppercase",
                                    p: 1,
                                    width: "100%",
                                    textAlign: "center",
                                    border: `1px solid ${colors.bgColor1}`,
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                            >
                                Rating
                            </Typography>
                            <Typography
                                sx={{
                                    p: 1,
                                    fontSize: 12,
                                }}
                            >
                                {product.rating !== undefined && (
                                    <Rating
                                        sx={{ fontSize: 18 }}
                                        value={product.rating}
                                        readOnly
                                        size="small"
                                        defaultValue={0}
                                    />
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                border: `1px solid ${colors.bgColor1}`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    textTransform: "uppercase",
                                    p: 1,
                                    width: "100%",
                                    textAlign: "center",
                                    border: `1px solid ${colors.bgColor1}`,
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                            >
                                Sub-Category
                            </Typography>
                            <Typography
                                sx={{
                                    p: 1,
                                    fontSize: 12,
                                }}
                            >
                                {product.sub_category_id ? (
                                    <>{product.sub_category_id.title}</>
                                ) : (
                                    <>
                                        <Remove
                                            sx={{ fontSize: 12, opacity: 0.8 }}
                                        />
                                    </>
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ProductStatus;
