import { Box, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import CustomTextField from "../forms/CustomTextField";
import CustomSubmitButton from "../forms/CustomSubmitButton";
import { colors } from "../../assets/utils/colors";
import { useLazyGetCustomerByPhoneQuery } from "../../api/customerAPI";
import { errorNotify, successNotify } from "../../helpers/notify";
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from "../../helpers/RTKHelpers";
import { isEmpty } from "lodash";
import CartCustomerWidgetTwo from "../widgets/CartCustomerWidgetTwo";
// ############ ValidationC+Schema #############
const customerPhoneValidation = Yup.object({
    phone_number: Yup.string()
        .required("phone number is a required field")
        .max(10)
        .min(10),
});

const CustomerSelect = ({ setCustomer }) => {
    // ############ Comp details ###########
    const [fetchedCustomer, setFetchedCustomer] = useState(null);

    // ############ RTK ###################
    const [getCustomerByPhone, { isLoading, isSuccess, isError, error, data }] =
        useLazyGetCustomerByPhoneQuery();

    // ############ useEffect ################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                let customer = getRTKResponseData(data);
                setFetchedCustomer(customer?.data);
                successNotify(getRTKSuccessMessage(data));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, data, error]);

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Grid container justifyContent={"center"} spacing={5}>
                <Grid item sm={10}>
                    <Formik
                        initialValues={{
                            phone_number: "",
                        }}
                        validationSchema={customerPhoneValidation}
                        onSubmit={(payload) => {
                            getCustomerByPhone(payload.phone_number);
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Grid
                                    container
                                    padding={5}
                                    justifyContent={"center"}
                                >
                                    <Grid item sm={10} xs={12}>
                                        <Box
                                            sx={{
                                                height: 250,
                                                borderRadius: 5,
                                                p: 2,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                                            }}
                                        >
                                            {isEmpty(fetchedCustomer) ? (
                                                <Typography
                                                    sx={{
                                                        p: 4,
                                                        fontWeight: "bold",
                                                        color: colors.primary,
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Please provide customer
                                                    phone number below
                                                </Typography>
                                            ) : (
                                                <CartCustomerWidgetTwo
                                                    customer={fetchedCustomer}
                                                    handler={setCustomer}
                                                />
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item sm={7} xs={12}>
                                        <CustomTextField
                                            type={"text"}
                                            name="phone_number"
                                            label={"Customer phone"}
                                        />
                                    </Grid>
                                    <Grid item sm={7} xs={12}>
                                        <Box sx={{ mt: 2 }}>
                                            <CustomSubmitButton
                                                loading={isLoading}
                                                onClick={formik.handleSubmit}
                                            >
                                                Search customer
                                            </CustomSubmitButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomerSelect;
