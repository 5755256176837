import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import AdminsSidebar from "../../../components/sidebars/AdminsSidebar";
import AdminWidget from "../../../components/widgets/AdminWidget";
import PageLayout from "../../layouts/PageLayout";
import CustomPaginationTwo from "../../../components/paginations/CustomPaginationTwo";
import { useSelector } from "react-redux";
import { adminsSelector } from "../../../state/features/selectors";
import { isEmpty, size } from "lodash";
import NoContent from "../../../components/NoContent";
import CountBadge from "../../../components/CountBadge";
import AdminsFilter from "../../../components/filters/AdminsFilter";
import { paginator } from "../../../helpers/paginationHelper";

const Admins = () => {
    // ############### Component state ###################
    const [admins, setAdmins] = useState([]);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ######### Redux states #############
    const { admins: allAdmins } = useSelector(adminsSelector);

    // ############## FILTER ###################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Filter by name/id
    const filterByNameOrID = (search) => {
        let admins = [];
        if (search) {
            admins = allAdmins.filter((delivery_guy) => {
                return (
                    delivery_guy.login_id
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    delivery_guy.first_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    delivery_guy.last_name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                );
            });
            return setAdmins(admins);
        }
        return setAdmins(allAdmins);
    };

    // ############## useEffect ################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setAdmins(allAdmins);
        }
        return () => {
            isSubscribed = false;
        };
    }, [setAdmins, allAdmins]);
    const paginatedAdmins = paginator(admins, page, perPage);

    return (
        <>
            <PageLayout
                sidebar={<AdminsSidebar />}
                pageHead={"Agiza africa admins"}
            >
                <CustomPaginationTwo
                    title={"Registered admins"}
                    data={paginatedAdmins}
                    handlePageChange={handlePageChange}
                    handlePerPageChage={handlePerPageChage}
                    action={
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: 350,
                            }}
                        >
                            <AdminsFilter filterByNameOrID={filterByNameOrID} />
                            <CountBadge total={size(admins)} item={"Admins"} />
                        </Box>
                    }
                >
                    {isEmpty(admins) ? (
                        <>
                            <NoContent message={"No admin added"} />
                        </>
                    ) : (
                        <Grid container spacing={3} padding={2}>
                            {admins?.map((admin) => (
                                <Grid item sm={4} key={admin._id}>
                                    <AdminWidget admin={admin} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </CustomPaginationTwo>
            </PageLayout>
        </>
    );
};

export default Admins;
