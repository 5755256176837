import { LocationOn, Message, Phone, WcOutlined } from '@mui/icons-material';
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';

const CustomerWidget2 = ({ customer }) => {
    return (
        <>
            <Box
                sx={{
                    'width': '100%',
                    'overflow': 'hidden',
                    'transition': '.5s all ease-in-out',
                    'display': 'flex',
                    'justifyContent': 'space-between',
                    'alignItems': 'center',
                    '& .card-top': {
                        transition: '.3s all ease-in-out',
                    },
                    'background': `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                }}
            >
                <Box className="card-top">
                    <List
                        sx={{
                            width: '100%',
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                            p: 0,
                        }}
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar
                                    src={customer.profile_picture}
                                    sx={{
                                        height: 60,
                                        width: 60,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                        p: 0.5,
                                        mr: 1,
                                        border: `2px solid ${colors.bgColor1}`,
                                        img: {
                                            objectFit: 'cover !important',
                                            borderRadius: 50
                                        },
                                    }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                disableTypography
                                secondaryTypographyProps={{
                                    fontSize: 11,
                                }}
                                primary={
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            opacity: 0.8,
                                        }}
                                    >{`${customer.first_name} ${customer.last_name}`}</Typography>
                                }
                                secondary={
                                    <>
                                        <Typography
                                            sx={{
                                                fontSize: 11,
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            <WcOutlined sx={{ fontSize: 14 }} />
                                            {`${customer.gender}`}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 11,
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: colors.primary,
                                            }}
                                        >
                                            <LocationOn sx={{ fontSize: 11 }} />
                                            {`${customer.city} - ${customer.country}`}
                                        </Typography>
                                    </>
                                }
                            />
                        </ListItem>
                    </List>
                </Box>
                <Box
                    sx={{
                        p: 1.5,
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        borderRadius: 2.5,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    }}
                >
                    <Tooltip title={customer.phone_number} arrow>
                        <a href={`tel:${customer.phone_number}`}>
                            <IconButton
                                sx={{
                                    ml: 1,
                                    p: 0.8,
                                    color: colors.primary,
                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                }}
                                size="small"
                            >
                                <Phone
                                    sx={{
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        </a>
                    </Tooltip>
                    <Tooltip title={customer.email} arrow>
                        <a href={`mailTo:${customer.email}`}>
                            <IconButton
                                sx={{
                                    ml: 1,
                                    p: 0.8,
                                    color: colors.primary,
                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                }}
                                size="small"
                            >
                                <Message
                                    sx={{
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        </a>
                    </Tooltip>
                </Box>
                {/* <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                textAlign: 'center',
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                width: '100%',
                                p: 1,
                            }}
                        >
                            <Typography
                                sx={{ fontSize: 12, fontWeight: 'bold' }}
                            >
                                34
                            </Typography>
                            <Typography sx={{ fontSize: 10 }}>
                                Wishlists
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                textAlign: 'center',
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                width: '100%',
                                p: 1,
                            }}
                        >
                            <Typography
                                sx={{ fontSize: 12, fontWeight: 'bold' }}
                            >
                                34
                            </Typography>
                            <Typography sx={{ fontSize: 10 }}>
                                Orders
                            </Typography>
                        </Box>
                    </Box> */}
            </Box>
        </>
    );
};

export default CustomerWidget2;
