import React from 'react';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { colors } from '../../../../assets/utils/colors';
import {
    Pending,
    QuestionMarkOutlined,
    Verified,
    Warning,
} from '@mui/icons-material';

const ApprovalActiveStatus = ({ product }) => {
    return (
        <>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                    my: 1,
                }}
            >
                <Grid container>
                    <Grid item sm={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${colors.bgColor1}`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    textTransform: 'uppercase',
                                    p: 1,
                                    width: '100%',
                                    textAlign: 'center',
                                    border: `1px solid ${colors.bgColor1}`,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                }}
                            >
                                Approval status
                            </Typography>
                            <Typography
                                sx={{
                                    p: 1,
                                    fontSize: 12,
                                    textTransform: 'capitalize',
                                    width: '100%',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 'bold',
                                    bgcolor: product.isApproved
                                        ? colors.green
                                        : product.isDenied
                                        ? colors.red
                                        : colors.secondary,
                                    color: product.isApproved
                                        ? '#000'
                                        : product.isDenied
                                        ? colors.bgColor3
                                        : '#000',
                                    position: 'relative',
                                }}
                            >
                                {product.isApproved ? (
                                    <>
                                        <Verified
                                            sx={{
                                                mr: 1,
                                                fontSize: 17,
                                                color: colors.primary,
                                            }}
                                        />
                                        Approved
                                    </>
                                ) : product.isDenied ? (
                                    <>
                                        <Warning sx={{ mr: 1, fontSize: 17 }} />
                                        Denied
                                    </>
                                ) : (
                                    <>
                                        <Pending sx={{ mr: 1, fontSize: 17, color: colors.bgColor3 }} />
                                        Pending
                                    </>
                                )}
                                {product.isDenied && (
                                    <IconButton
                                        size="small"
                                        sx={{
                                            position: 'absolute',
                                            right: 0,
                                            color: colors.bgColor3,
                                        }}
                                    >
                                        <Tooltip
                                            title={product.deny_reason}
                                            arrow
                                        >
                                            <QuestionMarkOutlined
                                                sx={{ mr: 1, fontSize: 12 }}
                                            />
                                        </Tooltip>
                                    </IconButton>
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${colors.bgColor1}`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    textTransform: 'uppercase',
                                    p: 1,
                                    width: '100%',
                                    textAlign: 'center',
                                    border: `1px solid ${colors.bgColor1}`,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                }}
                            >
                                Active status
                            </Typography>
                            <Typography
                                sx={{
                                    p: 1,
                                    fontSize: 12,
                                    textTransform: 'capitalize',
                                    bgcolor: product.isSuspended
                                        ? colors.secondary
                                        : product.isActive
                                        ? colors.green
                                        : colors.red,
                                    width: '100%',
                                    textAlign: 'center',
                                    color: product.isSuspended
                                        ? '#000'
                                        : product.isActive
                                        ? '#000'
                                        : colors.bgColor3,
                                    fontWeight: 'bold',
                                }}
                            >
                                {product.isSuspended
                                    ? 'Suspended'
                                    : product.isActive
                                    ? 'Active'
                                    : 'In active'}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ApprovalActiveStatus;
