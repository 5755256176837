import { createSlice } from '@reduxjs/toolkit';

// => initialstate
const initialState = {
    smsBalance: 0,
};

//! ############ SLICE ##############
const beemSlice = createSlice({
    name: 'beemSlice',
    initialState,
    reducers: {
        setSmsBalance: (state, action) => {
            state.smsBalance = action.payload;
        },
    },
});

export const { setSmsBalance } = beemSlice.actions;
export default beemSlice.reducer;
