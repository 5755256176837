import { PersonAddAlt } from "@mui/icons-material";
import { Box, Button, Grid, styled } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../assets/utils/colors";
import CreateDeliveryGuy from "../../pages/users/deliveryGuys/CreateDeliveryGuy";
import CustomDialog from "../dialogs/CustomDialog";

// ################ DeliveryGuysBar ###############
const DeliveryGuysBar = styled(Box)(({ theme }) => ({
    "& .section-head": {
        fontSize: 16,
        fontWeight: "bold",
        position: "relative",
        marginBottom: 15,
        color: colors.primary,
        "&::before": {
            content: "''",
            height: 2.5,
            width: 50,
            position: "absolute",
            bottom: -3,
            borderRadius: 110,
            background: colors.bgColor1,
        },
    },
}));

const DeliveryGuysSidebar = () => {
    const [openDialog, setOpenDialog] = useState(false);

    // ############ FUNC ###############
    const handleDialogyOpen = () => {
        setOpenDialog(true);
    };
    const handleDialogyClose = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Box sx={{ display: "flex", width: `100%` }}>
                <CustomDialog
                    openDialog={openDialog}
                    handleDialogClose={handleDialogyClose}
                    head={"Add agiza africa delivery guy"}
                >
                    <CreateDeliveryGuy />
                </CustomDialog>

                <Box sx={{ flex: 1 }}>
                    <Button
                        startIcon={<PersonAddAlt />}
                        variant="contained"
                        fullWidth
                        onClick={handleDialogyOpen}
                        sx={{
                            borderRadius: 2,
                            height: 80,
                            textTransform: "capitalize",
                            border: `10px solid ${colors.bgColor1}`,
                            color: colors.primary,
                            fontWeight: "bold",
                            boxShadow: "none !important",
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                            ":hover": {
                                color: colors.secondary,
                            },
                        }}
                    >
                        Add new delivery guy
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default DeliveryGuysSidebar;
