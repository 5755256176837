import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { colors } from '../../assets/utils/colors';
import {
    Avatar,
    Box,
    Chip,
    Grid,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { Store } from '@mui/icons-material';
import { currencyFomater } from '../../helpers/currencyFomater';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

const columns = [{ id: 's_n', label: 'S/N', minWidth: 0 }];

function createData(s_n) {
    return {
        s_n,
    };
}

// !############### MAIN FUNC #####################
const OrderedProductTable = ({ products, height }) => {
    const [page] = React.useState(0);
    const [rowsPerPage] = React.useState(10);

    const rows = products?.map((product) => {
        return createData(
            <Box
                sx={{
                    borderBottom: `5px solid ${colors.bgColor1}`,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                }}
            >
                <Grid container>
                    <Grid item sm={3} xs={6} order={{xs:1}}>
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Avatar
                                variant="square"
                                sx={{
                                    height: 125,
                                    width: 125,
                                    borderRadius: 5,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                }}
                                // src={
                                //     'https://asia.olympus-imaging.com/content/000107507.jpg'
                                // }
                                src={product.banner_image}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12} order={{xs:3}}>
                        <Box sx={{ p: 1 }}>
                            <Box>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Link
                                            to={`/products/details/${product._id}`}
                                        >
                                            <Tooltip
                                                title={'Product details'}
                                                arrow
                                            >
                                                <Typography
                                                    className="head-font"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        color: colors.primary,
                                                    }}
                                                >
                                                    {product.title}
                                                </Typography>
                                            </Tooltip>
                                        </Link>
                                    }
                                    secondary={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Store
                                                sx={{
                                                    fontSize: 18,
                                                    color: colors.primary,
                                                    mr: 0.5,
                                                }}
                                            />
                                            <Link
                                                to={`/stores/details/${product.store_id}`}
                                            >
                                                <Tooltip
                                                    title={'Store details'}
                                                    arrow
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 11,
                                                            fontWeight: 'bold',
                                                            color:
                                                                colors.primary,
                                                        }}
                                                    >
                                                        By {product.store_name}
                                                    </Typography>
                                                </Tooltip>
                                            </Link>
                                        </Box>
                                    }
                                />
                            </Box>
                            <Box sx={{ mb: 2, mt: 2 }}>
                                <Typography
                                    sx={{ fontSize: 12, fontWeight: 'bold' }}
                                >
                                    Description
                                </Typography>
                                <Chip
                                    label={`Size ${product.selected_size}`}
                                    size="small"
                                    sx={{ fontSize: 12, m: 0.5 }}
                                />
                                <Chip
                                    label={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 13,
                                                    opacity: 0.9,
                                                }}
                                            >
                                                Color
                                            </Typography>
                                            <Box
                                                sx={{
                                                    height: 15,
                                                    width: 15,
                                                    m: 0.5,
                                                    borderRadius: 50,
                                                    border: `1px solid ${colors.primary}`,
                                                    bgcolor:
                                                        product.selected_color,
                                                }}
                                            ></Box>
                                        </Box>
                                    }
                                    size="small"
                                    sx={{ fontSize: 12, m: 0.5 }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={3} xs={6} order={{xs:2}}>
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                p: 1,
                            }}
                        >
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        mb: 0.5,
                                    }}
                                >
                                    Quantity
                                </Typography>
                                <Typography
                                    className="head-font"
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    {product.ordered_quantity}
                                </Typography>
                            </Box>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        mb: 0.5,
                                    }}
                                >
                                    Subtotal
                                </Typography>
                                <Typography
                                    noWrap
                                    className="head-font"
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    {currencyFomater(product.price)}
                                    <sup
                                        style={{
                                            fontSize: 9,
                                            color: colors.primary,
                                        }}
                                    >
                                        TZS
                                    </sup>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>,
        );
    });

    return (
        <React.Fragment>
            <Paper
                sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}
            >
                {isEmpty(products) ? (
                    ''
                ) : (
                    <TableContainer>
                        <Table stickyHeader>
                            <TableBody>
                                {rows
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage,
                                    )
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                {columns.map((column) => {
                                                    const value =
                                                        row[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            sx={{
                                                                p:
                                                                    '0 !important',
                                                            }}
                                                        >
                                                            {column.format &&
                                                            typeof value ===
                                                                'number'
                                                                ? column.format(
                                                                      value,
                                                                  )
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Paper>
        </React.Fragment>
    );
};

export default OrderedProductTable;
