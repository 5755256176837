import { Box, Button, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../assets/utils/colors";
import CustomCard from "../cards/CustomCard";
import { isEmpty, orderBy, size, take } from "lodash";
import { useSelector } from "react-redux";
import { customersSelector } from "../../state/features/selectors";
import InnerContentLoader from "../InnerContentLoader";
import CustomerWidget4 from "../widgets/CustomerWidget4";
import { PersonAddAlt } from "@mui/icons-material";
import CustomDialog from "../dialogs/CustomDialog";
import CreateCustomer from "../../pages/users/customers/CreateCustomer";

const CustomersSidebar = () => {
    // ############### Comp state ####################
    const [openDialog, setOpenDialog] = useState(false);

    // ######### Redux states #############
    const { customers: allCustomers } = useSelector(customersSelector);

    // ############### FUNC ########################
    // => Vendor with most stores
    const customerWithMostOrders = () => {
        let customer = take(
            orderBy(allCustomers, ["customer_orders"], ["desc"]),
            4
        );
        return customer;
    };

    const handleDialogyOpen = () => {
        setOpenDialog(true);
    };
    const handleDialogyClose = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <CustomDialog
                openDialog={openDialog}
                handleDialogClose={handleDialogyClose}
                head={"Add agiza africa customer"}
            >
                <CreateCustomer />
            </CustomDialog>

            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        flexDirection: "column",
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            color: colors.primary,
                        }}
                    >
                        Customer statistics
                    </Typography>
                </Box>
                <Box sx={{ flex: 1, p: 1 }}>
                    {isEmpty(allCustomers) ? (
                        <InnerContentLoader height={"100%"} />
                    ) : (
                        <CustomCard bgColor={colors.bgColor3}>
                            <Typography
                                sx={{
                                    p: 0.5,
                                    pl: 1.5,
                                    fontSize: 12,
                                    opacity: 0.8,
                                    fontWeight: "bold",
                                }}
                            >
                                Customer with most orders
                            </Typography>
                            {customerWithMostOrders().map((customer, index) => (
                                <CustomerWidget4
                                    key={index}
                                    type={"orders"}
                                    customer={customer}
                                    total={size(customer.customer_orders)}
                                />
                            ))}
                        </CustomCard>
                    )}
                </Box>
                <Box>
                    <Button
                        startIcon={<PersonAddAlt />}
                        variant="contained"
                        fullWidth
                        onClick={handleDialogyOpen}
                        sx={{
                            borderRadius: 2,
                            height: 80,
                            textTransform: "capitalize",
                            border: `10px solid ${colors.bgColor1}`,
                            color: colors.primary,
                            fontWeight: "bold",
                            boxShadow: "none !important",
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                            ":hover": {
                                color: colors.secondary,
                            },
                        }}
                    >
                        Add new customer
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default CustomersSidebar;
