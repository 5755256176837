import { Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import CustomCard from "../cards/CustomCard";
import { currencyFomater } from "../../helpers/currencyFomater";
import { getCommission, getRevenue } from "../../helpers/financeHelper";

const FinanceSidebar = ({ orders }) => {
    const revenue = getRevenue(orders);
    const commission = getCommission(orders);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    p: 1,
                    px: 2,
                }}
            >
                <Typography
                    className="head-font"
                    sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        position: "relative",
                        color: colors.primary,
                        "&::before": {
                            content: "''",
                            height: 2.5,
                            width: 50,
                            position: "absolute",
                            bottom: -3,
                            borderRadius: 110,
                            background: colors.bgColor1,
                        },
                    }}
                >
                    Revenue & Commission Overview
                </Typography>
            </Box>
            <CustomCard height={"100%"} bgColor={colors.bgColor3}>
                <Box
                    sx={{
                        pt: 2,
                        pb: 2,
                        px: 1,
                        borderRadius: 4,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 12,
                                textAlign: "center",
                                fontWeight: "bold",
                                color: colors.primary,
                                textTransform: "uppercase",
                            }}
                        >
                            Expected revenue
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 15,
                                textAlign: "center",
                                fontWeight: "bold",
                                mt: 0.5,
                                opacity: 0.7,
                            }}
                            className="head-font"
                        >
                            TZS {currencyFomater(revenue.expected)}
                        </Typography>
                    </Box>
                    <Box mt={3}>
                        <Grid container spacing={1.5}>
                            <Grid item sm={6} xs={6}>
                                <Box
                                    sx={{
                                        py: 1,
                                        borderRadius: 3,
                                        borderBottom: `3px solid ${colors.secondary}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: colors.secondary,
                                        }}
                                    >
                                        Pending
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            mt: 0.5,
                                            opacity: 0.7,
                                        }}
                                        className="head-font"
                                    >
                                        {currencyFomater(revenue.pending)}
                                        <sup
                                            style={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                                color: colors.primary,
                                            }}
                                        >
                                            TZS
                                        </sup>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <Box
                                    sx={{
                                        py: 1,
                                        borderRadius: 3,
                                        borderBottom: `3px solid ${colors.red}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: colors.red,
                                        }}
                                    >
                                        Re-Funded
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            mt: 0.5,
                                            opacity: 0.7,
                                        }}
                                        className="head-font"
                                    >
                                        {currencyFomater(revenue.refunded)}
                                        <sup
                                            style={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                                color: colors.primary,
                                            }}
                                        >
                                            TZS
                                        </sup>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={12} xs={6}>
                                <Box
                                    sx={{
                                        py: 1,
                                        borderRadius: 3,
                                        borderBottom: `3px solid ${colors.green}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: colors.primary,
                                        }}
                                    >
                                        Collected
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            mt: 0.5,
                                            opacity: 0.7,
                                        }}
                                        className="head-font"
                                    >
                                        {currencyFomater(revenue.collected)}
                                        <sup
                                            style={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                                color: colors.primary,
                                            }}
                                        >
                                            TZS
                                        </sup>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={12} xs={6}>
                                <Box
                                    sx={{
                                        py: 1,
                                        borderRadius: 2,
                                        border: `3px dotted ${colors.primary}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: colors.primary,
                                        }}
                                    >
                                        Actual revenue
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 15,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            mt: 0.5,
                                            opacity: 0.7,
                                        }}
                                        className="head-font"
                                    >
                                        {currencyFomater(revenue.collected)}
                                        <sup
                                            style={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                                color: colors.primary,
                                            }}
                                        >
                                            TZS
                                        </sup>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {/* ####################################### Commission ############################### */}
                <Box
                    sx={{
                        mt: 2,
                        pt: 2,
                        pb: 2,
                        px: 1,
                        borderRadius: 4,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 12,
                                textAlign: "center",
                                fontWeight: "bold",
                                color: colors.primary,
                                textTransform: "uppercase",
                            }}
                        >
                            Expected Commission
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 15,
                                textAlign: "center",
                                fontWeight: "bold",
                                mt: 0.5,
                                opacity: 0.7,
                            }}
                            className="head-font"
                        >
                            TZS {currencyFomater(commission.expected)}
                        </Typography>
                    </Box>
                    <Box mt={3}>
                        <Grid container spacing={1.5}>
                            <Grid item sm={6} xs={6}>
                                <Box
                                    sx={{
                                        py: 1,
                                        borderRadius: 3,
                                        borderBottom: `3px solid ${colors.secondary}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: colors.secondary,
                                        }}
                                    >
                                        Pending
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            mt: 0.5,
                                            opacity: 0.7,
                                        }}
                                        className="head-font"
                                    >
                                        {currencyFomater(commission.pending)}
                                        <sup
                                            style={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                                color: colors.primary,
                                            }}
                                        >
                                            TZS
                                        </sup>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <Box
                                    sx={{
                                        py: 1,
                                        borderRadius: 3,
                                        borderBottom: `3px solid ${colors.red}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: colors.red,
                                        }}
                                    >
                                        Re-Funded
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            mt: 0.5,
                                            opacity: 0.7,
                                        }}
                                        className="head-font"
                                    >
                                        {currencyFomater(commission.refunded)}
                                        <sup
                                            style={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                                color: colors.primary,
                                            }}
                                        >
                                            TZS
                                        </sup>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={12} xs={6}>
                                <Box
                                    sx={{
                                        py: 1,
                                        borderRadius: 3,
                                        borderBottom: `3px solid ${colors.green}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: colors.primary,
                                        }}
                                    >
                                        Collected
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            mt: 0.5,
                                            opacity: 0.7,
                                        }}
                                        className="head-font"
                                    >
                                        {currencyFomater(commission.collected)}
                                        <sup
                                            style={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                                color: colors.primary,
                                            }}
                                        >
                                            TZS
                                        </sup>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={12} xs={6}>
                                <Box
                                    sx={{
                                        py: 1,
                                        borderRadius: 2,
                                        border: `3px dotted ${colors.primary}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: colors.primary,
                                        }}
                                    >
                                        Actual Commission
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 15,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            mt: 0.5,
                                            opacity: 0.7,
                                        }}
                                        className="head-font"
                                    >
                                        {currencyFomater(commission.collected)}
                                        <sup
                                            style={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                                color: colors.primary,
                                            }}
                                        >
                                            TZS
                                        </sup>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </CustomCard>
        </>
    );
};

export default FinanceSidebar;
