import { Box, Typography } from '@mui/material';
import React from 'react';

const DashOrdersStats = ({ title, total, bgColor, textColor, type }) => {
    return (
        <>
            <Box
                sx={{
                    height: 50,
                    bgcolor: bgColor,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    borderRadius: 2,
                }}
            >
                <Typography sx={{ fontSize: 10, color: textColor }}>
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: textColor,
                    }}
                >
                    ({total}) {type ? type : ` Orders`}
                </Typography>
            </Box>
        </>
    );
};

export default DashOrdersStats;
