import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { colors } from "../../../../assets/utils/colors";

const ProductImages = ({ bannerImage, moreImages }) => {
    // => Image preview state
    const [imagePreview, setImagePreview] = useState("");

    // => Func
    const handleImagePreview = (image) => {
        setImagePreview(image);
    };

    // => useEffect
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setImagePreview(bannerImage);
        }
        return () => {
            isSubscribed = false;
        };
    }, [bannerImage]);

    return (
        <>
            <Box
                sx={{
                    p: 1,
                    display: "flex",
                    borderRadius: 5,
                    position: "relative",
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                }}
                gap={1}
            >
                <Box sx={{ flex: 2 }}>
                    <img
                        src={imagePreview}
                        alt=""
                        style={{
                            height: 300,
                            width: "100%",
                            objectFit: "cover",
                            borderRadius: 15,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        borderRadius: 5,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                    }}
                >
                    <Grid container justifyContent={"space-around"}>
                        <Grid item sm={4}>
                            <Button
                                sx={{
                                    transition: `.3s all ease-in-out`,
                                    ":hover": {
                                        transition: `.3s all ease-in-out`,
                                        transform: `scale(1.05)`,
                                    },
                                }}
                                onClick={() => {
                                    handleImagePreview(bannerImage);
                                }}
                            >
                                <Box
                                    sx={{
                                        height: 70,
                                    }}
                                >
                                    <img
                                        src={bannerImage}
                                        alt=""
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "cover",
                                            borderRadius: 10,
                                        }}
                                    />
                                </Box>
                            </Button>
                        </Grid>
                        {moreImages?.map((image, index) => (
                            <Grid item sm={4} key={index}>
                                <Button
                                    sx={{
                                        transition: `.3s all ease-in-out`,
                                        ":hover": {
                                            transition: `.3s all ease-in-out`,
                                            transform: `scale(1.05)`,
                                        },
                                    }}
                                    onClick={() => {
                                        handleImagePreview(image.filePath);
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: 70,
                                        }}
                                    >
                                        <img
                                            src={image.filePath}
                                            alt=""
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                objectFit: "cover",
                                                borderRadius: 15,
                                            }}
                                        />
                                    </Box>
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default ProductImages;
