import { Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import CustomCard from "../cards/CustomCard";
import VendorWidget3 from "../widgets/VendorWidget3";
import {
    storesSelector,
    vendorsSelector,
} from "../../state/features/selectors";
import { useSelector } from "react-redux";
import { isEmpty, orderBy, size, take } from "lodash";
import InnerContentLoader from "../InnerContentLoader";

const VendorsSidebar = () => {
    // ############### Redux state ########################
    const { vendors } = useSelector(vendorsSelector);
    const { stores } = useSelector(storesSelector);

    // ############### FUNC ########################
    // => Vendor with most stores
    const vendorWithMostStores = () => {
        let vendor = take(orderBy(vendors, ["vendor_stores"], ["desc"]), 1);
        let data = {
            vendor: vendor[0],
            total: size(vendor[0].vendor_stores),
        };
        return data;
    };

    // => Vendor with most products
    const vendorWithMostProducts = () => {
        let vendor = take(orderBy(stores, ["store_products"], ["desc"]), 1);
        let data = {
            vendor: vendor[0].vendor_id,
            total: size(vendor[0].store_products),
        };
        return data;
    };

    // => Vendor with most orders
    const vendorWithMostOrders = () => {
        let vendor = take(orderBy(stores, ["store_orders"], ["desc"]), 1);
        let data = {
            vendor: vendor[0].vendor_id,
            total: size(vendor[0].store_orders),
        };
        return data;
    };

    return (
        <>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Box
                    sx={{
                        p: 2,
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        flexDirection: "column",
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            color: colors.primary,
                        }}
                    >
                        Vendors statistics
                    </Typography>
                </Box>
                <Box sx={{ flex: 1, p: 1 }}>
                    {isEmpty(stores) ? (
                        <InnerContentLoader height={"100%"} />
                    ) : (
                        <CustomCard bgColor={colors.bgColor3}>
                            <CustomCard bgColor={colors.bgColor3}>
                                <Typography
                                    sx={{
                                        p: 0.5,
                                        pl: 1.5,
                                        fontSize: 12,
                                        opacity: 0.8,
                                        fontWeight: "bold",
                                    }}
                                >
                                    Vendor with most stores
                                </Typography>
                                {isEmpty(vendors) ? (
                                    <InnerContentLoader />
                                ) : (
                                    <VendorWidget3
                                        type={"store"}
                                        data={vendorWithMostStores()}
                                    />
                                )}
                            </CustomCard>
                            <Box sx={{ mt: 2 }}>
                                <CustomCard bgColor={colors.bgColor3}>
                                    <Typography
                                        sx={{
                                            p: 0.5,
                                            pl: 1.5,
                                            fontSize: 12,
                                            opacity: 0.8,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Vendor with most products
                                    </Typography>
                                    {isEmpty(stores) ? (
                                        <InnerContentLoader />
                                    ) : (
                                        <VendorWidget3
                                            type={"product"}
                                            data={vendorWithMostProducts()}
                                        />
                                    )}
                                </CustomCard>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <CustomCard bgColor={colors.bgColor3}>
                                    <Typography
                                        sx={{
                                            p: 0.5,
                                            pl: 1.5,
                                            fontSize: 12,
                                            opacity: 0.8,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Vendor with most orders
                                    </Typography>
                                    {isEmpty(stores) ? (
                                        <InnerContentLoader />
                                    ) : (
                                        <VendorWidget3
                                            data={vendorWithMostOrders()}
                                        />
                                    )}
                                </CustomCard>
                            </Box>
                        </CustomCard>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default VendorsSidebar;
