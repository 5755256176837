import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';

// => initialState
const initialState = {
    promotions: [],
};

export const promotionsSlice = createSlice({
    name: 'promotionsSlice',
    initialState,
    reducers: {
        setPromotions: (state, action) => {
            let promotions = action.payload.data;
            state.promotions = promotions;
        },
        addPromotion: (state, action) => {
            let promotion = action.payload;
            state.promotions = [...state.promotions, promotion];
        },
        removePromotion: (state, action) => {
            let newPromos = filter(
                state.promotions,
                ({ _id }) => _id !== action.payload,
            );
            state.promotions = newPromos;
        },
    },
});

export const {
    setPromotions,
    addPromotion,
    removePromotion,
} = promotionsSlice.actions;
export default promotionsSlice.reducer;
