import React from "react";
import { Route, Routes } from "react-router-dom";
import Welcome from "../pages/Welcome";
import Dashboard from "../pages/Dashboard";
import AppLayout from "../pages/layouts/AppLayout";
import Roles from "../pages/settings/Roles";
import Customers from "../pages/users/customers/Customers";
import Vendors from "../pages/users/vendors/Vendors";
import Products from "../pages/menu/products/Products";
import Orders from "../pages/menu/orders/Orders";
import DeliveryGuys from "../pages/users/deliveryGuys/DeliveryGuys";
import PrivateRoutes from "./PrivateRoutes";
import GuestRoutes from "./GuestRoutes";
import OrderDetails from "../pages/menu/orders/OrderDetails";
import Stores from "../pages/menu/stores/Stores";
import StoreDetails from "../pages/menu/stores/StoreDetails";
import VendorDetails from "../pages/users/vendors/VendorDetails";
import ProductDetails from "../pages/menu/products/ProductDetails";
import ErrorPage404 from "../pages/errors/ErrorPage404";
import Admins from "../pages/users/admins/Admins";
import Categories from "../pages/settings/Categories";
import Regions from "../pages/settings/Regions";
import Promotions from "../pages/menu/promotions/Promotions";
import StoresByStatus from "../pages/menu/stores/StoresByStatus";
import OrdersByStatus from "../pages/menu/orders/OrdersByStatus";
import ProductsByStatus from "../pages/menu/products/ProductsByStatus";
import DeliveryGuyDetails from "../pages/users/deliveryGuys/DeliveryGuyDetails";
import CustomerDetails from "../pages/users/customers/CustomerDetails";
import Finance from "../pages/menu/finance/Finance";
import AdminDetails from "../pages/users/admins/AdminDetails";
import Notification from "../pages/notification/Notification";
import StatisticsAndReportsPage from "../pages/statisticsAndReports/StatisticsAndReportsPage";
import ProfilePage from "../pages/profile/ProfilePage";

// ?############## GUEST ELEMENTS ###########
const WelcomeElement = () => <Welcome />;

// ?############## ERROR ELEMENTS ###########
// -> Dashboard
const ErrorPage404Element = () => (
    <AppLayout>
        <ErrorPage404 />
    </AppLayout>
);

// ?############## PROTECTED ELEMENTS ###########
// -> Dashboard
const DashboardElement = () => (
    <AppLayout>
        <Dashboard />
    </AppLayout>
);
// -> Orders
const OrdersElement = () => (
    <AppLayout>
        <Orders />
    </AppLayout>
);
// -> Orders
const OrdersByStatusElement = () => (
    <AppLayout>
        <OrdersByStatus />
    </AppLayout>
);
// -> OrderDetails
const OrderDetailsElement = () => (
    <AppLayout>
        <OrderDetails />
    </AppLayout>
);
// -> Stores
const StoresElement = () => (
    <AppLayout>
        <Stores />
    </AppLayout>
);
// -> StoresByStatusElement
const StoresByStatusElement = () => (
    <AppLayout>
        <StoresByStatus />
    </AppLayout>
);
// -> StoresDetails
const StoresDetailsElement = () => (
    <AppLayout>
        <StoreDetails />
    </AppLayout>
);
// -> Products
const ProductsElement = () => (
    <AppLayout>
        <Products />
    </AppLayout>
);
// -> ProductByStatusElement
const ProductsByStatusElement = () => (
    <AppLayout>
        <ProductsByStatus />
    </AppLayout>
);
// -> ProductDetails
const ProductDetailsElement = () => (
    <AppLayout>
        <ProductDetails />
    </AppLayout>
);
// -> Categories
const CategoriesElement = () => (
    <AppLayout>
        <Categories />
    </AppLayout>
);
// -> DeliveryGuys
const DeliveryGuysElement = () => (
    <AppLayout>
        <DeliveryGuys />
    </AppLayout>
);
// -> DeliveryGuys details
const DeliveryGuyDetailsElement = () => (
    <AppLayout>
        <DeliveryGuyDetails />
    </AppLayout>
);
// -> Regions
const RegionsElement = () => (
    <AppLayout>
        <Regions />
    </AppLayout>
);
// -> Vendors
const VendorsElement = () => (
    <AppLayout>
        <Vendors />
    </AppLayout>
);
// -> VendorDetails
const VendorDetailsElement = () => (
    <AppLayout>
        <VendorDetails />
    </AppLayout>
);
// -> Customers
const CustomersElement = () => (
    <AppLayout>
        <Customers />
    </AppLayout>
);
// -> Customer details
const CustomersDetailsElement = () => (
    <AppLayout>
        <CustomerDetails />
    </AppLayout>
);
// -> Admins
const AdminsElement = () => (
    <AppLayout>
        <Admins />
    </AppLayout>
);
// -> AdminDetails
const AdminDetailsElement = () => (
    <AppLayout>
        <AdminDetails />
    </AppLayout>
);
// -> Roles
const RolesElement = () => (
    <AppLayout>
        <Roles />
    </AppLayout>
);
// -> Promotions
const PromotionsElement = () => (
    <AppLayout>
        <Promotions />
    </AppLayout>
);
// -> Finance
const FinanceElement = () => (
    <AppLayout>
        <Finance />
    </AppLayout>
);
// -> Profile
const ProfileElement = () => (
    <AppLayout>
        <ProfilePage />
    </AppLayout>
);
// -> Notifiction
const NotificationElement = () => (
    <AppLayout>
        <Notification />
    </AppLayout>
);
// -> Stats ANd report
const StatisticsAdnReportElement = () => (
    <AppLayout>
        <StatisticsAndReportsPage />
    </AppLayout>
);

// !############## MAIN FUNC ###########
const App = () => {
    return (
        <React.Fragment>
            <Routes>
                {/* ################## GUEST ROUTES ################# */}
                <Route element={<GuestRoutes />}>
                    <Route path="/" element={<WelcomeElement />} />
                </Route>

                {/* ################## PROTECTED ROUTES ################# */}
                <Route element={<PrivateRoutes />}>
                    <Route path="/dashboard" element={<DashboardElement />} />
                    <Route path="/orders" element={<OrdersElement />} />
                    <Route
                        path="/orders/status/:status"
                        element={<OrdersByStatusElement />}
                    />
                    <Route
                        path="/orders/details/:order_id"
                        element={<OrderDetailsElement />}
                    />
                    <Route path="/stores" element={<StoresElement />} />
                    <Route
                        path="/stores/details/:store_id"
                        element={<StoresDetailsElement />}
                    />
                    <Route
                        path="/stores/status/:status"
                        element={<StoresByStatusElement />}
                    />
                    <Route path="/products" element={<ProductsElement />} />
                    <Route
                        path="/products/status/:status"
                        element={<ProductsByStatusElement />}
                    />
                    <Route
                        path="/products/details/:product_id"
                        element={<ProductDetailsElement />}
                    />
                    <Route path="/categories" element={<CategoriesElement />} />
                    <Route
                        path="/delivery-guys"
                        element={<DeliveryGuysElement />}
                    />
                    <Route
                        path="/delivery-guys/details/:delivery_guy_id"
                        element={<DeliveryGuyDetailsElement />}
                    />
                    <Route path="/regions" element={<RegionsElement />} />
                    <Route path="/vendors" element={<VendorsElement />} />
                    <Route
                        path="/vendors/details/:vendor_id"
                        element={<VendorDetailsElement />}
                    />
                    <Route path="/customers" element={<CustomersElement />} />
                    <Route
                        path="/customers/details/:customer_id"
                        element={<CustomersDetailsElement />}
                    />
                    <Route path="/admins" element={<AdminsElement />} />
                    <Route
                        path="/admins/details/:admin_id"
                        element={<AdminDetailsElement />}
                    />
                    <Route path="/roles" element={<RolesElement />} />
                    <Route path="/promotions" element={<PromotionsElement />} />
                    <Route path="/finance" element={<FinanceElement />} />
                    <Route path="/profile" element={<ProfileElement />} />
                    <Route
                        path="/notifications"
                        element={<NotificationElement />}
                    />
                    <Route
                        path="/statistics-and-reports"
                        element={<StatisticsAdnReportElement />}
                    />
                </Route>

                {/* ################## ERROR ROUTES ################# */}
                <Route path="*" element={<ErrorPage404Element />} />
            </Routes>
        </React.Fragment>
    );
};

export default App;
