import React from 'react';
import { ArrowRightAltOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/colors';

const OrderInfobox = ({ title, total, bgColor, url, textColor }) => {
    return (
        <>
            <Link to={url}>
                <Box
                    sx={{
                        'height': 50,
                        'bgcolor': bgColor,
                        'width': '100%',
                        'display': 'flex',
                        'justifyContent': 'center',
                        'alignItems': 'center',
                        'flexDirection': 'column',
                        'borderRadius': 2,
                        'position': 'relative',
                        '& .icon-con': {
                            position: 'absolute',
                            right: 10,
                            mt: 1,
                            transition: '.3s all ease-in-out',
                        },
                        '&:hover': {
                            'transition': '.3s all ease-in-out',
                            '& .icon-con': {
                                right: 6,
                                transition: '.3s all ease-in-out',
                            },
                        },
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 10,
                            fontWeight: 'bold',
                            color: textColor ? textColor : colors.primary,
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: textColor ? textColor : colors.primary,
                        }}
                    >
                        ({total}) Orders
                    </Typography>
                    <Box sx={{}} className="icon-con">
                        <ArrowRightAltOutlined
                            sx={{
                                fontSize: 25,
                                color: textColor ? textColor : colors.primary,
                            }}
                        />
                    </Box>
                </Box>
            </Link>
        </>
    );
};

export default OrderInfobox;
