import React from "react";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import PageLayout from "../layouts/PageLayout";
import { useSelector } from "react-redux";
import { authSelector } from "../../state/features/selectors";
import { ManageAccounts } from "@mui/icons-material";
import ChangePassword from "./ChangePassword";
import { colors } from "../../assets/utils/colors";
// import ProfileSidebar from "../../components/sidebars/ProfileSidebar";

const ProfilePage = () => {
    // ############## Redux state ################
    const { user } = useSelector(authSelector);

    return (
        <>
            <PageLayout
                pageHead={"Profile management"}
                bp
                // sidebar={<ProfileSidebar />
            >
                <Box
                    sx={{
                        flex: 1,
                        overflow: "auto",
                        position: "relative",
                    }}
                >
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item sm={12} xs={12}>
                            <Box
                                sx={{
                                    height: "100%",
                                    overflow: "hidden",
                                    borderRadius: 2,
                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                                }}
                            >
                                <Grid
                                    container
                                    justifyContent={"space-between"}
                                >
                                    <Grid item sm={12} xs={12}>
                                        <Box
                                            sx={{
                                                p: 2,
                                                textAlign: "center",
                                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 20,
                                                    fontWeight: "bold",
                                                    color: colors.primary,
                                                }}
                                            >
                                                {user.login_id}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <Box
                                            sx={{
                                                p: 2,
                                                width: "100%",
                                                height: "100%",
                                                textAlign: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderTopRightRadius: 50,
                                                borderBottomRightRadius: 50,
                                                background: `linear-gradient(45deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                            }}
                                        >
                                            <Avatar
                                                src={""}
                                                // variant="square"
                                                sx={{
                                                    height: 150,
                                                    width: 150,
                                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                                    p: 0.5,
                                                    mr: 1,
                                                    // borderRadius: 5,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={7} xs={12}>
                                        <Box>
                                            <Grid
                                                container
                                                padding={2}
                                                spacing={2}
                                                justifyContent={"center"}
                                                sx={{
                                                    "& .head": {
                                                        fontWeight: "bold",
                                                        opacity: 0.7,
                                                    },
                                                    "& .value": {
                                                        fontWeight: "bold",
                                                        opacity: 0.7,
                                                        fontSize: 14,
                                                        color: colors.primary,
                                                    },
                                                }}
                                            >
                                                <Grid item sm={6} xs={6}>
                                                    <Box>
                                                        <Typography className="head">
                                                            First name
                                                        </Typography>
                                                        <Typography className="value">
                                                            {user.first_name}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={6} xs={6}>
                                                    <Box>
                                                        <Typography className="head">
                                                            Last name
                                                        </Typography>
                                                        <Typography className="value">
                                                            {user.last_name}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={6} xs={6}>
                                                    <Box>
                                                        <Typography className="head">
                                                            Phone number
                                                        </Typography>
                                                        <Typography className="value">
                                                            {user.phone_number}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={6} xs={6}>
                                                    <Box>
                                                        <Typography className="head">
                                                            Gender
                                                        </Typography>
                                                        <Typography className="value">
                                                            {user.gender}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm={12}
                                                    sx={{ mt: 1 }}
                                                >
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        className="app-btn-2"
                                                        startIcon={
                                                            <ManageAccounts />
                                                        }
                                                    >
                                                        Update profile
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item sm={11}>
                            <ChangePassword />
                        </Grid>
                    </Grid>
                </Box>
            </PageLayout>
        </>
    );
};

export default ProfilePage;
