import * as React from "react";
// -> MUI Icons
import {
    Cloud,
    FormatQuote,
    LightMode,
    StarHalf,
    StarRate,
} from "@mui/icons-material";
// -> MUI Imports
import {
    Avatar,
    Box,
    Container,
    Grid,
    styled,
    Typography,
} from "@mui/material";

// -> Asset import
import { colors } from "../assets/utils/colors";
import WelcomeTopbar from "../components/WelcomeTopbar";
import logo2 from "../assets/media/images/logo2.png";
import mockup1 from "../assets/media/images/mockup1.png";
import mockup2 from "../assets/media/images/mockup2.png";
import GooglePlay from "../assets/media/images/GooglePlay.png";
import AppStore from "../assets/media/images/AppStore.png";
import LoginDialogy from "../components/dialogs/LoginDialogy";
import { useDate } from "../hooks/useDate";
import useNetwork from "../hooks/useNetwork";
import NoNetworkError from "./errors/NoNetworkError";

// ########## MUI Custom ############
const PageBgImageCon = styled(Box)({
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
    zIndex: -1,
});

// ################ WelcomeContainer ################
const WelcomeContainer = styled(Box)(({ theme }) => ({
    height: "100vh",
    background: colors.bgColor3,
    overflow: "hidden",
    "& .top-content": {
        height: "70vh",
        display: "flex",
        alignItems: "center",
    },
    "& .bottom-content": {
        height: "40vh",
        borderTopLeftRadius: 50,
        borderTopRightRadius: 50,
        // background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.primary})`,
        position: "relative",
    },
    "& .fade-logo": {
        "& .logo-con": {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
        },
        "& .logo": {
            width: 250,
            height: 350,
            opacity: 0.05,
        },
    },
    [theme.breakpoints.down("md")]: {
        ".fade-logo": {
            position: "absolute",
            left: 65,
        },
    },
}));

// ################ GreatingCard ###############
const GreatingCard = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    position: "relative",
    ".icon-con": {
        padding: 10,
        display: "flex",
        ".icon-1": {
            zIndex: 2,
            color: colors.primary,
            fontSize: 45,
        },
    },
    ".icon-2-con": {
        position: "absolute",
        top: 1,
        left: 25,
        zIndex: 1,
        ".icon": {
            color: colors.primary,
            fontSize: 35,
        },
    },
    "& .greating": {
        fontSize: 30,
        fontWeight: "bold",
        color: colors.primary,
    },
    [theme.breakpoints.down("md")]: {
        "& .greating": {
            fontSize: 20,
            whiteSpace: "no-wrap",
        },
    },
}));

// ################ QuoteCard ###############
const QuoteCard = styled(Box)(({ theme }) => ({
    width: 300,
    marginLeft: 80,
    "& .quote": {
        fontSize: 20,
        fontStyle: "italic",
    },
    "& .open-quote": {
        transform: "rotateY(180deg)",
        color: colors.primary,
        fontSize: 35,
    },
    "& .close-quote": {
        color: colors.primary,
        fontSize: 35,
        float: "right",
    },
    [theme.breakpoints.down("md")]: {
        marginLeft: 20,
        "& .quote": {
            fontSize: 18,
        },
    },
}));

// ################ StoreButton ###############
const StoreButton = styled(Box)(({ theme }) => ({
    width: "100%",
    height: 115,
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.primary})`,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    border: "2px solid #fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    "& .icon": {
        width: 30,
        height: 30,
    },
    "& .star": {
        fontSize: 15,
        color: colors.yellow,
    },
    "& .rate": {
        fontWeight: "bold",
        color: "#fff",
        fontSize: 12,
    },
}));

// !############### MAIN FUNC #####################
const Welcome = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // ############### CONST ########################
    const date = useDate();
    const checkNetwork = useNetwork();

    return (
        <>
            {!checkNetwork ? (
                <>
                    <PageBgImageCon>
                        <img
                            src={logo2}
                            alt=""
                            style={{ marginLeft: 100, opacity: 0.04 }}
                        />
                    </PageBgImageCon>
                    <NoNetworkError />
                </>
            ) : (
                <>
                    {/* ################ MODAL ################ */}
                    <LoginDialogy open={open} handleClose={handleClose} />

                    {/* ################ TOPBAR ############### */}
                    <WelcomeTopbar handleClickOpen={handleClickOpen} />

                    {/* ################ CONTENT ############# */}
                    <WelcomeContainer>
                        <Container maxWidth="xl">
                            <Grid container justifyContent={"center"}>
                                {/* ############## TOP ############# */}
                                <Grid
                                    item
                                    sm={11}
                                    xs={12}
                                    className="top-content"
                                >
                                    <Grid container justifyContent={"center"}>
                                        <Grid
                                            container
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                        >
                                            <Grid item sm={4} xs={12}>
                                                <GreatingCard>
                                                    <Box className="icon-con">
                                                        <Cloud className="icon-1" />
                                                        <Box className="icon-2-con">
                                                            <LightMode className="icon" />
                                                        </Box>
                                                    </Box>
                                                    <Typography className="head-font greating">
                                                        {date.wish_1}
                                                    </Typography>
                                                </GreatingCard>
                                                <QuoteCard>
                                                    <FormatQuote className="open-quote" />
                                                    <Typography className="quote">
                                                        Programming is like
                                                        kicking yourself in the
                                                        face, sooner or later
                                                        your nose will bleed.
                                                    </Typography>
                                                    <FormatQuote className="close-quote" />
                                                </QuoteCard>
                                            </Grid>
                                            <Grid
                                                className="fade-logo"
                                                item
                                                sm={5}
                                                xs={12}
                                            >
                                                <Box className="logo-con">
                                                    <Avatar
                                                        alt="Agiza logo"
                                                        src={logo2}
                                                        variant="square"
                                                        className="logo"
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* ############## BOTTOM ############# */}
                                <Grid item sm={11} className="bottom-content">
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                height: "100%",
                                                position: "relative",
                                                overflow: "hidden",
                                                borderTopRightRadius: 50,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    top: 20,
                                                    bottom: 0,
                                                    left: 0,
                                                    right: -100,
                                                    borderTopLeftRadius: 100,
                                                    transform: `rotate(-2deg)`,
                                                    zIndex: 1,
                                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.primary})`,
                                                }}
                                            ></Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            position: "relative",
                                            zIndex: 10,
                                        }}
                                    >
                                        <Grid container justifyContent="center">
                                            {/* ######### MOCKUP ########### */}
                                            <Grid item sm={8}>
                                                <Grid
                                                    container
                                                    spacing={4}
                                                    sx={{
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <Grid item sm={5}>
                                                        <img
                                                            src={mockup1}
                                                            alt="mockup"
                                                            style={{
                                                                width: "100%",
                                                                height: "40vh",
                                                                marginTop: -35,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={5}>
                                                        <img
                                                            src={mockup2}
                                                            alt="mockup"
                                                            style={{
                                                                width: "100%",
                                                                height: "40vh",
                                                                marginTop: -35,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* ######### STORE BTN ######## */}
                                            <Grid item sm={3} sx={{ mt: -5 }}>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <Grid item sm={3.5}>
                                                        <StoreButton>
                                                            <Box>
                                                                <Avatar
                                                                    alt="store icon"
                                                                    src={
                                                                        AppStore
                                                                    }
                                                                    variant="square"
                                                                    className="icon"
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <StarRate className="star" />
                                                                <StarRate className="star" />
                                                                <StarRate className="star" />
                                                                <StarRate className="star" />
                                                                <StarHalf className="star" />
                                                            </Box>
                                                            <Box>
                                                                <Typography className="rate">
                                                                    4.5
                                                                </Typography>
                                                            </Box>
                                                        </StoreButton>
                                                    </Grid>
                                                    <Grid item sm={3.5}>
                                                        <StoreButton>
                                                            <Box>
                                                                <Avatar
                                                                    alt="store icon"
                                                                    src={
                                                                        GooglePlay
                                                                    }
                                                                    variant="square"
                                                                    className="icon"
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <StarRate className="star" />
                                                                <StarRate className="star" />
                                                                <StarRate className="star" />
                                                                <StarRate className="star" />
                                                                <StarHalf className="star" />
                                                            </Box>
                                                            <Box>
                                                                <Typography className="rate">
                                                                    4.5
                                                                </Typography>
                                                            </Box>
                                                        </StoreButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </WelcomeContainer>
                </>
            )}
        </>
    );
};

export default Welcome;
