import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ErrorMessage, useField } from "formik";

const CustomAutoCompleteSelect = ({
    formik,
    label,
    color,
    readOnly,
    options,
    type,
    handler,
    children,
    ...props
}) => {
    const [field, meta] = useField(props);
    const [selectValue, setSelectValue] = React.useState(
        field.value ? field.value : ""
    );

    const handleChange = (payload) => {
        setSelectValue(payload);
        const fieldName = field.name;
        formik.setFieldValue(`${fieldName}`, payload);
        handler && handler(payload);
    };

    return (
        <Autocomplete
            options={options}
            fullWidth
            onChange={(event, newValue) => {
                setSelectValue(newValue);
                handleChange(newValue.id);
            }}
            renderInput={(params) => (
                <>
                    <TextField
                        error={meta.touched && meta.error ? true : false}
                        variant="outlined"
                        fullWidth
                        type={type}
                        placeholder={label}
                        {...field}
                        {...props}
                        {...params}
                        size="small"
                    />
                    <ErrorMessage
                        component="span"
                        name={field.name}
                        className="error-text"
                    />
                </>
            )}
        />
    );
};

export default CustomAutoCompleteSelect;
