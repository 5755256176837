import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import StoreOrdersTable from '../../../../components/tables/StoreOrdersTable';
import CustomCard from '../../../../components/cards/CustomCard';
import { colors } from '../../../../assets/utils/colors';
import DashOrdersStats from '../../../../components/dash/DashOrdersStats';
import { filter, isEmpty, size } from 'lodash';
import CustomCard2 from '../../../../components/cards/CustomCard2';
import NoContent from '../../../../components/NoContent';
import OrderFilter from '../../../../components/filters/OrderFilter';
import moment from 'moment';

const StoreOrders = ({ orders: allOrders }) => {
    // ############### Component state ########################
    const [orders, setOrders] = useState([]);
    const [byDateFrom, setByDateFrom] = useState('');
    const [byDateTo, setByDateTo] = useState('');
    const [byPayment, setByPayment] = useState('');
    const [byStatus, setByStatus] = useState('');

    // ############### Filters ###########################
    // => Filter by date
    const filterByDateFrom = (date) => {
        setByDateFrom(date);
    };
    // => Filter by date
    const filterByDateTo = (date) => {
        setByDateTo(date);
    };
    // => Filter by payment
    const filterByPayment = (payment) => {
        setByPayment(payment);
    };
    // => Filter by status
    const filterByStatus = (status) => {
        setByStatus(status);
    };

    // ############## General Filter ###############
    const filterOrders = (dateFrom, dateTo, payment, status) => {
        let filteredOrders = [];
        var startDate = moment(dateFrom).format('YYYY-MM-DD');
        var endDate = moment(dateTo).format('YYYY-MM-DD');
        if (dateFrom !== '' || dateTo !== '') {
            filteredOrders = filter(
                allOrders,
                ({ createdAt }) =>
                    moment(createdAt).format('YYYY-MM-DD') >= startDate &&
                    moment(createdAt).format('YYYY-MM-DD') <= endDate,
            );
        }
        if (payment !== '') {
            filteredOrders = filter(
                isEmpty(filteredOrders) ? allOrders : filteredOrders,
                ({ isPaid }) => isPaid === payment,
            );
        }
        if (status !== '') {
            filteredOrders = filter(
                isEmpty(filteredOrders) ? allOrders : filteredOrders,
                ({ order_status }) => order_status === byStatus,
            );
        }
        if (
            dateFrom !== '' ||
            dateTo !== '' ||
            payment !== '' ||
            status !== ''
        ) {
            return setOrders(filteredOrders);
        } else {
            return setOrders(allOrders);
        }
    };

    // => Reset filter
    const resetFilter = () => {
        setByDateFrom('');
        setByDateTo('');
        setByPayment('');
        setByStatus('');
    };

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            filterOrders(byDateFrom, byDateTo, byPayment, byStatus);
        }
        return () => {
            isSubscribed = false;
        };
    }, [byDateFrom, byDateTo, byPayment, byStatus]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setOrders(allOrders);
        }

        return () => {
            isSubscribed = false;
        };
    }, [allOrders]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={9}>
                    <CustomCard2
                        height={423}
                        title={`${size(orders)} orders`}
                        action={
                            <OrderFilter
                                byDateFrom={byDateFrom}
                                byDateTo={byDateTo}
                                byPayment={byPayment}
                                byStatus={byStatus}
                                resetFilter={resetFilter}
                                filterByDateFrom={filterByDateFrom}
                                filterByDateTo={filterByDateTo}
                                filterByPayment={filterByPayment}
                                filterByStatus={filterByStatus}
                            />
                        }
                    >
                        {isEmpty(orders) ? (
                            <>
                                <NoContent
                                    message={'Store do not have any order'}
                                />
                            </>
                        ) : (
                            <>
                                <StoreOrdersTable orders={orders} />
                            </>
                        )}
                    </CustomCard2>
                </Grid>
                <Grid item sm={3}>
                    <CustomCard height={250} bgColor={colors.bgColor1}>
                        <Box sx={{ mt: 2 }}>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                }}
                            >
                                Total orders
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 22,
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    mt: 0.5,
                                }}
                                className="head-font"
                            >
                                {size(orders)}
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <Grid container spacing={2} padding={2}>
                                <Grid item sm={6} xs={6}>
                                    <DashOrdersStats
                                        title={'New'}
                                        total={size(
                                            filter(orders, { order_status: 1 }),
                                        )}
                                        bgColor={colors.bgColor3}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <DashOrdersStats
                                        title={'Processing'}
                                        total={size(
                                            filter(orders, { order_status: 2 }),
                                        )}
                                        bgColor={colors.bgColor2}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <DashOrdersStats
                                        title={'Shipped'}
                                        total={size(
                                            filter(orders, { order_status: 3 }),
                                        )}
                                        bgColor={colors.yellow}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <DashOrdersStats
                                        title={'Delivered'}
                                        total={size(
                                            filter(orders, { order_status: 4 }),
                                        )}
                                        bgColor={colors.green}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CustomCard>
                </Grid>
            </Grid>
        </>
    );
};

export default StoreOrders;
