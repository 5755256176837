import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "./baseUrl/baseURL";
import { headers } from "./baseUrl/setHeaders";
import { notificationEndpoints } from "./endpoints/notificationEndpoints";

export const notificationAPI = createApi({
    reducerPath: "NotificationAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ["Notifications"],
    endpoints: (builder) => ({
        // => GET Notification history
        getNotificationHistory: builder.query({
            query: () => `${notificationEndpoints.GET_NOTIFICATION_HISTORY}`,
            providesTags: ["Notifications"],
        }),

        // => SEND Notification
        sendNotification: builder.mutation({
            query: (payload) => ({
                url: `${
                    payload.to === "customers"
                        ? notificationEndpoints.SEND_NOTIFICATION_CUSTOMER
                        : payload.to === "vendors"
                        ? notificationEndpoints.SEND_NOTIFICATION_ALL_VENDOR
                        : notificationEndpoints.SEND_NOTIFICATION_ALL
                }`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    notificationAPI.util.invalidateTags(["Notifications"])
                );
            },
        }),

        // => SEND customer Notification
        sendCustomerNotification: builder.mutation({
            query: (payload) => ({
                url: `${notificationEndpoints.SEND_USER_NOTIFICATION}`,
                method: `POST`,
                body: payload,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    notificationAPI.util.invalidateTags(["Notifications"])
                );
            },
        }),
    }),
});

export const {
    useGetNotificationHistoryQuery,
    useSendNotificationMutation,
    useSendCustomerNotificationMutation,
} = notificationAPI;
