import React from "react";
import { Button } from "@mui/material";
import { Pause } from "@mui/icons-material";

const SuspendButton = ({ handleProductApprove }) => {
    return (
        <div>
            <>
                <Button
                    fullWidth
                    className="app-btn-2"
                    variant="contained"
                    size="small"
                    color="secondary"
                    startIcon={<Pause />}
                    sx={{
                        boxShadow: "none",
                        mb: 1,
                        mr: 1,
                        fontSize: 10,
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                        let type = "suspend";
                        handleProductApprove(type);
                    }}
                >
                    Suspend product
                </Button>
            </>
        </div>
    );
};

export default SuspendButton;
