import React from 'react';
import { Box } from '@mui/material';
import { colors } from '../assets/utils/colors';
import logo from '../assets/media/images/logo1.png';
import Loader from 'react-js-loader';

const ContentLoader = () => {
    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <img
                        src={logo}
                        alt=""
                        style={{ opacity: 0.8, width: '50%' }}
                    />
                    <Box sx={{ position: 'absolute', top: `70%` }}>
                        <Loader
                            type="bubble-loop"
                            bgColor={colors.primary}
                            color={colors.primary}
                            size={50}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ContentLoader;
