import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';

const DashStatsCard = ({title, total, bdColor, icon}) => {
    return (
        <>
            <Box
                sx={{
                    height: 50,
                    borderLeft: `7px solid ${bdColor}`,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    p: 1,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                    }}
                >
                    {title}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {icon}
                    <Typography
                        sx={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            color: colors.primary,
                        }}
                    >
                        {total}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default DashStatsCard;
