import React from 'react';
import { Box } from '@mui/material';
import CustomTextFieldFilter from '../forms/CustomTextFieldFilter';

const ProductFilter2 = ({ filterByProductName }) => {
    return (
        <>
            <Box
                sx={{
                    height: `100%`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mr: 4
                }}
            >
                <CustomTextFieldFilter
                    width={200}
                    label={'Product name'}
                    handleFilter={filterByProductName}
                />
            </Box>
        </>
    );
};

export default ProductFilter2;
