import React, { useEffect, useState } from "react";
import { storesSelector } from "../../../../state/features/selectors";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import CustomSubmitButton from "../../../../components/forms/CustomSubmitButton";
import { colors } from "../../../../assets/utils/colors";
import logo1 from "../../../../assets/media/images/logo1.png";
import { filter, isEmpty } from "lodash";
import { setProductDetails } from "../../../../state/features/products/productSlice";
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from "../../../../helpers/RTKHelpers";
import { errorNotify, successNotify } from "../../../../helpers/notify";
import { useChangeProductStoreMutation } from "../../../../api/productAPI";
import * as Yup from "yup";
import SuccessFeedback from "../../../../components/SuccessFeedback";
import CustomAutoCompleteSelect from "../../../../components/forms/CustomAutoCompleteSelect";
import StoreWidgetBottom from "../../../../components/widgets/StoreWidgetBottom";

// ############### VALIDATION ###############
const changeStoreValidationSchema = Yup.object({
    store_id: Yup.string().required("store is a required field"),
});

const ChangeProductStore = ({ store: currStore, product }) => {
    // ############## Comp State ################
    const [selectedStore, setSelectedStore] = useState({});
    const [vendorStores, setVendorStores] = useState([]);

    // ############## Redux state ###############
    const { stores } = useSelector(storesSelector);

    // ############## CONST ##############
    const dispatch = useDispatch();

    // ############# FUNC ###############
    // => Get vendor store
    const getVendorStores = () => {
        let vendorStores = filter(
            stores,
            ({ vendor_id }) => vendor_id._id === currStore.vendor_id._id
        );
        setVendorStores(vendorStores);
    };

    // => Restructure arr
    const restructureOptionArr = () => {
        let data = [];
        vendorStores?.forEach((store) => {
            data.push({
                id: store._id,
                label: store.title,
                name: store.title,
            });
        });
        return data;
    };
    const options = restructureOptionArr();
    // => Get selected store
    const getSelectedStore = (selected_id) => {
        let selectedStore = filter(vendorStores, { _id: selected_id });
        setSelectedStore(selectedStore[0]);
    };

    // ############## RTK ################
    // => Feature product
    const [
        changeProductStore,
        { isLoading, isSuccess, isError, error, data: productDetails },
    ] = useChangeProductStoreMutation();

    // ############### useEffect ###########
    // => Featured
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                successNotify(getRTKSuccessMessage(productDetails));
                dispatch(setProductDetails(getRTKResponseData(productDetails)));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, error, productDetails, dispatch]);
    // => Set curr store
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setSelectedStore(currStore);
            getVendorStores();
        }
        return () => {
            isSubscribed = false;
        };
    }, [currStore]);

    return (
        <div>
            {isSuccess ? (
                <>
                    <SuccessFeedback message={productDetails.message} />
                </>
            ) : (
                <Box className="form-section">
                    <Formik
                        enableReinitialize
                        initialValues={{
                            store_id: "",
                            // store_id: currStore._id,
                        }}
                        validationSchema={changeStoreValidationSchema}
                        onSubmit={(payload) => {
                            let newPayload = {
                                product_id: product._id,
                                store_id: payload.store_id,
                            };
                            changeProductStore(newPayload);
                        }}
                    >
                        {(formik) => (
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} sm={10}>
                                    <Box className="input-con">
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Avatar
                                                src={logo1}
                                                alt="Agiza logo"
                                                sx={{
                                                    width: 160,
                                                    height: 66,
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Box sx={{ mb: 4 }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: 22,
                                                        color: colors.primary,
                                                        fontWeight: "bold",
                                                        opacity: 0.8,
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Change {product.title} store
                                                </Typography>
                                            </Box>
                                            <Box sx={{ mb: 2 }}>
                                                <StoreWidgetBottom
                                                    store={
                                                        !isEmpty(
                                                            selectedStore
                                                        ) && selectedStore
                                                    }
                                                />
                                            </Box>
                                            <Box>
                                                <Grid
                                                    container
                                                    columnSpacing={2}
                                                >
                                                    <Grid item sm={12} xs={12}>
                                                        <CustomAutoCompleteSelect
                                                            name="store_id"
                                                            formik={formik}
                                                            options={options}
                                                            handler={
                                                                getSelectedStore
                                                            }
                                                            label={
                                                                "Select product store"
                                                            }
                                                        />
                                                        {/* <CustomSelect
                                                            name="store_id"
                                                            formik={formik}
                                                            label={
                                                                "Select product store"
                                                            }
                                                        >
                                                            {stores?.map(
                                                                (store) => (
                                                                    <MenuItem
                                                                        key={
                                                                            store._id
                                                                        }
                                                                        value={
                                                                            store._id
                                                                        }
                                                                    >
                                                                        {
                                                                            store.title
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </CustomSelect> */}
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                        <Box sx={{ mt: 5 }}>
                                            <CustomSubmitButton
                                                loading={isLoading}
                                                onClick={formik.handleSubmit}
                                                btnColor={"info"}
                                            >
                                                Update product store
                                            </CustomSubmitButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                </Box>
            )}
        </div>
    );
};

export default ChangeProductStore;
