import { filter } from 'lodash';

// => CommissionRange calc
const getCommissionRange = (productPrice) => {
    let commissionRange = 0;
    if (productPrice < 50000) {
        commissionRange = 20;
    } else if (productPrice > 50000 && productPrice < 100000) {
        commissionRange = 15;
    } else if (productPrice > 100000 && productPrice < 500000) {
        commissionRange = 12;
    } else if (productPrice > 500000 && productPrice < 1000000) {
        commissionRange = 10;
    } else {
        commissionRange = 5;
    }
    return commissionRange;
};

// => Get orders products
const getOrdersProducts = (orders) => {
    let ordersProducts = [];
    orders.forEach((order) => {
        order.ordered_products.forEach((products) => {
            ordersProducts.push(products);
        });
    });
    return ordersProducts;
};

// => Revenue calc
export const getRevenue = (orders) => {
    let totalExpectedRevenue = 0;
    let totalPendingRevenue = 0;
    let totalRefundedRevenue = 0;
    let totalCollectedRevenue = 0;

    // => Get expected revenue
    let expectedRevenueOrders = filter(
        orders,
        ({ order_status, isPaid }) =>
            order_status === 2 ||
            order_status === 3 ||
            order_status === 4 ||
            (order_status === 1 && isPaid === true) ||
            (order_status === 6 && isPaid === true),
    );
    expectedRevenueOrders.forEach((order) => {
        totalExpectedRevenue += parseInt(order.total_amount);
    });

    // => Get pending revenue
    let pendingRevenueOrders = filter(
        orders,
        ({ order_status, isPaid }) =>
            order_status === 2 ||
            (order_status === 1 && isPaid === true) ||
            (order_status === 3 && isPaid === true),
    );
    pendingRevenueOrders.forEach((order) => {
        totalPendingRevenue += parseInt(order.total_amount);
    });

    // => Get refunded revenue
    let refundedRevenueOrders = filter(
        orders,
        ({ order_status, isPaid }) => order_status === 6 && isPaid === true,
    );
    refundedRevenueOrders.forEach((order) => {
        totalRefundedRevenue += parseInt(order.total_amount);
    });

    // => Get refunded revenue
    let collectedRevenueOrders = filter(
        orders,
        ({ order_status, isPaid }) => order_status === 4 && isPaid === true,
    );
    collectedRevenueOrders.forEach((order) => {
        totalCollectedRevenue += parseInt(order.total_amount);
    });

    return {
        expected: totalExpectedRevenue,
        pending: totalPendingRevenue,
        refunded: totalRefundedRevenue,
        collected: totalCollectedRevenue,
        expectedOrders: expectedRevenueOrders,
        pendingOrders: pendingRevenueOrders,
        refundedOrders: refundedRevenueOrders,
        collectedOrders: collectedRevenueOrders,
    };
};

// => Commission calc
export const getCommission = (orders) => {
    let totalExpectedCommission = 0;
    let totalPendingCommission = 0;
    let totalRefundedCommission = 0;
    let totalCollectedCommission = 0;
    let commissionPer = 0;
    let revenue = getRevenue(orders);

    // => Get total expected commission
    const getTotalExpectedCommission = () => {
        let products = getOrdersProducts(revenue.expectedOrders);
        products.forEach((product) => {
            commissionPer = getCommissionRange(product.price);
            let expectedProductCommission =
                (commissionPer * product.price) / 100;
            totalExpectedCommission += expectedProductCommission;
        });
        return totalExpectedCommission;
    };
    totalExpectedCommission = getTotalExpectedCommission();

    // => Get total pending commission
    const getTotalPendingCommission = () => {
        let products = getOrdersProducts(revenue.pendingOrders);
        products.forEach((product) => {
            commissionPer = getCommissionRange(product.price);
            let expectedProductCommission =
                (commissionPer * product.price) / 100;
            totalPendingCommission += expectedProductCommission;
        });
        return totalPendingCommission;
    };
    totalPendingCommission = getTotalPendingCommission();

    // => Get total refunded commission
    const getTotalRefundedCommission = () => {
        let products = getOrdersProducts(revenue.refundedOrders);
        products.forEach((product) => {
            commissionPer = getCommissionRange(product.price);
            let expectedProductCommission =
                (commissionPer * product.price) / 100;
            totalRefundedCommission += expectedProductCommission;
        });
        return totalRefundedCommission;
    };
    totalRefundedCommission = getTotalRefundedCommission();

    // => Get total collected commission
    const getTotalCollectedCommission = () => {
        let products = getOrdersProducts(revenue.collectedOrders);
        products.forEach((product) => {
            commissionPer = getCommissionRange(product.price);
            let expectedProductCommission =
                (commissionPer * product.price) / 100;
            totalCollectedCommission += expectedProductCommission;
        });
        return totalCollectedCommission;
    };
    totalCollectedCommission = getTotalCollectedCommission();

    return {
        expected: Math.round(totalExpectedCommission),
        pending: Math.round(totalPendingCommission),
        refunded: Math.round(totalRefundedCommission),
        collected: Math.round(totalCollectedCommission),
    };
};
