import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { colors } from "../../assets/utils/colors";
import { Box, Grid } from "@mui/material";
import { filter, size } from "lodash";
import moment from "moment";

const StoreChart = ({ title, chartData, dateRange }) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const chatFilter = (dateRange) => {
        // => Get date range from filter & labels
        let labels = [];
        let startMonth = dateRange.substring(0, 3);
        if (startMonth === "Jan") {
            startMonth = "01";
            labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];
        } else {
            startMonth = "07";
            labels = ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        }
        let year = dateRange.slice(-4);

        // Get date date range
        let data1StartDate = year + startMonth + "01";
        let data1EndDate =
            year +
            startMonth +
            moment(year + "-" + startMonth, "YYYY-MM").daysInMonth();
        let data2StartDate =
            year +
            (startMonth === "01" ? "02" : startMonth === "07" ? "08" : "") +
            "01";
        let data2EndDate =
            year +
            (startMonth === "01" ? "02" : startMonth === "07" ? "08" : "") +
            moment(
                year +
                    "-" +
                    (startMonth === "01"
                        ? "02"
                        : startMonth === "07"
                        ? "08"
                        : ""),
                "YYYY-MM"
            ).daysInMonth();
        let data3StartDate =
            year +
            (startMonth === "01" ? "03" : startMonth === "07" ? "09" : "") +
            "01";
        let data3EndDate =
            year +
            (startMonth === "01" ? "03" : startMonth === "07" ? "09" : "") +
            moment(
                year +
                    "-" +
                    (startMonth === "01"
                        ? "03"
                        : startMonth === "07"
                        ? "09"
                        : ""),
                "YYYY-MM"
            ).daysInMonth();
        let data4StartDate =
            year +
            (startMonth === "01" ? "04" : startMonth === "07" ? "10" : "") +
            "01";
        let data4EndDate =
            year +
            (startMonth === "01" ? "04" : startMonth === "07" ? "10" : "") +
            moment(
                year +
                    "-" +
                    (startMonth === "01"
                        ? "04"
                        : startMonth === "07"
                        ? "10"
                        : ""),
                "YYYY-MM"
            ).daysInMonth();
        let data5StartDate =
            year +
            (startMonth === "01" ? "05" : startMonth === "07" ? "11" : "") +
            "01";
        let data5EndDate =
            year +
            (startMonth === "01" ? "05" : startMonth === "07" ? "11" : "") +
            moment(
                year +
                    "-" +
                    (startMonth === "01"
                        ? "05"
                        : startMonth === "07"
                        ? "11"
                        : ""),
                "YYYY-MM"
            ).daysInMonth();
        let data6StartDate =
            year +
            (startMonth === "01" ? "06" : startMonth === "07" ? "12" : "") +
            "01";
        let data6EndDate =
            year +
            (startMonth === "01" ? "06" : startMonth === "07" ? "12" : "") +
            moment(
                year +
                    "-" +
                    (startMonth === "01"
                        ? "06"
                        : startMonth === "07"
                        ? "12"
                        : ""),
                "YYYY-MM"
            ).daysInMonth();
        let data1 = size(
            filter(
                chartData,
                ({ createdAt }) =>
                    moment(createdAt).format("YYYY-MM-DD") >=
                        moment(data1StartDate, "YYYY-MM-DD").format(
                            "YYYY-MM-DD"
                        ) &&
                    moment(createdAt).format("YYYY-MM-DD") <=
                        moment(data1EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
            )
        );
        let data2 = size(
            filter(
                chartData,
                ({ createdAt }) =>
                    moment(createdAt).format("YYYY-MM-DD") >=
                        moment(data2StartDate, "YYYY-MM-DD").format(
                            "YYYY-MM-DD"
                        ) &&
                    moment(createdAt).format("YYYY-MM-DD") <=
                        moment(data2EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
            )
        );
        let data3 = size(
            filter(
                chartData,
                ({ createdAt }) =>
                    moment(createdAt).format("YYYY-MM-DD") >=
                        moment(data3StartDate, "YYYY-MM-DD").format(
                            "YYYY-MM-DD"
                        ) &&
                    moment(createdAt).format("YYYY-MM-DD") <=
                        moment(data3EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
            )
        );
        let data4 = size(
            filter(
                chartData,
                ({ createdAt }) =>
                    moment(createdAt).format("YYYY-MM-DD") >=
                        moment(data4StartDate, "YYYY-MM-DD").format(
                            "YYYY-MM-DD"
                        ) &&
                    moment(createdAt).format("YYYY-MM-DD") <=
                        moment(data4EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
            )
        );
        let data5 = size(
            filter(
                chartData,
                ({ createdAt }) =>
                    moment(createdAt).format("YYYY-MM-DD") >=
                        moment(data5StartDate, "YYYY-MM-DD").format(
                            "YYYY-MM-DD"
                        ) &&
                    moment(createdAt).format("YYYY-MM-DD") <=
                        moment(data5EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
            )
        );
        let data6 = size(
            filter(
                chartData,
                ({ createdAt }) =>
                    moment(createdAt).format("YYYY-MM-DD") >=
                        moment(data6StartDate, "YYYY-MM-DD").format(
                            "YYYY-MM-DD"
                        ) &&
                    moment(createdAt).format("YYYY-MM-DD") <=
                        moment(data6EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
            )
        );
        return {
            labels,
            data: [data1, data2, data3, data4, data5, data6],
        };
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: false,
                text: title,
            },
        },
    };

    const data = {
        labels: chatFilter(dateRange).labels,
        datasets: [
            {
                label: title,
                data: chatFilter(dateRange).data,
                backgroundColor: colors.bgColor1,
                borderRadius: 5,
            },
        ],
    };

    return (
        <>
            <Bar
                options={options}
                data={data}
                style={{ flex: 1, paddingLeft: 10 }}
            />
        </>
    );
};

export default StoreChart;
