import React, { useEffect } from "react";
import { Avatar, Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import { Formik } from "formik";
import CustomTextField from "../../../components/forms/CustomTextField";
import logo1 from "../../../assets/media/images/logo1.png";
import CustomMultlineTextField from "../../../components/forms/CustomMultlineTextField";
import CustomSubmitButton from "../../../components/forms/CustomSubmitButton";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { errorNotify, successNotify } from "../../../helpers/notify";
import {
    getRTKErrorMessage,
    // getRTKResponseData,
    getRTKSuccessMessage,
} from "../../../helpers/RTKHelpers";
import CustomSelect from "../../../components/forms/CustomSelect";
import { colors } from "../../../assets/utils/colors";
import { useSendNotificationMutation } from "../../../api/notificationAPI";
// import { setNotifications } from "../../../state/features/notifications/notificationsSlice";

// ############## MUI styled #################
const ContentSection = styled(Box)(({ theme }) => ({
    height: "80vh",
    borderRadius: 5,
    overflow: "hidden",
    // boxShadow: "-1px 1px 2px 2px rgba(125, 98, 212, .1)",
    "& .categories-section": {
        height: "80vh",
        backgroundImage: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
    },
    "& .form-section": {
        height: "80vh",
        background: colors.bgColor1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .input-sign-head": {
            fontWeight: "bold",
            color: colors.primary,
        },
        "& .input-con": {
            height: 400,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
        },
    },
    [theme.breakpoints.down("md")]: {},
}));

// ############### VALIDATION ###############
const createNotificationValidationSchema = Yup.object({
    to: Yup.string().required("notification user is required field"),
    title: Yup.string().required("notification title is a required field"),
    message: Yup.string().required("notification body is a required field"),
});

const ComposeNotification = () => {
    const dispatch = useDispatch();

    // ############## RTK ################
    // => CREATE category
    const [
        sendNotification,
        { isLoading, isSuccess, isError, error, data: notificationsData },
    ] = useSendNotificationMutation();

    // ############## useEffect ###############
    // => Dispatch created data
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                successNotify(getRTKSuccessMessage(notificationsData));
                // dispatch(
                // setNotifications(getRTKResponseData(notificationsData))
                // );
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, error, notificationsData, dispatch]);

    return (
        <>
            <ContentSection>
                <Box className="form-section">
                    <Formik
                        initialValues={{
                            to: "",
                            title: "",
                            message: "",
                        }}
                        validationSchema={createNotificationValidationSchema}
                        onSubmit={(payload) => {
                            sendNotification(payload);
                        }}
                    >
                        {(formik) => (
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} sm={10}>
                                    <Box className="input-con">
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Avatar
                                                src={logo1}
                                                alt="Agiza logo"
                                                sx={{
                                                    width: 200,
                                                    height: 80,
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography className="input-sign-head">
                                                In - App user notify
                                            </Typography>
                                            <Box>
                                                <Grid
                                                    container
                                                    columnSpacing={2}
                                                >
                                                    <Grid item sm={12}>
                                                        <CustomSelect
                                                            name="to"
                                                            formik={formik}
                                                            label={
                                                                "Send notification to"
                                                            }
                                                        >
                                                            <MenuItem
                                                                value={"all"}
                                                            >
                                                                All users
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={
                                                                    "customers"
                                                                }
                                                            >
                                                                Customers
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={
                                                                    "vendors"
                                                                }
                                                            >
                                                                Vendors
                                                            </MenuItem>
                                                        </CustomSelect>
                                                    </Grid>
                                                    <Grid item sm={12}>
                                                        <CustomTextField
                                                            type="text"
                                                            name="title"
                                                            label={
                                                                "Notification title"
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <CustomMultlineTextField
                                                    rows={3}
                                                    type="text"
                                                    name="message"
                                                    label={"Notification body"}
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{ mt: 4 }}>
                                            <CustomSubmitButton
                                                loading={isLoading}
                                                onClick={formik.handleSubmit}
                                            >
                                                Send notification
                                            </CustomSubmitButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                </Box>
            </ContentSection>
        </>
    );
};

export default ComposeNotification;
