import { createSlice } from "@reduxjs/toolkit";
import { successNotify } from "../../../helpers/notify";
import { filter } from "lodash";

// => initial state
const initialState = {
    cartItems: [],
    cartTotalQuantity: 0,
    cartSubTotalAmount: 0,
    cartTotalAmount: 0,
    cartPayload: null,
};

const posOrderSlice = createSlice({
    name: "posOrderSlice",
    initialState,
    reducers: {
        // * -> ADD PRODUCT TO CART
        addCartItem(state, action) {
            // * -> Check if product is already in cart
            const itemIndex = state.cartItems.findIndex(
                (item) =>
                    item._id === action.payload._id &&
                    item.selected_color === action.payload.selected_color &&
                    item.selected_size === action.payload.selected_size
            );

            const discountCheck = state.cartItems.findIndex(
                (item) =>
                    item._id === action.payload._id &&
                    item.selected_color === action.payload.selected_color &&
                    item.selected_size === action.payload.selected_size &&
                    item.isDiscounted !== action.payload.isDiscounted // *-> Check if item's discount don't match
            );

            // * -> If item is in cart, increment it by 1 using its index
            if (itemIndex >= 0) {
                // *-> Check if item has discount unmatch
                if (discountCheck >= 0) {
                    // * -> Remove the conflicting item
                    const nextCartItems = state.cartItems.filter(
                        (cartItem) => cartItem._id !== action.payload._id
                    );
                    // * -> Update the state
                    state.cartItems = nextCartItems;

                    // * -> Then, add the incoming item
                    // * -> Set item quantity when pressed
                    const tempItem = { ...action.payload, cartQuantity: 1 };
                    // * -> Push product to cart
                    state.cartItems.push(tempItem);

                    successNotify(`Added ${action.payload.title} to cart`);
                } else {
                    state.cartItems[itemIndex].cartQuantity += 1;
                    successNotify(`Increased ${action.payload.title} to cart`);
                }
            } else {
                // * -> Set item quantity when pressed
                const tempItem = { ...action.payload, cartQuantity: 1 };
                const productSizes = tempItem?.available_sizes;
                const selectedSize = filter(productSizes, {
                    name: tempItem.selected_size,
                });
                let selectedSizeObj = selectedSize[0];

                if (selectedSizeObj) {
                    if (selectedSizeObj.price) {
                        tempItem["price"] = selectedSizeObj.price;
                    } else {
                        tempItem["price"] = tempItem.price;
                    }
                }
                // * -> Push product to cart
                state.cartItems.push(tempItem);
                successNotify(`Added ${action.payload.title} to cart`);
            }
        },

        // * -> DECREASE PRODUCT FROM CART
        decreaseFromCart(state, action) {
            // * -> Check if product is in cart, find its index
            const itemIndex = state.cartItems.findIndex(
                (cartItem) => cartItem.cart_id === action.payload.cart_id
            );

            //-> if item is in cart, using its index, decrease cartQuantity by 1,
            //-> if quantity is greater than 1 and if its 1 respectively then remove the product
            if (state.cartItems.length !== 0 && state.cartItems[itemIndex]) {
                if (state.cartItems[itemIndex].cartQuantity > 1) {
                    state.cartItems[itemIndex].cartQuantity -= 1;
                    successNotify(
                        `Decreased ${action.payload.title} from cart`
                    );
                } else if (state.cartItems[itemIndex].cartQuantity === 1) {
                    const nextCartItems = state.cartItems.filter(
                        (cartItem) =>
                            cartItem.cart_id !== action.payload.cart_id
                    );
                    //-> update the state
                    state.cartItems = nextCartItems;
                    successNotify(`Removed ${action.payload.title} from cart`);
                }
            } else {
                successNotify(`Add this product to cart first!`);
            }
        },

        // * -> CLEAR CART
        clearCart(state, action) {
            state.cartItems = [];
            successNotify(`Cart cleared`);
        },

        // * -> CLEAR CART
        clearPOSCart(state, action) {
            state.cartItems = [];
            state.cartTotalQuantity = 0;
            state.cartSubTotalAmount = 0;
            state.cartTotalAmount = 0;
            state.cartPayload = null;
        },

        // * -> GET TOTALS / CALCULATE CHARGES
        getTotals(state) {
            let { total, quantity } = state.cartItems.reduce(
                (cartTotal, cartItem) => {
                    // * -> Take price and quantity at a single cartItem
                    const { price, cartQuantity } = cartItem;
                    const ItemTotal = price * cartQuantity;

                    // * -> Calculate total cart price
                    cartTotal.total += ItemTotal;
                    cartTotal.quantity += cartQuantity;

                    return cartTotal;
                },
                {
                    total: 0,
                    quantity: 0,
                }
            );

            state.cartTotalQuantity = quantity;
            state.cartSubTotalAmount = total;
            state.cartTotalAmount = total;
        },

        // *-> FOR CART CHECKOUT PURPOSE ONLY
        addAtOnceToCart(state, action) {
            state.cartItems = action.payload;
        },

        // *-> Set cart payload
        setCartPayload(state, action) {
            state.cartPayload = action.payload;
        },
    },
});

export const {
    addCartItem,
    decreaseFromCart,
    getTotals,
    clearCart,
    clearPOSCart,
    setCartPayload,
} = posOrderSlice.actions;
export default posOrderSlice.reducer;
