import { Box, Grid, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../../assets/utils/colors';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import { Add } from '@mui/icons-material';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import SubCategoryActionButtons from './SubCategoryActionButtons';
import ConfirmSubCatDelete from './ConfirmSubCatDelete';
import CreateSubCategory from './CreateSubCategory';
import { isEmpty } from 'lodash';
import NoContent from '../../../components/NoContent';

// ############## MUI styled #################
const ContentSection = styled(Box)(({ theme }) => ({
    'height': '75vh',
    'borderRadius': 5,
    'overflow': 'hidden',
    'boxShadow': '-1px 1px 2px 2px rgba(125, 98, 212, .1)',
    '& .categories-section': {
        height: '75vh',
        backgroundImage: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
    },
    '& .section-head': {
        'fontSize': 18,
        'fontWeight': 'bold',
        'color': colors.primary,
        'ml': 2,
        'position': 'relative',
        '&::before': {
            content: "''",
            height: 5,
            width: 100,
            background: colors.bgColor1,
            position: 'absolute',
            bottom: -8,
            borderRadius: 5,
        },
    },
    '& .table-con': {
        'background': 'transparent',
        'boxShadow': 'none',
        'height': 500,
        '& .table': {
            'width': '100%',
            '& tr': {
                borderBottom: `2px solid ${colors.bg}`,
            },
        },
        '& .thead-th': {
            fontWeight: 'bold',
            background: `${colors.bgColor1} !important`,
            opacity: 0.8,
            paddingTop: 10,
            paddingBottom: 10,
            borderBottom: `1px solid ${colors.bgColor1}`,
        },
    },
    '& .form-section': {
        'height': '75vh',
        'background': colors.bgColor1,
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        '& .input-sign-head': {
            fontWeight: 'bold',
            color: colors.primary,
        },
        '& .input-con': {
            height: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
    },
    [theme.breakpoints.down('md')]: {},
}));

function createData(
    s_n,
    name,
    total_sub,
    action,
    category_id,
    status,
    category_name,
    description,
    main_cat_id
) {
    return {
        s_n,
        name,
        total_sub,
        action,
        category_id,
        status,
        category_name,
        description,
        main_cat_id
    };
}

const SubCategories = ({ categories }) => {
    // ############## Table data #################
    const rows = categories?.map((category, index) =>
        createData(
            <Typography sx={{ fontWeight: 'bold', opacity: 0.6 }}>
                {++index}
            </Typography>,
            <Typography
                sx={{
                    fontSize: 14,
                    p: 1,
                    textAlign: 'left',
                    borderRadius: 2,
                    color: colors.primary,
                    // background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                }}
            >
                {category.title}
            </Typography>,
            <Typography
                sx={{
                    fontSize: 14,
                    p: 1,
                    textAlign: 'left',
                    borderRadius: 2,
                    color: colors.primary,
                    // background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                }}
            >
                {category.category_id.category_name}
            </Typography>,
            0,
            category._id,
            category.is_active,
            category.title,
            category.category_description,
            category.category_id._id
        ),
    );

    // ############## Comp State #############
    const [categoryName, setCategoryName] = useState('');
    const [updateName, setUpdateName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [categoryID, setCategoryID] = useState('');
    const [mainCategoryID, setMainCategoryID] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);
    const [confimationDialogOpen, setConfimationDialogOpen] = useState(false);

    // ############## FUNC #################
    // => handle confimation dialog open
    const handleConfimationDialogOpen = () => {
        setConfimationDialogOpen(true);
    };

    // => handle confimation dialog close
    const handleConfimationDialogClose = () => {
        resetForm();
        setConfimationDialogOpen(false);
    };

    // => handle update category
    const handleUpdateCategory = (payload) => {
        setIsUpdate(true);
        setCategoryName(payload.name);
        setCategoryID(payload.category_id);
        setMainCategoryID(payload.main_cat_id);
        setCategoryDescription(payload.description);
    };

    // => handle delete category
    const handleDeleteCategory = (category_id, categoryName) => {
        setCategoryID(category_id);
        setUpdateName(categoryName);
        handleConfimationDialogOpen();
    };

    // => handle add subcategory
    // const handleAddSubCategory = () => {};

    // => Reset form state
    const resetForm = () => {
        setIsUpdate(false);
        setCategoryName('');
        setMainCategoryID('');
        setCategoryID('');
        setCategoryDescription('');
    };

    return (
        <>
            {/* ############# Confimation dialogy ################ */}
            <ConfirmationDialog
                noDropClose
                openDialog={confimationDialogOpen}
                handleDialogClose={handleConfimationDialogClose}
            >
                <ConfirmSubCatDelete category={updateName} category_id={categoryID} />
            </ConfirmationDialog>
            <ContentSection>
                <Grid container>
                    <Grid item sm={8} xs={12}>
                        {isEmpty(categories) ? (
                            <>
                                <NoContent
                                    message={'No sub-categories added'}
                                    height={400}
                                />
                            </>
                        ) : (
                            <Box className="categories-section">
                                {/* <Box sx={{ p: 2 }}>
                                    <Typography className="head-font section-head">
                                        Agiza store sub - categories
                                    </Typography>
                                </Box> */}
                                <Box
                                    sx={{
                                        // px: 2,
                                        // py: 2,
                                    }}
                                >
                                    <TableContainer
                                        className="table-con"
                                        component={Paper}
                                    >
                                        <Table
                                            className="table"
                                            stickyHeader
                                            size="small"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        className="head-font thead-th"
                                                        align="center"
                                                        width={10}
                                                    >
                                                        S/N
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        className="head-font thead-th"
                                                    >
                                                        Sub - category
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        className="head-font thead-th"
                                                    >
                                                        Main category
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        className="head-font thead-th"
                                                    >
                                                        Status
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        className="head-font thead-th"
                                                    >
                                                        Action
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{
                                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                                        }}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                            sx={{
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            {row.s_n}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row.total_sub}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.status === true ? (
                                                                <Box
                                                                    sx={{
                                                                        width: 15,
                                                                        height: 15,
                                                                        borderRadius: 50,
                                                                        bgcolor:
                                                                            'green',
                                                                        ml: 2,
                                                                    }}
                                                                ></Box>
                                                            ) : (
                                                                <Box
                                                                    sx={{
                                                                        width: 15,
                                                                        height: 15,
                                                                        borderRadius: 50,
                                                                        bgcolor:
                                                                            'red',
                                                                        ml: 2,
                                                                    }}
                                                                ></Box>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Box
                                                                sx={{
                                                                    ml: -6,
                                                                }}
                                                            >
                                                                <SubCategoryActionButtons
                                                                    row={row}
                                                                    handleUpdateCategory={
                                                                        handleUpdateCategory
                                                                    }
                                                                    handleDeleteCategory={
                                                                        handleDeleteCategory
                                                                    }
                                                                />
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        )}
                    </Grid>

                    {/* ######################## Form Input ###################### */}
                    <Grid item sm={4} xs={12}>
                        <CreateSubCategory
                            isUpdate={isUpdate}
                            resetForm={() => {
                                resetForm();
                            }}
                            categoryID={categoryID}
                            mainCategoryID={mainCategoryID}
                            categoryName={categoryName}
                            categoryDescription={categoryDescription}
                        />
                    </Grid>
                </Grid>
            </ContentSection>
        </>
    );
};

export default SubCategories;
